import React from "react";
import { IPO } from "../../../Model/ServerResponse";
import { IKeyValue } from "../../../Model/UI";
import "./PODetailKeyValueList.scss";
import { KeyValueList } from "../../KeyValueList/KeyValueList";

export interface IPODetailKeyValueListProp {
    po: IPO;
}

export function PODetailKeyValueList(props: IPODetailKeyValueListProp) {
    function getHeaderTableUI() {
        let _data = getKeyValueListData();
        return (
            <div className="po-header-key-value">
                <KeyValueList data={_data} />
            </div>

        );
    }


    function createKeyValue(dataArray: IKeyValue[], key: string, value: string, seperator: string) {
        if (value)
            dataArray.push({ _key: key, value, seperator })
    }
    function getAccountAssignment(dataArray: IKeyValue[], accountAssignment: string[]): void {
        if (accountAssignment?.length) {
            for (let i = 0; i < props.po.accountAssignment?.length; i++) {
                if (i === 0) {
                    createKeyValue(dataArray, "Account Assignment", props.po.accountAssignment[0], ":");
                } else {
                    createKeyValue(dataArray, "", props.po.accountAssignment[i], "&nbsp;");
                }
            }
        }
    }
    function getKeyValueListData(): IKeyValue[] {
        let poAttachmentCount = props.po.poAttachmentCount;
        let dataArray: IKeyValue[] = [];
        if (props.po.poNumber)
            createKeyValue(dataArray, "Purchase Order", props.po.poNumber, ":");
        if (props.po.approvalSystemId === "PO-Optimus")
            createKeyValue(dataArray, "PO Type", props.po.docTypeDescription + " " + "(" + props.po.poType + ")", ":");
        createKeyValue(dataArray, "Purchasing Group", props.po.purchasingGroup, ":");
        createKeyValue(dataArray, "Purchase requisition No", props.po.requisitionNumber, ":");
        if (props.po.approvalSystemId === "PO-ITT")
            createKeyValue(dataArray, "Vendor Code", props.po.vendorId, ":");
        createKeyValue(dataArray, "Requisitioner Name", props.po.requisitionerName, ":");
        getAccountAssignment(dataArray, props.po.accountAssignment)
        if (props.po.approvalSystemId === "PO-ITT")
            createKeyValue(dataArray, "Plant", props.po.organization, ":");
        createKeyValue(dataArray, "Payment Term", props.po.paymentTerms, ":");
        createKeyValue(dataArray, "Incoterms", props.po.incoTerms, ":");
        if (props.po.approvalSystemId === "PO-Optimus")
            createKeyValue(dataArray, "Vendor", props.po.vendorName, ":");
        createKeyValue(dataArray, "Company Code", props.po.companyCode, ":");
        if (poAttachmentCount != "0" && props.po.approvalSystemId === "PO-Optimus") {
            const attachmentCount = props.po.poAttachmentCount.length === 1 ? "0" + props.po.poAttachmentCount : props.po.poAttachmentCount;
            createKeyValue(dataArray, "PO Attachment Count", attachmentCount, ":");
        };
        return dataArray;
    }

    return getHeaderTableUI();
}

