import React from "react";
import {
  IEjourneyCommentData,
  IEjourneyTab,
} from "../../../Model/ServerResponse";
import { CommentsTemplate } from "../../CommentsTemplate/CommentsTemplate";
import { IComment } from "../../../Model/UI/IComment";
import "./EJourneyComments.scss";
import Utility from "../../../common/utilities";

export interface IEJCommentsProp {
  ejTabData: IEjourneyTab | undefined;
}

export function EJourneyComments(props: IEJCommentsProp) {
  let commentDataModel: IComment[] = [];

  return <div className="ej-comments-tab"data-testid="ejourney-comments">{tabUI()}</div>;

  function tabUI() {
    if (props.ejTabData && !Utility.isArrayEmpty(props.ejTabData.data)) {
      const commentData: IEjourneyCommentData[] = props.ejTabData
        .data as IEjourneyCommentData[];
      commentData.forEach((cd) => {
        let dateItem = cd;
        commentDataModel.push({
          label1: dateItem.date,
          label2: dateItem.commentBy,
          label3: dateItem.comment,
        });
      });

      return (
        <CommentsTemplate commentData={commentDataModel}></CommentsTemplate>
      );
    } else {
      return <div className="no-comments">No Comments</div>;
    }
  }
}
