export class SessionStorageService {

    private static instance: SessionStorageService;
    EmloyeeGIN!: string;

    private constructor() {
    }

    public static getInstance(): SessionStorageService {
        if (!this.instance) {
            this.instance = new SessionStorageService();
        }

        return this.instance;
    }

    private parseValue(value: string | null): string {
        return value ? value : "";
    }

    public static clear(): void {
        sessionStorage.clear();
    }

    //Keys
    private readonly TransactionTokenKey = "TransactionToken";
    private readonly DebugAliasKey = "DebugAlias";
    private readonly ConsiderFetchingDisabledApprovalOperationsKey = "ConsiderFetchingDisabledApprovalOperations";
    private readonly TeamsAppKey = "IsTeamsApp";
    private readonly TeamsUserKey = "TeamsUser";
    private readonly TeamsUserDisplayNameKey = "TeamsUserDisplayName";

    //Transaction Token
    get transactionToken(): string {
        return this.parseValue(sessionStorage.getItem(this.TransactionTokenKey));
    }
    set transactionToken(value: string) {
        if (!value) return;
        sessionStorage.setItem(this.TransactionTokenKey, value);
    }

    //Debug Alias
    get debugAlias(): string {
        //return "malandkar";
        return this.parseValue(sessionStorage.getItem(this.DebugAliasKey));
    }
    set debugAlias(value: string) {
        // if(!value)return;
        sessionStorage.setItem(this.DebugAliasKey, value);
    }

    //ConsiderFetchingDisabledApprovalOperations
    get ConsiderFetchingDisabledApprovalOperations(): boolean {
        let result = this.parseValue(sessionStorage.getItem(this.ConsiderFetchingDisabledApprovalOperationsKey));
        return result.toLowerCase() === "true";
    }
    set ConsiderFetchingDisabledApprovalOperations(value: boolean) {
        let result = value ? "true" : "false";
        sessionStorage.setItem(this.ConsiderFetchingDisabledApprovalOperationsKey, result);
    }


     //Teams App
     get IsTeamsApp(): boolean {
        let result = this.parseValue(sessionStorage.getItem(this.TeamsAppKey));
        return result.toLowerCase() === "true";
    }
    set IsTeamsApp(value: boolean) {
        let result = value ? "true" : "false";
        sessionStorage.setItem(this.TeamsAppKey, result);
    }

    //Teams User
    get TeamsUser(): string {
        let result = this.parseValue(sessionStorage.getItem(this.TeamsUserKey));
        return result;
    }
    set TeamsUser(value: string) {
        let result = value;
        sessionStorage.setItem(this.TeamsUserKey, result);
    }

    //Teams User Name
    get TeamsUserDisplayName(): string {
        let result = this.parseValue(sessionStorage.getItem(this.TeamsUserDisplayNameKey));
        return result;
    }
    set TeamsUserDisplayName(value: string) {
        let result = value;
        sessionStorage.setItem(this.TeamsUserDisplayNameKey, result);
    }
}