import { IKeyValue, ISystemCardBaseProp } from "Model/UI";
import React from "react";
import { Card2 } from "Components2/CardTemplates/Card2/Card2";
import { IDLP } from "Model/ServerResponse";
import Utility from "common/utilities";

// Its important that we import local scss after Card1/2/3 so the styles are overidden in correct way

import "./DLPCard.scss";

export interface IDLPCardProp extends ISystemCardBaseProp {
  dlp: IDLP;
}

export function DLPCard(props: IDLPCardProp) {
  let keyValues: IKeyValue[] = [
    {
      _key: "Equipment",
      value: props.dlp?.equipment,
      seperator: ":",
    },
  ];
  function getCardUI()  {
    return (
      <Card2
        label1={props.dlp.approvalSystemId}
        label2={props.dlp.title}
        label3={props.dlp.date}
        chip1={Utility.stringToPascalCase(props.dlp.risk)}
        keyValue1={keyValues}
        userDetails={{
          userId: props.dlp.requestorID,
          userCardName: props.dlp.requestor,
          userOrganization: props.dlp.locationName,
        }}
        className={Utility.mergeCssClassNames([
          "dlp-card",
          getChipStyle(props.dlp.risk),
        ])}
      ></Card2>
    );
  }

  function getChipStyle(chipText: string | null): string {
    let result = "";

    if (!chipText) {
      return "chip1-hidden";
    }
    switch (chipText.toLowerCase()) {
      case "high":
        result = "chip-1-high";
        break;
      case "medium":
        result = "chip-1-medium";
        break;
      case "low":
        result = "chip-1-low";
        break;
      case "insignificant":
        result = "chip-1-insignificant";
        break;
    }
    return result;
  }

  return getCardUI();
}
