import { IServerResponse } from "Model/ServerResponse";
import { ServicesUtility } from "./servicesUtility";

export class LoggingService {
    private static instance: LoggingService;
  
    private constructor() {}
  
    public static getInstance(): LoggingService {
      if (!this.instance) {
        this.instance = new LoggingService();
      }
  
      return this.instance;
    }

    public async logError(
        screenId: string, approvalSystem: string, errorMsg: string, errorStack ?: string
      ): Promise<IServerResponse<void>> {
        let token = await ServicesUtility.getGraphToken();
        if (!token) {
          throw new Error('Failed to get authentication token');
        }
        let url = ServicesUtility.prefixBaseUrl("/api/errorlog");

        let errorBody = {
          "screenId": screenId,
          "approvalSystem": approvalSystem,
          "errorMessage": errorMsg,
          "errorDetails": errorStack,
        }

        let requestOptions = {
          method: "POST",
          headers: {
            ...ServicesUtility.getDefaultHeaders(token),
          },
          body: JSON.stringify(errorBody)
        };
    
        let result: IServerResponse<void> = {
          statusCode: 0,
          payload: null,
          errorMessage: "",
        };

        return ServicesUtility.timedRequest(
          ServicesUtility.Min5,
          url,
          requestOptions
        ).then(
          async (response) => {
            try {
              result.statusCode = response.status;
              return result;
            } catch (error) {
              result.statusCode = response.status;
              result.errorMessage = response.statusText;
              return result;
            }
          },
          (error: Error) => {
            result.errorMessage = error.message;
            return result;
          }
        );
      }
}