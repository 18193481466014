import React from "react";
import { ActionModal } from "../ActionModal/ActionModal";
import "./ApproveModal.scss";

export interface IApproveModalProp {
  open: boolean;
  isCommentMandatory?: boolean;
  requestDescription?: string | React.ReactNode;
  maxCommentChar?: number;
  onCancel?: (e: any) => void;
  onApprove: (e: any, comment: string) => void;
}

export function ApproveModal(props: IApproveModalProp) {
  return (
    <ActionModal
      open={props.open}
      title={"Confirmation"}
      question={"Are you sure you want to approve?"}
      requestDescription={props.requestDescription}
      isCommentMandatory={props.isCommentMandatory}
      maxCommentChar={props.maxCommentChar}
      primaryActionLabel={"Approve"}
      onCancel={props?.onCancel}
      onPrimaryAction={props.onApprove}
      className="approve-modal"
    ></ActionModal>
  );
}
