import { Grid } from "@material-ui/core";
import Utility from "../../../common/utilities";
import React from "react";
import { IKeyValue, IHeaderTemplateBaseProp } from "../../../Model/UI";
import { KeyValue } from "../../../Components2/KeyValue/KeyValue";
import UserDetails from "../../UserDetails/UserDetails";

import "./Header2.scss";
import { Chip } from "../../Chip/Chip";

export interface IHeader2Prop extends IHeaderTemplateBaseProp {
  label1: string;
  label2: string;
  keyValue1: IKeyValue;
  chip1: string;
}

/*
UI placement:

    label1          
    label2
    userDetails     chip1
                    keyValue1       
*/

export function Header2(props: IHeader2Prop)  {
  function getHeaderUI()  {
    return (
      <div
        data-testid="header-2"
        className={Utility.getClassNamesFromProps("header-2", props.className)}
      >
        {/* 1st row */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="row-1"
        >
          <div className="dot"></div>
          <p className="label-1">{props.label1}</p>
          <div className="spacer"></div>
        </Grid>

        {/* 2nd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-2"
        >
          <p className="label-2">{props.label2}</p>
        </Grid>

        {/* 3rd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-3"
        >
          <UserDetails
            token={"test"}
            userId={props.userDetails.userId}
            userCardName={props.userDetails.userCardName}
            userOrganization={props.userDetails.userOrganization}
          ></UserDetails>
          <div className="spacer"></div>
          <div className="col-1">
            <Chip className="chip-1" label={props.chip1}></Chip>
            <KeyValue
              className="key-value-1"
              _key={props.keyValue1._key}
              value={props.keyValue1.value}
              seperator={":"}
            ></KeyValue>
          </div>
        </Grid>
      </div>
    );
  }

  return getHeaderUI();
}
