import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { IDefaultUIProp } from "../../../Model/UI";
import * as React from "react";
import "./AttachmentModal.scss";
import { IAttachmentData } from "Model/ServerResponse";
import { useEffect, useState } from "react";
import b64toBlob from "b64-to-blob";
import { SessionStorageService } from "Services/SessionStorageService";
import { isAndroid, isIOS } from "react-device-detect";
import ExcelViewer from "./ExcelViewer";

export interface IAttachmentModalProp extends IDefaultUIProp {
  onClose?: (e: any) => void;
  open: boolean;
  selectedFilename: string;
  selectedFileID: string;
  downloadfromURL?: boolean;
  getAttachmentData: (
    fileID: string,
    fileName: string
  ) => Promise<IAttachmentData>;
}

export function AttachmentModal(props: IAttachmentModalProp)  {
  const [attachmentData, setattachmentData] = useState<IAttachmentData | null>(
    null
  );

  const [xlsUrl, setXlsUrl] = useState("");

  useEffect(() => {
    setXlsUrl(props.selectedFilename);
  }, [props.selectedFilename]);

  async function getAttachmentData(_fileName: string, _fileID: string) {
    let fileNameToUse = _fileName;
    let fileIDToUse = _fileID;
    if (props.downloadfromURL) {
      fileNameToUse = props.selectedFilename;
      fileIDToUse = props.selectedFileID;
      setpdfDownloadable(true);
      await getFileFromURl(fileNameToUse, fileIDToUse);
    } else {
      let result = await props.getAttachmentData(fileNameToUse, fileIDToUse);
      setattachmentData(result);
    }
  }
  const [pdfDownloadable, setpdfDownloadable] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    setOpen(props.open);
    if (props.open) {
      getAttachmentData(props.selectedFilename, props.selectedFileID);
    } else {
      setattachmentData(null);
    }
  }, [props.open]);

  return (
    <Dialog
      maxWidth="md"
      data-testid="attachment-modal"
      className="attachment-modal"
      open={open}
      onClose={handleClose}
    >
      <div className="attachment-header">
        <p className="attachment-title">{props.selectedFileID}</p>
        <div className="spacer"></div>
        <Button className="download-btn" onClick={onDownloadFile}>
          Download
        </Button>
        <IconButton data-testid="close"className="close-btn" onClick={handleClose}>
          <CancelIcon />
        </IconButton>
      </div>
      <Divider className="divider-1" variant="fullWidth" />
      <DialogContent className="attachment-content">
        {getAttachmentUI()}
      </DialogContent>
    </Dialog>
  );

  function handleClose(e: any) {
    setOpen(false);
    if (props.onClose) {
      props.onClose(e);
    }
  }

  function onDownloadFile() {
    if (
      SessionStorageService.getInstance().IsTeamsApp &&
      (isAndroid || isIOS)
    ) {
      //File downloading is not supported on Android Teams
      alert("Downloading file is not supported on this device");
    } else {
      const contentType = attachmentData?.fileType;
      const b64Data = attachmentData?.fileData;
      if (b64Data) {
        const downloadHref = props.downloadfromURL ? b64Data : 'data:' + contentType + ';base64,' + b64Data;
        const downloadLink = document.createElement("a");
        downloadLink.href = downloadHref;
        downloadLink.download = props.selectedFileID; // Use the filename from props
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
     else {
        alert("Please wait before the file loads");
      }
    }
  }

  function getAttachmentUI()  {
    // switch case for file type and return the appropriate UI
    const filePath = props.downloadfromURL
      ? attachmentData?.fileData
      : `data:${attachmentData?.fileType};base64,${attachmentData?.fileData}`;
    switch (attachmentData?.fileType) {
      case "image/jpeg":
      case "image/png":
      case "image/jpg":
        return <img data-testid="img-frame"className="img-frame" src={filePath} alt="" />;
      case "application/pdf":
        return loadIFrame(filePath);
      case "video/mp4":
      case "video/avi":
      case "audio/mp3":
      case "audio/wav":
      case "audio/ogg":
      case "audio/flac":
      case "audio/aac":
      case "audio/mpeg":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return renderXLSExcelFile(filePath);
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
      case "application/vnd.ms-excel":
      case "application/yaml":
      case "text/html":
      case "application/json":
      case "application/javascript":
      case "text/csv":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/octet-stream":
      case "application/msexcel":
      case "text/htm":
      case "plain/text":
      case "application/xml":
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
      case "application/vnd.ms-word.document.macroEnabled.12":
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "application/vnd.openxmlformats-officedocument.presentationml.template":
      case "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
      case "application/vnd.openxmlformats-officedocument.presentationml.slide":
      case "application/rtf":
      case "application/zip":
      case "image/gif":
      case "image/bmp":
      case "image/x-pcx":
      case "image/vnd.adobe.photoshop":
      case "text/plain":
        return (
          <div data-testid="no-support"className="no-support">
            <div className="not-supported-text">
              <img
                className="file-image"
                alt=""
                src="./Attachment/File_icn.svg"
              ></img>
              <div className="not-supported">
                <p>File not supported for preview</p>
                <p>Please download the file to view</p>
              </div>
            </div>
          </div>
        );
      default:
        return <CircularProgress data-testid="circular-progress" className="circular-progress" />;
    }
  }

  async function getFileFromURl(_fileName: string, _fileID: string) {
    let attach: IAttachmentData = {
      fileData: "",
      fileName: "",
      fileType: "",
      id: "",
    };
    const blob: any = await getBase64(_fileName, _fileID);
    if (blob) {
      const fileext = _fileID.split(".").pop();
      const imgext = ["jpeg", "png", "jpg"];
      if (fileext?.toLowerCase() === "pdf") {
        if (blob.size > 1400000) {
          setpdfDownloadable(false);
        }
        attach.fileType = "application/pdf";
      } else if (fileext && imgext.includes(fileext?.toLowerCase())) {
        attach.fileType = "image/png";
      } else if (fileext?.toLowerCase() === "xlsx") {
        attach.fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (fileext?.toLowerCase() === "xls") {
        attach.fileType = "application/vnd.ms-excel";
      } else {
        attach.fileType = "application/octet-stream";
      }
      attach.fileData = blob;
      setattachmentData(attach);
    }
  }

  async function getFileFromUrl(url: string, fileName: string) {
    const blob = await fetch(url)
      .then((response) => {
        return response.blob();
      })
      .catch((error) => {
        throw error;
      });
    if (fileName.includes(".pdf") || props.selectedFileID.includes(".pdf")) {
      if (blob.size > 1400000) {
        setpdfDownloadable(false);
      }

      let metadata = {
        type: "application/pdf",
      };
      return new File([blob], fileName, metadata);
    }
    return new File([blob], fileName);
  }

  async function getBase64(filePath: any, filename: any) {
    if (filePath === null) {
      return null;
    }
    const file = await getFileFromUrl(filePath, filename);
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result as any;
        resolve(baseURL);
      };
    });
  }

  function loadIFrame(filePath: any)  {
    if (pdfDownloadable) {
      return (
        <iframe
          title="pdfIframe"
          data-testid="pdf-iframe"
          className="pdf-iframe"
          loading="lazy"
          src={filePath}
        ></iframe>
      );
    } else {
      return (
        <div className="no-support">
          <div className="not-supported-text">
            <img
              className="file-image"
              alt=""
              src="./Attachment/File_icn.svg"
            ></img>
            <p className="not-supported">File not supported for preview</p>
            <p>Please download the file to view</p>
          </div>
        </div>
      );
    }
  }

  function renderXLSExcelFile(filePath: any) {

    if (props.downloadfromURL) {
      return (
        <div data-testid="doc-viewer-container"className="doc-viewer-container">
          <ExcelViewer
            attachmentData={attachmentData}
            excelName={props.selectedFileID}
            excelLink={xlsUrl}
          />
        </div>
      );
    } else {
      const contentType = attachmentData?.fileType;
      const b64Data: string = attachmentData?.fileData ?? "";
      const blob = props.downloadfromURL
        ? b64Data
        : b64toBlob(b64Data, contentType);
      console.log(blob);
      return <ExcelViewer attachmentData={attachmentData} excelBlob={blob} />;
    }
  }
}
