import React from "react";
import { ITabData } from "Model/UI";
import { DetailTabsComponent } from "Components2/DetailTabsComponent/DetailTabsComponent";
import { IWatershed } from "Model/ServerResponse/IWatershed";
import { WATERSHED_DETAILS_TAB_ID } from "../WatershedUtil";
import { IWatershedTab } from "Model/ServerResponse/IWatershedTab";
import { WatershedDetails } from "../WatershedDetails/WatershedDetails";

export interface IWatershedTabsProp {
  watershed: IWatershed;
}

export function WatershedTabs(props: IWatershedTabsProp) {
  function getTabsUI() {
    const data = getTabsData(props.watershed.tabs);
    return <DetailTabsComponent tabs={data}></DetailTabsComponent>;
  }

  function getTabsData(tabs: IWatershedTab[]): ITabData[] {
    let tdata: ITabData[] = [];

    tabs.forEach((tab: IWatershedTab) => {
      let tabInfo: ITabData = {
        tabId: "",
        title: tab.title,
        imageSrc: "",
        count: tab.count,
      };

      if (tab.tabId === WATERSHED_DETAILS_TAB_ID) {
        tabInfo.tabId = WATERSHED_DETAILS_TAB_ID;
        tabInfo.imageSrc = "./DetailTab/details_icn.svg";
        tabInfo.getTabUI = ItemsUI;
      }
      
      tdata.push(tabInfo);
    });
    return tdata;
  }

  function ItemsUI() {
    return (
      <WatershedDetails watershedDetails={props.watershed}></WatershedDetails>
    );
  }

  return getTabsUI();
}
