import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import Utility from "common/utilities";
import { RequestStatus } from "Model/Enums";
import {
  IGetPendingApprovals,
  IServerResponse,
  IUpdateProfileRequest,
} from "Model/ServerResponse";
import {
  IApprovalOperationEditDict,
  IApprovalSystemEntity,
  IEntity,
} from "Model/UI";
import { ApprovalService, SystemSelectionService } from "Services";
import { LoggingService } from "Services/LoggingService";
import { SetScreenLoader } from "Store/Common/CommonSlice";
import { IAppState } from "Store/store";
import {
  AddNewApprovalOperationAddedByUser,
  RemoveApprovalOperationRemovedByUser,
  UpdateMapsWithSystemProfileResponse,
} from "./UserSystemSlice";
import { mainModule } from "process";

export interface IUserSystemModalState {
  approvalOperationsEditMap: IApprovalOperationEditDict; //key : approvalOperationKey, value : {isChecked, isEnabled}
  status: RequestStatus;
  refreshProfileStatus: RequestStatus;
}

const initialState: IUserSystemModalState = {
  approvalOperationsEditMap: {},
  status: RequestStatus.Idle,
  refreshProfileStatus: RequestStatus.Idle,
};

export const SetupUserSystemModalData = createAsyncThunk(
  "UserSystemModalSlice/setupUserSystemModalData",
  async (_, thunkApi): Promise<void> => {
    let loggingService = LoggingService.getInstance();
    try {
      let dispatch = thunkApi.dispatch;
      let userSystemState = (thunkApi.getState() as IAppState).UserSystem;

      let selfActions = UserSystemModalSlice.actions;

      let _aoKeys = userSystemState.approvalOperationKeys;
      let _aoMap = userSystemState.approvalOperationsMap;
      let _asMap = userSystemState.approvalSystemsMap;

      let tempEditDict: IApprovalOperationEditDict = {};

      let selectedApprovalOperationKeys =
        Utility.getApprovalOperationsHavingAtleastOneSelected(
          _aoKeys,
          _aoMap,
          _asMap
        );
      let enabledApprovalOperationKeys =
        Utility.getApprovalOperationsHavingAtleastOneEnabled(
          _aoKeys,
          _aoMap,
          _asMap
        );


      const selectedCount = Utility.calculateSelectedSystemsCount(_asMap);
      
      _aoKeys.forEach((esaok) => {
        let _isChecked =
        selectedCount > 5
            ? false
            : selectedApprovalOperationKeys.includes(esaok);
        let _isEnabled = enabledApprovalOperationKeys.includes(esaok);

        tempEditDict[esaok] = {
          isChecked: _isChecked,
          isEnabled: _isEnabled,
        };
      });

      //Update self state
      dispatch(selfActions.setApprovalOperationsEditMap(tempEditDict));
    } catch (err) {
      await loggingService.logError(
        "UserSystemModalSlice : SetupUserSystemModalData thunk",
        "",
        JSON.stringify(err, Object.getOwnPropertyNames(err)),
        JSON.stringify(err, Object.getOwnPropertyNames(err))
      );
    }
  }
);

export const UpdateEditedApprovalOperations = createAsyncThunk(
  "UserSystemModalSlice/updateEditedApprovalOperations",
  async (_, thunkApi): Promise<void> => {
    let loggingService = LoggingService.getInstance();
    try {
      let dispatch = thunkApi.dispatch;
      let state = (thunkApi.getState() as IAppState).UserSystemModal;
      let userSystemState = (thunkApi.getState() as IAppState).UserSystem;

      let selfActions = UserSystemModalSlice.actions;
      let systemSelectionService = SystemSelectionService.getInstance();

      let approvalOperationKeys = userSystemState.approvalOperationKeys;

      // Create request body
      let reqBody: IUpdateProfileRequest[] = [];
      let questAdded = false;

      approvalOperationKeys.forEach((aok) => {
        let approvalSystemKeys =
          userSystemState.approvalOperationsMap[aok].approvalSystems;

        approvalSystemKeys.forEach((ask) => {
          // Patch for Quest starts
          if (ask === "Exemption" || ask === "MOC") {
            if (!questAdded) {
              questAdded = true;
              reqBody.push({
                activity: "QuestChange",
                system: "Quest",
                isSelected: state.approvalOperationsEditMap[aok].isChecked
              });
            }
          }
          // Patch for Quest ends
          else {
            reqBody.push({
              activity: aok,
              system: ask,
              isSelected: state.approvalOperationsEditMap[aok].isChecked,
            });
          }
        });
      });

      dispatch(SetScreenLoader(true));
      dispatch(selfActions.setUserSystemModalStatus(RequestStatus.Loading));

      let response = await systemSelectionService.updateUserProfile(reqBody);

      dispatch(SetScreenLoader(false));

      // Update approvalSystemsMap from success systems
      if (response && response.statusCode === 200 && response.payload) {
        dispatch(selfActions.setUserSystemModalStatus(RequestStatus.Success));
        dispatch(UpdateMapsWithSystemProfileResponse(response.payload));

        // Create a system map based on update system response
        let responseASMap: IEntity<IApprovalSystemEntity> = {};
        response.payload.systems.forEach((s) => {
          responseASMap[s.system] = {
            name: s.system,
            isEnabled: s.isEnabled,
            isSelected: s.isSelected,
            count: 0,
            error: "",
          };
        });

        approvalOperationKeys.forEach((aok) => {
          let approvalSystemKeys: string[];
          if (aok === "QuestChange") {
            approvalSystemKeys = ["Quest"];
          } else {
            approvalSystemKeys =
              userSystemState.approvalOperationsMap[aok].approvalSystems;
          }
          let isAdded = false;
          approvalSystemKeys.forEach((ask) => {
            isAdded = isAdded || responseASMap[ask].isSelected;
          });

          // Add approval operation to master maps & keys, and start pending call
          if (isAdded) {
            dispatch(
              AddNewApprovalOperationAddedByUser({
                approvalOperationKeyAdded: aok,
                approvalOperationsMap: userSystemState.approvalOperationsMap,
                approvalSystemsMap: responseASMap,
              })
            );
          }
          // Update approval systems to have selected = false
          else {
            dispatch(
              RemoveApprovalOperationRemovedByUser({
                approvalOperationKeyRemoved: aok,
              })
            );
          }
        });
      } else {
        dispatch(selfActions.setUserSystemModalStatus(RequestStatus.Failed));
      }
    } catch (err) {
      await loggingService.logError(
        "UserSystemModalSlice : UpdateEditedApprovalOperations thunk",
        "",
        JSON.stringify(err, Object.getOwnPropertyNames(err)),
        JSON.stringify(err, Object.getOwnPropertyNames(err))
      );
    }
  }
);

export const ToggelIsCheckedForApprovalOperation = createAsyncThunk(
  "UserSystemModalSlice/toggelIsCheckedForApprovalOperationThunk",
  async (
    {
      approvalOperationKey,
      isChecked,
    }: { approvalOperationKey: string; isChecked: boolean },
    thunkApi
  ): Promise<void> => {
    let loggingService = LoggingService.getInstance();
    try {
      let dispatch = thunkApi.dispatch;
      let state = (thunkApi.getState() as IAppState).UserSystemModal;
      let userSystemState = (thunkApi.getState() as IAppState).UserSystem;
      let selfActions = UserSystemModalSlice.actions;

      let toggleValue = isChecked;

      //Count the number of approval operations checked
      let numberOfCheckedApprovalOperations = 0;
      userSystemState.approvalOperationKeys.forEach((aoek) => {
        if (state.approvalOperationsEditMap[aoek].isChecked) {
          numberOfCheckedApprovalOperations += 1;
        }
      });

      // Restriction: Do not allow more than 5 approval operations to be checked
      if (isChecked && numberOfCheckedApprovalOperations >= 5) {
        return; // Exit early without dispatching the toggle action
      }

      // Restriction: Do not allow to uncheck all approval operations
      if (!isChecked && numberOfCheckedApprovalOperations === 1) {
        toggleValue = true;
      }

      dispatch(
        selfActions.toggelIsCheckedForApprovalOperation({
          approvalOperationKey: approvalOperationKey,
          isChecked: toggleValue,
        })
      );
    } catch (err) {
      await loggingService.logError(
        "UserSystemModalSlice : ToggelIsCheckedForApprovalOperation thunk",
        "",
        JSON.stringify(err, Object.getOwnPropertyNames(err)),
        JSON.stringify(err, Object.getOwnPropertyNames(err))
      );
    }
  }
);

export const ResetProfileCache = createAsyncThunk(
  "UserSystemModalSlice/resetProfileCache",
  async (_, thunkApi) => {
    let loggingService = LoggingService.getInstance();
    try {
      let dispatch = thunkApi.dispatch;
      let userSystemState = (thunkApi.getState() as IAppState).UserSystem;

      let selfActions = UserSystemModalSlice.actions;

      let _aoKeys = userSystemState.approvalOperationKeys;

      dispatch(selfActions.setResetProfileStatus(RequestStatus.Loading));

      //Call reset cache api
      SystemSelectionService.getInstance()
        .resetCache()
        .then((response) => {
          if (response.statusCode === 200) {
            let approvalService = ApprovalService.getInstance();
            let promises: Promise<any>[] = [];
            _aoKeys.forEach(async (aok) => {
              promises.push(
                approvalService.getPendingApprovals<IGetPendingApprovals<any>>(
                  `api/approvals/${aok}`
                )
              );
            });

            return Promise.all(promises)
              .then(
                (responses: IServerResponse<IGetPendingApprovals<any>>[]) => {
                  let atLeastOneResponseFailed = false;
                  responses.forEach((_r) => {
                    if (_r.statusCode != 200) {
                      atLeastOneResponseFailed = true;
                    }
                  });
                  if (atLeastOneResponseFailed) {
                    dispatch(
                      selfActions.setResetProfileStatus(RequestStatus.Failed)
                    );
                  } else {
                    dispatch(
                      selfActions.setResetProfileStatus(RequestStatus.Success)
                    );
                  }
                }
              )
              .catch(() => {
                dispatch(
                  selfActions.setResetProfileStatus(RequestStatus.Failed)
                );
              });
          }
        })
        .catch(() => {
          dispatch(selfActions.setResetProfileStatus(RequestStatus.Failed));
        });
    } catch (err) {
      await loggingService.logError(
        "UserSystemModalSlice : ResetProfileCache thunk",
        "",
        JSON.stringify(err, Object.getOwnPropertyNames(err)),
        JSON.stringify(err, Object.getOwnPropertyNames(err))
      );
      return null;
    }
  }
);

const UserSystemModalSlice = createSlice({
  name: "UserSystemModalSlice",
  initialState: initialState,
  reducers: {
    setApprovalOperationsEditMap(
      state: IUserSystemModalState,
      action: PayloadAction<IApprovalOperationEditDict>
    ) {
      state.approvalOperationsEditMap = action.payload;
    },
    toggelIsCheckedForApprovalOperation(
      state: IUserSystemModalState,
      action: PayloadAction<{
        approvalOperationKey: string;
        isChecked: boolean;
      }>
    ) {
      state.approvalOperationsEditMap[action.payload.approvalOperationKey] = {
        ...state.approvalOperationsEditMap[action.payload.approvalOperationKey],
        isChecked: action.payload.isChecked,
      };
    },
    setUserSystemModalStatus(
      state: IUserSystemModalState,
      action: PayloadAction<RequestStatus>
    ) {
      state.status = action.payload;
    },
    setResetProfileStatus(
      state: IUserSystemModalState,
      action: PayloadAction<RequestStatus>
    ) {
      state.refreshProfileStatus = action.payload;
    },
  },
});

//Selector
export const UserSystemModalSelector = (state: IAppState) =>
  state.UserSystemModal;
export const ApprovalOperationsEditMapSelector = createSelector(
  UserSystemModalSelector,
  (details) => details.approvalOperationsEditMap
);
export const UserSystemModalStatusSelector = createSelector(
  UserSystemModalSelector,
  (details) => details.status
);
export const ResetProfileStatusSelector = createSelector(
  UserSystemModalSelector,
  (details) => details.refreshProfileStatus
);

export const UserSystemModalReducer = UserSystemModalSlice.reducer;
