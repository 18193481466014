import { IPO, IPOItemData } from "../../../Model/ServerResponse";
import { ISystemDetailsCardBaseProp } from "../../../Model/UI";
import React from "react";
import { useSelector } from "react-redux";
import {
  GetApprovalDetails,
  MultiSelectedApprovalsSelector,
  PendingApprovalsStatusSelector,
  SelectedApprovalDetailErrorSelector,
  SelectedApprovalDetailsSelector,
  SelectedApprovalDetailStatusSelector,
  SelectedApprovalSelector,
  setSelectedApprovalDetails,
} from "../../../Store/PO/POSlice";
import { POTabs } from "../../PO/POTabs/POTabs";
import { POHeader } from "../POHeader/POHeader";
import { POChangeLogIndicator } from "../POChangeLogIndicator/POChangeLogIndicator";
import "./PODetailsCard.scss";
import { useAppDispatch } from "../../../Store/store";
import { RequestStatus } from "../../../Model/Enums";
import { Message1 } from "../../../Components2/MessageTemplates/Message1/Message1";
import { DetailSkeleton1 } from "../../../Components2/Skeletons/DetailSkeletons/DetailSkeleton1/DetailSkeleton1";
import { PODetailKeyValueList } from "../PODetailKeyValueList/PODetailKeyValueList";
import { POBudgetReport } from "../POBIReport/POBudgetReport";
import { msalInstance } from "authProvider";
import { SessionStorageService } from "Services";

export interface IPODetailsCardProp<T> extends ISystemDetailsCardBaseProp<T> {}

export function PODetailsCard(props: IPODetailsCardProp<IPO>) {
  let appDispatch = useAppDispatch();

  let selectedApproval = useSelector(SelectedApprovalSelector);
  let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);

  let selectedApprovalDetails = useSelector(SelectedApprovalDetailsSelector);
  let selectedApprovalDetailsStatus = useSelector(
    SelectedApprovalDetailStatusSelector
  );
  let selectedApprovalDetailsError = useSelector(
    SelectedApprovalDetailErrorSelector
  );

  let pendingApprovalsStatus = useSelector(PendingApprovalsStatusSelector);
  const pobudget: IPO = selectedApprovalDetails as IPO;
  const poPlantCode = pobudget ? pobudget.plant : 0;
  const poAssignment = pobudget?.accountAssignment;

  //Re-render
  React.useEffect(() => {
    appDispatch(setSelectedApprovalDetails(null));
    if (selectedApproval) {
      appDispatch(GetApprovalDetails());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApproval]);

  return (
    <div className="po-details-card">{detailsUI()}</div>
  );

  function getMaterialNumbers(selectedApprovalDetails: any): string[] {
    const pobudget: IPO = selectedApprovalDetails as IPO;
    const materialNumbers: string[] = [];

    function hasPOBudgetData(){
      if (pobudget?.tabs) {
        for (const tab of pobudget.tabs)
        hasTabData(tab);
    }
  }
    function hasTabData(tab:any){
      if (tab.data) {
        for (const item of tab.data as IPOItemData[])
        hasItemMaterialNumber(item);

    }
  }
    function hasItemMaterialNumber(item: IPOItemData){
      if (
        item.materialNumber &&
        item.materialNumber.trim() !== "" &&
        (materialNumbers.length === 0 ||
          item.materialNumber !==
            materialNumbers[materialNumbers.length - 1])
      ) {
        materialNumbers.push(item.materialNumber);
      }
    }
hasPOBudgetData()
    

    return materialNumbers;
  }


  function getMaterialNumberOfFirstLineItem(
    selectedApprovalDetails: any
  ): string {
    const pobudget: IPO = selectedApprovalDetails as IPO;

    if (pobudget?.tabs) {
      for (const tab of pobudget.tabs) {
        if (tab.data && tab.data.length > 0) {
          const item = tab.data[0] as IPOItemData;
          if (item.materialNumber && item.materialNumber.trim() !== "") {
            return item.materialNumber;
          }
        }
      }
    }

    return "";
  }

  function getProfitCenterFirstLineItem(selectedApprovalDetails: any): string {
    const tabs = selectedApprovalDetails.tabs;
    for (const tab of tabs) {
      if (tab.tabId === "PO-Items") {
        const data = tab.data;
        if (data.length > 0 && "profitCenter" in data[0]) {
          return data[0].profitCenter;
        }
      }
    }
    return "";
  }

  function detailsUI(){
    if (
      selectedApprovalDetailsStatus === RequestStatus.Loading ||
      (pendingApprovalsStatus === RequestStatus.Loading && !selectedApproval)
    ) {
      return loadingUI();
    } else if (selectedApprovalDetailsStatus === RequestStatus.Failed) {
      return errorUI();
    } else if (!selectedApproval) {
      return emptyIndicatorUI();
    }

    if (selectedApprovalDetails) {
      const pobudget: IPO = selectedApprovalDetails as IPO;
      return (
        <>
          <div className="po-details-header">
            <POHeader po={selectedApprovalDetails as IPO}></POHeader>
            <div className="dividerDiv"></div>
          </div>
          <div className="po-details-content">
            <POChangeLogIndicator
              po={selectedApprovalDetails as IPO}
            ></POChangeLogIndicator>
            <PODetailKeyValueList
              po={selectedApprovalDetails as IPO}
            ></PODetailKeyValueList>
            <POTabs po={selectedApprovalDetails as IPO}></POTabs>
          </div>
          {selectedApprovalDetails?.approvalSystemId?.toLowerCase() !==
          "po-optimus" ? (
            loadPOInsights(pobudget?.accountingInfo?.profitCenter)
          ) : (
            <></>
          )}
          {actionContainerUI()}
        </>
      );
    } else {
      return <></>;
    }
  }

  function loadingUI(){
    return <DetailSkeleton1 />;
  }

  function getLoggedInUser() {
    if (SessionStorageService.getInstance().IsTeamsApp) {
      return SessionStorageService.getInstance().TeamsUser;
    } else {
      return msalInstance.getActiveAccount()?.username;
    }
  }

  function loadPOInsights(profitCenter: string) {
   

    const profitCentreFinal: string =
      getProfitCenterFirstLineItem(selectedApprovalDetails) !== ""
        ? getProfitCenterFirstLineItem(selectedApprovalDetails)
        : profitCenter;
    if (
      (getMaterialNumbers(selectedApprovalDetails) &&
        getMaterialNumbers(selectedApprovalDetails).length > 0) ||
      profitCentreFinal != ""
    ) {
      return (
        <POBudgetReport
          accountAssignment={poAssignment}
          profitCentre={profitCenter}
          plantCode={poPlantCode}
          stockMaterialNumber={getMaterialNumbers(selectedApprovalDetails)}
          stockMaterialNumberFirstLineItem={getMaterialNumberOfFirstLineItem(
            selectedApprovalDetails
          )}
          profitCentreFirstLineItem={getProfitCenterFirstLineItem(
            selectedApprovalDetails
          )}
        ></POBudgetReport>
      );
    }

    return <></>;
  }

  function emptyIndicatorUI(){
    return (
      <div className="message-container">
        <Message1
          title="No details to show."
          imgSrc="CardAssets/NoPendingRequests.svg"
        ></Message1>
      </div>
    );
  }

  function errorUI(){
    if (selectedApprovalDetailsError) {
      return (
        <div className="message-container">
          <Message1
            title={selectedApprovalDetailsError.message}
            imgSrc="CardAssets/error.svg"
            subTitle={selectedApprovalDetailsError.description}
          ></Message1>
        </div>
      );
    }
    return <></>;
  }

  function actionContainerUI(){
    if (selectedApprovalDetails && multiSelectedApprovals.length === 0) {
      return (
        <div className="action-container">
          {props.getActionsUI(false, [selectedApprovalDetails as IPO])}
        </div>
      );
    }
    return <></>;
  }
}
