import Utility from "common/utilities";
import { ISystemHeaderBaseProp } from "Model/UI";
import React from "react";
import "./EQuoteHeader.scss";
import { Constants } from "common/constants";
import { Header6 } from "Components2/HeaderTemplates/Header6/Header6";
import { IEQuote } from "Model/ServerResponse/IEquote";

export interface IEQuoteHeaderData extends ISystemHeaderBaseProp {
  equote: IEQuote;
}

export function EQuoteHeader(props: IEQuoteHeaderData) {
  function getHeaderUI() {
    return (
      <Header6
        label1={props.equote.approvalSystemId}
        label2={Utility.formatDate(props.equote.date, Constants.DD_MM_YY)}
        label3={props.equote.title}
        label4={
          props.equote.costPercentDelta
            ? props.equote.costPercentDelta + "%"
            : ""
        }
        userDetails={{
          userId: props.equote.requestorID,
          userCardName: props.equote.requestor,
          userOrganization: "",
        }}
        className={Utility.mergeCssClassNames(["eQuote-header"])}
        label5={""}
      ></Header6>
    );
  }
  return getHeaderUI();
}
