import { Grid } from "@material-ui/core";
import React from "react";
import Utility from "../../../common/utilities";
import { IKeyValue, ICardTemplateBaseProp } from "../../../Model/UI";
import { Chip } from "../../Chip/Chip";
import { KeyValue } from "../../KeyValue/KeyValue";
import UserDetails from "../../UserDetails/UserDetails";

import "./Card3.scss";

export interface ICard3Prop extends ICardTemplateBaseProp {
  label1: string;
  label2: string;
  label3: string;
  label4?: any;
  keyValue1: IKeyValue;
  keyValue2: IKeyValue;
  chip1: string;
  chip2: string;
}
/*
UI placement:

    label1          chip1
    label2
    keyValue1
    userDetails     chip2
                    keyValue2
                    label4
                    label3     
 */

export function Card3(props: ICard3Prop)  {
  function getCardUI()  {
    return (
      <div
        data-testid="card-3"
        className={Utility.getClassNamesFromProps("card-3", props.className)}
      >
        {/* 1st row */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="row-1"
        >
          <div className="dot"></div>
          <p className="label-1">{props.label1}</p>
          <div className="spacer"></div>
          <Chip className="chip-1" label={props.chip1}></Chip>
        </Grid>

        {/* 2nd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-2"
        >
          <p className="label-2">{props.label2}</p>
        </Grid>

        {/* 3rd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-3"
        >
          <KeyValue
            className="key-value-1"
            _key={props.keyValue1._key}
            value={props.keyValue1.value}
            seperator={":"}
          ></KeyValue>
        </Grid>

        {/* 4th row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-4"
        >
          <div className="user-details">
            <UserDetails
              token={"test"}
              userId={props.userDetails.userId}
              userCardName={props.userDetails.userCardName}
              userOrganization={props.userDetails.userOrganization}
            ></UserDetails>
          </div>
          <div className="spacer"></div>
          <div className="col-1">
            <Chip className="chip-2" label={props.chip2}></Chip>
            <KeyValue
              className="key-value-2"
              _key={props.keyValue2._key}
              value={props.keyValue2.value}
              seperator={props.keyValue2.seperator}
            ></KeyValue>
            <span>{props.label4}</span>
            <p className="label-3">{props.label3}</p>
          </div>
        </Grid>
      </div>
    );
  }

  return getCardUI();
}
