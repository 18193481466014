import { Grid } from "@material-ui/core";
import { EJ_ITEMS_TAB_ID } from "Components2/EJourney/EJourneyUtil";
import {
  IEjourneyComplianceDetail,
  IEjourneyDetailData,
} from "Model/ServerResponse";
import { IEjourney } from "Model/ServerResponse/IEjourney";
import React, { useEffect, useState } from "react";
import Utility from "../../../../common/utilities";
import "./EJourneyDetailCompliance.scss";

export interface IEJourneyDetailsComplienceProp {
  ejItemData: IEjourney;
}
export function EJourneyDetailsComplience(
  props: IEJourneyDetailsComplienceProp
)  {
  const tripData: IEjourneyDetailData = props.ejItemData.tabs.find(
    (td) => td.tabId === EJ_ITEMS_TAB_ID
  )?.data as IEjourneyDetailData;
  let vehicleData: IEjourneyComplianceDetail[] = tripData?.complianceDetails;
  let [score, setScore] = useState<number>(0);

  useEffect(() => {
    getTotalScore(vehicleData);
  }, []);

  return (
    <div className="compliance-sec">
      <div className="panelheader">
        <b className="panelheadertext">Compliance Details</b>
        <span className="compliance-header">
          <b className="compliance-status">
            <span
              className={
                props.ejItemData.complianceStatus == "Compliant"
                  ? "compliance"
                  : "noncompliance"
              }
            >
              {props.ejItemData.complianceStatus}
            </span>{" "}
            &nbsp;{`( ${score}/${vehicleData?.length ?? 0} )`}
          </b>
        </span>
      </div>
      {getContentUI()}
    </div>
  );

  function getTotalScore(detailData: IEjourneyComplianceDetail[]) {
    if (!Utility.isArrayEmpty(detailData)) {
      detailData.forEach((element: any) => {
        if (element.isCompliant != null && !element.isCompliant) {
          setScore((score) => score + 1);
        }
      });
    }
  }

  function getContentUI()  {
    return (
      <React.Fragment>
        {!Utility.isArrayEmpty(vehicleData) ? (
          <Grid container className="compliance-container">
            {vehicleData.map((element: IEjourneyComplianceDetail) => {
              return (
                <Grid
                  key={element.description}
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className="complianceRow"
                >
                  <>
                    <span>
                      {element.isCompliant ? (
                        <img
                          alt="complaint"
                          src={"./DetailTab/Compliant_icn.svg"}
                        ></img>
                      ) : (
                        <img
                          alt="noncomplaint"
                          src={"./DetailTab/Non_compliant_icn.svg"}
                        ></img>
                      )}
                    </span>{" "}
                    &nbsp; &nbsp;
                    <span className="compliance-row">
                      {element.description}
                    </span>
                  </>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <div>No Vehicle Details</div>
        )}
      </React.Fragment>
    );
  }
}
