import React from "react";
import { IPO } from "../../../Model/ServerResponse";
import { Header1 } from "../../HeaderTemplates/Header1/Header1";
import { ISystemHeaderBaseProp } from "../../../Model/UI";

// It is important that we import local scss after Header/2/3 so the styles are overidden in correct way
import "./POHeader.scss";
import Utility from "common/utilities";
import { Constants } from "common/constants";

export interface IPOHeaderData extends ISystemHeaderBaseProp {
  po: IPO;
}

export function POHeader(props: IPOHeaderData) {
  function getHeaderUI(){
    return (
      <Header1
        label1={props.po.approvalSystemId}
        label2={Utility.formatDate(props.po.date, Constants.DD_MM_YY)}
        label3={props.po.vendorName}
        keyValue1={{
          _key: props.po.amount.toLocaleString(),
          value: props.po.currency,
        }}
        userDetails={{
          userId: props.po.requestorID,
          userCardName: props.po.requestor,
          userOrganization: props.po.organization,
        }}
        className={Utility.mergeCssClassNames(["po-header", getDotStyle(props.po.approvalSystemId)])}
      ></Header1>
    );
  }

  function getDotStyle(dotText: string): string {
    let result = "";
    switch (dotText) {
      case "PO-ITT":
        result = "po-itt";
        break;
      case "PO-Optimus":
        result = "po-optimus";
        break;
    }
    return result;
  }


  return getHeaderUI();
}
