import Utility from "../../../common/utilities";
import { TableComponent } from "../../../Components2/TableComponent/TableComponent";
import { ITableDataMapping } from "../../../Model/UI/ITableDataMapping";
import React from "react";
import "./SRMItem.scss";
import { KeyValueList } from "Components2/KeyValueList/KeyValueList";
import { IKeyValue } from "Model/UI";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TableCell,
  TableRow,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Constants } from "common/constants";

import { ISRMItemData } from "Model/ServerResponse/ISRMItemData";
import { Accordian } from "Components2/Accordian/Accordian";
import { ISRMNotesData } from "Model/ServerResponse/ISRMNotes";
import { SRMItemAttachment } from "../SRMItemAttachment/SRMItemAttachment";
import { ISRMAccountsData } from "Model/ServerResponse/ISRMAccounts";
import { ISRM } from "Model/ServerResponse/ISRM";
import { SRM_ITEMS_TAB_ID } from "../SRMUtil";

export interface ISRMItemsProp {
  srmData: ISRM;
}

export function SRMItems(props: ISRMItemsProp) {
  let srmItemsTabData = props?.srmData.tabs.find(
    (td) => td.tabId === SRM_ITEMS_TAB_ID
  );

  let [expanded, setExpanded] = React.useState<number | false>(0);

  let handleChange =
    (index: number) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? index : false);
    };

  const tableHeaderMapping: ITableDataMapping[] = [
    {
      id: "lineNumber",
      label: "Line Number",
    },
    {
      id: "itemType",
      label: "Item Type",
    },
    {
      id: "productId",
      label: "Product ID",
    },
    {
      id: "productDescription",
      label: "Description",
    },

    {
      id: "quantity",
      label: "Quantity",
    },
    {
      id: "netValue",
      label: "Net Price/Cur",
    },
    {
      id: "deliveryDate",
      label: "Delivery Date",
    },
  ];

  const notesHeaderMapping: ITableDataMapping[] = [
    {
      id: "title",
      label: "Category",
    },
    {
      id: "comment",
      label: "Description",
    },
  ];

  const accountsHeaderMapping: ITableDataMapping[] = [
    {
      id: "accountCategory",
      label: "Account Assignment Category",
    },
    {
      id: "glAccount",
      label: "General Ledger Account",
    },
    {
      id: "distributionPercentage",
      label: "Percentage",
    },
    {
      id: "",
      label: "Other Details",
    },
  ];

  return <div className="srm-items-tab">{tabPOData()}</div>;

  function tabPOData() {
    if (srmItemsTabData && !Utility.isArrayEmpty(srmItemsTabData.data)) {
      return (
        <div className="srm-item-rows">
          <TableComponent
            tableData={srmItemsTabData.data}
            mappingTableData={tableHeaderMapping}
            getTableRow={getTablePOItemRow}
          ></TableComponent>
        </div>
      );
    } else {
      return <div className="no-srm-items">No Items</div>;
    }
  }

  function getTablePOItemRow(index: number, srmItemData: ISRMItemData) {
    return (
      <>
        <TableRow>
          <TableRow className="spacing"></TableRow>
        </TableRow>
        <TableRow className="srm-items-row-1 srm-items-row">
          <TableCell>
            <p className="srm-items-row-text-1">{srmItemData?.lineNumber}</p>
          </TableCell>
          <TableCell>
            <p className="srm-items-row-text-1">{srmItemData?.itemType}</p>
          </TableCell>
          <TableCell>
            <p className="srm-items-row-text-1">{srmItemData?.productId}</p>
          </TableCell>
          <TableCell>
            <p className="srm-items-row-text-1">
              {srmItemData?.productDescription}
            </p>
          </TableCell>
          <TableCell>
            <p className="srm-items-row-text-1">
              {srmItemData?.quantity}{" "}
              <span className="unit">&nbsp;{srmItemData?.unitOfMeasure}</span>
            </p>
          </TableCell>
          <TableCell>
            <p className="srm-items-row-text-1">
              {srmItemData?.netValue}
              <span className="unit">&nbsp;{srmItemData?.currency}</span>
            </p>
          </TableCell>
          <TableCell>
            <p className="srm-items-row-text-1">
              {srmItemData?.deliveryDate &&
                Utility.formatDate(
                  srmItemData?.deliveryDate,
                  Constants.DD_MM_YY
                )}
            </p>
          </TableCell>
        </TableRow>
        <TableRow className="detail-row">
          <TableCell colSpan={12}>
            {getDetailSection(index, srmItemData)}
          </TableCell>
        </TableRow>
      </>
    );
  }

  function getDetailSection(index: number, srmItemData: ISRMItemData) {
    return (
      <Accordion expanded={expanded === index} onChange={handleChange(index)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="header-content">
            <b>Details</b>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="border-div">
            {/* Details product category start  */}
            {srmItemData?.productCategoryId ||
            srmItemData?.categoryDescription ? (
              <div className="border-bottom ">
                {getProductCategorySection(srmItemData)}
              </div>
            ) : (
              <></>
            )}
            {/* Details product category end  */}
            {/* Details Supplier Section start  */}
            {srmItemData?.supplierName || srmItemData?.supplierId ? (
              <div className="border-bottom ">
                <p className="section-Heading">Supplier Details</p>
                <div className="border-bottom ">
                  {getSupplierSection(srmItemData)}
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* Details Supplier Section end  */}
            {/* Details Identification Section start  */}
            {srmItemData?.catalogue ? (
              <div className="border-bottom ">
                <p className="section-Heading">Identification</p>
                {getIdentificationSection(srmItemData)}
              </div>
            ) : (
              <></>
            )}
            {/* Details Identification Section end  */}
            {/* Details Organization Section start  */}
            {srmItemData?.purchaseGroup ||
            srmItemData?.companyCode ||
            srmItemData?.plant ||
            srmItemData?.deliveryAddress ? (
              <div className="border-bottom ">
                <p className="section-Heading">Organization</p>
                {getOrganizationSection(srmItemData)}
              </div>
            ) : (
              <></>
            )}
            {/* Details Organization Section end  */}
            {/* Details Currency, Values and Pricing Section start  */}
            {srmItemData?.price || srmItemData?.quantity ? (
              <div className="border-bottom ">
                <p className="section-Heading">Currency, Values and Pricing</p>
                {getCurrencyValuePricingSection(srmItemData)}
              </div>
            ) : (
              <></>
            )}
            {/* Details Currency, Values and Pricing Section end  */}
            {/* Details Limit Service start  */}
            {srmItemData?.startDate ||
            srmItemData?.endDate ||
            srmItemData?.valueLimit ||
            srmItemData?.expectedValue ? (
              <div className="border-bottom ">
                <p className="section-Heading">Limit / Service</p>
                {getLimitServiceSupplierSection(srmItemData)}
              </div>
            ) : (
              <></>
            )}
            {/* Details Limit Service  Section end  */}
            {/* Details Account Assignment Section start  */}
            <Accordian
              headerContent={
                <span className="accordian-header">
                  Account Assignment{" "}
                  <span className="headerCount">
                    {srmItemData?.accounts ? (
                      "(" + srmItemData?.accounts?.length + ")"
                    ) : (
                      <></>
                    )}
                  </span>{" "}
                </span>
              }
              content={getAccountsContentUI(srmItemData)}
            />
            {/* Details Account Assignment end  */}
            {/* Details Notes Section start  */}
            <Accordian
              headerContent={
                <span className="accordian-header">
                  Notes{" "}
                  <span className="headerCount">
                    {srmItemData?.notes ? (
                      "(" + srmItemData?.notes?.length + ")"
                    ) : (
                      <></>
                    )}
                  </span>
                </span>
              }
              content={getNotesContentUI(srmItemData)}
            />
            {/* Details Notes Section end  */}
            {/* Details Attachment Section start  */}
            <Accordian
              headerContent={
                <span className="accordian-header">
                  Attachments{" "}
                  <span className="headerCount">
                    {srmItemData?.attachments ? (
                      "(" + srmItemData?.attachments?.length + ")"
                    ) : (
                      <></>
                    )}
                  </span>{" "}
                </span>
              }
              content={getAttachmentContentUI(srmItemData)}
            />
            {/* Details Attachment Section end  */}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
  function getProductCategorySection(srmItemData: ISRMItemData) {
    let _data = getProductCategoryKeyValueList(srmItemData);
    return <KeyValueList data={_data} />;
  }

  function getProductCategoryKeyValueList(
    srmItemData: ISRMItemData
  ): IKeyValue[] {
    let dataArray: IKeyValue[] = [];
    if (srmItemData?.productCategoryId)
      dataArray.push({
        _key: "Product Category",
        value: srmItemData?.productCategoryId,
        seperator: ":",
      });
    if (srmItemData?.categoryDescription)
      dataArray.push({
        _key: "Product Category Description",
        value: srmItemData?.categoryDescription,
        seperator: ":",
      });
    if (srmItemData?.orderAsDirectMaterial)
      dataArray.push({
        _key: "Order as Direct Material ",
        value: srmItemData?.orderAsDirectMaterial,
        seperator: ":",
      });
    return dataArray;
  }

  function getSupplierSection(srmItemData: ISRMItemData) {
    let _data = getSupplierKeyValueList(srmItemData);
    return <KeyValueList data={_data} />;
  }

  function getSupplierKeyValueList(srmItemData: ISRMItemData): IKeyValue[] {
    let dataArray: IKeyValue[] = [];
    if (srmItemData?.supplierName)
      dataArray.push({
        _key: "Supplier / Preferred Supplier Name",
        value: srmItemData?.supplierName,
        seperator: ":",
      });
    if (srmItemData?.supplierId)
      dataArray.push({
        _key: "Supplier / Preferred Supplier ID",
        value: srmItemData?.supplierId,
        seperator: ":",
      });

    return dataArray;
  }

  function getIdentificationSection(srmItemData: ISRMItemData) {
    let _data = getIdentificationKeyValueList(srmItemData);
    return <KeyValueList data={_data} />;
  }

  function getIdentificationKeyValueList(
    srmItemData: ISRMItemData
  ): IKeyValue[] {
    let dataArray: IKeyValue[] = [];
    if (srmItemData?.catalogue)
      dataArray.push({
        _key: "Catalog",
        value: srmItemData?.catalogue,
        seperator: ":",
      });
    return dataArray;
  }

  function getOrganizationSection(srmItemData: ISRMItemData) {
    let _data = getOrganizationSectionKeyValueList(srmItemData);
    return <KeyValueList data={_data} />;
  }

  function getOrganizationSectionKeyValueList(
    srmItemData: ISRMItemData
  ): IKeyValue[] {
    const dataArray: IKeyValue[] = [];
    const addKeyValue = (
      key: string,
      value: string | undefined,
      extraValue: string | undefined
    ) => {
      if (value || extraValue) {
        const valuePart = value || "";
        const extraValuePart = extraValue ? ` (${extraValue})` : "";
        const finalValue = `${valuePart}${extraValuePart}`;

        dataArray.push({
          _key: key,
          value: finalValue,
          seperator: ":",
        });
      }
    };

    addKeyValue(
      "Purchase Group",
      srmItemData?.purchaseGroupName,
      srmItemData?.purchaseGroup
    );

    addKeyValue(
      "Purchase Org",
      srmItemData?.purchaseOrgName,
      srmItemData?.purchaseOrg
    );

    addKeyValue(
      "Company Code",
      srmItemData?.companyCodeName,
      srmItemData?.companyCode
    );

    if (srmItemData?.plant) {
      dataArray.push({
        _key: "Plant",
        value: srmItemData.plant,
        seperator: ":",
      });
    }

    if (srmItemData?.deliveryAddress) {
      dataArray.push({
        _key: "Delivery Address",
        value: srmItemData.deliveryAddress,
        seperator: ":",
      });
    }
    return dataArray;
  }

  function getCurrencyValuePricingSection(srmItemData: ISRMItemData) {
    let _data = getCurrencyValuePricingKeyValueList(srmItemData);
    return <KeyValueList data={_data} />;
  }

  function getCurrencyValuePricingKeyValueList(
    srmItemData: ISRMItemData
  ): IKeyValue[] {
    let dataArray: IKeyValue[] = [];
    if (srmItemData?.quantity)
      dataArray.push({
        _key: "Order / Unit",
        value: srmItemData?.quantity + " " + srmItemData?.unitOfMeasure,
        seperator: ":",
      });
    if (srmItemData?.price)
      dataArray.push({
        _key: "Price / Currency",
        value: srmItemData?.price + " " + srmItemData?.currency,
        seperator: ":",
      });
    return dataArray;
  }

  function getLimitServiceSupplierSection(srmItemData: ISRMItemData) {
    let _data = getLimitServiceSupplierSectionKeyValueList(srmItemData);
    return <KeyValueList data={_data} />;
  }

  function getLimitServiceSupplierSectionKeyValueList(
    srmItemData: ISRMItemData
  ): IKeyValue[] {
    let dataArray: IKeyValue[] = [];
    if (srmItemData?.startDate)
      dataArray.push({
        _key: "Start Date",
        value: srmItemData?.startDate,
        seperator: ":",
      });
    if (srmItemData?.endDate)
      dataArray.push({
        _key: "End Date",
        value: srmItemData?.endDate,
        seperator: ":",
      });
    if (srmItemData?.valueLimit)
      dataArray.push({
        _key: "Value Limit",
        value: srmItemData?.valueLimit + srmItemData?.currency,
        seperator: ":",
      });
    if (srmItemData?.expectedValue)
      dataArray.push({
        _key: "Expected Value",
        value: srmItemData?.expectedValue + srmItemData?.currency,
        seperator: ":",
      });

    return dataArray;
  }

  function getAccountsContentUI(srmItemData: ISRMItemData) {
    return (
      <div className="section-table">
        {accountsTable(srmItemData)}
        <br></br>
      </div>
    );
  }

  function accountsTable(srmItemData: ISRMItemData) {
    if (srmItemData && !Utility.isArrayEmpty(srmItemData?.accounts)) {
      return (
        <TableComponent
          tableData={srmItemData?.accounts}
          mappingTableData={accountsHeaderMapping}
          getTableRow={getAccountsTableTableAccountItemRow}
        ></TableComponent>
      );
    } else {
      return <div className="no-srm-items">No Account Assignment</div>;
    }
  }

  function getAccountsTableTableAccountItemRow(
    index: number,
    accountsData: ISRMAccountsData
  ) {
    return (
      <TableRow>
        <TableCell>{accountsData?.accountCategory}</TableCell>
        <TableCell>{accountsData?.glAccount}</TableCell>
        <TableCell>{accountsData?.distributionPercentage}</TableCell>
        <TableCell>{getAssigmentCategoryOtherDetails(accountsData)}</TableCell>
      </TableRow>
    );
  }

  function getAssigmentCategoryOtherDetails(accountsData: ISRMAccountsData) {
    switch (accountsData?.accountCategory?.toLowerCase()) {
      case "cost center":
        return (
          <p>
            {accountsData?.costCenter &&
              `Cost center : ${accountsData?.costCenter}`}
          </p>
        );
      case "order":
        return (
          <>
            <p>
              {accountsData?.orderNumber &&
                `Order Number : ${accountsData?.orderNumber}`}
            </p>
            <p>
              {accountsData?.costCenter &&
                `Cost center : ${accountsData?.costCenter}`}
            </p>
          </>
        );
      case "network":
        return (
          <>
            <p>
              {accountsData?.network && `Network : ${accountsData?.network}`}
            </p>
            <p>
              {accountsData?.networkActivity &&
                `Network Activity : ${accountsData?.networkActivity}`}
            </p>
          </>
        );
      case "sales order":
        return (
          <>
            <p>
              {accountsData?.salesDocument &&
                `Sales Document : ${accountsData?.salesDocument}`}
            </p>
            <p>
              {accountsData?.saleDocumentItem &&
                `Item : ${accountsData?.saleDocumentItem}`}
            </p>
            <p>
              {accountsData?.scheduleLine &&
                `Schedule Line : ${accountsData?.scheduleLine}`}
            </p>
          </>
        );
      case "wbs element":
      case "project stock":
        return (
          <p>
            {accountsData?.wbsElement &&
              `WBS Element : ${accountsData?.wbsElement}`}
          </p>
        );
      default:
        return <></>;
    }
  }

  function getNotesContentUI(srmItemData: ISRMItemData) {
    return (
      <div className="section-table">
        {notesTable(srmItemData)}
        <br></br>
      </div>
    );
  }

  function notesTable(srmItemData: ISRMItemData) {
    if (srmItemData && !Utility.isArrayEmpty(srmItemData?.notes)) {
      return (
        <TableComponent
          tableData={srmItemData?.notes}
          mappingTableData={notesHeaderMapping}
          getTableRow={getNotesTableAccountItemRow}
        ></TableComponent>
      );
    } else {
      return <div className="no-srm-items">No Notes</div>;
    }
  }

  function getNotesTableAccountItemRow(
    index: number,
    notesData: ISRMNotesData
  ) {
    const notesArray = notesData?.comment.split("\n");
    const enhancedNotesArray = notesArray.map((line, index) => ({
      id: index + 1,
      content: line,
    }));
    return (
      <TableRow>
        <TableCell>{notesData?.title}</TableCell>
        <TableCell>
          {enhancedNotesArray.map((line) => (
            <p key={line.id}>{line.content}</p>
          ))}
        </TableCell>
      </TableRow>
    );
  }

  function getAttachmentContentUI(srmItemData: ISRMItemData) {
    return (
      <div className="section-table">
        <SRMItemAttachment
          srm={srmItemData?.attachments}
          approvalSystemId={props?.srmData?.approvalSystemId}
          cartNumber={props?.srmData?.cartNumber}
        ></SRMItemAttachment>
      </div>
    );
  }
}
