
import { IDefaultUIProp, IKeyValue } from '../../Model/UI';
import React from 'react';
import Utility from '../../common/utilities';
import "./KeyValue.scss";

export interface IKeyValueProp extends IDefaultUIProp, IKeyValue {
}

export function KeyValue(props: IKeyValueProp) {

    return (
        <>
            <div data-testid='key-value-pair' className={Utility.getClassNamesFromProps("key-value", props.className)}>
                <p className="key">{props._key}</p>
                {props.seperator && <p className="seperator" dangerouslySetInnerHTML={{ __html: props.seperator }}></p>}
                <p className="value" dangerouslySetInnerHTML={{ __html: props.value }}></p>
            </div>
        </>
    );
}