import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import "./ActionModal.scss";
import { IDefaultUIProp } from "Model/UI";
import Utility from "common/utilities";

export interface IActionModalProp extends IDefaultUIProp {
  open: boolean;
  title?: string;
  question?: string;
  isCommentMandatory?: boolean;
  requestDescription?: string | React.ReactNode;
  onPrimaryAction?: (e: any, comment: string) => void;
  onCancel?: (e: any) => void;
  maxCommentChar?: number;
  primaryActionLabel: string;
  rejectDropDown?: any;
  rejectReasonValue?: string;
}

export function ActionModal(props: IActionModalProp) {
  const [open, setOpen] = React.useState(false);
  let [comment, setComment] = React.useState<string>("");

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog
      disableBackdropClick
      open={open}
      onClose={handleClose}
      data-testid="action-modal"
      className={Utility.mergeCssClassNames(["action-modal", props.className])}
    >
      <DialogTitle>
        {props.title && <p className="title">{props.title}</p>}
      </DialogTitle>
      <DialogContent className="action-content">
        {props.question && (
          <p
            className="question"
            dangerouslySetInnerHTML={{ __html: props.question }}
          ></p>
        )}
        {props.requestDescription && (
          <p className="requests">{props.requestDescription}</p>
        )}
        {props.rejectDropDown ? props.rejectDropDown : <></>}

        <TextField
          className="comment-ip"
          value={comment}
          hiddenLabel
          placeholder={
            props.isCommentMandatory
              ? "Comment is mandatory"
              : "Comment is optional"
          }
          rows={5}
          multiline
          variant="outlined"
          onChange={(e) => {
            setComment(e.target.value);
          }}
          error={isError()}
          helperText={getComment()}
          inputProps={{
            "data-testid": "comment",
          }}
        />
      </DialogContent>
      <DialogActions className="action-container">
        <Button className="action-btn cancel-btn" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          data-testid="primaryAction"
          className="action-btn primary-action-btn"
          onClick={handlePrimaryAction}
          disabled={isPrimaryActionDisabled()}
        >
          {props.primaryActionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );

  function isError(): boolean {
    if (comment.length > props.maxCommentChar!) {
      return true;
    }
    if (
      props.rejectDropDown &&
      props?.rejectReasonValue &&
      props.rejectReasonValue === ""
    ) {
      return true;
    }
    return false;
  }

  function isPrimaryActionDisabled(): boolean {
    if (
      (props.isCommentMandatory && comment?.trim().length === 0) ||
      isError()
    ) {
      return true;
    }
    if (
      props.rejectDropDown &&
      props.rejectDropDown?.props &&
      Object.keys(props.rejectDropDown?.props || {}).length !== 0 &&
      props.rejectReasonValue === ""
    ) {
      return true;
    }
    return false;
  }

  function getComment(): string {
    if (props.maxCommentChar) {
      if (comment.length === 0) {
        return `Max ${props.maxCommentChar} characters`;
      } else if (comment.length > props.maxCommentChar) {
        return `Max ${props.maxCommentChar} characters is reached`;
      } else if (comment.length === props.maxCommentChar) {
        return `Max ${props.maxCommentChar} characters : 0 remaining`;
      }
      return `Max ${props.maxCommentChar} characters : ${
        props.maxCommentChar - comment?.trim().length
      } remaining`;
    }
    return "";
  }

  function handleClose(e: any) {
    setOpen(false);
    setComment("");
    if (props.onCancel) {
      props.onCancel(e);
    }
  }

  function handlePrimaryAction(e: any) {
    setOpen(false);
    if (props.onPrimaryAction) {
      props.onPrimaryAction(e, comment);
    }
    setComment("");
  }
}
