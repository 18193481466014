import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../Model/Enums";
import { IAppState } from "../../Store/store";
import {
  getPendingApprovals1Thunk,
  getApprovalDetails1Thunk,
  postDeleteApprovals1Thunk,
  deleteApprovals1Thunk,
  systemSlice1Reducer,
  ISystemSlice1State,
} from "../../Store/SystemTemplate/SystemSlice1";

export const timesheetSliceInitialState: ISystemSlice1State = {
  pendingApprovalsStatus: RequestStatus.Idle,
  pendingApprovalsError: null,
  selectedApprovalDetailStatus: RequestStatus.Idle,
  selectedApprovalDetailError: null,
  supportMulti: true,
  selectedApproval: null,
  selectedApprovalDetails: null,
  multiSelectedApprovals: [],
  searchQuery: "",
  filterQuery: "",
  isAsc: false,
  selectedSubSystem: null,
  fromPosition: 0,
  subSystems: [],
  totalCountForFilter: 0,
  pendingApprovals: [],
  isTabletView: false,

};

const TimeSheetSlice = createSlice({
  name: "Timesheet",
  initialState: timesheetSliceInitialState,
  reducers: systemSlice1Reducer,
});

export const GetPendingApprovals = getPendingApprovals1Thunk(
  timesheetSliceInitialState,
  TimeSheetSlice,
  "Timesheet",
  "Timesheet"
);

export const GetApprovalDetails = getApprovalDetails1Thunk(
  TimeSheetSlice,
  "Timesheet",
  "Timesheet"
);

export const PostDeleteApprovals = postDeleteApprovals1Thunk(
  TimeSheetSlice,
  "Timesheet"
);

export const DeleteApprovals = deleteApprovals1Thunk(
  TimeSheetSlice,
  "Timesheet",
  "Timesheet"
);

//Action
export const {
  resetSystemSliceState,
  setPendingApprovalsStatus,
  setSelectedApproval,
  setSelectedApprovalDetails,
  setMultiSelectedApprovals,
  setSearchQuery,
  setFilterQuery,
  setIsAsc,
  setSelectedSubSystem,
  setFromPosition,
  setSubSystems,
  setTotalCountForFilter,
  setPendingApprovals,
  addToPendingApprovals,
  reCalculateSelectedApproval,
  setIsTabletView,

} = TimeSheetSlice.actions;

//Selector
export const TimeSheetSelector = (state: IAppState) => state.Timesheet;
export const PendingApprovalsStatusSelector = createSelector(
  TimeSheetSelector,
  (details) => details?.pendingApprovalsStatus
);
export const PendingApprovalsErrorSelector = createSelector(
  TimeSheetSelector,
  (details) => details?.pendingApprovalsError
);
export const SelectedApprovalDetailStatusSelector = createSelector(
  TimeSheetSelector,
  (details) => details?.selectedApprovalDetailStatus
);
export const SelectedApprovalDetailErrorSelector = createSelector(
  TimeSheetSelector,
  (details) => details?.selectedApprovalDetailError
);
export const SupportMultiSelector = createSelector(
  TimeSheetSelector,
  (details) => details?.supportMulti
);
export const SelectedApprovalSelector = createSelector(
  TimeSheetSelector,
  (details) => details?.selectedApproval
);
export const SelectedApprovalDetailsSelector = createSelector(
  TimeSheetSelector,
  (details) => details?.selectedApprovalDetails
);
export const MultiSelectedApprovalsSelector = createSelector(
  TimeSheetSelector,
  (details) => details?.multiSelectedApprovals
);
export const SearchQuerySelector = createSelector(
  TimeSheetSelector,
  (details) => details?.searchQuery
);
export const FilterQuerySelector = createSelector(
  TimeSheetSelector,
  (details) => details?.filterQuery
);
export const IsAscSelector = createSelector(
  TimeSheetSelector,
  (details) => details?.isAsc
);
export const SelectedSubSystemSelector = createSelector(
  TimeSheetSelector,
  (details) => details?.selectedSubSystem
);
export const FromPositionSelector = createSelector(
  TimeSheetSelector,
  (details) => details?.fromPosition
);
export const SubSystemsSelector = createSelector(
  TimeSheetSelector,
  (details) => details?.subSystems
);
export const TotalCountForFilterSelector = createSelector(
  TimeSheetSelector,
  (details) => details?.totalCountForFilter
);
export const PendingApprovalsSelector = createSelector(
  TimeSheetSelector,
  (details) => details?.pendingApprovals
);
export const IsTabletViewSelector = createSelector(
  TimeSheetSelector,
  (details) => details?.isTabletView
);


export const TimeSheetReducer = TimeSheetSlice.reducer;
