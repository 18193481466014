import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  DeleteApprovals,
  PostDeleteApprovals,
  reCalculateSelectedApproval,
  SelectedApprovalSelector,
  setIsTabletView,
  setSelectedApproval,
} from "../../../Store/EJourney/EJourneySlice";
import { useAppDispatch } from "../../../Store/store";
import { ListDetailsView } from "../../ListDetailsView/ListDetailsView";
import { TakeActionFailedModal } from "../../Modals/TakeActionFailedModal/TakeActionFailedModal";
import { EJourneyActions } from "../EJourneyActions/EJourneyActions";
import {
  IEjourney,
  IFailedTakeAction,
  ISystem,
  ITakeActionRequest,
} from "../../../Model/ServerResponse";
import { EJourneyDetailsCard } from "../EJourneyDetailsCard/EJourneyDetailsCard";
import { EJourneyListView } from "../EJourneyListView/EJourneyListView";

export interface IEJourneyPageProp {
}

export function EJourneyPage(props: IEJourneyPageProp)  {
  let appDispatch = useAppDispatch();
  let selectedApproval = useSelector(SelectedApprovalSelector);

  let [takeActionFailedModalOpen, setTakeActionFailedModalOpen] =
    useState<boolean>(false);
  let [failedTakeActions, setFailedTakeActions] = useState<IFailedTakeAction[]>(
    []
  );


  return (
    <div>
      {takeActionFailedModalOpen && (
        <TakeActionFailedModal
          open={takeActionFailedModalOpen}
          failedActions={failedTakeActions}
          onOk={(e: any) => setTakeActionFailedModalOpen(false)}
        />
      )}
      <ListDetailsView
        selectedApproval={selectedApproval}
        onBackClick={handleBackClick}
        onWindowSizeChange={handleWindowSizeChange}
        listView={listUI}
        detailsView={detailsUI}
      />
    </div>
  );

  function listUI()  {
    return <EJourneyListView getActionsUI={actionsUI} />;
  }

  function detailsUI()  {
    return <EJourneyDetailsCard getActionsUI={actionsUI} />;
  }

  function actionsUI(_isMulti: boolean, _approvals: IEjourney[])  {
    return (
      <EJourneyActions
        isMulti={_isMulti}
        count={_approvals.length}
        onApprove={handleOnApprove}
        onReject={handleOnReject}
      />
    );
  }

  function handleBackClick(e: any) {
    appDispatch(setSelectedApproval(null));
  }

  function handleWindowSizeChange(e: any, isTabletView: boolean) {
    appDispatch(setIsTabletView(isTabletView));
    appDispatch(reCalculateSelectedApproval());
  }

  function handleOnApprove(e: any, isMulti: boolean, comment: string) {
    takeAction(true, isMulti, comment);
  }

  function handleOnReject(e: any, isMulti: boolean, comment: string) {
    takeAction(false, isMulti, comment);
  }

  async function takeAction(
    isApprove: boolean,
    isMulti: boolean,
    comment: string
  ): Promise<void> {
    let result = (
      await appDispatch(
        PostDeleteApprovals({
          isApprove: isApprove,
          isMulti: isMulti,
          comment: comment,
          getRequestBody: getTakeActionRequestBody,
        })
      )
    ).payload as {
      passedApprovals: IEjourney[];
      failedTakeActions: IFailedTakeAction[];
    };
    if (result.failedTakeActions.length > 0) {
      //Some approvals failed
      setTakeActionFailedModalOpen(true);
      setFailedTakeActions(result.failedTakeActions);
    }

    //Remove cards & subtract count for passed
    appDispatch(DeleteApprovals(result.passedApprovals));
  }
  function getTakeActionRequestBody(_approval: ISystem, _comment: string): any {
    let _ejourney = _approval as IEjourney;
    let arr: ITakeActionRequest<{ approverType: string }>[] = [];
    for (let item of _ejourney.approverTypes) {
      arr.push({
        approvalId: _ejourney.id?.toString(),
        comment: _comment,
        extra: {
          approverType: item?.toString(),
        },
      });
    }
    return arr;
  }
}
