import {
  IPretrip,
  IPretripDetailData,
  IPretripTravellerData,
} from "Model/ServerResponse";
import "./PreTripTripDetails.scss";
import React, { useState } from "react";
import { PRETRIP_DETAILS_TAB_ID } from "../../PreTripUtil";
import Utility from "common/utilities";
import { PreTripTravellerDetails } from "./PreTripTravellerDetails";

export interface IPreTripTripDetailsProp {
  tableData: IPretrip | undefined;
}

export function PreTripTripDetails(
  props: IPreTripTripDetailsProp
) {
  const [viewDetailsPopupOpen, setViewDetailsPopupOpen] = useState(false);
  const [selectedDetails, setSelectedDetails] =
    useState<IPretripTravellerData>();

  let _pretripdata = props.tableData?.tabs.find(
    (td) => td.tabId === PRETRIP_DETAILS_TAB_ID
  );
  let _preTripTripData = _pretripdata?.data as IPretripDetailData;

  const handleVDClick = (traveller: IPretripTravellerData) => {
    setViewDetailsPopupOpen(true);
    setSelectedDetails(traveller);
  };

  const handleVDClose = () => {
    setViewDetailsPopupOpen(false);
  };


  return <>{getSection()}</>;

  function getSection() {
    return (
      <>
        <div className="pretrip-trip-container">
          {getTripSection()}
          {getOriginDestination()}
          {getTravelReason()}
          {getCustomerSupplier()}
        </div>
      </>
    );
  }

  function getTripSection() {
    return (
      <>
        <div className="main-container">
          <div className="title-container">
            <div className="pretrip-section-title">
              <b>Trip</b>
              <div className="spacer"></div>
            </div>
          </div>
        </div>

        <div className="subtitle-container">
          <div className="key-value-list">
            <div className="key-value">
              <p className="key">
                Traveller Details
                <p className="count">
                  {_preTripTripData && _preTripTripData.travellers &&_preTripTripData.travellers?.length > 1
                    ? " (" + _preTripTripData.travellers?.length + ")"
                    : " (1) "}</p>
              </p>
              {props.tableData?.requestType === "3rd Party Proxy"&& _preTripTripData && _preTripTripData.travellers  ? (
                <div className="traveller-name">
                  {_preTripTripData.travellers.map((traveller: IPretripTravellerData) => {
                    return (
                      <>
                        <PreTripTravellerDetails
                          key={traveller.email}
                          travellerData={selectedDetails}
                          open={viewDetailsPopupOpen}
                          handleClose={handleVDClose}
                        />

                        <p className="name">{traveller.name}</p>
                        <div className="main">
                          <p className="email">{traveller.email}</p>
                          <div
                            onClick={() => handleVDClick(traveller)}
                          >
                            <span className="country-covid-riskLink">
                              {"View Details >"}
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              ) : (
                <div className="col-1">
                  <div className="value">
                    <strong>{props.tableData?.requestor}</strong>
                  </div>
                  <div className="value job-title">
                    {props.tableData?.jobTitle}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  function getOriginDestination(){
    return (
      <>
        <div className="subtitle-container">
          <div className="row">
            <div className="column traveller-trip">
              <div>
                <p className="origin-key">Origin:</p>
                <p className="origin-value">
                  {_preTripTripData?.originLocation?.city}
                  {_preTripTripData?.originLocation?.city ? ", " : ""}
                  {_preTripTripData?.originLocation?.country}
                </p>
              </div>
            </div>
            <div className="column traveller-trip traveller-destination-border ">
              <div>
                <p className="origin-key">Destination:</p>
                <p className="origin-value">
                  {_preTripTripData?.destinationLocation?.city}
                  {_preTripTripData?.destinationLocation?.city ? ", " : ""}
                  {_preTripTripData?.destinationLocation?.country}
                </p>
                <p className="riskLabel">
                  Country Security Risk:
                  <span
                    className={Utility.mergeCssClassNames([
                      "risk",
                      getColorCodeRisk(_preTripTripData?.countrySecurityRisk),
                    ])}
                  >
                    {_preTripTripData?.countrySecurityRisk}
                  </span>
                </p>
                <p className="riskLabel">
                  Country Covid Risk:
                  <span
                    className={Utility.mergeCssClassNames([
                      "risk",
                      getColorCodeRisk(_preTripTripData?.countryCovidRisk),
                    ])}
                  >
                    {_preTripTripData?.countryCovidRisk}
                  </span>
                </p>

              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function getTravelReason() {
    return (
      <>
        <div className="subtitle-container">
          <div className="key-value-list ">
            <div className="key-value key-value">
              <p className="key">Reason of Travel</p>
              <div className="col-1">
                <div className="value">
                  <strong>{_preTripTripData?.reason}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function getCustomerSupplier(){
    return (
      <>
        <div className="subtitle-container">
          <div className="key-value-list ">
            <div className="key-value">
              <p className="key">Customer/Supplier (If Involved)</p>
              <div className="col-1">
                <div className="value ">
                  <strong>
                    {_preTripTripData?.customerSupplierName === null
                      ? "NA"
                      : _preTripTripData?.customerSupplierName}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function getColorCodeRisk(risk: string): string {
    let riskColor = "";
    switch (risk?.toLowerCase()) {
      case "high":
        riskColor = "risk-high";
        break;
      case "medium":
        riskColor = "risk-medium";
        break;
      case "low":
        riskColor = "risk-low";
        break;
      case "extreme":
        riskColor = "risk-extreme";
        break;
      case "insignificant":
        riskColor = "risk-insignificant";
        break;
    }
    return riskColor;
  }
}
