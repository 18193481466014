import Utility from "common/utilities";
import { IJRA } from "Model/ServerResponse";
import { IKeyValue, ISystemCardBaseProp } from "Model/UI";
import React from "react";
import { Card6 } from "../../CardTemplates/Card6/Card6";
import "./JRACard.scss";

export interface IJRACardProp extends ISystemCardBaseProp {
  jra: IJRA;

}

export function JRACard(
  props: IJRACardProp


) {
  let keyValues: IKeyValue[] = [
    { _key: "Customer", value: (props.jra.customerName), seperator: '-' },
    { _key: "Rig", value: props.jra.rigName, seperator: '-' },
    { _key: "Well", value: props.jra.wellName, seperator: '-' }
  ];

  function getCardUI() {
    return (
      <Card6
        label1={props.jra.approvalSystemId}
        label2={props.jra.title}
        label3={'Start - ' + props.jra.fromDate}
        chip1={props.jra.jraScore + ''}
        chip2={props.jra.metroStopName}
        supportMulti={props.supportMulti}
        multiSelectUI={props.multiSelectUI}
        keyValues={keyValues}
        userDetails={{
          userId: props.jra.requestorID,
          userCardName: props.jra.requestor,
          userOrganization: props.jra.opsDistrictName,
        }}
        className={Utility.mergeCssClassNames([
          "jra-card",
          getChipStyle(props.jra.risk),
        ])}

      ></Card6>
    );
  }

  function getChipStyle(chipText: string | null): string {

    let chipStyleContainer = "";
    if(!chipText){
      return 'chip1-hidden';
    }

    switch (chipText.toLowerCase()) {
      case "high":
        chipStyleContainer = "chip-1-high";
        break;
      case "medium":
        chipStyleContainer = "chip-1-medium";
        break;
      case "low":
        chipStyleContainer = "chip-1-low";
        break;
    }

    return chipStyleContainer;

  }

  return (getCardUI());
}
