import { Chip, Grid } from "@material-ui/core";
import Utility from "common/utilities";
import React, { useEffect } from "react";
import { ITabData } from "../../Model/UI/ITabData";
import "./DetailTabsComponent.scss";

export interface IDetailTabsComponentProp {
  tabs: ITabData[];
}

export function DetailTabsComponent(props: IDetailTabsComponentProp) {
  let [, setSelectedTabName] = React.useState<string>("");
  let [selectedTabIndex, setselectedTabIndex] = React.useState<number>(0);

  useEffect(() => {
    if (!Utility.isArrayEmpty(props.tabs) && props.tabs && props.tabs[0]) {
      setSelectedTabName(props.tabs[0].title);
    }
  }, [props.tabs]);

  return (
    <div className="detailsTab">
      <div className="tabComponent">
        {!Utility.isObjectEmpty(props.tabs)
          ? props.tabs.map((tab: ITabData, index: any) => {
              return (
                <div
                  key={Utility.createUniqueId(tab,index)}
                  className={Utility.mergeCssClassNames([
                    "tab",
                    isTabSelected(index) ? "selected-tab" : "",
                  ])}
                  onClick={(e) => {
                    handleTabClick(e, index, tab);
                  }}
                >
                  <img
                    alt="tabImage"
                    src={tab.imageSrc}
                    className={Utility.mergeCssClassNames([
                      "tab-img",
                      isTabSelected(index) ? "selected-tab-img" : "",
                    ])}
                  />

                  <span
                    className={Utility.mergeCssClassNames([
                      "tab-name",
                      isTabSelected(index) ? "selected-tab-name" : "",
                    ])}
                  >
                    {tab.title}
                  </span>

                  {tab.count !== undefined && tab.count !== null && (
                    <Chip
                      className={Utility.mergeCssClassNames([
                        "tab-chip",
                        isTabSelected(index) ? "selected-tab-chip" : "",
                      ])}
                      label={tab.count}
                    ></Chip>
                  )}
                </div>
              );
            })
          : null}
      </div>
      <Grid data-testid="overflow"className="overflow">
        {!Utility.isObjectEmpty(props.tabs)
          ? props.tabs.map((tab: ITabData, index: number) => {
              return (
                <React.Fragment key={Utility.createUniqueId(tab,index)}>
                  {selectedTabIndex === index && tab.getTabUI
                    ? tab.getTabUI()
                    : null}
                </React.Fragment>
              );
            })
          : null}
      </Grid>
    </div>
  );

  function isTabSelected(tabIndex: number): boolean {
    return tabIndex === selectedTabIndex;
  }

  function handleTabClick(_e: any, newValue: number, clickedTab: ITabData) {
    setselectedTabIndex(newValue);
    setSelectedTabName(clickedTab.title);
  }
}
