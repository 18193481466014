import { IKeyValue, ISystemCardBaseProp } from "Model/UI";
import React from "react";
import "./EQuoteCard.scss";
import Utility from "common/utilities";
import { IEQuote } from "Model/ServerResponse/IEquote";
import { Constants } from "common/constants";
import { Card8 } from "Components2/CardTemplates/Card8/Card8";

export interface IEQuoteCardProp extends ISystemCardBaseProp {
  equote: IEQuote;
}

export function EQuoteCard(props: IEQuoteCardProp) {
  let keyValues: IKeyValue[] = [
    { _key: "Part Number", value: props.equote.partNumber, seperator: "-" },
    {
      _key: "Manufacturing Center",
      value: props.equote.mfgCenter,
      seperator: "-",
    },
  ];
  function getCardUI() {
    return (
      <Card8
        label1={props.equote.approvalSystemId}
        label2={props.equote.title}
        label3={Utility.formatDate(props.equote.date, Constants.DD_MM_YY)}
        keyValue1={{
          _key: "",
          value: props.equote.costPercentDelta + "%",
        }}
        supportMulti={props.supportMulti}
        multiSelectUI={props.multiSelectUI}
        keyValues={keyValues}
        userDetails={{
          userId: props.equote.requestorID,
          userCardName: props.equote.requestor,
          userOrganization: "",
        }}
        className={Utility.mergeCssClassNames(["equote-card"])}
      ></Card8>
    );
  }
  return getCardUI();
}
