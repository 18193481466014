import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Utility from 'common/utilities';
import React, { useEffect, useState } from 'react';
import { ITableDataMapping } from "../../Model/UI/ITableDataMapping";
import { ITableColumn } from "../../Model/UI/ITableColumn";
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import "./TableComponent.scss";

export interface ITableComponentProp<T> {
    tableData: T[];
    mappingTableData: ITableDataMapping[];
    getTableRow: (index: number, rowData: any) => React.ReactNode;
}

export function TableComponent(props: ITableComponentProp<any>) {

    let [sortColumn, setSortColumn] = useState<ITableColumn | null>(null);
    let [tableData, setTableData] = useState<any[]>([]);

    useEffect(() => {
        setTableData(props.tableData);
        if (!Utility.isObjectEmpty(sortColumn)) {
            setTableData([...props.tableData].sort(comparator));
        }
    }, [props.tableData]);

    useEffect(() => {
        if (!Utility.isObjectEmpty(sortColumn)) {
            setTableData([...props.tableData].sort(comparator));
        }
    }, [sortColumn]);

    return (
        <TableContainer className='custom-table'>
            <Table data-testid="table-component">
                <TableHead >
                    <TableRow className='table-row'>
                        {props.mappingTableData.map((col, index) => (
                            <TableCell key={Utility.createUniqueId(col,index)} onClick={() => handlesort(col)} className='table-cell'>
                                <div className={Utility.mergeCssClassNames(['header-cell-content', col.sorting ? 'has-sorting' : 'no-sorting'])}>
                                    <span className='header-label'>{col.label}</span>
                                    {col.sorting ? <SyncAltIcon className='sorting-icon'/> : null}
                                </div>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {tableData ?
                    <TableBody className='table-body'>
                        {tableData.map((row: any, index: any) => (
                            props.getTableRow(index, row)
                        ))}
                    </TableBody>
                    : null
                }
            </Table>
        </TableContainer>
    );

    function handlesort(col: ITableDataMapping) {
        if (!col.sorting) {
            return;
        }

        if (sortColumn && col.id === sortColumn.property) {
            //change order
            setSortColumn({
                ...sortColumn,
                isAsc: !sortColumn.isAsc
            });
        }
        else {
            setSortColumn({
                property: col.id,
                isAsc: true,
                type: ""
            });
        }
    }

    //for date type sorting
    function DateComparator(value1: any, value2: any) {
        value1 = new Date(value1).getTime();
        value2 = new Date(value2).getTime();
        return comparator(value1, value2);
    }

    function comparator(value1: any, value2: any): number {
        if (sortColumn) {
            if (sortColumn.isAsc)
                return value1[sortColumn.property] - value2[sortColumn.property];
            else
                return value2[sortColumn.property] - value1[sortColumn.property];
        }
        return 0;
    }
}