import { Card1 } from "../../CardTemplates/Card1/Card1";
import { IPR } from "../../../Model/ServerResponse/IPR";
import { ISystemCardBaseProp } from "../../../Model/UI";
import React from "react";

import "./PRCard.scss";
import Utility from "common/utilities";

export interface IPRCardProp extends ISystemCardBaseProp {
    pr: IPR;
    //any should be removed once api is integrated
}

export function getChipStyle(chipText: string | null): string {
    //convert chiptext to boolean once api integrated
    let result = "";
    if (!chipText) {
        result = "chip1-hidden";
    }
    return result;
}

export function PRCard(props: IPRCardProp) {
    function getCardUI() {
        return (
            <Card1
                label1={props.pr.approvalSystemId}
                label2={props.pr.title}
                label3={props.pr.date}
                chip1={props.pr.plant ? "Substitute" : ""}
                keyValue1={{
                    _key: props.pr.amount.toString(),
                    value: props.pr.currency,
                }}
                keyValue2={{ _key: "Items", value: props.pr.items.toString() }}
                userDetails={{
                    userId: props.pr.requestorID,
                    userCardName: props.pr.requestor,
                    userOrganization: props.pr.plant,
                }}
                supportMulti={false}
                className={Utility.mergeCssClassNames([
                    "pr-card",
                    getChipStyle(props.pr.plant),
                ])}
            ></Card1>
        );
    }

    return getCardUI();
}