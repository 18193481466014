import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { msalInstance } from "./authProvider";
import { HashRouter, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorkerRegistration";
import { Provider } from "react-redux";
import { createTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import { Approvebuddy2 } from "./Approvebuddy2";
import * as store2 from "Store/store";
import VariablesJson from "./configVariables.json";
import {
  AuthenticatedTemplate,
  MsalContext,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Constants } from "common/constants";
import { InteractionStatus } from "@azure/msal-browser";
import { app } from "@microsoft/teams-js";
import * as microsoftTeams from "@microsoft/teams-js";
import SplashScreen from "SplashScreen";

const appTheme = createTheme({
  props: {
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application!
    },
  },
  palette: {
    primary: {
      main: "#106ff9", //#0A81D5",
    },
    secondary: {
      main: "#106ff9",
    },
  },
  typography: {
    fontFamily: ["SLBSans", "Arial", "sans-serif"].join(","),
  },
});
// check microsoft teams host client type as teams app

export function checkInTeams() {
  const microsoftTeamsLib: any =
    microsoftTeams || window["microsoftTeams" as any];

  if (!microsoftTeamsLib) {
    return false; // the Microsoft Teams library is for some reason not loaded
  }

  if (
    (window.parent === window.self && (window as any).nativeInterface) ||
    window.name === "embedded-page-container" ||
    window.name === "extension-tab-frame"
  ) {
    return true;
  }
  return false;
}
console.log("checkInTeams", checkInTeams());

function appInitialize() {
  if (checkInTeams()) {
    app.initialize();
  }
}

appInitialize();

setTimeout(() => {
  if (app.isInitialized()) {
    console.log("Microsoft Teams Initialised... Launching Splash Screen");
    ReactDOM.render((<SplashScreen />) as any, document.getElementById("root"));
  } else {
    console.log("Web App Initialised... Launching Main Screen");
    ReactDOM.render((<Main />) as any, document.getElementById("root"));
  }
}, 1500);

let InstallEvent: Event | null = null;
export function Main() {
  return (
    <div>
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          <App InstallEvent={InstallEvent}></App>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <LoginRedirectUI></LoginRedirectUI>
        </UnauthenticatedTemplate>
      </MsalProvider>
    </div>
  );
}

window.addEventListener("beforeinstallprompt", (event: Event) => {
  InstallEvent = event as BeforeInstallPromptEvent;
  event.preventDefault();
});

window.removeEventListener("beforeinstallprompt", (event: Event) => {
  InstallEvent = event as BeforeInstallPromptEvent;
});

export function LoginRedirectUI(){
  let msalContext = React.useContext(MsalContext);

  useEffect(() => {
    const isAuthenticated = msalContext.accounts.length > 0;
    const msalInstance = msalContext.instance;

    // If a user is not logged in and authentication is not already in progress, invoke login
    if (!isAuthenticated && msalContext.inProgress === InteractionStatus.None) {
      msalInstance.loginRedirect({
        scopes: [Constants.GRAPH_SCOPE],
        loginHint: "", //preferred_username',
        extraQueryParameters: { domain_hint: "@slb.com" },
      });
    }
  }, []);

  return <p>Logging In...</p>;
}

interface AppProps {
  InstallEvent: any;
}

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

function App(props: AppProps) {
  let [token, setToken] = useState<string | null>(null);

  const { instance, inProgress } = useMsal();
  
  useEffect(() => {
    const silentRequest = {
      loginHint: "", //preferred_username',
      extraQueryParameters: { domain_hint: "@slb.com" },
      scopes: [Constants.DEFAULT_SCOPE],
    };

    //try silent
    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(silentRequest)
        .then((_token) => {
          let result =
            VariablesJson.Variables.temp_token_enabled.toLowerCase() == "true"
              ? VariablesJson.Variables.temp_token_value
              : _token.accessToken;
          setToken(result);
        })
        .catch((er) => {
          msalInstance.loginRedirect({
            scopes: [Constants.GRAPH_SCOPE],
            loginHint: "", //preferred_username',
            extraQueryParameters: { domain_hint: "@slb.com" }
          });
        });
    }

    //acquireTokenPopup can be tried in catch
  }, [instance, inProgress]);

  return !token ? (
    <p>Loading...</p>
  ) : (
    // <Provider store={store}>
    <Provider store={store2.default}>
      <React.StrictMode>
        <ThemeProvider theme={appTheme}>
          <CssBaseline />
          <HashRouter>
            <Route
              render={(_: any) => (
                <>
                  <Approvebuddy2
                    InstallEvent={props.InstallEvent}
                  ></Approvebuddy2>
                </>
              )}
            />
          </HashRouter>
        </ThemeProvider>
      </React.StrictMode>
    </Provider>
  );
}

serviceWorker.register();
