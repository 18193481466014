import {
    createSelector, createSlice,
} from "@reduxjs/toolkit";
import { RequestStatus } from "Model/Enums";
import { IAppState } from "Store/store";
import { getPendingApprovals1Thunk, getApprovalDetails1Thunk, postDeleteApprovals1Thunk, deleteApprovals1Thunk, systemSlice1Reducer, ISystemSlice1State } from "Store/SystemTemplate/SystemSlice1";

export const eQuoteSliceInitialState: ISystemSlice1State = {
    pendingApprovalsStatus: RequestStatus.Idle,
    pendingApprovalsError: null,
    selectedApprovalDetailStatus: RequestStatus.Idle,
    selectedApprovalDetailError: null,
    supportMulti: false,
    selectedApproval: null,
    selectedApprovalDetails: null,
    multiSelectedApprovals: [],
    searchQuery: "",
    isAsc: false,
    selectedSubSystem: null,
    fromPosition: 0,
    subSystems: [],
    totalCountForFilter: 0,
    pendingApprovals: [],
    isTabletView: false,
};
const EQuoteslice = createSlice({
    name: "Quote",
    initialState: eQuoteSliceInitialState,
    reducers: systemSlice1Reducer
});

export const GetPendingApprovals = getPendingApprovals1Thunk(eQuoteSliceInitialState, EQuoteslice, "Quote", "Quote");
export const GetApprovalDetails = getApprovalDetails1Thunk(EQuoteslice, "Quote", "Quote");
export const PostDeleteApprovals = postDeleteApprovals1Thunk(EQuoteslice, "Quote");
export const DeleteApprovals = deleteApprovals1Thunk(EQuoteslice, "Quote", "Quote");
//Action
export const {
    resetSystemSliceState,
    setPendingApprovalsStatus,
    setSelectedApproval,
    setSelectedApprovalDetails,
    setMultiSelectedApprovals,
    setSearchQuery,
    setIsAsc,
    setSelectedSubSystem,
    setFromPosition,
    setSubSystems,
    setTotalCountForFilter,
    setPendingApprovals,
    addToPendingApprovals,
    reCalculateSelectedApproval,
    setIsTabletView,
} = EQuoteslice.actions;

//Selector
export const EQuoteSelector = (state: IAppState) => state.Quote;
export const PendingApprovalsStatusSelector = createSelector(
    EQuoteSelector,
    (details) => details?.pendingApprovalsStatus
);
export const PendingApprovalsErrorSelector = createSelector(
    EQuoteSelector,
    (details) => details?.pendingApprovalsError
);
export const SelectedApprovalDetailStatusSelector = createSelector(
    EQuoteSelector,
    (details) => details?.selectedApprovalDetailStatus
);
export const SelectedApprovalDetailErrorSelector = createSelector(
    EQuoteSelector,
    (details) => details?.selectedApprovalDetailError
);
export const SupportMultiSelector = createSelector(
    EQuoteSelector,
    (details) => details?.supportMulti
);
export const SelectedApprovalSelector = createSelector(
    EQuoteSelector,
    (details) => details?.selectedApproval
);
export const SelectedApprovalDetailsSelector = createSelector(
    EQuoteSelector,
    (details) => details?.selectedApprovalDetails
);
export const MultiSelectedApprovalsSelector = createSelector(
    EQuoteSelector,
    (details) => details?.multiSelectedApprovals
);
export const SearchQuerySelector = createSelector(
    EQuoteSelector,
    (details) => details?.searchQuery
);
export const IsAscSelector = createSelector(
    EQuoteSelector,
    (details) => details?.isAsc
);
export const SelectedSubSystemSelector = createSelector(
    EQuoteSelector,
    (details) => details?.selectedSubSystem
);
export const FromPositionSelector = createSelector(
    EQuoteSelector,
    (details) => details?.fromPosition
);
export const SubSystemsSelector = createSelector(
    EQuoteSelector,
    (details) => details?.subSystems
);
export const TotalCountForFilterSelector = createSelector(
    EQuoteSelector,
    (details) => details?.totalCountForFilter
);
export const PendingApprovalsSelector = createSelector(
    EQuoteSelector,
    (details) => details?.pendingApprovals
);
export const IsTabletViewSelector = createSelector(
    EQuoteSelector,
    (details) => details?.isTabletView
);


export const EQuoteReducer = EQuoteslice.reducer;

