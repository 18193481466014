import React from "react";
import { Card3 } from "../../CardTemplates/Card3/Card3";
import { IEjourney } from "Model/ServerResponse";
import { Constants } from "common/constants";
import "./EJourneyCard.scss";
import Utility from "../../../common/utilities";
import { ISystemCardBaseProp } from "Model/UI";

export interface IEJourneyCardProp extends ISystemCardBaseProp {
  ejourney: IEjourney;
}

export function EJourneyCard(props: IEJourneyCardProp) {
  function getCardUI()  {
    return (
      <Card3
        label1={props.ejourney.approvalSystemId}
        label2={props.ejourney.title}
        label3={props.ejourney.complianceStatus}
        chip1={props.ejourney.risk}
        chip2={props.ejourney.type}
        label4={getDriverCount()}
        keyValue1={{
          _key: "Duration",
          value:
            Utility.formatDate(
              props.ejourney.fromDate,
              Constants.DD_MM_YY_HH_mm
            ) +
            " - " +
            Utility.formatDate(props.ejourney.toDate, Constants.DD_MM_YY_HH_mm),
        }}
        //driver count not available need to reconfirm
        keyValue2={{ _key: "", value: "", seperator: "" }}
        userDetails={{
          userId: props.ejourney.requestorID,
          userCardName: props.ejourney.requestor,
          token: "",
          userOrganization: props.ejourney.location,
        }}
        className={Utility.mergeCssClassNames([
          "ejourney-card",
          getChipStyle(props.ejourney.risk),
          getLabelStyle(props.ejourney.complianceStatus),
        ])}
      ></Card3>
    );
  }

  function getChipStyle(chipText: string | null): string {
    let chipStyleContainer = "";
    if (!chipText) {
      return "chip1-hidden";
    }

    switch (chipText.toLowerCase()) {
      case "high":
        chipStyleContainer = "chip-1-high";
        break;
      case "medium":
        chipStyleContainer = "chip-1-medium";
        break;
      case "low":
        chipStyleContainer = "chip-1-low";
        break;
      case "extreme":
        chipStyleContainer = "chip-1-extreme";
        break;
    }

    return chipStyleContainer;
  }

  function getLabelStyle(labelText: string | null): string {
    let labelStyleContainer = "";
    if (!labelText) {
      return "chip1-hidden";
    }

    if (labelText.toLowerCase() === "compliant") {
      labelStyleContainer = "label-3-compliant";
    } else {
      labelStyleContainer = "label-3-non-compliant";
    }

    return labelStyleContainer;
  }

  function getDriverCount()  {
    const drivercount =
      props.ejourney?.primaryDriverCount > 10
        ? ""
        : 0 + "" + props.ejourney?.primaryDriverCount;
    return (
      <>
        {props.ejourney?.primaryDriverCount > 1 ? (
          <div className="driverCount">
            <span>
              <strong>{drivercount}</strong>&nbsp;Drivers
            </span>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }

  return getCardUI();
}
