import Utility from "common/utilities";
import React from "react";
import { useSelector } from "react-redux";
import { SidebarOpenSelector } from "Store/Common/CommonSlice";
import "./SidebarAppContent.scss";

export interface ISidebarAppContent {
    sidebarUI: React.ReactElement;
    appContent:React.ReactElement;
}

export function SidebarAppContent(props: ISidebarAppContent) {
    let sidebarOpen = useSelector(SidebarOpenSelector);

    return (
        <div className="sidebar-app-content"  data-testid="sidebar-app-content">
            <div className={Utility.mergeCssClassNames(["sidebar-placeholder", getSidebarStyle()])} data-testid="sidebar-placeholder">
                {props.sidebarUI}
            </div>
            <div className={Utility.mergeCssClassNames(["app-content-placeholder", getAppContentStyle()])} data-testid="app-content-placeholder">
                {props.appContent}
            </div>
        </div>
    );

    function getSidebarStyle(): string {
        let result = "";
        if (!sidebarOpen) {
            result = "sidebar-close";
        }
        return result;
    }

    function getAppContentStyle(): string {
        let result = "";
        if (!sidebarOpen) {
            result = "app-content-placeholder-expanded";
        }
        return result;
    }
} 