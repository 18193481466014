import React from "react";
import { Header4 } from "../../HeaderTemplates/Header4/Header4";
import { IJRA } from "../../../Model/ServerResponse"
import { ISystemHeaderBaseProp } from "../../../Model/UI";
import Utility from "common/utilities";
import "./JRAHeader.scss";

export interface IJRAHeaderData extends ISystemHeaderBaseProp {
  jra: IJRA;
}

export function JRAHeader(
  props: IJRAHeaderData
) {
  function getHeaderUI()  {
    return (
      <Header4
        label1={props.jra.approvalSystemId}
        label2={props.jra.date}
        label3={props.jra.title}
        chip1={props.jra.jraScore + ''}
        keyValue1={{ _key: "Risk", value: props.jra.risk }}
        userDetails={{ userId: props.jra.requestorID, userCardName: props.jra.requestor, userOrganization: '(' + props.jra.opsDistrictCode + ') - ' + props.jra.opsDistrictName }}
        className={Utility.mergeCssClassNames([
          "jra-header",
          getChipStyle(props.jra.risk),
        ])}
      ></Header4>
    );
  }

  function getChipStyle(chipText: string): string {
    let result = "";

    switch (chipText.toLowerCase()) {
      case "high":
        result = "chip-1-high";
        break;
      case "medium":
        result = "chip-1-medium";
        break;
      case "low":
        result = "chip-1-low";
        break;
    }
    return result;
  }

  return (getHeaderUI());
}