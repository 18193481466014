import { ISystemDetailsCardBaseProp } from "Model/UI";
import React from "react";
import { useSelector } from "react-redux";
import { GetApprovalDetails, MultiSelectedApprovalsSelector, PendingApprovalsStatusSelector, SelectedApprovalDetailErrorSelector, SelectedApprovalDetailsSelector, SelectedApprovalDetailStatusSelector, SelectedApprovalSelector, setSelectedApprovalDetails } from "Store/Quest/QuestSlice";
import "./QuestDetailsCard.scss";
import { useAppDispatch } from "Store/store";
import { RequestStatus } from "Model/Enums";
import { Message1 } from "Components2/MessageTemplates/Message1/Message1";
import { DetailSkeleton1 } from "Components2/Skeletons/DetailSkeletons/DetailSkeleton1/DetailSkeleton1";
import { IQuest } from "Model/ServerResponse/IQuest";
import { QuestHeader } from "../QuestHeader/QuestHeader";
import { QuestDetailKeyValueList } from "../QuestDetailKeyValueList/QuestDetailKeyValueList";
import { QuestTabs } from "../QuestTabs/QuestTabs";
import { QuestRiskTable } from "../QuestRiskTable/QuestRiskTable";

export interface IQuestDetailsCardProp<T>
  extends ISystemDetailsCardBaseProp<T> { }

export function QuestDetailsCard(props: IQuestDetailsCardProp<IQuest>) {

  let appDispatch = useAppDispatch();

  let selectedApproval = useSelector(SelectedApprovalSelector);
  let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);

  let selectedApprovalDetails = useSelector(SelectedApprovalDetailsSelector);
  let selectedApprovalDetailsStatus = useSelector(SelectedApprovalDetailStatusSelector);
  let selectedApprovalDetailsError = useSelector(SelectedApprovalDetailErrorSelector);

  let pendingApprovalsStatus = useSelector(PendingApprovalsStatusSelector);

  //Re-render
  React.useEffect(() => {
    appDispatch(setSelectedApprovalDetails(null));

    if (selectedApproval) {
      appDispatch(GetApprovalDetails());
    }

  }, [selectedApproval]);

  return (
    <React.Fragment>
      <div className="quest-details-card">
        {detailsUI()}
      </div>
    </React.Fragment>

  );

  function detailsUI() {

    if (selectedApprovalDetailsStatus === RequestStatus.Loading || (pendingApprovalsStatus === RequestStatus.Loading && !selectedApproval)) {
      return loadingUI();
    }
    else if (selectedApprovalDetailsStatus === RequestStatus.Failed) {
      return errorUI();
    }
    else if (!selectedApproval) {
      return emptyIndicatorUI();
    }


    if (selectedApprovalDetails) {
      return (
        <>
          <div className="quest-details-header">
            <QuestHeader quest={selectedApprovalDetails as IQuest} ></QuestHeader>
            <div className="dividerDiv" ></div>
          </div>

          <div className="quest-details-content">
            <QuestDetailKeyValueList quest={selectedApprovalDetails as IQuest}></QuestDetailKeyValueList>
            <QuestRiskTable questRiskTableData={selectedApprovalDetails as IQuest}></QuestRiskTable>
            <QuestTabs quest={selectedApprovalDetails as IQuest}></QuestTabs>
          </div>

          {actionContainerUI()}
        </>
      );
    }
    else {
      return <></>
    }

  }

  function loadingUI(){
    return <DetailSkeleton1 />;
  }

  function emptyIndicatorUI() {
    return (
      <div className="message-container">
        <Message1 title="No details to show." imgSrc="CardAssets/NoPendingRequests.svg"></Message1>
      </div>
    )
  }

  function errorUI(){
    if (selectedApprovalDetailsError) {
      return (
        <div className="message-container">
          <Message1 title={selectedApprovalDetailsError.message}
            imgSrc="CardAssets/error.svg"
            subTitle={selectedApprovalDetailsError.description}></Message1>
        </div>
      )
    }
    return <></>;
  }

  function actionContainerUI(){
    if (selectedApprovalDetails && multiSelectedApprovals.length === 0) {
      return (
        <div className="action-container">
          {props.getActionsUI(false, [selectedApprovalDetails as IQuest])}
        </div>
      );
    }
    return <></>;
  }
}
