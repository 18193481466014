import React from "react";
import { Header3 } from "../../HeaderTemplates/Header3/Header3";
import { IQuest } from "Model/ServerResponse";
// It is important that we import local scss after Header1/2/3 so the styles are overidden in correct way
import "./QuestHeader.scss";
import { ISystemHeaderBaseProp } from "Model/UI";
import Utility from "../../../common/utilities";
import { Constants } from "common/constants";
//replace QuestDetails with props.quest and remove import and delete PODetails.json

export interface IQuestHeaderData extends ISystemHeaderBaseProp {
  quest: IQuest;
}

export function QuestHeader(props: IQuestHeaderData) {

  function getHeaderUI(){
    return (
      <Header3
        label1={props.quest.approvalSystemId}
        label2={Utility.formatDate(props.quest.date, Constants.DD_MM_YY_HH_mm)}
        label3={props.quest.title}
        chip1={props.quest.potentialRisk}
        userDetails={{
          userId: props.quest.requestorID,
          userCardName: props.quest.originator,
          userOrganization: props.quest.location,
        }}
        className={Utility.mergeCssClassNames([
          "quest-header",
          getChipStyle(props.quest.potentialRisk),
        ])}
      ></Header3>
    );
  }

  function getChipStyle(chipText: string): string {
    let result = "";

    switch (chipText.toLowerCase()) {
      case "high":
        result = "chip-1-high";
        break;
      case "medium":
        result = "chip-1-medium";
        break;
      case "low":
        result = "chip-1-low";
        break;
      case "insignificant":
        result = "chip-1-insignificant";
        break;
    }
    return result;
  }

  return getHeaderUI();
}
