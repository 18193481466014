import React from "react";
import { ITableDataMapping } from "../../../Model/UI";
import { IQuest, IQuestRisk} from "../../../Model/ServerResponse";
import { TableComponent } from "../../TableComponent/TableComponent";
import { TableCell, TableRow } from "@material-ui/core";
import Utility from "../../../common/utilities";
import "./QuestRiskTable.scss";

export interface IQuestRiskTableProp {
    questRiskTableData: IQuest | undefined;
}

export function QuestRiskTable(props: IQuestRiskTableProp) {

    let tableHeaderMapping: ITableDataMapping[] =
        [
            {
                id: "riskClassification",
                label: "Risk Classification"
            },
            {
                id: "likelyHood",
                label: "Likehood"
            },
            {
                id: "severity",
                label: "Severity"
            },
            {
                id: "risk",
                label: "Risk"
            }
        ];


    return (
        <div className="quest-risk-table">
            {tabUI()}
        </div>
    );

    function tabUI(){
        if (props.questRiskTableData && !Utility.isArrayEmpty(props.questRiskTableData)) {
            return <TableComponent tableData={props.questRiskTableData.risks} mappingTableData={tableHeaderMapping} getTableRow={getRiskTableRow}></TableComponent>
        }
        else {
            return (
                <div className="no-approvers">
                    No Risk Data
                </div>
            );
        }
    }

    function getRiskTableRow(index:number, row: IQuestRisk){
        return (
                <TableRow className="main-row" key={index}>
                    <TableCell className="risk-classification">{row.riskClassification}</TableCell>
                    <TableCell className="likelyhood">{row.likelyHood}</TableCell>
                    <TableCell className="severity">{row.severity}</TableCell>
                    <TableCell className={Utility.mergeCssClassNames(["risk",getRiskStyle(row.risk)])}>{row.risk}</TableCell>
                </TableRow>
        );
    }

    function getRiskStyle(risk: string): string {
        if (risk.toLowerCase() === "high") {
            return "risk-high";
        }
        else if (risk.toLowerCase() === "medium") {
            return "risk-medium";
        }
        else if (risk.toLowerCase() === "low") {
            return "risk-low";
        }
        else if (risk.toLowerCase() === "insignificant") {
            return "risk-insignificant";
        }
        else {
            return "";
        }
    }
}

