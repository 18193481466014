import React, { useEffect } from "react";
import { ITimeSheetTab } from "../../../Model/ServerResponse";
import "./TimeSheetDetails.scss";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Utility from "common/utilities";
import { useSelector } from "react-redux";
import { MultiSelectedApprovalsSelector } from "Store/TimeSheet/TimeSheetSlice";

export interface ITimeSheetDetailsProp {
  timeSheetDetails: ITimeSheetTab | undefined;
  sendSelectedLeaves: (data: string[]) => void;
}

export function TimeSheetDetails(props: ITimeSheetDetailsProp)  {
  const rowData = props.timeSheetDetails?.data;
  let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);

  useEffect(() => {
    if (multiSelectedApprovals.length > 0) {
      setSelected([]);
      props.sendSelectedLeaves([]);
    } else if (rowData) {
        const newSelected = rowData.map((row) => row.id);
        setSelected(newSelected);
        props.sendSelectedLeaves(newSelected);
      }
  }, [multiSelectedApprovals]);

  const [selected, setSelected] = React.useState<string[]>([]);
  const handleSelectAllClick = (event) => {
    if (event.target.checked && rowData && !Utility.isArrayEmpty(rowData)) {
      const newSelected = rowData.map((row) => row.id);
      setSelected(newSelected);
      props.sendSelectedLeaves(newSelected);
    } else {
      setSelected([]);
      props.sendSelectedLeaves([]);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else if (selectedIndex === 0) {
      newSelected = selected.slice(1);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = selected
        .slice(0, selectedIndex)
        .concat(selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
    props.sendSelectedLeaves(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  return (
    <div data-testid="time-sheet-details"className="time-sheet-details">
      <div className="grey-bg-color padding-18">
        <span className="header-label">Requests</span>
      </div>
      <Table className="top-margin-5">
        <TableHead className="grey-bg-color">
          <TableRow>
            <TableCell>
              <Checkbox
                indeterminate={
                  selected.length > 0 &&
                  rowData &&
                  selected.length < rowData?.length
                }
                disabled={multiSelectedApprovals?.length > 0}
                checked={selected.length === rowData?.length}
                onChange={handleSelectAllClick}
              />
            </TableCell>
            <TableCell className="headerLabel">Day</TableCell>
            <TableCell className="headerLabel">Date</TableCell>
            <TableCell className="headerLabel">Sub Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData && !Utility.isArrayEmpty(rowData) ? (
            rowData.map((row) => {
              const isRowSelected = isSelected(row.id);
              return (
                <TableRow
                  className="row"
                  key={row.id}
                  hover
                  role="checkbox"
                  aria-checked={isRowSelected}
                  selected={isRowSelected}
                >
                  <TableCell>
                    <Checkbox
                      disabled={multiSelectedApprovals?.length > 0}
                      onClick={(event) => handleClick(event, row.id)}
                      checked={isRowSelected}
                    />
                  </TableCell>
                  <TableCell className="day">
                    {row.startDate
                      ? new Date(row.startDate)?.toString()?.split(" ")[0]
                      : "-"}
                  </TableCell>
                  <TableCell className="date">{row.endDate}</TableCell>
                  <TableCell className="type">
                    {row.subType ? row.subType : "-"}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <div className="no-time-sheet-details">No Details</div>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
