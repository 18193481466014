import {
  ActionHandler1,
  IActionsHandler1Prop,
} from "../../../Components2/ActionsHandlerTemplate/ActionsHandler1/ActionsHandler1";
import React from "react";

export function POActions(props: IActionsHandler1Prop) {
  return (
    <ActionHandler1
      isMulti={props.isMulti}
      count={props.count}
      isApproveCommentMandatory={false}
      onApprove={props.onApprove}
      isRejectCommentMandatory={true}
      onCancel={props.onCancel}
      onReject={props.onReject}
      rejectReasonValue={props.rejectReasonValue}
      rejectDropDown={props.rejectDropDown}
      onForward={props.onForward}
    />
  );
}
