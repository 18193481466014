import React from "react";
import { Header5 } from "../../HeaderTemplates/Header5/Header5";
import { IDLP } from "Model/ServerResponse";
import { ISystemHeaderBaseProp } from "Model/UI";
import "./DLPHeader.scss";
import Utility from "common/utilities";

export interface IDLPHeaderData extends ISystemHeaderBaseProp {
  dlp: IDLP;
}

export function DLPHeader(props: IDLPHeaderData)  {
  function getHeaderUI()  {
    return (
      <Header5
        label1={props.dlp.approvalSystemId}
        label2={props.dlp.title}
        chip1={Utility.stringToPascalCase(props.dlp.risk)}
        userDetails={{
          userId: props.dlp.requestorID,
          userCardName: props.dlp.requestor,
          userOrganization: props.dlp.locationName,
        }}
        className={Utility.mergeCssClassNames([
          "dlp-header",
          getChipStyle(props.dlp.risk),
        ])}
      ></Header5>
    );
  }

  function getChipStyle(chipText: string): string {
    let result = "";

    switch (chipText.toLowerCase()) {
      case "high":
        result = "chip-1-high";
        break;
      case "medium":
        result = "chip-1-medium";
        break;
      case "low":
        result = "chip-1-low";
        break;
    }
    return result;
  }

  return getHeaderUI();
}
