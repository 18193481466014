import React from "react";
import { Grid } from "@material-ui/core";
import UserDetails from "../../UserDetails/UserDetails";
import { IHeaderTemplateBaseProp } from "../../../Model/UI";

import "./Header6.scss";
import Utility from "../../../common/utilities";

export interface IHeader6Prop extends IHeaderTemplateBaseProp {
  label1: string;
  label2: string;
  label3: string;
  label4: string;
  label5: string;
}
/*
UI placement:

    label1          label2
    label3
    userDetails             label4
                            label5
                    
 */

export function Header6(props: IHeader6Prop)  {
  function getHeaderUI()  {
    return (
      <div
        data-testid="header-6"
        className={Utility.getClassNamesFromProps("header-6", props.className)}
      >
        {/* 1st row */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="row-1"
        >
          <div className="dot"> </div>
          <p className="label-1">{props.label1}</p>
          <div className="spacer"></div>
          <p className="label-2">{props.label2}</p>
        </Grid>

        {/* 2nd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-2"
        >
          <p className="label-3">{props.label3}</p>
        </Grid>

        {/* 3rd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-3"
        >
          <UserDetails
            userId={props.userDetails.userId}
            userCardName={props.userDetails.userCardName}
            userOrganization={props.userDetails.userOrganization}
          ></UserDetails>
          <div className="spacer"></div>
          <div className="col-1">
            <p className="label-4">{props.label4}</p>
            <p className="label-5">{props.label5}</p>
          </div>
        </Grid>
      </div>
    );
  }

  return getHeaderUI();
}
