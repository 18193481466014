import {
  ICart, IFailedTakeAction,
} from "Model/ServerResponse";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  DeleteApprovals,
  PostDeleteApprovals,
  reCalculateSelectedApproval,
  SelectedApprovalSelector,
  setIsTabletView,
  setSelectedApproval
} from "Store/SWPS/SWPSSlice";
import { ListDetailsView } from "../../ListDetailsView/ListDetailsView";
import { SWPSDetailsCard } from "../SWPSDetailsCard/SWPSDetailsCard";
import { SWPSListView } from "../SWPSListView/SWPSListView";
import { SWPSActions } from "../SWPSActions/SWPSActions";
import { TakeActionFailedModal } from "../../Modals/TakeActionFailedModal/TakeActionFailedModal";
import { useAppDispatch } from "Store/store";

export interface ISWPSPageProp {
}

export function SWPSPage(props : ISWPSPageProp){
  let appDispatch = useAppDispatch();

  let selectedApproval = useSelector(SelectedApprovalSelector);
  let [takeActionFailedModalOpen, setTakeActionFailedModalOpen] =
    useState<boolean>(false);
  let [failedTakeActions, setFailedTakeActions] =
    useState<IFailedTakeAction[]>([]);



  return (
    <div>
      {takeActionFailedModalOpen && (
        <TakeActionFailedModal
          open={takeActionFailedModalOpen}
          failedActions={failedTakeActions}
          onOk={(e: any) => setTakeActionFailedModalOpen(false)}
        />
      )}
      <ListDetailsView
        selectedApproval={selectedApproval}
        onBackClick={handleBackClick}
        onWindowSizeChange={handleWindowSizeChange}
        listView={listUI}
        detailsView={detailsUI}
      />
    </div>
  );

  function listUI(){
    return (
      <SWPSListView getActionsUI={actionsUI}></SWPSListView>
    );
  }

  function detailsUI(){
    return (
      <SWPSDetailsCard getActionsUI={actionsUI}/>
    );
  }

  function actionsUI(
    _isMulti: boolean,
    _approvals: ICart[],
  ){
    return (
      <SWPSActions
          isMulti={_isMulti}
          count={_approvals.length}
          onApprove={handleOnApprove}
          onReject={handleOnReject}
        />
    );
  }

  function handleBackClick(e: any) {
    appDispatch(setSelectedApproval(null));
  }

  function handleWindowSizeChange(e: any, isTabletView : boolean){
    appDispatch(setIsTabletView(isTabletView));
    appDispatch(reCalculateSelectedApproval());
  }

  function handleOnApprove(e: any, isMulti: boolean, comment: string) {
    takeAction(true, isMulti, comment);
  }

  function handleOnReject(e: any, isMulti: boolean, comment: string) {
    takeAction(false, isMulti, comment);
  }

  async function takeAction(isApprove: boolean, isMulti: boolean, comment: string): Promise<void> {
    let result = ((await appDispatch(PostDeleteApprovals({ isApprove: isApprove, isMulti: isMulti, comment: comment }))).payload) as { passedApprovals: ICart[], failedTakeActions: IFailedTakeAction[] };
    if (result.failedTakeActions.length > 0) {
      //Some approvals failed
      setTakeActionFailedModalOpen(true);
      setFailedTakeActions(result.failedTakeActions);
    }

    //Remove cards & subtract count for passed
    appDispatch(DeleteApprovals(result.passedApprovals));
  }
}
