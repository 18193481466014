import { Checkbox } from "@material-ui/core";
import Utility from "common/utilities";
import { CardContainer } from "Components2/CardContainer/CardContainer";
import { CardSkeleton1 } from "Components2/Skeletons/CardSkeletons/CardSkeleton1/CardSkeleton1";
import { useElementOnScreen, useWindowSize } from "Hooks";
import { ScreenSize } from "Model/Enums";
import { IDefaultUIProp } from "Model/UI";
import React, { useEffect } from "react";
import "./ListView.scss";

export interface IListViewProp extends IDefaultUIProp {
  className: string;
  quickSelect?: boolean; // to select one record at a  time
  totalCount: number; //totalCount
  dataList: any[]; //list of data, used to display rows
  selectedData: any; //default selected data
  multiSelectedData: any[]; //default multi selected data
  loading: boolean; //boolean to indicate if data retrieval is in progress
  supportMulti: boolean; //boolean to indicate whether list supports multi or not
  supportFilter?: boolean; // boolean to support Filter functionality
  getFilterUI?: () => React.ReactNode; //function to get filter UI
  getRowUI: (data: any, multiSelectUI?: React.ReactNode) => React.ReactNode; //function to get row UI
  getUniqueDataIdentifier: (data: any) => string; //function to get unique identifier for each data
  onSelectChange?: (e: any, value: any) => void; //call back function for isSelected
  onMultiSelectChange?: (e: any, values: any[]) => void; //call back function for isMultiSelected
  onFromPositionChange: (fromPosition: number) => void; //page change call back function when user scroll to bottom of list
  getEmptyIndicatorUI: () => React.ReactNode;
  isTabletView: boolean;
}

interface IDataId {
  id: string;
  data: any;
}

export function ListView(props: IListViewProp) {
  let windowSize = useWindowSize();

  let [dataIdList, setDataIdList] = React.useState<IDataId[]>(
    addIdsToData(props.dataList)
  );
  let [selectedDataId, setSelectedDataId] = React.useState<IDataId | null>(
    addIdToDefaultSelectedData(props.selectedData)
  );
  let [multiSelectedDataIdList, setMultiSelectedDataIdList] = React.useState<
    IDataId[]
  >(addIdsToDefaultMultiSelectedData(props.multiSelectedData));

  const [lastListItemRef, isLastListItemVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8,
  });

  useEffect(() => {
    if (
      isLastListItemVisible &&
      !props.loading &&
      props.dataList.length < props.totalCount
    ) {
      props.onFromPositionChange(props.dataList.length);
    }
  }, [isLastListItemVisible]);

  //Re-render cards when dataList changes
  React.useEffect(() => {
    let _dataIdList = addIdsToData(props.dataList);
    //Set new data list with id's
    setDataIdList(_dataIdList);

    if (props.totalCount > 0 && props.dataList.length === 0) {
      props.onFromPositionChange(0);
    }

    // updateMultiSelectDataIdList(_dataIdList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataList]);

  React.useEffect(() => {
    setSelectedDataId(addIdToDefaultSelectedData(props.selectedData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedData]);

  React.useEffect(() => {
    setMultiSelectedDataIdList(
      addIdsToDefaultMultiSelectedData(props.multiSelectedData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.multiSelectedData]);

  return ListViewUI();

  function ListViewUI()  {
    //Return empty UI indicator
    if (dataIdList?.length === 0 && !props.loading) {
      return (
        <>
          {props.supportFilter ? (
            <div
              className={Utility.getClassNamesFromProps(
                "list-view-container",
                props.className
              )}
            >
              <div data-testid="empty-filter-container"className="empty-filter-container">
                {props.supportFilter && <>{FilterDropdown()}</>}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div data-testid="empty-indicator-container"className="empty-indicator-container">
            {props.getEmptyIndicatorUI()}
          </div>
        </>
      );
    }

    //Else return root multi-select & list UI

    return (
      <div
        className={Utility.getClassNamesFromProps(
          "list-view-container",
          props.className
        )}
      >
        <div className="row-align-multiselect-filter">
          {props.supportMulti && (
            <div className="multi-select-root-container">
                {multiSelectRootCheckBox()}
              {multiSelectedDataIdList.length > 0 ? (
                <p data-testid="multi-select-label"className="multi-select-label">
                  {multiSelectedDataIdList.length}
                </p>
              ) : (
                <p className="multi-select-label">Multi select</p>
              )}
            </div>
          )}
          {props.supportFilter && <>{FilterDropdown()}</>}
        </div>

        <div
          className="list"
          // onScroll={handleListScroll}
        >
          {dataIdList?.map((_dataId, index) => {
            return (
              <div
                key={_dataId.id}
                onClick={(e) => {
                  handleSelectClick(e, _dataId);
                }}
                ref={
                  index === dataIdList.length - 1
                    ? (lastListItemRef)
                    : null
                }
              >
                <CardContainer
                  isSelected={_dataId.id === selectedDataId?.id}
                  content={
                    props.supportMulti
                      ? props.getRowUI(
                          _dataId.data,
                          multiSelectCheckBox(_dataId)
                        )
                      : props.getRowUI(_dataId.data)
                  }
                  className="list-item"
                />
              </div>
            );
          })}

          {props.loading && (
            <CardContainer
              className="list-item"
              isSelected={false}
              content={<CardSkeleton1 />}
            />
          )}
        </div>
      </div>
    );
  }

  function FilterDropdown()  {
    if (props.getFilterUI) {
      return (
        <div className="multi-select-root-container">
          <p className="multi-select-label labelDisplay">Type</p>
          {props.getFilterUI()}
        </div>
      );
    } else {
      return <></>;
    }
  }

  function addIdToDefaultSelectedData(data: any): IDataId | null {
    if (!data) {
      return null;
    }
    return addIdToData(data);
  }

  function addIdsToDefaultMultiSelectedData(dataList: any[]): IDataId[] {
    if (!dataList) {
      return [];
    }
    return addIdsToData(dataList);
  }

  function addIdsToData(_data: any[]): IDataId[] {
    return _data?.map((_item) => {
      return addIdToData(_item);
    });
  }

  function addIdToData(_data: any): IDataId {
    return {
      id: props.getUniqueDataIdentifier(_data),
      data: _data,
    };
  }

  function handleSelectClick(e: any, _dataId: IDataId) {
    const isCheckboxClick = (e.target.id as string).startsWith("multi-select");
    const isMobileOrTablet = windowSize <= ScreenSize.TabletMx;
    const shouldTreatSeparately = isCheckboxClick && (!props.quickSelect || isMobileOrTablet);

    // If the conditions dictate that checkbox and card clicks should be treated separately, return early.
    if (shouldTreatSeparately) {
      return;
    }

    // The following code executes when either it's not a checkbox click, or when 
    // it's a checkbox click but we're treating it the same as a card click.
    setSelectedDataId(_dataId);
    if (props.onSelectChange) {
      props.onSelectChange(e, _dataId.data);
    }
}



  function multiSelectRootCheckBox()  {
    return (
        <Checkbox
          className={
            props.quickSelect
              ? "multi-select-root-checkbox-disabled"
              : "multi-select-root-checkbox"
          }
          disabled={props.quickSelect ? props.quickSelect : false}
          onChange={(e) => handleMultiSelectRootCheckBox(e)}
          checked={
            multiSelectedDataIdList.length === dataIdList.length &&
            multiSelectedDataIdList.length > 0
          }
          indeterminate={
            multiSelectedDataIdList.length > 0 &&
            multiSelectedDataIdList.length < dataIdList.length
          }
        />
    );

    function handleMultiSelectRootCheckBox(e: any) {
      let tempDataIdList: IDataId[];
      if (e.target.checked) {
        tempDataIdList = dataIdList;
      } else {
        tempDataIdList = [];
      }

      setMultiSelectedDataIdList(tempDataIdList);
      if (props.onMultiSelectChange) {
        let dataList = tempDataIdList.map((x) => {
          return x.data;
        });
        props.onMultiSelectChange(e, dataList);
      }
    }
  }

  function multiSelectCheckBox(_dataId: IDataId)  {
    return (
      <Checkbox
        id={`multi-select-${_dataId.id}`}
        className="multi-select-checkbox"
        onChange={(e) => handleMultiSelectCheckBoxChange(e, _dataId)}
        checked={
          multiSelectedDataIdList.find((x) => x.id === _dataId.id) !== undefined
        }
      />
    );

    function handleMultiSelectCheckBoxChange(e: any, dataId: IDataId) {
      let tempList: IDataId[];

      //If quick select is enabled
      if (props.quickSelect) {
        tempList = [];
      } else {
        tempList = multiSelectedDataIdList;
      }

      //Check if it checked OR unchecked
      if (e.target.checked) {
        //Add to list if checked
        tempList = tempList.concat(dataId);
      } else {
        //Remove from list if unchecked
        tempList = tempList.filter((x) => x.id !== dataId.id);
      }

      //Set dataId list
      setMultiSelectedDataIdList(tempList);

      if (props.onMultiSelectChange) {
        //Remove the unique which is only used for list's internal use, and relay the data
        let dataList = tempList.map((x) => {
          return x.data;
        });
        props.onMultiSelectChange(e, dataList);
      }
    }
  }
}
