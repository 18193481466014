import React from "react";
import { IQuest } from "../../../Model/ServerResponse";
import { IKeyValue } from "../../../Model/UI";
import "./QuestDetailKeyValueList.scss";
import { KeyValueList } from "Components2/KeyValueList/KeyValueList";
import { Constants } from "common/constants";
import Utility from "common/utilities";

export interface IQuestDetailKeyValueListProp {
  quest: IQuest;
}

export function QuestDetailKeyValueList(props: IQuestDetailKeyValueListProp) {
  function getHeaderTableUI() {
    let _data = getKeyValueListData();
    return (
      <>
        <div className="quest-details-key-value">
          <KeyValueList data={_data} />
        </div>
      </>
    );
  }

  function getKeyValueListData(): IKeyValue[] {
    let dataArray: IKeyValue[] = [];
    if (props.quest?.exemptionNumber)
      dataArray.push({
        _key: "Report No.",
        value: props.quest?.exemptionNumber,
        seperator: ":",
      });
    if (props.quest.originator)
      dataArray.push({
        _key: "Originator",
        value: props.quest.originator,
        seperator: ":",
      });
    if (props.quest.to)
      dataArray.push({
        _key: "Period",
        value:
          Utility.formatDate(props.quest.from, Constants.DD_MM_YY) +
          " - " +
          Utility.formatDate(props.quest.to, Constants.DD_MM_YY),
        seperator: ":",
      });
    if (props.quest.crmClient)
      dataArray.push({
        _key: "CRM Client",
        value: props.quest.crmClient,
        seperator: ":",
      });
    if (props.quest.aslSupplier)
      dataArray.push({
        _key: "ASL Supplier",
        value: props.quest.aslSupplier,
        seperator: ":",
      });
    return dataArray;
  }

  return getHeaderTableUI();
}
