import React from "react";
import { IEjourney, IEjourneyTab } from "../../../Model/ServerResponse";
import { ITabData } from "../../../Model/UI/ITabData";
import { DetailTabsComponent } from "../../DetailTabsComponent/DetailTabsComponent";
import { EJourneyApprovers } from "../EJourneyApprovers/EJourneyApprovers";
import { EJourneyComments } from "../EJourneyComments/EJourneyComments";
import { EJourneyDetails } from "../EJourneyDetails/EJourneyDetails";
import {
  EJ_APPROVERS_TAB_ID,
  EJ_COMMENTS_TAB_ID,
  EJ_ITEMS_TAB_ID,
} from "../EJourneyUtil";

export interface IEJTabsProp {
  ej: IEjourney;
}

export function EJourneyTabs(props: IEJTabsProp) {
  function getTabsUI()  {
    const data = getTabsData(props.ej.tabs);
    return <DetailTabsComponent tabs={data}></DetailTabsComponent>;
  }

  function getTabsData(tabs: IEjourneyTab[]): ITabData[] {
    let tdata: ITabData[] = [];

    tabs.forEach((tab: IEjourneyTab) => {
      let tabInfo: ITabData = {
        tabId: "",
        title: tab.title,
        imageSrc: "",
        // @ts-ignore: Unreachable code error
        count: tab.data.length,
      };

      //By id
      switch (tab.tabId) {
        case EJ_ITEMS_TAB_ID:
          tabInfo.tabId = EJ_ITEMS_TAB_ID;
          tabInfo.imageSrc = "./DetailTab/details_icn.svg";
          tabInfo.getTabUI = ItemsUI;
          break;
        case EJ_APPROVERS_TAB_ID:
          tabInfo.tabId = EJ_APPROVERS_TAB_ID;
          tabInfo.imageSrc = "./DetailTab/approvericn.svg";
          tabInfo.getTabUI = ApproversUI;
          break;
        case EJ_COMMENTS_TAB_ID:
          tabInfo.tabId = EJ_COMMENTS_TAB_ID;
          tabInfo.imageSrc = "./DetailTab/comment_icn.svg";
          tabInfo.getTabUI = CommentsUI;
          break;
        default:
          break;
      }
      tdata.push(tabInfo);
    });
    return tdata;
  }

  function ItemsUI()  {
    return <EJourneyDetails ej={props.ej} />;
  }

  function ApproversUI()  {
    let _ejApproversData = props.ej.tabs.find(
      (td) => td.tabId === EJ_APPROVERS_TAB_ID
    );
    return <EJourneyApprovers ejApproversTabData={_ejApproversData} />;
  }

  function CommentsUI()  {
    let _ejItemsData = props.ej.tabs.find(
      (td) => td.tabId === EJ_COMMENTS_TAB_ID
    );
    return <EJourneyComments ejTabData={_ejItemsData} />;
  }

  return getTabsUI();
}
