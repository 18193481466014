import { SearchFilterToolbar } from "../../SearchFilterToolbar/SearchFilterToolbar";
import { Message1 } from "../../MessageTemplates/Message1/Message1";
import React, { useEffect } from "react";
import { ListView } from "../../ListView/ListView";
import { ISystemListViewBaseProp } from "Model/UI";
import { useSelector } from "react-redux";
import { RequestStatus } from "../../../Model/Enums";
import {
  IsAscSelector,
  MultiSelectedApprovalsSelector,
  PendingApprovalsSelector,
  SearchQuerySelector,
  SelectedApprovalSelector,
  SelectedSubSystemSelector,
  setIsAsc,
  setMultiSelectedApprovals,
  setFromPosition,
  setSearchQuery,
  setSelectedApproval,
  setSelectedSubSystem,
  SubSystemsSelector,
  TotalCountForFilterSelector,
  GetPendingApprovals,
  resetSystemSliceState,
  PendingApprovalsStatusSelector,
  PendingApprovalsErrorSelector,
  SupportMultiSelector,
  watershedSliceInitialState,
  IsTabletViewSelector,
} from "Store/Watershed/WatershedSlice";
import { useAppDispatch } from "../../../Store/store";
import "./WatershedListView.scss";
import { IWatershed } from "Model/ServerResponse/IWatershed";
import { WatershedCard } from "../WatershedCard/WatershedCard";
import VariablesJson from "../../../configVariables.json";

export interface IWatershedListViewProp<T> extends ISystemListViewBaseProp<T> {}

export function WatershedListView(props: IWatershedListViewProp<IWatershed>) {
  let appDispatch = useAppDispatch();

  let supportMulti = useSelector(SupportMultiSelector);

  let pendingApprovalsStatus = useSelector(PendingApprovalsStatusSelector);
  let pendingApprovalsError = useSelector(PendingApprovalsErrorSelector);

  let selectedApproval = useSelector(SelectedApprovalSelector);
  let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);
  let subSystems = useSelector(SubSystemsSelector);
  let totalCountForFilter = useSelector(TotalCountForFilterSelector);
  let pendingApprovals = useSelector(PendingApprovalsSelector);

  let searchQuery = useSelector(SearchQuerySelector);
  let isAsc = useSelector(IsAscSelector);
  let selectedSubSystem = useSelector(SelectedSubSystemSelector);

  let isTabletView = useSelector(IsTabletViewSelector);

  useEffect(() => {
    appDispatch(resetSystemSliceState(watershedSliceInitialState));
  }, []);

  //Set up data
  useEffect(() => {
    appDispatch(setSelectedApproval(null));
    appDispatch(GetPendingApprovals());
  }, [searchQuery, isAsc, selectedSubSystem]);

  return (
    <div className="watershed-list-container">
      <SearchFilterToolbar
        multiSelectedCount={multiSelectedApprovals?.length}
        title="Task Sheet"
        totalCount={totalCountForFilter}
        error={pendingApprovalsStatus === RequestStatus.Failed}
        count={pendingApprovals?.length}
        subSystems={subSystems}
        powerBILink={VariablesJson.Variables.power_bi_tasksheet_link}
        powerBIReportText="Approval History"
        loading={pendingApprovalsStatus === RequestStatus.Loading}
        onRefresh={handleRefresh}
        onSearch={handleSearchChange}
        onSort={handleSortChange}
        onSubSystemChange={handleSubSystemChange}
      ></SearchFilterToolbar>
      {listUI()}
    </div>
  );

  function getRowUI(data: IWatershed, multiSelectUI) {
    return (
      <>
        <WatershedCard
          supportMulti={supportMulti}
          multiSelectUI={multiSelectUI}
          waterShed={data}
        ></WatershedCard>
      </>
    );
  }

  function listUI() {
    if (pendingApprovalsStatus === RequestStatus.Failed) {
      return errorUI();
    }

    return (
      <>
        <ListView
          className="watershed-list"
          totalCount={totalCountForFilter}
          dataList={pendingApprovals}
          selectedData={selectedApproval}
          multiSelectedData={multiSelectedApprovals}
          loading={pendingApprovalsStatus === RequestStatus.Loading}
          supportMulti={supportMulti}
          getRowUI={getRowUI}
          getUniqueDataIdentifier={getUniqueDataIndentifier}
          onSelectChange={handleSelectChange}
          onMultiSelectChange={handleMultiSelectChange}
          onFromPositionChange={handleFromPositionChange}
          getEmptyIndicatorUI={emptyIndicatorUI}
          isTabletView={isTabletView}
        ></ListView>

        {multiSelectedApprovals?.length > 0 && actionContainer()}
      </>
    );
  }

  function emptyIndicatorUI() {
    return (
      <div className="message-container">
        <Message1
          title="No approvals."
          imgSrc="CardAssets/NoPendingRequests.svg"
        ></Message1>
      </div>
    );
  }

  function errorUI() {
    if (pendingApprovalsError) {
      return (
        <div className="message-container">
          <Message1
            title={pendingApprovalsError.message}
            subTitle={pendingApprovalsError.description}
            imgSrc="CardAssets/error.svg"
          ></Message1>
        </div>
      );
    }
    return <></>;
  }

  function handleRefresh(e: any) {
    //Reset all state
    appDispatch(resetSystemSliceState(watershedSliceInitialState));
    //Get approvals
    appDispatch(GetPendingApprovals());
  }

  function handleSearchChange(e: any, _query: string) {
    appDispatch(setSearchQuery(_query));
  }

  function handleSortChange(e: any, _isAsc: boolean) {
    appDispatch(setIsAsc(_isAsc));
  }

  function handleSubSystemChange(e: any, _subSystem: any) {
    appDispatch(setSelectedSubSystem(_subSystem));
  }

  function getUniqueDataIndentifier(_data: IWatershed): string {
    //Return unique identifier
    return _data.id.toString();
  }

  function handleFromPositionChange(_fromPostion: number): void {
    appDispatch(setFromPosition(_fromPostion));
    appDispatch(GetPendingApprovals());
  }

  function handleMultiSelectChange(e: any, _values: any) {
    appDispatch(setMultiSelectedApprovals(_values));
  }

  function handleSelectChange(e: any, _value: any) {
    appDispatch(setSelectedApproval(_value));
  }

  function actionContainer() {
    if (props.getActionsUI) {
      return (
        <div className="action-container">
          {props.getActionsUI(true, multiSelectedApprovals as IWatershed[])}
        </div>
      );
    }
    return <></>;
  }
}
