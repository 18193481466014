import { configureStore } from "@reduxjs/toolkit";
import { CommonStateReducer } from "./Common/CommonSlice";
import { SWPSReducer } from "./SWPS/SWPSSlice";
import { UserSystemReducer } from "./UserSystemProfile/UserSystemSlice";
import thunk from "redux-thunk";
import { RoutesReducer } from "./Routes/RoutesSlice";
import { NewApprovalOperationSelectorReducer } from "./UserSystemProfile/NewApprovalOperationSelectorSlice";
import { useDispatch } from "react-redux";
import { UserSystemModalReducer } from "./UserSystemProfile/UserSystemModalSlice";
import { PRReducer } from "./PR/PRSlice";
import { QuestReducer } from "./Quest/QuestSlice";
import { TimeSheetReducer } from "./TimeSheet/TimeSheetSlice";
import { POReducer } from "./PO/POSlice";
import { EJourneyReducer } from "./EJourney/EJourneySlice";
import { DLPReducer } from "./DLP/DLPSlice";
import { JRAReducer } from "./JRA/JRASlice";
import { PretripReducer } from "./PreTrip/PreTripSlice";
import { WatershedReducer } from "./Watershed/WatershedSlice";
import { EQuoteReducer } from "./EQuote/EQuoteSlice";
import { SRMReducer } from "./SRM/SRMSlice";

const store = configureStore({
  reducer: {
    Common: CommonStateReducer,
    Cart: SWPSReducer,
    PR: PRReducer,
    Timesheet: TimeSheetReducer,
    PO: POReducer,
    QuestChange: QuestReducer,
    UserSystem: UserSystemReducer,
    Trip: EJourneyReducer,
    Plan: DLPReducer,
    FDP: JRAReducer,
    Travel: PretripReducer,
    Watershed: WatershedReducer,
    Quote: EQuoteReducer,
    SRM: SRMReducer,
    Routes: RoutesReducer,
    NewApprovalOperationSelector: NewApprovalOperationSelectorReducer,
    UserSystemModal: UserSystemModalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
});

export type IAppState = ReturnType<typeof store.getState>;
export type IAppDispatch = typeof store.dispatch;
export let useAppDispatch = () => useDispatch<IAppDispatch>();

export default store;
