import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { EJ_ITEMS_TAB_ID } from "Components2/EJourney/EJourneyUtil";
import moment from "moment";
import React from "react";
import { Constants } from "../../../../common/constants";
import Utility from "../../../../common/utilities";
import {
  IEjourney,
  IEjourneyDetailData,
  IEjourneyDriverFatigue,
} from "../../../../Model/ServerResponse";
import { Accordian } from "../../../Accordian/Accordian";
import "./EJourneyDriverFatigue.scss";

export interface IEJourneyDriverFatigueProps {
  ejItemData: IEjourney;
}
export function EJourneyDriverFatigue(
  props: IEJourneyDriverFatigueProps
)  {
  const tripData: IEjourneyDetailData = props.ejItemData.tabs.find(
    (td) => td.tabId === EJ_ITEMS_TAB_ID
  )?.data as IEjourneyDetailData;
  const fatigueData = tripData?.driverFatigues;
  return (
    <>
      {!Utility.isArrayEmpty(fatigueData) ? (
        <div className="driver-fatigue">
          <div className="panelheader">
            <div>
              <span className="panelheadertext">
                <b>Driver Fatigue Status</b>
              </span>
              <span className="panelheadertext score">
                {" "}
                <b> {("0" + fatigueData?.length).slice(-2)}</b>
              </span>
            </div>
          </div>

          {fatigueData.map((element: IEjourneyDriverFatigue, index: number) => {
            const key = `${element.name}-${index}`;
            return (
              <div key={key} className="border-bottom-container">
                <Accordian
                  headerContent={getHeaderUI(element)}
                  content={getContentUI(element)}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  );

  function getHeaderUI(element: IEjourneyDriverFatigue)  {
    return (
      <Grid container>
        <Grid item lg={4} xs={4} sm={4} md={4}>
          <Typography>Driver</Typography>
        </Grid>
        <Grid item lg={4} xs={4} sm={4} md={4}>
          <strong className="driver-name">{element.name}</strong>
        </Grid>
        <Grid item lg={4} xs={4} sm={4} md={4}>
          <Typography
            className={
              element.restStatus?.toLowerCase() === "not rested"
                ? "rest-red-Status"
                : "rest-green-Status"
            }
          >
            {element.restStatus}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  function getContentUI(element: any)  {
    let stackedBarChartData = getDataFromTimeline(element.timeline);
    let bodyMatrix = getTableBodyMatrix(element.restSlots, element.dimSlots);
    return (
      <Grid container direction="column">
        <Grid container item>
          {getRow(element.qualityRestDuration)}
        </Grid>

        <Grid container className="padding-row" item direction="column">
          {/* 24 hrs */}
          <Grid item className="grid-Container3">
            24 hrs
          </Grid>
          {/* horizontal stacked bar chart */}
          <Grid item container className="grid-container">
            {stackedBarChartData.map((data, index: number) => {
              const key = `${data}-${index}`;
              return (
                <div
                  key={key}
                  style={{
                    width: String(Number(2.08333333 * data.fill)) + "%",
                    height: "15px",
                    backgroundColor: data.color,
                  }}
                ></div>
              );
            })}
          </Grid>
          {/* start end time interval below chart */}

          <Grid item className="grid-container1" container direction="row">
            <Grid item>{formatDate(new Date(element.timeline[0]?.slot))}</Grid>
            <Grid item>
              {formatDate(
                add30Min(
                  new Date(element.timeline[element.timeline.length - 1]?.slot)
                )
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container item className="grid-container2">
          <TableContainer data-testid="cell">
            <Table>
              <TableHead>
                <TableRow className="tableRow">
                  <TableCell className="tableHeader">Rested Hours</TableCell>
                  <TableCell className="tableHeader">DIM Hours</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bodyMatrix.map((bodyRow: any, index) => {
                  return (
                    <TableRow key={Utility.createUniqueId(index,bodyRow)}>
                      {bodyRow.map((bodyValue: any, index: number) => {
                        return (
                          <div key={Utility.createUniqueId(index,bodyValue)}>
                            <TableCell className="tableCell" key={Utility.createUniqueId(index,bodyValue)}>
                              {bodyValue}
                            </TableCell>
                          </div>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }

  function getRow(content: any) {
    return (
      <Grid container className="rowContainer">
        <Grid item lg={4} xs={4} sm={4} md={4}>
          <Typography className="restedHours">Rested Hours</Typography>
        </Grid>
        {/* content */}
        <Grid item lg={4} xs={4} sm={4} md={4}>
          <Typography className="restedValue">{content} Hrs</Typography>
        </Grid>
      </Grid>
    );
  }

  function formatDate(date: Date) {
    return moment(date).format(Constants.D_MMM_xa0_HH_mm);
  }

  function add30Min(date: Date) {
    return moment(date).add(30, "minutes").toDate();
  }

  function getDataFromTimeline(timeline: any) {
    let data: any = [];
    let block = {
      fill: 1,
      color: "",
    };
    let restedColor = "#3CBE46";
    let idleStateColor = "#EAEAEA";
    let dimStateColor = "#EA910D";

    //when data empty
    //add green
    if (timeline[0]?.isRest === true) {
      block.color = restedColor;
    }
    //add orange
    else if (timeline[0]?.isDim === true) {
      block.color = dimStateColor;
    }
    //add white
    else {
      block.color = idleStateColor;
    }

    data.push(block);

    for (let i = 1; i < timeline.length; i++) {
      let lastBlockColor: string = data[data.length - 1].color;
      let newBlockColor = "";

      if (timeline[i].isRest === true) {
        newBlockColor = restedColor;
      } else if (timeline[i].isDim === true) {
        newBlockColor = dimStateColor;
      } else {
        newBlockColor = idleStateColor;
      }

      if (lastBlockColor !== newBlockColor) {
        let newBlock = {
          fill: 1,
          color: newBlockColor,
        };
        data.push(newBlock);
      } else {
        data[data.length - 1].fill += 1;
      }
    }

    return data;
  }

  function getTableBodyMatrix(
    restSlots: (string | undefined)[],
    dimSlots: (string | undefined)[]
  ): string[][] {
    let loopLength = Math.max(restSlots?.length, dimSlots?.length);
    let matrix: string[][] = [];
    for (let i = 0; i < loopLength; i++) {
      let arr: string[] = [];
      if (restSlots[i] !== undefined) {
        arr.push(restSlots[i]!);
      } else {
        arr.push("");
      }

      if (dimSlots[i] !== undefined) {
        arr.push(dimSlots[i]!);
      } else {
        arr.push("");
      }
      matrix.push(arr);
    }
    return matrix;
  }
}
