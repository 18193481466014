import React from "react";
import {
    IQuestDetailData,
    IQuestAnswerData
} from "../../../Model/ServerResponse";
import "./QuestDetailsRow.scss";

export interface IQuestDetailsRowProp {
    rowData: IQuestDetailData | undefined;
}

export function QuestDetailsRow(props: IQuestDetailsRowProp){
    return (
        <div className="main-container">
            <div className="title-container">
            <div className="quest-section-title">{props.rowData?.title}</div>
            </div>
            <div>
                {getRowData()}
            </div>
        </div>
    )

    function getRowData(){
            return (
                <div className="subtitle-container">
                    {
                        props.rowData?.questions.map(
                            (e:IQuestAnswerData) => {
                                return (
                                    <>
                                    <div className="quest-details-row-title"> {e.title}</div>
                                    <div className="quest-details-row-answer"> {e.answer}</div>
                                    </>
                                );
                            }
                        )
    }
                </div>
            );
}
}

                        
                
        