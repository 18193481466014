import { ISystemCardBaseProp } from "Model/UI";
import React from "react";
import { Card5 } from "../../CardTemplates/Card5/Card5";
import { IPretrip } from "Model/ServerResponse";

import "./PreTripCard.scss";
import Utility from "common/utilities";
import { Constants } from "common/constants";

export interface IPreTripCardProp extends ISystemCardBaseProp {
  preTrip: IPretrip;
}

export function PreTripCard(props: IPreTripCardProp) {
  function getCardUI(){
    return (
      <Card5
        label1={props.preTrip.approvalSystemId}
        label2={props.preTrip.isCompliant ? "Compliant" : "Non-Compliant"}
        label3={props.preTrip.title}
        label4={Utility.formatDate(props.preTrip.date, Constants.DD_MM_YY)}
        keyValue1={{
          _key: "Duration",
          value: props.preTrip.fromDate + " - " + props.preTrip.toDate,
        }}
        userDetails={{
          userId: props.preTrip.requestorID,
          userCardName: props.preTrip.requestor,
          userOrganization: props.preTrip.jobTitle,
        }}
        className={Utility.mergeCssClassNames([
          "pretrip-card",
          getCompliantStyle(props.preTrip.isCompliant),
        ])}
        supportMulti={props.supportMulti}
        multiSelectUI={props.multiSelectUI}
      ></Card5>
    );
  }

  function getCompliantStyle(isCompliant: boolean) {
    return isCompliant ? "is-compliant-green" : "is-compliant-red";
  }

  return getCardUI();
}
