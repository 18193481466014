import React from "react";
import { Header1 } from "../../HeaderTemplates/Header1/Header1";
import { ITimeSheet } from "Model/ServerResponse";
// It is important that we import local scss after Card1/2/3 so the styles are overidden in correct way
import "./TimeSheetHeader.scss";
import { ISystemHeaderBaseProp } from "Model/UI";
import Utility from "common/utilities";
import { Constants } from "common/constants";

export interface ITimeSheetHeaderData extends ISystemHeaderBaseProp {
  timeSheet: ITimeSheet ;
}

export function TimeSheetHeader(props: ITimeSheetHeaderData) {
  function getHeaderUI()  {
    return (
      <Header1
        label1={props.timeSheet.approvalSystemId}
        label2={Utility.formatDate(props.timeSheet.date, Constants.DD_MM_YY)}
        label3={props.timeSheet.title}
        keyValue1={{ _key: '', value: '' }}
        userDetails={{
          userId: props.timeSheet.requestorID,
          userCardName: props.timeSheet.requestor,
          userOrganization: props.timeSheet.jobTitle,
        }}
        className="timesheet-header"
      ></Header1>
    );
  }


  return (getHeaderUI());
}
