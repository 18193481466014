import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@material-ui/core";
import React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import { IDefaultUIProp } from "Model/UI";
import "./PWADialog.scss";

export interface IPWADialogProp extends IDefaultUIProp {
  onClose: (e: any) => void;
  open: boolean;
}

export function PWADialog(props: IPWADialogProp) {
  let minWidth ;

  if (window.innerWidth < 415) {
      minWidth = "70%";
  } else if (window.innerWidth < 768) {
      minWidth = "40%";
  } else {
      minWidth = "20%";
  }

  let maxWidth ;
    
    if (window.innerWidth < 415) {
      maxWidth = "75%";
  } else if (window.innerWidth < 768) {
      maxWidth = "45%";
  } else {
      maxWidth = "25%";
  }
  const minHeight = `${window.innerWidth < 415 ? "16%" : "10%"}`;
  const maxHeight = `${window.innerWidth < 415 ? "32%" : "30%"}`;
  const PaperStyle = {
    margin: "10px",
    borderRadius: "5px",
    minWidth: minWidth,
    maxWidth: maxWidth,
    minHeight: minHeight,
    maxHeight: maxHeight,
  };
  return (
    <div>
      <Dialog
        className="dialog-pwa"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{ style: PaperStyle }}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={props.onClose}
        scroll="paper"
        open={props.open}
      >
        {
          <React.Fragment>
            {/* Header */}
            <DialogTitle className="title">
              <div className="title-header">
                <div className="header">
                  {/* Buttons */}
                  <div className="button-close-container">
                    <IconButton onClick={() => props.onClose(false)}>
                      <ClearIcon
                        data-testid="close-button"
                        fontSize="small"
                        className="close-button"
                      ></ClearIcon>
                    </IconButton>
                  </div>
                </div>
              </div>
              <DialogContent className="content">
                <Grid container>
                  <div className="title">Approve Buddy</div>
                </Grid>
                <div className="button-container">
                  <Button
                    onClick={() => props.onClose(true)}
                    className="dialog-button"
                  >
                    {"Add to Home Screen"}
                  </Button>
                </div>
              </DialogContent>
            </DialogTitle>

            {/* Content */}
          </React.Fragment>
        }
      </Dialog>
    </div>
  );
}
