import { } from "@material-ui/core";
import utilities from "common/utilities";
import { AttachmentCell } from "Components2/AttachmentList/AttachmentCell";
import { AttachmentModal } from "Components2/Modals/AttachmentModal/AttachmentModal";
import { IAttachmentData, IServerResponse } from "Model/ServerResponse";
import { IDefaultUIProp, IPOItemAttachmentData } from "Model/UI";
import React, { useState } from "react";
import { AttachmentService } from "Services";

export interface IPOItemAttachmentProp extends IDefaultUIProp {
    po: IPOItemAttachmentData[] | undefined;
    approvalSystemId: string;
    poID: string;
}

export function POItemAttachment(props: IPOItemAttachmentProp){
    let attachmentData = props.po;
    const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
    const [selectedFilename, setSelectedFilename] = useState("");
    const [selectedFileID, setselectedFileID] = useState("");

    const handleAttachmentModalClose = () => {
        setAttachmentModalOpen(false);
    };

    function handleAttachmentClick(e: any, fileName: string, fileID: string) {
        setAttachmentModalOpen(true);
        setSelectedFilename(fileName);
        setselectedFileID(fileID);
    }

    let attachmentService = AttachmentService.getInstance();

    async function getAttachmentData(
        fileID: string,
        filePath: string
    ): Promise<IAttachmentData> {
        return attachmentService
            .getAttachmentData(
                `api/approvals/${utilities.getSystemActivityMapping(props.approvalSystemId)[1]
                }/${utilities.getSystemActivityMapping(props.approvalSystemId)[0]
                }/${props.poID}/attachments/${fileID}`
            )
            .then((attachmentResponse: IServerResponse<IAttachmentData>) => {
                return attachmentResponse.payload!;
            });
    }

    function getAttachmentList(){
        if (attachmentData !== undefined && attachmentData.length > 0) {
          return (
            <div data-testid="attachment-list" className="attachment-list">
              {attachmentData.map((e) => {
                if (e.id !== undefined && e.fileName !== undefined) {
                  return (
                    <AttachmentCell
                      key={e.id}
                      onClick={handleAttachmentClick}
                      fileID={e.id}
                      fileName={e.fileName}
                      isDownloadable={true}
                    />
                  );
                } else {
                  return null; // Skip rendering the AttachmentCell if either fileID or fileName is undefined
                }
              })}
            </div>
          );
        } else {
          return <div className="no-attachment"> No Attachments </div>;
        }
      }      

    return (
        <>
        <div data-testid="attachment-modal">
      <div data-testid="attachment-cell"> 
            {getAttachmentList()}</div>
            <AttachmentModal
                open={attachmentModalOpen}
                selectedFilename={selectedFilename}
                selectedFileID={selectedFileID}
                getAttachmentData={getAttachmentData}
                onClose={handleAttachmentModalClose}
            />
            </div>
        </>
    );

}


