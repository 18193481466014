import { ITimeSheet } from "../../../Model/ServerResponse";
import { ISystemDetailsCardBaseProp } from "../../../Model/UI";
import React from "react";
import { useSelector } from "react-redux";
import {
  GetApprovalDetails,
  MultiSelectedApprovalsSelector,
  PendingApprovalsStatusSelector,
  SelectedApprovalDetailErrorSelector,
  SelectedApprovalDetailsSelector,
  SelectedApprovalDetailStatusSelector,
  SelectedApprovalSelector,
  setSelectedApprovalDetails,
} from "../../../Store/TimeSheet/TimeSheetSlice";
import { TimeSheetTabs } from "../TimeSheetTabs/TimeSheetTabs";
import { TimeSheetHeader } from "../TimeSheetHeader/TimeSheetHeader";
import "./TimeSheetDetailsCard.scss";
import { useAppDispatch } from "../../../Store/store";
import { RequestStatus } from "../../../Model/Enums";
import { Message1 } from "../../../Components2/MessageTemplates/Message1/Message1";
import { DetailSkeleton1 } from "../../../Components2/Skeletons/DetailSkeletons/DetailSkeleton1/DetailSkeleton1";

export interface ITimeSheetDetailsCardProp<T>
  extends ISystemDetailsCardBaseProp<T> {
  sendSelectedLeaves: (data: string[]) => void;
}

export function TimeSheetDetailsCard(
  props: ITimeSheetDetailsCardProp<ITimeSheet>
) {
  let appDispatch = useAppDispatch();

  let selectedApproval = useSelector(SelectedApprovalSelector);
  let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);

  let selectedApprovalDetails = useSelector(SelectedApprovalDetailsSelector);
  let selectedApprovalDetailsStatus = useSelector(
    SelectedApprovalDetailStatusSelector
  );
  let selectedApprovalDetailsError = useSelector(
    SelectedApprovalDetailErrorSelector
  );

  let pendingApprovalsStatus = useSelector(PendingApprovalsStatusSelector);

  //Re-render
  React.useEffect(() => {
    appDispatch(setSelectedApprovalDetails(null));

    if (selectedApproval) {
      appDispatch(GetApprovalDetails());
    }
  }, [selectedApproval]);

  return (
      <div className="timesheet-details-card">{detailsUI()}</div>
  );

  function detailsUI()  {
    const handleSelectedLeaveItems = (data: string[]) => {
      props.sendSelectedLeaves(data);
    };

    if (
      selectedApprovalDetailsStatus === RequestStatus.Loading ||
      (pendingApprovalsStatus === RequestStatus.Loading && !selectedApproval)
    ) {
      return loadingUI();
    } else if (selectedApprovalDetailsStatus === RequestStatus.Failed) {
      return errorUI();
    } else if (!selectedApproval) {
      return emptyIndicatorUI();
    }

    if (selectedApprovalDetails) {
      return (
        <>
          <div className="timesheet-details-header">
            <TimeSheetHeader
              timeSheet={selectedApprovalDetails as ITimeSheet}
            ></TimeSheetHeader>
            <div className="dividerDiv"></div>
          </div>

          <div className="timesheet-details-content">
            <TimeSheetTabs
              sendSelectedLeaves={handleSelectedLeaveItems}
              timeSheet={selectedApprovalDetails as ITimeSheet}
            ></TimeSheetTabs>
          </div>

          {actionContainerUI()}
        </>
      );
    } else {
      return <></>;
    }
  }

  function loadingUI()  {
    return <DetailSkeleton1 />;
  }

  function emptyIndicatorUI()  {
    return (
      <div className="message-container">
        <Message1
          title="No details to show."
          imgSrc="CardAssets/NoPendingRequests.svg"
        ></Message1>
      </div>
    );
  }

  function errorUI()  {
    if (selectedApprovalDetailsError) {
      return (
        <div className="message-container">
          <Message1
            title={selectedApprovalDetailsError.message}
            imgSrc="CardAssets/error.svg"
            subTitle={selectedApprovalDetailsError.description}
          ></Message1>
        </div>
      );
    }
    return <></>;
  }

  function actionContainerUI()  {
    if (selectedApprovalDetails && multiSelectedApprovals.length === 0) {
      return (
        <div className="action-container">
          {props.getActionsUI(false, [selectedApprovalDetails as ITimeSheet])}
        </div>
      );
    }
    return <></>;
  }
}
