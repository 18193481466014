import { Card, Grid, Typography } from "@material-ui/core";
import React from "react";
import UserDetails from "../../Components2/UserDetails/UserDetails";
import styles from "./CommentsTemplate.module.scss";
import Utility from "../../common/utilities";
import { IComment } from "../../Model/UI/IComment";

export interface ICommentsTemplateProps {
  commentData: IComment[];
}

export function CommentsTemplate(props: ICommentsTemplateProps) {
  return (
    <React.Fragment>
      {!Utility.isArrayEmpty(props.commentData) ? (
        <Grid className={styles.commentCard}>
          {props.commentData?.map((comment: IComment, index: number) => (
            <Card className={styles.cardContainer} key={Utility.createUniqueId(comment,index)}>
              <Grid container className={styles.parentContainer}>
                <UserDetails
                  userOrganization={comment.label1}
                  userId=""
                  userCardName={comment.label2}
                />
                {comment.label4 ? (
                  <div className={styles.commentContainer}>
                    <Typography className={styles.commentTitle}>
                      {comment.label4}
                    </Typography>
                  </div>
                ) : (
                  <></>
                )}
                <div className={styles.commentContainer}>
                  <Typography className={styles.comment}>
                    {comment.label3}
                  </Typography>
                </div>
              </Grid>
            </Card>
          ))}
        </Grid>
      ) : (
        <div className={styles.noComments}>No Comments</div>
      )}
    </React.Fragment>
  );
}
