import React from "react";
import { IPretrip, IPretripTab } from "Model/ServerResponse";
import { ITabData } from "Model/UI";
import { DetailTabsComponent } from "Components2/DetailTabsComponent/DetailTabsComponent";
import { PreTripDetails } from "../PreTripDetails/PreTripDetails";
import { PRETRIP_DETAILS_TAB_ID, PRETRIP_APPROVERS_TAB_ID } from "../PreTripUtil";
import { PreTripApprovers } from "../PreTripApprovers/PreTripApprovers";

export interface IPretripTabsProp {
    pretrip: IPretrip;
}

export function PreTripTabs(props: IPretripTabsProp) {
    function getTabsUI() {
        const data = getTabsData(props.pretrip.tabs);
        return (
            <DetailTabsComponent tabs={data}></DetailTabsComponent>
        );
    }

    function getTabsData(tabs: IPretripTab[]): ITabData[] {
        let tdata: ITabData[] = [];

        tabs.forEach((tab: IPretripTab) => {
            let tabInfo: ITabData = {
                tabId: "",
                title: tab.title,
                imageSrc: "",
                count: tab.count,
            };

            //Switch Tab By id
            switch (tab.tabId) {
                case PRETRIP_DETAILS_TAB_ID:
                    tabInfo.tabId = PRETRIP_DETAILS_TAB_ID; tabInfo.imageSrc = "./DetailTab/details_icn.svg"; tabInfo.getTabUI = ItemsUI;
                    break;
                case PRETRIP_APPROVERS_TAB_ID:
                    tabInfo.tabId = PRETRIP_APPROVERS_TAB_ID; tabInfo.imageSrc = "./DetailTab/approvericn.svg"; tabInfo.getTabUI = ApproversUI;
                    break;
                default:
                    break;
            }
            tdata.push(tabInfo);
        });
        return tdata;
    }

    function ItemsUI() {
        return (
            <PreTripDetails pretrip={props.pretrip} />
        )
    }

    function ApproversUI() {
        let _pretripData = props.pretrip.tabs.find(td => td.tabId === PRETRIP_APPROVERS_TAB_ID)
        return (
            <PreTripApprovers pretripApproversTabData={_pretripData} />
        )
    }

    return getTabsUI();

}

