import {
    createSelector, createSlice,
  } from "@reduxjs/toolkit";
  import { RequestStatus } from "Model/Enums";
  import { IAppState } from "Store/store";
  import { getPendingApprovals1Thunk, getApprovalDetails1Thunk, postDeleteApprovals1Thunk, deleteApprovals1Thunk, systemSlice1Reducer, ISystemSlice1State } from "Store/SystemTemplate/SystemSlice1";
  
    export const dlpSliceInitialState: ISystemSlice1State = {
        pendingApprovalsStatus: RequestStatus.Idle,
        pendingApprovalsError: null,
        selectedApprovalDetailStatus: RequestStatus.Idle,
        selectedApprovalDetailError: null,
        supportMulti: false,
        selectedApproval: null,
        selectedApprovalDetails: null,
        multiSelectedApprovals: [],
        searchQuery: "",
        isAsc: false,
        selectedSubSystem: null,
        fromPosition: 0,
        subSystems: [],
        totalCountForFilter: 0,
        pendingApprovals: [],
        isTabletView: false,
    };
    const DLPslice = createSlice({
        name: "Plan",
        initialState: dlpSliceInitialState,
        reducers: systemSlice1Reducer
    });

    export const GetPendingApprovals = getPendingApprovals1Thunk(dlpSliceInitialState, DLPslice, "Plan", "Plan");
    export const GetApprovalDetails = getApprovalDetails1Thunk(DLPslice, "Plan", "Plan");
    export const PostDeleteApprovals = postDeleteApprovals1Thunk(DLPslice, "Plan");
    export const DeleteApprovals = deleteApprovals1Thunk(DLPslice, "Plan", "Plan");
    //Action
    export const {
        resetSystemSliceState,
        setPendingApprovalsStatus,
        setSelectedApproval,
        setSelectedApprovalDetails,
        setMultiSelectedApprovals,
        setSearchQuery,
        setIsAsc,
        setSelectedSubSystem,
        setFromPosition,
        setSubSystems,
        setTotalCountForFilter,
        setPendingApprovals,
        addToPendingApprovals,
        reCalculateSelectedApproval,
        setIsTabletView,
      } = DLPslice.actions;

        //Selector
    export const DLPSelector = (state: IAppState) => state.Plan;
    export const PendingApprovalsStatusSelector = createSelector(
        DLPSelector,
        (details) => details?.pendingApprovalsStatus
      );
      export const PendingApprovalsErrorSelector = createSelector(
        DLPSelector,
        (details) => details?.pendingApprovalsError
      );
      export const SelectedApprovalDetailStatusSelector = createSelector(
        DLPSelector,
        (details) => details?.selectedApprovalDetailStatus
      );
      export const SelectedApprovalDetailErrorSelector = createSelector(
        DLPSelector,
        (details) => details?.selectedApprovalDetailError
      );
      export const SupportMultiSelector = createSelector(
        DLPSelector,
        (details) => details?.supportMulti
      );
      export const SelectedApprovalSelector = createSelector(
        DLPSelector,
        (details) => details?.selectedApproval
      );
      export const SelectedApprovalDetailsSelector = createSelector(
        DLPSelector,
        (details) => details?.selectedApprovalDetails
      );
      export const MultiSelectedApprovalsSelector = createSelector(
        DLPSelector,
        (details) => details?.multiSelectedApprovals
      );
      export const SearchQuerySelector = createSelector(
        DLPSelector,
        (details) => details?.searchQuery
      );
      export const IsAscSelector = createSelector(
        DLPSelector,
        (details) => details?.isAsc
      );
      export const SelectedSubSystemSelector = createSelector(
        DLPSelector,
        (details) => details?.selectedSubSystem
      );
      export const FromPositionSelector = createSelector(
        DLPSelector,
        (details) => details?.fromPosition
      );
      export const SubSystemsSelector = createSelector(
        DLPSelector,
        (details) => details?.subSystems
      );
      export const TotalCountForFilterSelector = createSelector(
        DLPSelector,
        (details) => details?.totalCountForFilter
      );
      export const PendingApprovalsSelector = createSelector(
        DLPSelector,
        (details) => details?.pendingApprovals
      );
      export const IsTabletViewSelector = createSelector(
        DLPSelector,
        (details) => details?.isTabletView
      );
      
      
      export const DLPReducer = DLPslice.reducer;
          
          