import React from "react";
import { IQuest, IQuestLinkData } from "../../../Model/ServerResponse";
import { QUEST_LINKS_TAB_ID } from "../QuestUtil";
import "./QuestLinks.scss";

export interface IQuestLinksProp {
  quest: IQuest | undefined;
}

export function QuestLinks(props: IQuestLinksProp) {
  let questLinkData = props.quest?.tabs.find(
    (td) => td.tabId === QUEST_LINKS_TAB_ID
  );
  let _questLinkData = questLinkData?.data as IQuestLinkData[];
  return <div className="quest-section">{getLinks()}</div>;

  function getLinks(){
    if (questLinkData) {
      return (
        <div className="link-container-1">
          {_questLinkData.map((e: IQuestLinkData, index: number) => {
            return (
              <div className="link-container" key={e.url}>
                <div className="quest-link-container">
                  <div className="attachment-icon">
                    <img src="./DetailTab/link_icn.svg" alt="attachment" />
                  </div>
                  <div className="link-text">
                    <div className="quest-link-title">{e.title}</div>
                    <a
                      className="quest-link-url"
                      rel="noreferrer"
                      target="_blank"
                      href={e.url}
                    >
                      {e.url}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div className="no-link-container">No Links</div>;
    }
  }
}
