import { IDLPRisk, IDLPRiskItem } from "Model/ServerResponse";
import "./DLPMethodStatementDetails.scss";
import React from "react";
import { Chip } from "@material-ui/core";
import Utility from "common/utilities";

export interface IDLPMethodStatementDetailsProp {
  dlpMethodStatementDetail: IDLPRisk | undefined;
}

export function DLPMethodStatementDetails(
  props: IDLPMethodStatementDetailsProp
)  {
  let dlpMethodStatementDetailContent = props.dlpMethodStatementDetail
    ?.items as IDLPRiskItem[];
  return (
    <div
      data-testid="dlp-method-statement-details"
      className="dlp-methodstatement-container"
    >
      {getHeaderUI()}
      {getContentUI()}
    </div>
  );

  function getHeaderUI() {
    return (
      <div className="main-container">
        <div className="title-container">
          <div className="dlp-section-title">
            <b>{props.dlpMethodStatementDetail?.title}</b>
            <div className="spacer"></div>
            <div className="col-1">
              <Chip
                className={Utility.mergeCssClassNames([
                  "chip-1",
                  getChipStyle(props.dlpMethodStatementDetail?.risk),
                ])}
                label={Utility.stringToPascalCase(
                  props.dlpMethodStatementDetail?.risk
                )}
              ></Chip>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getContentUI() {
    return (
      <div className="method-statement">
        <div className="key-value-list">
          {dlpMethodStatementDetailContent?.map((e: IDLPRiskItem) => {
            return (
              <div className="key-value" key={e.value}>
                <p className="key">{e.label}</p>
                <p className="value load-Value">
                  <strong>{e.value}</strong>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function getChipStyle(chipText: string | undefined): string {
    let result = "";

    switch (chipText?.toLowerCase()) {
      case "high":
        result = "chip-1-high";
        break;
      case "medium":
        result = "chip-1-medium";
        break;
      case "low":
        result = "chip-1-low";
        break;
    }
    return result;
  }
}
