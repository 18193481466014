import { JRADetails } from "Components2/JRA/JRADetails/JRADetails";
import React from "react";
import { IJRA, IJRATab } from "../../../Model/ServerResponse";
import { ITabData } from "../../../Model/UI/ITabData";
import { DetailTabsComponent } from "../../DetailTabsComponent/DetailTabsComponent";
import { JRA_ITEMS_TAB_ID } from "../JRAUtil";


export interface IJRATabsProp {
    jra: IJRA;
}

export function JRATabs(props: IJRATabsProp) {

    function getTabsUI()  {
        const data = getTabsData(props.jra.tabs);
        return (
                <DetailTabsComponent tabs={data}></DetailTabsComponent>
        );
    }

    function getTabsData(tabs: IJRATab[]): ITabData[] {
        let tdata: ITabData[] = [];

        tabs.forEach((tab: IJRATab) => {

            let tabInfo: ITabData = {
                tabId: "",
                title: tab.title,
                imageSrc: ""
            };

            //By id
            if (tab.tabId === JRA_ITEMS_TAB_ID) {
                tabInfo.tabId = JRA_ITEMS_TAB_ID;
                tabInfo.imageSrc = "./DetailTab/details_icn.svg";
                tabInfo.getTabUI = ItemsUI;
            }
            tdata.push(tabInfo);
        });
        return tdata;
    }

    function ItemsUI()  {

        return (
                <JRADetails jra={props.jra} />
        )
    }

    return getTabsUI();
}


