import React from "react";
import { ITabData } from "../../../Model/UI/ITabData";
import { DetailTabsComponent } from "../../DetailTabsComponent/DetailTabsComponent";
import { IEQuote } from "Model/ServerResponse/IEquote";
import { IEQuoteTab } from "Model/ServerResponse/IEquoteTab";
import { EQuoteComments } from "../EQuoteComments/EQuoteComments";
import { EQUOTE_COMMENTS_TAB_ID, EQUOTE_DETAILS_TAB_ID } from "../EQuoteUtil";
import { EQuoteDetails } from "../EQuoteDetails/EQuoteDetails";

export interface IEQuoteTabsProp {
  eQuote: IEQuote;
}

export function EQuoteTabs(props: IEQuoteTabsProp) {
  function getTabsUI() {
    const data = getTabsData(props.eQuote.tabs);
    return (
      <>
        <DetailTabsComponent tabs={data}></DetailTabsComponent>
      </>
    );
  }

  function getTabsData(tabs: IEQuoteTab[]): ITabData[] {
    let tdata: ITabData[] = [];

    tabs.forEach((tab: IEQuoteTab) => {
      let tabInfo: ITabData = {
        tabId: "",
        title: tab.title,
        imageSrc: "",
        // @ts-ignore: Unreachable code error
        count: tab.data.length,
      };

      //By id
      switch (tab.tabId) {
        case EQUOTE_DETAILS_TAB_ID:
          tabInfo.tabId = EQUOTE_DETAILS_TAB_ID;
          tabInfo.imageSrc = "./DetailTab/details_icn.svg";
          tabInfo.getTabUI = ItemsUI;
          break;
        case EQUOTE_COMMENTS_TAB_ID:
          tabInfo.tabId = EQUOTE_COMMENTS_TAB_ID;
          tabInfo.imageSrc = "./DetailTab/comment_icn.svg";
          tabInfo.getTabUI = CommentsUI;
          break;
        default:
          break;
      }
      tdata.push(tabInfo);
    });
    return tdata;
  }

  function ItemsUI() {
    let eQuoteDetailsData = props.eQuote.tabs.find(
      (td) => td.tabId === EQUOTE_DETAILS_TAB_ID
    );
    return (
      <>
        <EQuoteDetails eQuoteDetailsTab={eQuoteDetailsData}></EQuoteDetails>
      </>
    );
  }

  function CommentsUI() {
    let eQuoteItemsData = props.eQuote.tabs.find(
      (td) => td.tabId === EQUOTE_COMMENTS_TAB_ID
    );
    return (
      <>
        <EQuoteComments eQuoteTabData={eQuoteItemsData} />
      </>
    );
  }

  return getTabsUI();
}
