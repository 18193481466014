import React, { useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import { SubSystemList } from "./SubSystemList/SubSystemList";
import { IDefaultUIProp, ISubSystem } from "Model/UI";

import "./SearchFilterToolbar.scss";
import Utility from "common/utilities";

export interface ISearchFilterToolbar extends IDefaultUIProp {
  title?: string;
  totalCount: number;
  count: number;
  multiSelectedCount: number;
  searchPlaceHolderText?: string;
  loading?: boolean;
  error?: boolean;
  powerBILink?: string;
  powerBIReportText?: string;
  onRefresh?: (event: any) => void;
  subSystems?: ISubSystem[];
  onSearch?: (event: any, query: string) => void;
  onSort?: (event: any, isAsc: boolean) => void;
  onSubSystemChange?: (event: any, subSystem: ISubSystem) => void;
}
export const SearchFilterToolbar = React.forwardRef(
  (props: ISearchFilterToolbar, ref: any) => {
    //Filter variables
    let [searchString, setSearchString] = useState<string>("");
    let [onTypeSearchString, setOnTypeSearchString] = useState<string>("");
    let [isAsc, setIsAsc] = useState<boolean>(false);
    let [selectedSubSystem, setSelectedSubSystem] = useState<any>();
    //UI state variables
    let [searchBarVisible, setSearchBarVisible] = useState<boolean>(false);
    React.useImperativeHandle(ref, () => ({
      clearSearchTextBoxValue,
    }));
    //Data variables
    const MinCharCount = 3;

    function renderTitle() {
      if (props.title) {
        return <p className="title">{props.title}</p>;
      }
    }

    function renderCount() {
      return <p className="count">{props.totalCount}</p>;
    }

    function renderIcons() {
      if (!props.error) {
        return (
          <>
            {refreshIcon()}
            {searchQueryIcon()}
            {sortIcon()}
          </>
        );
      }
      return null;
    }

    function renderPowerBIIcon() {
      if (props.powerBILink && props.powerBIReportText) {
        return (
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            className="power-bi-icon-class"
          >
            {powerBIIcon()}
          </Grid>
        );
      }
      return null; // Return null instead of empty fragment
    }
    function refreshIcon() {
      function handleRefresh(e) {
        if (props.onRefresh) {
          setSelectedSubSystem(null);
          props.onRefresh(e);
        }
      }
    
      return (
        <img
          alt=""
          src={"./CardAssets/Refresh_icn.svg"}
          data-testid="refresh-icon"
          className={Utility.mergeCssClassNames([
            "filter-icon",
            getDisabledStyleForRefresh(props.loading || false),
          ])}
          onClick={handleRefresh}
        />
      );
    }
    

    function searchQueryIcon() {
      return (
        <img
          alt=""
          src={"./CardAssets/search_icn.svg"}
          data-testid="search-icon"
          className={Utility.mergeCssClassNames([
            "filter-icon",
            getDisabledStyleForSearch(props.totalCount),
          ])}
          onClick={() => setSearchBarVisible(!searchBarVisible)}
        />
      );
    }
    

    function sortIcon() {
      function handleOnSort(e: any, _isAsc: boolean) {
        setIsAsc(_isAsc);
        if (props.onSort) {
          props.onSort(e, _isAsc);
        }
      }

      if (isAsc) {
        return (
          <img
            alt=""
            src={"./CardAssets/sort listing_O2N.svg"}
            className={Utility.mergeCssClassNames([
              "filter-icon",
              getDisabledStyleForSort(props.count),
            ])}
            onClick={(e) => {
              handleOnSort(e, false);
            }}
          ></img>
        );
      } else {
        return (
          <img
            alt=""
            data-testid="sort-icon"
            src={"./CardAssets/sort listing_N2O.svg"}
            className={Utility.mergeCssClassNames([
              "filter-icon",
              getDisabledStyleForSort(props.count),
            ])}
            onClick={(e) => {
              handleOnSort(e, true);
            }}
          ></img>
        );
      }
    }

    function searchBar() {
      return (
        <div data-testid="search-bar" className="search-bar-container">
          <TextField
            autoFocus
            value={onTypeSearchString}
            onChange={handleSearchBarOnChange}
            onKeyDown={handleSearchBarEnter}
            placeholder={
              props.searchPlaceHolderText
                ? props.searchPlaceHolderText
                : "Title or Requester Name"
            }
            InputProps={{
              startAdornment: <SearchIcon className="search-icon" />,
              endAdornment: (
                <CancelIcon
                  data-testid="close-icon"
                  className="close-icon"
                  onClick={handleSearchBarCancel}
                />
              ),
            }}
            variant="outlined"
            className="search-bar"
          />
        </div>
      );

      function handleSearchBarOnChange(e: any) {
        setOnTypeSearchString(e.target.value);
      }

      function handleSearchBarEnter(e) {
        // Check for the opposite conditions and return early if met
        if (e.key !== "Enter" || onTypeSearchString.length <= MinCharCount) {
          return;
        }
      
        setSearchString(onTypeSearchString);
        if (props.onSearch) {
          props.onSearch(e, onTypeSearchString.trim());
        }
      }
      

      function handleSearchBarCancel(e: any) {
        if (props.onSearch && searchString.trim().length > 0) {
          props.onSearch(e, "");
        }
        if (
          searchString.trim().length > 0 &&
          searchString === onTypeSearchString
        ) {
          setSearchString("");
        }
        setOnTypeSearchString("");
        setSearchBarVisible(!searchBarVisible);
      }
    }

    function powerBIIcon() {
      return (
        <a
          className="power-bi-link"
          href={props.powerBILink}
          target="_blank"
          rel="noreferrer"
        >
          <div className="icon-container">
            <img
              alt={props.powerBIReportText}
              src={"./CardAssets/power-bi-icon.svg"}
              className="power-bi-icon"
            />

            <span className="icon-text">{props.powerBIReportText}</span>
          </div>
        </a>
      );
    }

    function handleSubSystemChange(e: any, ss: ISubSystem) {
      setSelectedSubSystem(ss);
      if (props.onSubSystemChange) {
        props.onSubSystemChange(e, ss);
      }
    }

    function getDisabledStyleForSort(count: number): string {
      let result = "";
      if (count <= 1 || props.multiSelectedCount > 0) {
        result = "disabled-icon";
      }
      return result;
    }

    function getDisabledStyleForRefresh(loading: boolean): string {
      let result = "";
      if (loading || props.multiSelectedCount > 0) {
        result = "disabled-icon";
      }
      return result;
    }

    function clearSearchTextBoxValue() {
      setOnTypeSearchString("");
      setSearchBarVisible(false);
    }

    function getDisabledStyleForSearch(totalCount: number): string {
      let result = "";
      if (totalCount <= 1 || props.multiSelectedCount > 0) {
        result = "disabled-icon";
      }
      return result;
    }
    
    return (
      <>
        <Grid container direction="column" className="search-filter-toolbar">
          {/* label, refresh, search, sort */}
          {/* Visible if  searchBarVisible is false*/}

          <>
            {!searchBarVisible && (
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                className="row-1"
              >
                {renderTitle()}
                {renderCount()}
                <div className="spacer"></div>
                {renderIcons()}
              </Grid>
            )}
          </>
          {/* search bar, sort */}
          {/* Visible if  searchBarVisible is true*/}
          {searchBarVisible && (
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              className="row-2"
            >
              {searchBar()}
              <div className="spacer"></div>
              {sortIcon()}
            </Grid>
          )}
          {/* sub-system filter */}
          {props.subSystems && props.subSystems.length > 1 && (
            <Grid
              data-testid="subSystem-list-grid"
              container
              justify="flex-start"
              alignItems="center"
              className="row-3"
            >
              {
                <SubSystemList
                  data-testid="subSystem-list"
                  subSystems={props.subSystems}
                  value={selectedSubSystem}
                  loading={props.loading || false}
                  onSubSystemChange={handleSubSystemChange}
                />
              }
            </Grid>
          )}
          {renderPowerBIIcon()}
        </Grid>
      </>
    );


  }
);
