import Utility from "../../../src/common/utilities";
import React from "react";
import "./AttachmentCell.scss";

export interface IAttachmentListProps {
  onClick: (e: any, fileID: string, fileName: string) => void;
  fileID: string;
  fileName: string;
  isDownloadable: boolean;
}
export function AttachmentCell(props: IAttachmentListProps) {
  const handleFileClick = (e: any, fileName: string, fileID: string) => {
    props.onClick(e, fileID, fileName);
  };

  return (
    <>
      <div
        data-testid="attachment-cell"
        className={Utility.mergeCssClassNames([
          "attachment-cell",
          getDownloadableStyle(),
        ])}
        onClick={(e) => {
          handleFileClick(e, props.fileName, props.fileID);
        }}
      >
        <img
          className="attachment-image"
          src="./Attachment/File_icn.svg"
          title={props.fileName}
          alt=""
        />
        <div
          title={props?.fileName}
          data-testid="attachment-filename"
          className="attachment-filename"
        >
          {shortenFileName(props.fileName)}
        </div>
      </div>
    </>
  );

  function shortenFileName(fileName: string): string {
    if (fileName && fileName.length > 10) {
      return fileName.substring(0, 20) + "...";
    }
    return fileName;
  }

  function getDownloadableStyle(): string {
    let result = "";
    if (props.isDownloadable) {
      result = "attachment-cell-downloadable";
    }
    return result;
  }
}
