import React from "react";
import "./PreTripApprovers.scss";

import { ITableDataMapping } from "../../../Model/UI";
import { IPretripApproverData, IPretripTab } from "../../../Model/ServerResponse";
import { TableComponent } from "../../TableComponent/TableComponent";
import { TableCell, TableRow } from "@material-ui/core";
import Utility from "../../../common/utilities";

export interface IPreTripApproversProp {
  pretripApproversTabData: IPretripTab | undefined;
}

export function PreTripApprovers(props: IPreTripApproversProp){
  let tableHeaderMapping: ITableDataMapping[] = [
    {
      id: "approverType",
      label: "Level/Code",
    },
    {
      id: "approverName",
      label: "Approver",
    },
    {
      id: "jobTitle",
      label: "Job Title",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "actionDate",
      label: "Action Date",
    },
    {
      id: "comments",
      label: "Approver Comment",
    },
  ];

  return <div className="pretrip-approvers-tab">{tabUI()}</div>;

  function tabUI(){
    let tableRes: IPretripApproverData[] = props.pretripApproversTabData?.data as IPretripApproverData[];
    if (
      props.pretripApproversTabData &&
      !Utility.isArrayEmpty(props.pretripApproversTabData.data)
    ) {
      return (
        <TableComponent
          tableData={tableRes}
          mappingTableData={tableHeaderMapping}
          getTableRow={getTableApproversRow}
        ></TableComponent>
      );
    } else {
      return <div data-testid="pretrip-approvers-tab" className="no-approvers">No Approvers</div>;
    }
  }

  function getTableApproversRow(index: number, row: IPretripApproverData){
    return (
      <>
        <TableRow className="spacing"></TableRow>
        <TableRow data-testid="table-row" className="main-row" key={index}>
          <TableCell className="approval-level">{row.level}</TableCell>
          <TableCell className="approver-name">{row.approverName}</TableCell>
          <TableCell className="job-title">{row.jobTitle}</TableCell>
          <TableCell data-testid="pretrip-approvers-tab"
            className={Utility.mergeCssClassNames([
              "pretrip-approvers-tab",
              getApproverStatusStyle(row.status),
            ])}
          >
            {row.status}
          </TableCell>
          <TableCell className="action-date">{row.actionDate}</TableCell>
          <TableCell className="comments">{row.comments}</TableCell>
        </TableRow>
      </>
    );
  }

  function getApproverStatusStyle(status: string): string {
    let result = "";
    switch (status.toLowerCase()) {
      case "approved":
        result = "approved";
        break;
      case "rejected":
        result = "rejected";
        break;
      case "pending":
        result = "pending";
        break;
      default:
        return "";
    }
    return result;
  }
}
