import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { IDefaultUIProp } from "../../../Model/UI";
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report, Embed, service } from "powerbi-client";
import { ServicesUtility } from "../../../Services/servicesUtility";
import "./POBudgetReport.scss";
import VariablesJson from "../../../configVariables.json";
import { Accordion, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Constants } from "common/constants";

export interface IPOBudgetReporttProp extends IDefaultUIProp {
  profitCentre: string;
  stockMaterialNumber: Array<string>;
  stockMaterialNumberFirstLineItem: string;
  plantCode: string | number;
  accountAssignment: string[];
  profitCentreFirstLineItem: string;
}

export function POBudgetReport(props: IPOBudgetReporttProp) {
  const stockMaterialNumber = props.stockMaterialNumber;
  const accountAssignment = props.accountAssignment;
  const profitCentre = props.profitCentre ? +props.profitCentre : 0;
  function findProfitCentreFirstLineItem(profitCentreFirstLineItem) {
    return profitCentreFirstLineItem ? +profitCentreFirstLineItem : 0;
  }
  const profitCentreFirstLineItem = findProfitCentreFirstLineItem(
    props.profitCentreFirstLineItem
  );

  const profitCentreFinal: number =
    profitCentreFirstLineItem !== 0 ? profitCentreFirstLineItem : profitCentre;
  //new logic which first checks profitcentre from firstline item then from header

  const plantCode = props.plantCode ? props.plantCode : 0;

  //preserved older logic where we were checking for profit center and plant code when we were not getting profit center from backend

  const getInitialValue = (): string => {
    return !profitCentreFalseFlag() ? "poinsights" : "dhoInsights";
  };

  let [currentTab, setCurrentTab] = React.useState<string>(getInitialValue());
  let [errorMessage, setErrorMessage] = React.useState<string>("");
  const [showAnimation, setShowAnimation] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  let reportObj: Report;
  const [report, setReport] = useState<Report>();

  // patchwork for ProfitCenter starts

  function profitCentreFalseFlag(): boolean {
    return profitCentreFinal === 0;
  }

  let filters: models.ReportLevelFilters[] = [];
  filters = [
    {
      $schema: "https://powerbi.com/product/schema#basic",
      target: {
        table: "Query1",
        column: VariablesJson.Variables.PowerBIConfigPO.TargetColumn,
      },
      operator: "In",
      values: [profitCentreFinal],
      filterType: models.FilterType.Basic,
      requireSingleSelection: true,
    },
  ];

  const [poReport, setPoReport] = useState<models.IVisualEmbedConfiguration>({
    type: "visual",
    embedUrl: undefined,
    tokenType: models.TokenType.Aad,
    accessToken: undefined,
    pageName: VariablesJson.Variables.PowerBIConfigPO.pageName,
    visualName: VariablesJson.Variables.PowerBIConfigPO.visualName,
    filters: filters,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
    },
  });

  const [dhoReport, setDhoReport] = useState<models.IVisualEmbedConfiguration>({
    type: "visual",
    embedUrl: undefined,
    tokenType: models.TokenType.Aad,
    accessToken: undefined,
    pageName: VariablesJson.Variables.PowerBIConfigDHO.pageName,
    visualName: VariablesJson.Variables.PowerBIConfigDHO.visualName,
    filters: [
      {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "Query1",
          column:
            VariablesJson.Variables.PowerBIConfigDHO.TargetColumnPlantCode,
        },
        operator: "In",
        values: [plantCode],
        filterType: models.FilterType.Basic,
        requireSingleSelection: true,
      },
      {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "Query1",
          column:
            VariablesJson.Variables.PowerBIConfigDHO.TargetColumnStockMaterial,
        },
        operator: "In",
        values: stockMaterialNumber,
        filterType: models.FilterType.Basic,
        requireSingleSelection: true,
      },
    ],
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
    },
  });

  const handleAccordionToggle = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
    setShowAnimation(false);
  };

  const titleText = isExpanded
    ? "Supply Insights"
    : "Click here to review overall spend report";
  const wobbleClass = showAnimation && !isExpanded ? "" : "wobble-stop";

  const getConfiguration = async () => {
    let token = await ServicesUtility.getPowerBiToken();
    console.log("profitCentre", profitCentre);
    console.log("PowerBI Token", token);
    setPoReport({
      ...poReport,

      embedUrl: VariablesJson.Variables.PowerBIConfigPO.embedUrl,
      accessToken: token,
    });

    setDhoReport({
      ...dhoReport,
      embedUrl: VariablesJson.Variables.PowerBIConfigDHO.embedUrl,
      accessToken: token,
    });
  };

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
    setErrorMessage("");
    if (newValue === 0) {
      if (!!accountAssignment && accountAssignment.length > 0) {
        setCurrentTab("poinsights");
      } else if (!!stockMaterialNumber && stockMaterialNumber.length > 0) {
        setCurrentTab("dhoinsights");
      }
    } else {
      setCurrentTab("dhoinsights");
    }
  };

  async function getPageVisuals() {
    if (reportObj != undefined) {
      reportObj.getActivePage().then((activePage) => {
        console.log("Active Page: " + activePage.name);
        activePage.getVisuals().then((visuals) => {
          console.log("Visuals Count: " + visuals.length);
          console.log("Visuals: " + visuals);
          for (const v of visuals) {
            if (
              v.name === VariablesJson.Variables.PowerBIConfigPO.visualName ||
              v.name === VariablesJson.Variables.PowerBIConfigDHO.visualName
            ) {
              // Your logic for specific visuals (if needed)
            }
            console.log("Visual Title: " + v.title);
            console.log("Visual Name: " + v.name);
            console.log("Visual Layout: " + v.layout);
          }
        });
      });
    }
  }

  async function getfilters() {
    console.log("getfilters()");
    if (reportObj != undefined) {
      await reportObj.getFilters();
    }
  }

  useEffect(() => {}, [report]);

  useEffect(() => {
    getConfiguration();
  }, []);

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    ["loaded", function () {}],
    [
      "rendered",
      function () {
        getfilters();
        getPageVisuals();
      },
    ],
    [
      "error",
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          if (
            event?.detail?.errorCode == "403" ||
            event?.detail?.errorCode == "401"
          ) {
            const msg =
              "Cannot see the content of this report because you do not have required permissions";
            setErrorMessage(msg);
          } else if (event?.detail?.errorCode) {
            setErrorMessage(event?.detail?.message);
          }
          console.log("PowerBi Error", event);
        }
      },
    ],
  ]);
  return (
    <div className="powerBI-report-sec">
      <Accordion
        defaultExpanded={false}
        className="accordian"
        onChange={handleAccordionToggle}
      >
        <AccordionSummary
          className="accordian-summary"
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="header-title">
            <span className={`title-align ${wobbleClass}`}>
              <img alt="PowerBI_icn" src="./DetailTab/PowerBI_icn.svg"></img>
              {titleText}
            </span>
          </div>
        </AccordionSummary>
        <div className="accordian-details">
          <div className="reports-container">
            <div className="tabs-container">
              <Paper square>
                <Tabs value={tabIndex} onChange={handleChange} aria-label="">
                  {!profitCentreFalseFlag() ? (
                    <Tab
                      className={`tab${
                        currentTab === "poinsights" ? " active" : ""
                      }`}
                      label="Spend Insights"
                    />
                  ) : (
                    ""
                  )}
                  {Boolean(
                    stockMaterialNumber?.length &&
                      !stockMaterialNumber.includes("")
                  ) && (
                    <Tab
                      className={`tab${
                        currentTab === "dhoreport" ? " active" : ""
                      }`}
                      label="Inventory Insights"
                    />
                  )}
                </Tabs>
              </Paper>
            </div>

            <div className="reports-wrapper">
              <div className="report-container">
                <div className="poInsight-container">
                  {
                    <div className="note">
                      <div className="note-style">
                        {console.log("currentTab", currentTab)}
                        <span className="note-text">
                          {currentTab === "poinsights"
                            ? Constants.POINSIGHTNOTE
                            : Constants.SPENDINSIGHTNOTE}
                        </span>
                      </div>
                    </div>
                  }
                  {errorMessage ? (
                    <h3 className="errorMessage">{errorMessage}</h3>
                  ) : (
                    <PowerBIEmbed
                      cssClassName="power-bi-report"
                      embedConfig={
                        currentTab === "poinsights" ? poReport : dhoReport
                      }
                      eventHandlers={eventHandlersMap}
                      getEmbeddedComponent={(embedObject: Embed) => {
                        console.log(
                          `Embedded object of type "${embedObject.embedtype}" received`
                        );
                        setReport(embedObject as Report);
                        reportObj = embedObject as Report;
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Accordion>
    </div>
  );
}
