import Utility from "common/utilities";
import React from "react";
import { IPO, IPOItemData } from "../../../Model/ServerResponse";
import { PO_ITEMS_TAB_ID } from "../POUtil";
import "./POChangeLogIndicator.scss";


export interface IPOChangeLogIndicatorProp {
    po: IPO;
}



export function POChangeLogIndicator(props: IPOChangeLogIndicatorProp) {

    let itemsData = props.po.tabs.find(td => td.tabId === PO_ITEMS_TAB_ID);

    let poItemsTabData = itemsData?.data as IPOItemData[] || [];

    let poChangedLogsData = poItemsTabData.filter((item) => item.changeLogs?.length > 0);


    function getHeaderTableUI() {

        return (
            <div className="po-change-log-indicator">
                <div data-testid="change-log" className={Utility.mergeCssClassNames(["po-changelog-text", getText(poChangedLogsData)])}>
                    <span>(Changed Log)</span>
                </div>
            </div>
        );
    }

    function getText(poChangedLogsData: IPOItemData[]): string {
        return poChangedLogsData?.length === 0 ? "po-changelog-text-hidden" : "po-changelog-text-visible";
    }

    return getHeaderTableUI();
}