import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Constants } from "common/constants";
import Utility from "common/utilities";
import { RequestStatus, SidebarMenuItemState } from "Model/Enums";
import { IGetPendingApprovals, IUserProfile } from "Model/ServerResponse";
import { IUpdateProfileResponse } from "Model/ServerResponse/IUpdateProfileResponse";
import {
  IEntity,
  IApprovalOperationEntity,
  IApprovalSystemEntity,
} from "Model/UI";
import { normalize } from "normalizr";
import {
  ApprovalService,
  ServicesUtility,
  SessionStorageService,
  UserProfileService,
} from "Services";
import { LoggingService } from "Services/LoggingService";
import {
  SetSplashProgress,
  SetSplashProgressOpen,
} from "Store/Common/CommonSlice";
import { IAppState } from "Store/store";
import { SetNewApprovalOperations } from "./NewApprovalOperationSelectorSlice";

export interface IUserSystemState {
  approvalOperationKeys: string[];
  approvalOperationsMap: IEntity<IApprovalOperationEntity>; //All approval operations dict, only stores the total count (not filter based count)
  approvalSystemsMap: IEntity<IApprovalSystemEntity>; //All approval systems dict, only stores the total count (not filter based count)
  approvalOperationKeysToShowInSidebar: string[];
  userProfile: IUserProfile | null; //User Profile
  status: string;
  tempSystemName: string;
  message?: string;
}

const initialState: IUserSystemState = {
  approvalOperationKeys: [],
  approvalOperationsMap: {},
  approvalSystemsMap: {},
  approvalOperationKeysToShowInSidebar: [],
  userProfile: null,
  status: RequestStatus.None,
  tempSystemName: "",
  message: "",
};

//Api service instances
let userProfileService = UserProfileService.getInstance();

//Merge Utility - for merging old & new cold base
export const MU_GetUserProfile = createAsyncThunk(
  "UserProfile/mu_getUserProfile",
  async (userProfileResponse: IUserProfile, thunkApi): Promise<void> => {
    let dispatch = thunkApi.dispatch;
    let selfActions = UserSystemSlice.actions;

    //Normalize the response
    let normalizedData = normalize(
      userProfileResponse.approvalOperations.concat(
        userProfileResponse.newSystems
      ),
      [Constants.approvalOperationEntity]
    );
    const _aoKeys = normalizedData.result;
    const _aoMap = normalizedData.entities["approvalOperations"];
    const _asMap = normalizedData.entities["approvalSystems"];
    let userProfile = userProfileResponse;

    if (_aoMap && _asMap) {
      //Patch for Quest starts
      let questApprovalOperation: IApprovalOperationEntity = {
        ..._aoMap["QuestChange"],
      };
      questApprovalOperation.approvalSystems = ["Exemption", "MOC"];
      _aoMap["QuestChange"] = questApprovalOperation;

      let questSystem: IApprovalSystemEntity = _asMap["Quest"];
      _asMap["Exemption"] = {
        name: "Exemption",
        isEnabled: questSystem.isEnabled,
        isSelected: questSystem.isSelected,
        count: 0,
        error: null,
      };
      _asMap["MOC"] = {
        name: "MOC",
        isEnabled: questSystem.isEnabled,
        isSelected: questSystem.isSelected,
        count: 0,
        error: null,
      };
      //Patch for Quest starts

      //Set succces state of self
      dispatch(selfActions.setStatus(RequestStatus.Success));
      dispatch(selfActions.setUserProfile(userProfile));
      dispatch(selfActions.setApprovalOperationMaps(_aoMap));
      dispatch(selfActions.setApprovalSystemMaps(_asMap));
      dispatch(selfActions.setApprovalOperationKeys(_aoKeys));

      let enabledSelectedApprovalOperationsKeys =
        Utility.getApprovalOperationKeysHavingAtleastOneEnabledSelected(
          _aoKeys,
          _aoMap,
          _asMap
        );
      dispatch(
        AddApprovalOperationsToSideBar({
          approvalOperationKeys: enabledSelectedApprovalOperationsKeys,
          approvalOperationsMap: _aoMap,
        })
      );

      // Set new approval operations of NewApprovalOperationSelector
      //UNcomment when refac code become core logic
      // dispatch(SetNewApprovalOperations(userProfile.newSystems));
    }
  }
);

//Get user profile thunk
export const GetUserProfile = createAsyncThunk(
  "UserProfile/getUserProfile",
  async (_, thunkApi): Promise<void> => {
    let loggingService = LoggingService.getInstance();
    try {
      let dispatch = thunkApi.dispatch;
      let selfActions = UserSystemSlice.actions;

      //Set loading state before api call
      dispatch(selfActions.setStatus(RequestStatus.Loading));

      dispatch(SetSplashProgressOpen(true));

      //Call get user profile api
      let response = await userProfileService.getUserProfile();

      // Parse response for error
      let error = ServicesUtility.ParseResponseForErrors(response);
      //Set status & error for api request
      if (error) {
        dispatch(SetSplashProgress({ taskKey: "userprofile", error: error }));
      }

      //Check for success response
      if (response.statusCode === 200 && response.payload) {
        //Normalize the response
        let normalizedData = normalize(
          response.payload.approvalOperations.concat(
            response.payload.newSystems
          ),
          [Constants.approvalOperationEntity]
        );

        // Logic to check if user has permission for ITT System --start
        const approvalsytsems: any = normalizedData.entities.approvalSystems;
        const keys = Object.keys(approvalsytsems);
        if (keys.some((x) => x == "ITT")) {
          sessionStorage.setItem("ITTAccess", approvalsytsems["ITT"].isEnabled);
        }
        // Logic to check if user has permission for ITT System --end
        const _aoKeys = normalizedData.result;
        const _aoMap = normalizedData.entities["approvalOperations"];
        const _asMap = normalizedData.entities["approvalSystems"];
        let userProfile = response.payload;
        const empId =
          userProfile?.graphDetails
            ?.extension_8b4e685f580748b59864d62c2e7fcfad_employeeNumber;
        if (empId) {
          SessionStorageService.getInstance().EmloyeeGIN = empId?.toString();
        }
        if (_aoMap && _asMap) {
          //Patch for Quest starts
          let questApprovalOperation: IApprovalOperationEntity = {
            ..._aoMap["QuestChange"],
          };
          questApprovalOperation.approvalSystems = ["Exemption", "MOC"];
          _aoMap["QuestChange"] = questApprovalOperation;

          let questSystem: IApprovalSystemEntity = _asMap["Quest"];
          _asMap["Exemption"] = {
            name: "Exemption",
            isEnabled: questSystem.isEnabled,
            isSelected: questSystem.isSelected,
            count: 0,
            error: null,
          };
          _asMap["MOC"] = {
            name: "MOC",
            isEnabled: questSystem.isEnabled,
            isSelected: questSystem.isSelected,
            count: 0,
            error: null,
          };
          //Patch for Quest starts

          //Set succces state of self
          dispatch(selfActions.setStatus(RequestStatus.Success));
          dispatch(selfActions.setUserProfile(userProfile));
          dispatch(selfActions.setApprovalOperationMaps(_aoMap));
          dispatch(selfActions.setApprovalSystemMaps(_asMap));
          dispatch(selfActions.setApprovalOperationKeys(_aoKeys));

          let enabledSelectedApprovalOperationsKeys =
            Utility.getApprovalOperationKeysHavingAtleastOneEnabledSelected(
              _aoKeys,
              _aoMap,
              _asMap
            );
          dispatch(
            AddApprovalOperationsToSideBar({
              approvalOperationKeys: enabledSelectedApprovalOperationsKeys,
              approvalOperationsMap: _aoMap,
            })
          );

          // Set new approval operations of NewApprovalOperationSelector
          dispatch(SetNewApprovalOperations(userProfile.newSystems));
        }
      } else {
        //Set failure state of self
        dispatch(selfActions.setStatus(RequestStatus.Failed));
        dispatch(selfActions.setMessage(response.errorMessage));
      }
    } catch (err) {
      await loggingService.logError(
        "UserSysteSlice : GetUserProfile thunk",
        "",
        JSON.stringify(err, Object.getOwnPropertyNames(err)),
        JSON.stringify(err, Object.getOwnPropertyNames(err))
      );
    }
  }
);

export const AddApprovalOperationsToSideBar = createAsyncThunk(
  "UserProfile/addApprovalOperationsToSideBar",
  async (
    {
      approvalOperationKeys,
      approvalOperationsMap,
    }: {
      approvalOperationKeys: string[];
      approvalOperationsMap: IEntity<IApprovalOperationEntity>;
    },
    thunkApi
  ): Promise<void> => {
    let loggingService = LoggingService.getInstance();
    try {
      let dispatch = thunkApi.dispatch;
      let selfActions = UserSystemSlice.actions;

      dispatch(
        selfActions.setApprovalOperationKeysToShowInSidebar(
          approvalOperationKeys
        )
      );

      approvalOperationKeys.forEach((aok) => {
        dispatch(SetLoadingForApprovalOperation({ approvalOperationKey: aok }));
      });
      approvalOperationKeys.forEach((aok) => {
        dispatch(
          GetPendingApprovals({
            approvalOperationKey: aok,
            approvalOperationsMap: approvalOperationsMap,
          })
        );
      });
    } catch (err) {
      await loggingService.logError(
        "UserSysteSlice : AddApprovalOperationsToSideBar thunk",
        "",
        JSON.stringify(err, Object.getOwnPropertyNames(err)),
        JSON.stringify(err, Object.getOwnPropertyNames(err))
      );
    }
  }
);

export const UpdateMapsWithSystemProfileResponse = createAsyncThunk(
  "UserProfile/updateMapsWithSystemProfileResponse",
  async (
    updateSytemResponse: IUpdateProfileResponse,
    thunkApi
  ): Promise<void> => {
    let loggingService = LoggingService.getInstance();
    try {
      let dispatch = thunkApi.dispatch;
      let state = (thunkApi.getState() as IAppState).UserSystem;
      let selfActions = UserSystemSlice.actions;

      //update the maps with activity & sub-systems
      // & remove sub-system setting from getPending approvals
      let approvalSystemEntitiesToAdd: IApprovalSystemEntity[] = [];
      updateSytemResponse.systems.forEach((s) => {
        approvalSystemEntitiesToAdd.push({
          name: s.system,
          isEnabled: s.isEnabled,
          isSelected: s.isSelected,
          count: state.approvalSystemsMap[s.system].count,
          error: state.approvalSystemsMap[s.system].error,
        });
      });

      approvalSystemEntitiesToAdd.forEach((ase) => {
        dispatch(selfActions.addOneToApprovalSystemMaps(ase));
      });
    } catch (err) {
      await loggingService.logError(
        "UserSysteSlice : UpdateMapsWithSystemProfileResponse thunk",
        "",
        JSON.stringify(err, Object.getOwnPropertyNames(err)),
        JSON.stringify(err, Object.getOwnPropertyNames(err))
      );
    }
  }
);

export const SetApprovalOperationCount = createAsyncThunk(
  "UserProfile/setApprovalOperationCount",
  async (
    {
      approvalOperationKey,
      count,
    }: { approvalOperationKey: string; count: number },
    thunkApi
  ): Promise<void> => {
    let loggingService = LoggingService.getInstance();
    try {
      let state = (thunkApi.getState() as IAppState).UserSystem;
      let dispatch = thunkApi.dispatch;
      let selfActions = UserSystemSlice.actions;

      //Make a copy
      let approvalOperation = {
        ...state.approvalOperationsMap[approvalOperationKey],
      };
      approvalOperation.count = count;

      dispatch(selfActions.addOneToApprovalOperationMaps(approvalOperation));
    } catch (err) {
      await loggingService.logError(
        "UserSysteSlice : SetApprovalOperationCount thunk",
        "",
        JSON.stringify(err, Object.getOwnPropertyNames(err)),
        JSON.stringify(err, Object.getOwnPropertyNames(err))
      );
    }
  }
);

export const SetApprovalSystemCount = createAsyncThunk(
  "UserProfile/setApprovalSystemCount",
  async (
    { approvalSystemKey, count }: { approvalSystemKey: string; count: number },
    thunkApi
  ): Promise<void> => {
    let loggingService = LoggingService.getInstance();
    try {
      let state = (thunkApi.getState() as IAppState).UserSystem;
      let dispatch = thunkApi.dispatch;
      let selfActions = UserSystemSlice.actions;

      //Make a copy
      let approvalSystem = { ...state.approvalSystemsMap[approvalSystemKey] };
      approvalSystem.count = count;

      dispatch(selfActions.addOneToApprovalSystemMaps(approvalSystem));
    } catch (err) {
      await loggingService.logError(
        "UserSysteSlice : SetApprovalSystemCount thunk",
        "",
        JSON.stringify(err, Object.getOwnPropertyNames(err)),
        JSON.stringify(err, Object.getOwnPropertyNames(err))
      );
    }
  }
);

export const AddNewApprovalOperationAddedByUser = createAsyncThunk(
  "UserProfile/setNewApprovalsAddedByUser",
  async (
    {
      approvalOperationKeyAdded,
      approvalOperationsMap,
    }: {
      approvalOperationKeyAdded: string;
      approvalOperationsMap: IEntity<IApprovalOperationEntity>;
      approvalSystemsMap: IEntity<IApprovalSystemEntity>;
    },
    thunkApi
  ): Promise<void> => {
    let loggingService = LoggingService.getInstance();
    try {
      let dispatch = thunkApi.dispatch;
      let selfActions = UserSystemSlice.actions;

      let aoeToAdd = approvalOperationsMap[approvalOperationKeyAdded];

      dispatch(
        selfActions.addOneToApprovalOperationKeysToShowInSidebar(
          approvalOperationKeyAdded
        )
      );
      dispatch(
        SetLoadingForApprovalOperation({ approvalOperationKey: aoeToAdd.id })
      );
      dispatch(
        GetPendingApprovals({
          approvalOperationKey: aoeToAdd.id,
          approvalOperationsMap: approvalOperationsMap,
        })
      );
    } catch (err) {
      await loggingService.logError(
        "UserSysteSlice : AddNewApprovalOperationAddedByUser thunk",
        "",
        JSON.stringify(err, Object.getOwnPropertyNames(err)),
        JSON.stringify(err, Object.getOwnPropertyNames(err))
      );
    }
  }
);

export const RemoveApprovalOperationRemovedByUser = createAsyncThunk(
  "UserProfile/removeApprovalOperationRemovedByUser",
  async (
    { approvalOperationKeyRemoved }: { approvalOperationKeyRemoved: string },
    thunkApi
  ): Promise<void> => {
    let loggingService = LoggingService.getInstance();
    try {
      let dispatch = thunkApi.dispatch;
      let state = (thunkApi.getState() as IAppState).UserSystem;
      let selfActions = UserSystemSlice.actions;

      let aoeToRemove =
        state.approvalOperationsMap[approvalOperationKeyRemoved];

      if (aoeToRemove) {
        dispatch(
          selfActions.removeOneApprovalOperationKeysToShowInSidebar(
            aoeToRemove.id
          )
        );
      }
    } catch (err) {
      await loggingService.logError(
        "UserSysteSlice : RemoveApprovalOperationRemovedByUser thunk",
        "",
        JSON.stringify(err, Object.getOwnPropertyNames(err)),
        JSON.stringify(err, Object.getOwnPropertyNames(err))
      );
    }
  }
);

export const TempApprovalSystemAdd = createAsyncThunk(
  "UserProfile/tempApprovalSystemAdd",
  async (
    { approvalOperationKeyAdded }: { approvalOperationKeyAdded: string },
    thunkApi
  ): Promise<void> => {
    let loggingService = LoggingService.getInstance();
    try {
      let dispatch = thunkApi.dispatch;
      let selfActions = UserSystemSlice.actions;
      dispatch(selfActions.settempSystemName(approvalOperationKeyAdded));
    } catch (err) {
      await loggingService.logError(
        "UserSysteSlice : TempApprovalSystemAdd thunk",
        "",
        JSON.stringify(err, Object.getOwnPropertyNames(err)),
        JSON.stringify(err, Object.getOwnPropertyNames(err))
      );
    }
  }
);

export const TempApprovalSystemRemove = createAsyncThunk(
  "UserProfile/tempApprovalSystemRemove",
  async (
    { approvalOperationKeyRemoved }: { approvalOperationKeyRemoved: string },
    thunkApi
  ): Promise<void> => {
    let loggingService = LoggingService.getInstance();
    try {
      let dispatch = thunkApi.dispatch;
      let selfActions = UserSystemSlice.actions;
      dispatch(selfActions.settempSystemName(approvalOperationKeyRemoved));
    } catch (err) {
      await loggingService.logError(
        "UserSysteSlice : TempApprovalSystemAdd thunk",
        "",
        JSON.stringify(err, Object.getOwnPropertyNames(err)),
        JSON.stringify(err, Object.getOwnPropertyNames(err))
      );
    }
  }
);

export const SetLoadingForApprovalOperation = createAsyncThunk(
  "UserSystem/setLoadingForApprovalOperation",
  async (
    { approvalOperationKey }: { approvalOperationKey: string },
    thunkApi
  ): Promise<void> => {
    let loggingService = LoggingService.getInstance();
    try {
      let dispatch = thunkApi.dispatch;
      let state = (thunkApi.getState() as IAppState).UserSystem;
      let selfActions = UserSystemSlice.actions;

      let intialAOToAdd: IApprovalOperationEntity = {
        id: approvalOperationKey,
        description:
          state.approvalOperationsMap[approvalOperationKey].description,
        state: SidebarMenuItemState.Loading,
        count: 0,
        approvalSystems:
          state.approvalOperationsMap[approvalOperationKey].approvalSystems,
      };
      dispatch(selfActions.addOneToApprovalOperationMaps(intialAOToAdd));
    } catch (err) {
      await loggingService.logError(
        "UserSysteSlice : SetLoadingForApprovalOperation thunk",
        "",
        JSON.stringify(err, Object.getOwnPropertyNames(err)),
        JSON.stringify(err, Object.getOwnPropertyNames(err))
      );
    }
  }
);

export const GetPendingApprovals = createAsyncThunk(
  "UserSystem/GetPendingApprovals",
  async (
    {
      approvalOperationKey,
      approvalOperationsMap,
    }: {
      approvalOperationKey: string;
      approvalOperationsMap: IEntity<IApprovalOperationEntity>;
    },
    thunkApi
  ): Promise<void> => {
    let loggingService = LoggingService.getInstance();

    try {
      let dispatch = thunkApi.dispatch;
      let approvalService = ApprovalService.getInstance();
      let state = (thunkApi.getState() as IAppState).UserSystem;
      let selfActions = UserSystemSlice.actions;
      let aok = approvalOperationKey;

      //Call get user profile api
      await approvalService
        .getPendingApprovals<IGetPendingApprovals<any>>(`api/approvals/${aok}`)
        .then((response) => {
          let approvalOperationEntityToAdd: IApprovalOperationEntity;
          let approvalSystemEntitiesToAdd: IApprovalSystemEntity[] = [];

          if (response.statusCode === 200 && response.payload) {
            let approvalSystemKeys: string[] = [];

            response.payload.displayCount.forEach((dc) => {
              let approvalSystemName = Utility.getSystemActivityMapping(
                dc.systemName
              )[1];
              approvalSystemKeys.push(approvalSystemName);

              approvalSystemEntitiesToAdd.push({
                name: approvalSystemName,
                isEnabled: dc.isEnabled,
                isSelected:
                  state.approvalSystemsMap[approvalSystemName].isSelected,
                count: dc.count,
                error: dc.errorMessage,
              });
            });

            let ssCount = 0;
            response.payload.displayCount.forEach((dc) => {
              ssCount += dc.count;
            });

            let hasError = false;
            let tempHasError = false;
            response.payload.displayCount.forEach((dc) => {
              if (
                dc.errorMessage &&
                dc.isEnabled &&
                dc.errorMessage.trim().length > 0
              ) {
                if (
                  dc.systemName !== "PO-ITT" &&
                  dc.systemName !== "PO-Optimus"
                ) {
                  hasError = true;
                } else {
                  if (dc.systemName === "PO-ITT") tempHasError = true;
                  else if (
                    dc.systemName === "PO-Optimus" &&
                    tempHasError === true
                  )
                    hasError = true;
                }
              }
            });

            if (hasError) {
              approvalOperationEntityToAdd = {
                id: aok,
                description: approvalOperationsMap[aok].description,
                state: SidebarMenuItemState.Error,
                count: 0,
                approvalSystems: approvalSystemKeys,
              };
            } else {
              approvalOperationEntityToAdd = {
                id: aok,
                description: approvalOperationsMap[aok].description,
                state: SidebarMenuItemState.Loaded,
                count: ssCount,
                approvalSystems: approvalSystemKeys,
              };

              dispatch(
                selfActions.addOneToApprovalOperationMaps(
                  approvalOperationEntityToAdd
                )
              );
            }

            approvalSystemEntitiesToAdd.forEach((ase) => {
              dispatch(selfActions.addOneToApprovalSystemMaps(ase));
            });
          } else {
            approvalOperationEntityToAdd = {
              id: aok,
              description: approvalOperationsMap[aok].description,
              state: SidebarMenuItemState.Error,
              approvalSystems: approvalOperationsMap[aok].approvalSystems,
              count: 0,
            };
          }

          dispatch(
            selfActions.addOneToApprovalOperationMaps(
              approvalOperationEntityToAdd
            )
          );
        })
        .catch((err) => {
          let approvalOperationEntityToAdd = {
            id: aok,
            description: approvalOperationsMap[aok].description,
            state: SidebarMenuItemState.Error,
            approvalSystems: approvalOperationsMap[aok].approvalSystems,
            count: 0,
          };
          dispatch(
            selfActions.addOneToApprovalOperationMaps(
              approvalOperationEntityToAdd
            )
          );
        });
    } catch (err) {
      await loggingService.logError(
        "UserSysteSlice : GetPendingApprovals thunk",
        "",
        JSON.stringify(err, Object.getOwnPropertyNames(err)),
        JSON.stringify(err, Object.getOwnPropertyNames(err))
      );
    }
  }
);

const UserSystemSlice = createSlice({
  name: "UserSystemProfile",
  initialState: initialState,
  reducers: {
    setStatus(state: IUserSystemState, action: PayloadAction<RequestStatus>) {
      state.status = action.payload;
    },
    setMessage(state: IUserSystemState, action: PayloadAction<string>) {
      state.message = action.payload;
    },
    settempSystemName(state: IUserSystemState, action: PayloadAction<string>) {
      state.tempSystemName = action.payload;
    },
    setUserProfile(
      state: IUserSystemState,
      action: PayloadAction<IUserProfile | null>
    ) {
      state.userProfile = action.payload;
    },
    setApprovalOperationKeys(
      state: IUserSystemState,
      action: PayloadAction<string[]>
    ) {
      state.approvalOperationKeys = Utility.sortApprovalOperationKeys(
        [...action.payload],
        state.approvalOperationsMap
      );
    },
    addOneToApprovalOperationKeys(
      state: IUserSystemState,
      action: PayloadAction<string>
    ) {
      if (state.approvalOperationKeys.includes(action.payload)) {
        return;
      }
      let tempRes = state.approvalOperationKeys.concat(action.payload);
      state.approvalOperationKeys = Utility.sortApprovalOperationKeys(
        [...tempRes],
        state.approvalOperationsMap
      );
    },
    setApprovalOperationMaps(
      state: IUserSystemState,
      action: PayloadAction<IEntity<IApprovalOperationEntity>>
    ) {
      state.approvalOperationsMap = action.payload;
    },
    addOneToApprovalOperationMaps(
      state: IUserSystemState,
      action: PayloadAction<IApprovalOperationEntity>
    ) {
      state.approvalOperationsMap[action.payload.id] = { ...action.payload };
    },
    setApprovalSystemMaps(
      state: IUserSystemState,
      action: PayloadAction<IEntity<IApprovalSystemEntity>>
    ) {
      state.approvalSystemsMap = action.payload;
    },
    addOneToApprovalSystemMaps(
      state: IUserSystemState,
      action: PayloadAction<IApprovalSystemEntity>
    ) {
      state.approvalSystemsMap[action.payload.name] = { ...action.payload };
    },
    setApprovalOperationKeysToShowInSidebar(
      state: IUserSystemState,
      action: PayloadAction<string[]>
    ) {
      state.approvalOperationKeysToShowInSidebar =
        Utility.sortApprovalOperationKeys(
          [...action.payload],
          state.approvalOperationsMap
        );
    },
    addOneToApprovalOperationKeysToShowInSidebar(
      state: IUserSystemState,
      action: PayloadAction<string>
    ) {
      if (state.approvalOperationKeysToShowInSidebar.includes(action.payload)) {
        return;
      }
      let tempRes = state.approvalOperationKeysToShowInSidebar.concat(
        action.payload
      );
      state.approvalOperationKeysToShowInSidebar =
        Utility.sortApprovalOperationKeys(tempRes, state.approvalOperationsMap);
    },
    removeOneApprovalOperationKeysToShowInSidebar(
      state: IUserSystemState,
      action: PayloadAction<string>
    ) {
      let tempRes = state.approvalOperationKeysToShowInSidebar.filter(
        (aok) => aok !== action.payload
      );
      state.approvalOperationKeysToShowInSidebar =
        Utility.sortApprovalOperationKeys(
          [...tempRes],
          state.approvalOperationsMap
        );
    },
    addTempOneApprovalSystemToShowInSidebar(
      state: IUserSystemState,
      action: PayloadAction<string>
    ) {
      //   let tempRes = state.tempSystemName.filter(
      //     (aok) => aok !== action.payload
      //   );
      //   state.approvalOperationKeysToShowInSidebar =
      //     Utility.sortApprovalOperationKeys(
      //       [...tempRes],
      //       state.approvalOperationsMap
      //     );
    },
  },
});

//Selector
export const UserSystemSelector = (state: IAppState) => state.UserSystem;
export const ApprovalOperationKeysSelector = createSelector(
  UserSystemSelector,
  (details) => details.approvalOperationKeys
);
export const ApprovalOperationsMapSelector = createSelector(
  UserSystemSelector,
  (details) => details.approvalOperationsMap
);
export const ApprovalSystemsMapSelector = createSelector(
  UserSystemSelector,
  (details) => details.approvalSystemsMap
);
export const ApprovalOperationKeysToShowInSidebarSelector = createSelector(
  UserSystemSelector,
  (details) => details.approvalOperationKeysToShowInSidebar
);

export const TempSystemNameSelector = createSelector(
  UserSystemSelector,
  (details) => details.tempSystemName
);
export const UserProfileResponseSelector = createSelector(
  UserSystemSelector,
  (details) => (details && details.userProfile ? details.userProfile : null)
);

export const UserSystemReducer = UserSystemSlice.reducer;
