import { SearchFilterToolbar } from "../../SearchFilterToolbar/SearchFilterToolbar";
import { Message1 } from "../../MessageTemplates/Message1/Message1";
import React, { useEffect } from "react";
import { ListView } from "../../ListView/ListView";
import { useSelector } from "react-redux";
import { RequestStatus } from "../../../Model/Enums";
import {
  IsAscSelector,
  MultiSelectedApprovalsSelector,
  PendingApprovalsSelector,
  SearchQuerySelector,
  SelectedApprovalSelector,
  SelectedSubSystemSelector,
  setIsAsc,
  setMultiSelectedApprovals,
  setFromPosition,
  setSearchQuery,
  setSelectedApproval,
  setSelectedSubSystem,
  SubSystemsSelector,
  TotalCountForFilterSelector,
  GetPendingApprovals,
  resetSystemSliceState,
  PendingApprovalsStatusSelector,
  PendingApprovalsErrorSelector,
  SupportMultiSelector,
  srmSliceInitialState,
  IsTabletViewSelector,
} from "../../../Store/SRM/SRMSlice";
import { useAppDispatch } from "../../../Store/store";
import "./SRMListView.scss";
import { ISystemListViewBaseProp } from "../../../Model/UI";
import { SRMCard } from "../SRMCard/SRMCard";
import { ISRM } from "Model/ServerResponse/ISRM";

export interface ISRMListViewProp<T> extends ISystemListViewBaseProp<T> {}

export function SRMListView(props: ISRMListViewProp<ISRM>) {
  let appDispatch = useAppDispatch();

  let supportMulti = useSelector(SupportMultiSelector);

  let pendingApprovalsStatus = useSelector(PendingApprovalsStatusSelector);
  let pendingApprovalsError = useSelector(PendingApprovalsErrorSelector);

  let selectedApproval = useSelector(SelectedApprovalSelector);
  let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);
  let subSystems = useSelector(SubSystemsSelector);
  let totalCountForFilter = useSelector(TotalCountForFilterSelector);
  let pendingApprovals = useSelector(PendingApprovalsSelector);

  let searchQuery = useSelector(SearchQuerySelector);
  let isAsc = useSelector(IsAscSelector);
  let selectedSubSystem = useSelector(SelectedSubSystemSelector);

  let isTabletView = useSelector(IsTabletViewSelector);

  useEffect(() => {
    appDispatch(resetSystemSliceState(srmSliceInitialState));
  }, []);

  //Set up data
  useEffect(() => {
    appDispatch(setSelectedApproval(null));
    appDispatch(GetPendingApprovals());
  }, [searchQuery, isAsc, selectedSubSystem]);

  return (
    <div className="srm-list-container">
      <SearchFilterToolbar
        multiSelectedCount={multiSelectedApprovals?.length}
        title="Shopping Cart"
        error={pendingApprovalsStatus === RequestStatus.Failed}
        totalCount={totalCountForFilter}
        count={pendingApprovals?.length}
        subSystems={subSystems}
        loading={pendingApprovalsStatus === RequestStatus.Loading}
        onRefresh={handleRefresh}
        onSearch={handleSearchChange}
        onSort={handleSortChange}
        onSubSystemChange={handleSubSystemChange}
      ></SearchFilterToolbar>
      {listUI()}
    </div>
  );

  function getRowUI(data: ISRM, multiSelectUI) {
    return (
      <SRMCard
        supportMulti={supportMulti}
        multiSelectUI={multiSelectUI}
        srm={data}
      ></SRMCard>
    );
  }

  function listUI() {
    if (pendingApprovalsStatus === RequestStatus.Failed) {
      return errorUI();
    }

    return (
      <>
        <ListView
          className="srm-list"
          totalCount={totalCountForFilter}
          dataList={pendingApprovals}
          selectedData={selectedApproval}
          multiSelectedData={multiSelectedApprovals}
          loading={pendingApprovalsStatus === RequestStatus.Loading}
          supportMulti={supportMulti}
          getRowUI={(data) => getRowUI(data, supportMulti)}
          getUniqueDataIdentifier={getUniqueDataIndentifier}
          onSelectChange={handleSelectChange}
          onMultiSelectChange={handleMultiSelectChange}
          onFromPositionChange={handleFromPositionChange}
          getEmptyIndicatorUI= {emptyIndicatorUI}
          isTabletView={isTabletView}
        ></ListView>

        {multiSelectedApprovals?.length > 0 && actionContainer(props)}
      </>
    );
  }

  function emptyIndicatorUI() {
    return (
      <div className="message-container">
        <Message1
          title="No approvals."
          imgSrc="CardAssets/NoPendingRequests.svg"
        ></Message1>
      </div>
    );
  }

  function errorUI() {
    if (pendingApprovalsError) {
      return (
        <div className="message-container">
          <Message1
            title={pendingApprovalsError.message}
            subTitle={pendingApprovalsError.description}
            imgSrc={"CardAssets/error.svg"}
          ></Message1>
        </div>
      );
    }
    return <></>;
  }

  function handleRefresh(_e: any) {
    //Reset all state
    appDispatch(resetSystemSliceState(srmSliceInitialState));
    //Get approvals
    appDispatch(GetPendingApprovals());
  }

  function handleSearchChange(_e: any, _query: string) {
    appDispatch(setSearchQuery(_query));
  }

  function handleSortChange(_e: any, _isAsc: boolean) {
    appDispatch(setIsAsc(_isAsc));
  }

  function handleSubSystemChange(_e: any, _subSystem: any) {
    appDispatch(setSelectedSubSystem(_subSystem));
  }

  function getUniqueDataIndentifier(_data: ISRM): string {
    //Return unique identifier
    return _data.id.toString();
  }

  function handleFromPositionChange(_fromPostion: number): void {
    appDispatch(setFromPosition(_fromPostion));
    appDispatch(GetPendingApprovals());
  }

  function handleMultiSelectChange(_e: any, _values: any) {
    appDispatch(setMultiSelectedApprovals(_values));
  }

  function handleSelectChange(_e: any, _value: any) {
    appDispatch(setSelectedApproval(_value));
  }

  function actionContainer(props) {
    if (props.getActionsUI) {
      return (
        <div className="action-container">
          {props.getActionsUI(true, multiSelectedApprovals as ISRM[])}
        </div>
      );
    }
    return <></>;
  }
}
