import { IFailedTakeAction, ISystem, IDLP } from "Model/ServerResponse";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  DeleteApprovals,
  PostDeleteApprovals,
  reCalculateSelectedApproval,
  SelectedApprovalSelector,
  setIsTabletView,
  setSelectedApproval,
} from "Store/DLP/DLPSlice";
import { ListDetailsView } from "../../ListDetailsView/ListDetailsView";
import { DLPActions } from "../../DLP/DLPActions/DLPActions";
import { TakeActionFailedModal } from "../../Modals/TakeActionFailedModal/TakeActionFailedModal";
import { useAppDispatch } from "Store/store";
import { DLPListView } from "../../DLP/DLPListView/DLPListView";
import { DLPDetailsCard } from "../../DLP/DLPDetailsCard/DLPDetailsCard";

export interface IDLPPageProp {
}

export function DLPPage(props: IDLPPageProp) {
  let appDispatch = useAppDispatch();

  let selectedApproval = useSelector(SelectedApprovalSelector);

  let [takeActionFailedModalOpen, setTakeActionFailedModalOpen] =
    useState<boolean>(false);
  let [failedTakeActions, setFailedTakeActions] =
    useState<IFailedTakeAction[]>([]);

  return (
    <div>
      {takeActionFailedModalOpen && (
        <TakeActionFailedModal
          open={takeActionFailedModalOpen}
          failedActions={failedTakeActions}
          onOk={(e: any) => setTakeActionFailedModalOpen(false)}
        />
      )}
      <ListDetailsView
        selectedApproval={selectedApproval}
        onBackClick={handleBackClick}
        onWindowSizeChange={handleWindowSizeChange}
        listView={listUI}
        detailsView={detailsUI}
      />
    </div>
  );

  function listUI(){
    return (
        <DLPListView getActionsUI={actionsUI} />
    );
  }

  function detailsUI(){
    return (
        <DLPDetailsCard getActionsUI={actionsUI} />
    );
  }

  function actionsUI(
    _isMulti: boolean,
    _approvals: IDLP[],
  ) {
    return (
        <DLPActions
          isMulti={_isMulti}
          count={_approvals.length}
          onApprove={handleOnApprove}
          onReject={handleOnReject}
        />
    );
  }

  function handleBackClick(e: any) {
    appDispatch(setSelectedApproval(null));
  }

  function handleWindowSizeChange(e: any, isTabletView: boolean) {
    appDispatch(setIsTabletView(isTabletView));
    appDispatch(reCalculateSelectedApproval());
  }

  function handleOnApprove(e: any, isMulti: boolean, comment: string) {
    takeAction(true, isMulti, comment);
  }

  function handleOnReject(e: any, isMulti: boolean, comment: string) {
    takeAction(false, isMulti, comment);
  }

 async function takeAction(isApprove: boolean, isMulti: boolean, comment: string): Promise<void> {
    let result = ((await appDispatch(PostDeleteApprovals({ isApprove: isApprove, isMulti: isMulti, comment: comment, getRequestBody: getTakeActionRequestBody }))).payload) as { passedApprovals: IDLP[], failedTakeActions: IFailedTakeAction[] };
    if (result.failedTakeActions.length > 0) {
      //Some approvals failed
      setTakeActionFailedModalOpen(true);
      setFailedTakeActions(result.failedTakeActions);
    }

    //Remove cards & subtract count for passed
    appDispatch(DeleteApprovals(result.passedApprovals));
  }

  function getTakeActionRequestBody(_approval: ISystem, _comment: string): any {
    let _dlp = _approval as IDLP;
    return {
      approvalId: _dlp.id.toString(),
      comment: _comment,
    }
  }
}




