import Utility from "../../../common/utilities";
import { TableComponent } from "../../../Components2/TableComponent/TableComponent";
import { ITableDataMapping } from "../../../Model/UI/ITableDataMapping";
import React, { useEffect } from "react";
import {
  IPO,
  IPOItemData,
  IServerResponse,
} from "../../../Model/ServerResponse";
import "./POItems.scss";
import { Accordian } from "../../Accordian/Accordian";
import { PO_ITEMS_TAB_ID } from "../POUtil";
import { KeyValueList } from "Components2/KeyValueList/KeyValueList";
import {
  IKeyValue,
  INotesData,
  IPOAccountAssignments,
  IPricingConditions,
} from "Model/UI";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TableCell,
  TableRow,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { POService } from "Services/POService";
import { IPOItemDetailsResponseModel } from "../../../Model/UI/IPOItemDetailsResponseModel";
import { Constants } from "common/constants";
import { CardSkeleton1 } from "Components2/Skeletons/CardSkeletons/CardSkeleton1/CardSkeleton1";
import { POItemAttachment } from "./POItemAttachment/POItemAttachment";
import { IServicesData } from "../../../Model/UI/IServiceData";

export interface IPOItemsProp {
  poData: IPO;
}
const initialState: { selectedPODetails: any } = {
  selectedPODetails: "",
};

export function POItems(props: IPOItemsProp) {
  let poItemsTabData = props.poData.tabs.find(
    (td) => td.tabId === PO_ITEMS_TAB_ID
  );

  let [selectedPODetails, setSelectedPODetails] = React.useState<any>();
  let [poDetails, setPoDetails] = React.useState<IPOItemDetailsResponseModel[]>(
    []
  );
  let [expanded, setExpanded] = React.useState<number | false>(0);

  const handleChange =
    (index: number, id: string) =>
    async (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setSelectedPODetails(initialState);
      setExpanded(newExpanded ? index : false);
      const existingItem = poDetails.find(
        (x: IPOItemDetailsResponseModel) => x.itemNumber === id
      );
      if (newExpanded && !existingItem) {
        try {
          const poService = POService.getInstance();
          const res = await poService.getPODetailsData(
            props.poData.id,
            props.poData.poNumber,
            id
          );
          setSelectedPODetails(res.payload);
          setPoDetails((prevDetails) => [...prevDetails, res.payload]);
        } catch (error) {
          // Handle error appropriately
          console.error("Error fetching PO details:", error);
        }
      } else if (existingItem) {
        setSelectedPODetails(existingItem);
      }
    };

  useEffect(() => {
    if (
      props.poData.id &&
      props.poData.title &&
      poItemsTabData &&
      poItemsTabData.data
    ) {
      const itemNumber: any = poItemsTabData?.data[0];
      if (itemNumber) {
        let poService = POService.getInstance();

        poService
          .getPODetailsData(
            props.poData.id,
            props.poData.poNumber,
            itemNumber.itemNo
          )
          .then((res: IServerResponse<any>) => {
            setSelectedPODetails(res.payload);
            setPoDetails([...poDetails, res.payload]);
          });
      }
    }
  }, [props?.poData]);

  const tableHeaderMapping: ITableDataMapping[] = [
    {
      id: "itemNo",
      label: "Line Item No",
    },
    {
      id: "description",
      label: "Description",
    },
    {
      id: "scheduledDate",
      label: "Scheduled Date",
    },
    {
      id: "pricePerUnit",
      label: "Unit Price",
    },
    {
      id: "quantity",
      label: "Quantity",
    },
    {
      id: "subTotalCost",
      label: "Amount",
      sorting: true,
    },
  ];

  const accountHeaderMapping: ITableDataMapping[] = [
    {
      id: "salesOrder",
      label: "Sales Order",
    },
    {
      id: "profitCenter",
      label: "Profit Center",
    },
    {
      id: "costCenter",
      label: "Cost Center",
    },
    {
      id: "glAccount",
      label: "GL Account",
    },
    {
      id: "share",
      label: "Share",
    },
  ];
  const pricingHeaderMapping: ITableDataMapping[] = [
    {
      id: "description",
      label: "Description",
    },
    {
      id: "price",
      label: "Price",
    },
  ];
  const notesHeaderMapping: ITableDataMapping[] = [
    {
      id: "title",
      label: "Title",
    },
    {
      id: "comment",
      label: "Description",
    },
    {
      id: "commentBy",
      label: "Created By",
    },
    {
      id: "date",
      label: "Created On",
    },
  ];

  const serviceHeaderMapping: ITableDataMapping[] = [
    {
      id: "description",
      label: "Description",
    },
    {
      id: "quantity",
      label: "Quantity",
    },
    {
      id: "pricePerUnit",
      label: "Price Per Unit (PPU)",
    },
    {
      id: "quantityForPPU",
      label: "Quantity For PPU",
    },
    {
      id: "value",
      label: "Subtotal",
    },
  ];
  return <div className="po-items-tab">{tabPOData()}</div>;

  function tabPOData() {
    if (poItemsTabData && !Utility.isArrayEmpty(poItemsTabData.data)) {
      return (
        <div className="po-item-rows">
          <TableComponent
            tableData={poItemsTabData.data}
            mappingTableData={tableHeaderMapping}
            getTableRow={getTablePOItemRow}
          ></TableComponent>
        </div>
      );
    } else {
      return <div className="no-po-items">No Items</div>;
    }
  }

  function getTablePOItemRow(index: number, poItemData: IPOItemData) {
    let poChangedLogsData = poItemData.changeLogs;
    return (
      <>
        <TableRow>
          <TableRow className="spacing"></TableRow>
        </TableRow>
        <TableRow className="po-items-row-1 po-items-row">
          <TableCell>
            <p className="po-items-row-text-1">{poItemData?.itemNo}</p>
          </TableCell>
          <TableCell>
            <div>
              <p className="po-items-row-text-1">{poItemData?.description}</p>
            </div>
          </TableCell>
          <TableCell>
            <p className="po-items-row-text-1">
              {Utility.formatDate(
                poItemData?.scheduledDate,
                Constants.DD_MM_YY
              )}
            </p>
          </TableCell>
          <TableCell>
            <p className="po-items-row-text-1">
              {poItemData.pricePerUnit}
              <span className="unit">&nbsp;{poItemData?.currencyForPPU}</span>
            </p>
          </TableCell>
          <TableCell>
            <p className="po-items-row-text-1">
              {poItemData?.quantity?.toString()}
              <span className="unit">
                &nbsp;{poItemData?.unitForPPUDescription}
              </span>
            </p>
          </TableCell>
          <TableCell>
            <p className="po-items-row-text-1">
              {poItemData?.subTotalCost}
              <span className="unit">&nbsp;{poItemData?.currencyForPPU}</span>
            </p>
          </TableCell>
        </TableRow>
        {poChangedLogsData && poChangedLogsData.length > 0
          ? getChangeLogUI(poItemData)
          : null}
        <TableRow className="detail-row">
          <TableCell colSpan={6}>
            {getDetailSection(index, poItemData)}
          </TableCell>
        </TableRow>
      </>
    );
  }

  function getChangeLogUI(poItemData: IPOItemData) {
    let quantity = 0;
    let pricePerUnit = 0;
    let subTotalCost = 0;
    if (poItemData?.changeLogs && poItemData?.changeLogs?.length > 1) {
      quantity =
        poItemData?.changeLogs[0]?.quantity -
        poItemData?.changeLogs[1]?.quantity;
      pricePerUnit =
        poItemData?.changeLogs[0]?.pricePerUnit -
        poItemData?.changeLogs[1]?.pricePerUnit;
      subTotalCost =
        poItemData?.changeLogs[0]?.subTotalCost -
        poItemData?.changeLogs[1]?.subTotalCost;
    }
    return (
      <TableRow className="change-log-row po-items-row-1 po-items-row">
        <TableCell>
          <p className="change-log-text">
            <b>Changed logs by</b>
            {poItemData?.changeLogs[0]["reason"] ? (
              <span className="reason-text">
                Reason : {poItemData?.changeLogs[0]["reason"]}
              </span>
            ) : null}
          </p>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell>
          <p className="po-items-row-text">
            {poItemData?.changeLogs[0]?.pricePerUnit}
            <span className="unit">&nbsp;{poItemData?.currencyForPPU}</span>
          </p>
          <p
            className={Utility.mergeCssClassNames([
              "po-items-row-text",
              getChangeLogStyle(pricePerUnit),
            ])}
          >
            {" "}
            <span>{pricePerUnit <= 0 ? "" : "+"}</span>
            {pricePerUnit}
          </p>
        </TableCell>
        <TableCell>
          <p className="po-items-row-text">
            {poItemData?.changeLogs[0]?.quantity}
            <span className="unit">
              &nbsp;{poItemData.unitForPPUDescription}
            </span>
          </p>
          <p
            className={Utility.mergeCssClassNames([
              "po-items-row-text",
              getChangeLogStyle(quantity),
            ])}
          >
            {quantity}
          </p>
        </TableCell>
        <TableCell>
          <p className="po-items-row-text">
            {poItemData?.changeLogs
              ? poItemData?.changeLogs[0]?.subTotalCost
              : ""}
            <span className="unit">&nbsp;{poItemData.currencyForPPU}</span>
          </p>
          <p
            className={Utility.mergeCssClassNames([
              "po-items-row-text",
              getChangeLogStyle(subTotalCost),
            ])}
          >
            {subTotalCost}
          </p>
        </TableCell>
      </TableRow>
    );
  }

  function getChangeLogStyle(value: number): string {
    if (value > 0) {
      return "green-text";
    } else if (value < 0) {
      return "red-text";
    } else {
      return "po-items-row-text";
    }
  }

  function getDetailSection(index: number, poItemData: any) {
    const itemNumber: string = poItemData?.itemNo;
    return (
      <Accordion
        expanded={expanded === index}
        onChange={handleChange(index, itemNumber)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="header-content">
            <b>Details</b>
          </div>
        </AccordionSummary>
        <AccordionDetails data-testid="accordion-details">
          
          <div className="border-div">
            {selectedPODetails != null && selectedPODetails ? (
              <div className="detail-value">
                {getQuantitySection()}

                {getMaterialSection(poItemData)}
                <div className="line"></div>
                <p className="shipping-detail-title">Shipping Details</p>
                {getShippingDetailection()}
              </div>
            ) : (
              <CardSkeleton1 />
            )}
            {selectedPODetails?.itemCategoryDescription == "Service" ? (
              <Accordian
                headerContent={
                  <span className="accordian-header">
                    Services
                    <span className="headerCount">
                      {selectedPODetails?.serviceLines ? (
                        "(" + selectedPODetails?.serviceLines?.length + ")"
                      ) : (
                        <></>
                      )}
                    </span>
                  </span>
                }
                content={getServicesContentUI()}
              />
            ) : (
              <></>
            )}
            <Accordian
              headerContent={
                <span className="accordian-header">
                  Notes{" "}
                  <span className="headerCount">
                    {selectedPODetails?.notes ? (
                      "(" + selectedPODetails?.notes?.length + ")"
                    ) : (
                      <></>
                    )}
                  </span>
                </span>
              }
              content={getNotesContentUI()}
            />
            <Accordian
              headerContent={
                <span className="accordian-header">Account Assignment</span>
              }
              content={getAccountAssignmentContentUI()}
            />
            <Accordian
              headerContent={
                <span className="accordian-header">Pricing Conditions</span>
              }
              content={getpricingConditionsContentUI()}
            />
            <Accordian
              headerContent={
                <span className="accordian-header">
                  Attachments{" "}
                  <span className="headerCount">
                    {selectedPODetails?.attachments ? (
                      "(" + selectedPODetails?.attachments?.length + ")"
                    ) : (
                      <></>
                    )}
                  </span>{" "}
                </span>
              }
              content={getAttachmentContentUI()}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  function getQuantitySection() {
    return (
      <>
        <div className="quantitySection">
          {selectedPODetails?.quantity && selectedPODetails?.unitDescription ? (
            <p className="text">
              {" "}
              {selectedPODetails?.quantity +
                " " +
                selectedPODetails?.unitDescription}
            </p>
          ) : (
            <></>
          )}
          {selectedPODetails?.pricePerUnit &&
          selectedPODetails?.currencyForPPU &&
          selectedPODetails?.quantityForPPU &&
          selectedPODetails?.unitForPPUDescription ? (
            <p className="text">
              {selectedPODetails?.pricePerUnit +
                " " +
                selectedPODetails?.currencyForPPU +
                " / "}
              {selectedPODetails?.quantityForPPU +
                " " +
                selectedPODetails?.unitForPPUDescription}
            </p>
          ) : (
            <></>
          )}
          {selectedPODetails?.itemCategoryDescription ? (
            <p className="text">{selectedPODetails?.itemCategoryDescription}</p>
          ) : (
            <></>
          )}
        </div>
        <div className="line"></div>
      </>
    );
  }

  function getMaterialSection(poItemData: any) {
    let _data = getMaterialKeyValueList(poItemData);
    return <KeyValueList data={_data} />;
  }

  function getMaterialKeyValueList(poItemData: any): IKeyValue[] {
    let dataArray: IKeyValue[] = [];
    if (poItemData?.materialNumber)
      dataArray.push({
        _key: "Material ID",
        value: poItemData?.materialNumber,
        seperator: ":",
      });
    if (poItemData?.zMatGrpDesc)
      dataArray.push({
        _key: "Material Group",
        value:
          poItemData?.zMatGrpDesc +
          (selectedPODetails?.materialGroup
            ? " (" + selectedPODetails?.materialGroup + ") "
            : ""),
        seperator: ":",
      });
    if (poItemData?.valuationClass)
      dataArray.push({
        _key: "Valuation Class",
        value: poItemData?.valuationClass,
        seperator: ":",
      });
    if (poItemData?.inventoryType)
      dataArray.push({
        _key: "Inventory Type",
        value: poItemData?.inventoryType,
        seperator: ":",
      });
    return dataArray;
  }

  function getShippingDetailection() {
    let _data = getShippingKeyValueList();
    return <KeyValueList data={_data} />;
  }

  function getShippingKeyValueList(): IKeyValue[] {
    let dataArray: IKeyValue[] = [];
    const plantName = selectedPODetails?.plantName;
    const PlantNumber = selectedPODetails?.plant
      ? " (" + selectedPODetails?.plant + ")"
      : "";
    dataArray.push({
      _key: "Plant",
      value: plantName ? plantName + PlantNumber : "",
      seperator: ":",
    });
    if (selectedPODetails?.deliveryAddress)
      dataArray.push({
        _key: "Address",
        value: selectedPODetails?.deliveryAddress,
        seperator: ":",
      });
    dataArray.push({
      _key: "Delivery On",
      value: selectedPODetails?.deliveryDate
        ? Utility.formatDate(
            selectedPODetails?.deliveryDate,
            Constants.DD_MM_YY
          )
        : "",
      seperator: ":",
    });
    return dataArray;
  }

  function getNotesContentUI() {
    return (
      <div className="account-assignment-table">
        {notesTable()}
        <br></br>
      </div>
    );
  }

  function notesTable() {
    if (selectedPODetails && !Utility.isArrayEmpty(selectedPODetails?.notes)) {
      return (
        <TableComponent
          tableData={selectedPODetails?.notes}
          mappingTableData={notesHeaderMapping}
          getTableRow={getNotesTableAccountItemRow}
        ></TableComponent>
      );
    } else {
      return <div className="no-po-items">No Notes</div>;
    }
  }

  function getNotesTableAccountItemRow(index: number, notesData: INotesData) {
    return (
      <TableRow>
        <TableCell>{notesData?.title}</TableCell>
        <TableCell>{notesData?.comment}</TableCell>
        <TableCell>{notesData?.commentBy}</TableCell>
        <TableCell>
          {notesData?.date &&
            Utility.formatDate(notesData.date, Constants.DD_MM_YY)}
        </TableCell>
      </TableRow>
    );
  }

  function getServicesContentUI() {
    return (
      <div className="account-assignment-table">
        {servicesTable()}
        <br></br>
      </div>
    );
  }

  function servicesTable() {
    if (
      selectedPODetails &&
      !Utility.isArrayEmpty(selectedPODetails?.serviceLines)
    ) {
      return (
        <TableComponent
          tableData={selectedPODetails?.serviceLines}
          mappingTableData={serviceHeaderMapping}
          getTableRow={getServicesTableAccountItemRow}
        ></TableComponent>
      );
    } else {
      return <div className="no-po-items">No Services</div>;
    }
  }

  function getServicesTableAccountItemRow(
    index: number,
    servicesData: IServicesData
  ) {
    return (
      <TableRow key={index}>
        <TableCell>{servicesData?.description}</TableCell>
        <TableCell>
          {servicesData?.quantity}
          {servicesData?.unitDescription ? (
            <span className="pricing-currency">
              {" "}
              {servicesData?.unitDescription}
            </span>
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell>
          {servicesData?.pricePerUnit}
          {servicesData?.currencyForPPU ? (
            <span className="pricing-currency">
              {" "}
              {servicesData?.currencyForPPU}
            </span>
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell>
          {servicesData?.quantityForPPU}
          {servicesData?.unitForPPUDescription ? (
            <span className="pricing-currency">
              {" "}
              {servicesData?.unitForPPUDescription}
            </span>
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell>
          {servicesData?.value}
          {servicesData?.currency ? (
            <span className="pricing-currency"> {servicesData?.currency}</span>
          ) : (
            <></>
          )}
        </TableCell>
      </TableRow>
    );
  }

  function getAccountAssignmentContentUI() {
    return (
      <div className="account-assignment-table">
        {accountAssignmentTable()}
        <br></br>
      </div>
    );
  }

  function accountAssignmentTable() {
    if (
      selectedPODetails &&
      !Utility.isArrayEmpty(selectedPODetails?.accountAssignments)
    ) {
      return (
        <TableComponent
          tableData={selectedPODetails?.accountAssignments}
          mappingTableData={accountHeaderMapping}
          getTableRow={getTableAccountItemRow}
        ></TableComponent>
      );
    } else {
      return <div className="no-po-items">No Assignment</div>;
    }
  }

  function getTableAccountItemRow(
    index: number,
    accountData: IPOAccountAssignments
  ) {
    return (
      <TableRow key={index}>
        <TableCell>{accountData?.salesOrder}</TableCell>
        <TableCell>{accountData?.profitCenter}</TableCell>
        <TableCell>{accountData?.costCenter}</TableCell>
        <TableCell>{accountData?.glAccount}</TableCell>
        <TableCell>{accountData?.share}</TableCell>
      </TableRow>
    );
  }

  function getpricingConditionsContentUI() {
    return (
      <div className="account-assignment-table">
        {pricingConditionsTable()}
        <br></br>
      </div>
    );
  }

  function pricingConditionsTable() {
    if (
      selectedPODetails?.pricingConditions &&
      selectedPODetails.pricingConditions.length > 0
    ) {
      return (
        <TableComponent
          tableData={selectedPODetails.pricingConditions}
          mappingTableData={pricingHeaderMapping}
          getTableRow={getTablePricingConditionsItemRow}
        ></TableComponent>
      );
    } else {
      return <div className="no-po-items">No Pricing Condition</div>;
    }
  }

  function getTablePricingConditionsItemRow(
    index: number,
    pricing: IPricingConditions
  ) {
    return (
      <TableRow key={index}>
        <TableCell>{pricing?.description}</TableCell>
        <TableCell>
          {pricing?.price}{" "}
          {pricing?.currency ? (
            <span className="pricing-currency">{pricing?.currency}</span>
          ) : (
            <></>
          )}
        </TableCell>
      </TableRow>
    );
  }

  function getAttachmentContentUI() {
    return (
      <div className="account-assignment-table">
        <POItemAttachment
          po={selectedPODetails?.attachments}
          approvalSystemId={props.poData?.approvalSystemId}
          poID={props.poData?.id}
        ></POItemAttachment>
      </div>
    );
  }
}
