import { EJ_ITEMS_TAB_ID } from "Components2/EJourney/EJourneyUtil";
import {
  IEjourney,
  IEjourneyDetailData,
  IEjourneyDrivingRisk,
  IEjourneyDrivingRiskData,
} from "Model/ServerResponse";
import React from "react";
import Utility from "../../../../common/utilities";
import { Accordian } from "../../../Accordian/Accordian";
import "./EJourneyDetailDrivingRisk.scss";

export interface IEjourneyDetailDrivingRiskProps {
  ejItemData: IEjourney;
}
export function EJourneyDetailsDrivingrisk(
  props: IEjourneyDetailDrivingRiskProps
)  {
  const tripData: IEjourneyDetailData = props.ejItemData.tabs.find(
    (td) => td.tabId === EJ_ITEMS_TAB_ID
  )?.data as IEjourneyDetailData;
  let drivingRisks: IEjourneyDrivingRisk[] = tripData?.drivingRisks;
  return (
    <>
      {!Utility.isArrayEmpty(drivingRisks) ? (
        <div className="detailDriving">
          <div className="panelheader">
            <div>
              <span className="panelheadertext">
                <b>Driving Risk</b>
              </span>
              <span className="panelheadertext riskScore">
                <b className={props.ejItemData.risk}>
                  {props.ejItemData.risk}{" "}
                </b>
                &nbsp;&nbsp;<b>( {props.ejItemData.riskScore} )</b>
              </span>
            </div>
            <table className="tableLayout">
              <tr className="row-detail">
                <td className="col-1">Risk Factor</td>
                <td className="col-2">Answer</td>
                <td className="col-3">Score</td>
              </tr>
            </table>
          </div>
          <div>
            {drivingRisks.map(
              (element: IEjourneyDrivingRisk, _index: number) => (
                <div className="border-bottom-container" key={Utility.createUniqueId(element,_index)}>
                  <Accordian
                    headerContent={getHeaderUI(element)}
                    content={getContentUI(element)}
                  />
                </div>
              )
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );

  function getSumofScores(element: IEjourneyDrivingRisk) {
    let TotalScore = 0;

    element.risks.forEach((risk: any) => {
      TotalScore += risk.score;
    });

    return TotalScore;
  }

  function getHeaderUI(element: IEjourneyDrivingRisk)  {
    let sumofScore = getSumofScores(element);
    return (
      <>
        <span className="risk-header-Text">
          <b>{element.riskType}</b>
        </span>
        <span className="risk-header-Text riskCount">
          <b>({sumofScore})</b>
        </span>
      </>
    );
  }

  function getContentUI(element: IEjourneyDrivingRisk)  {
    return (
      <table className="tableLayout">
        {element.risks.map((risk: IEjourneyDrivingRiskData, index: number) => {
          return (
            <tr key={Utility.createUniqueId(element,index)}>
              <td className="rowvalue col-1">{risk.riskFactor}</td>
              <td className="rowrowanswer col-2">
                <b>{risk.answer}</b>
              </td>
              <td className="col-3">
                <b>{risk.score}</b>
              </td>
            </tr>
          );
        })}
      </table>
    );
  }
}
