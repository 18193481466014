import React from "react";
import { ICart } from "../../../Model/ServerResponse";
import { IKeyValue } from "../../../Model/UI";
import { KeyValueList } from "Components2/KeyValueList/KeyValueList";


export interface ISWPSDetailKeyValueListProp {
  swps: ICart;
}

export function SWPSDetailKeyValueList(props: ISWPSDetailKeyValueListProp) {

  function getHeaderTableUI(){
    let _data = getKeyValueListData();
    return (
      <KeyValueList data={_data} />
    );
  }

  function getKeyValueListData(): IKeyValue[] {
    let dataArray: IKeyValue[] = [
      { _key: "Doc Type", value: props.swps.docType, seperator: ":" }
    ];
    return dataArray;
  }

  return getHeaderTableUI();
}
