import React from "react";
import { useSelector } from "react-redux";
import {
  GetApprovalDetails,
  MultiSelectedApprovalsSelector,
  PendingApprovalsStatusSelector,
  SelectedApprovalDetailErrorSelector,
  SelectedApprovalDetailsSelector,
  SelectedApprovalDetailStatusSelector,
  SelectedApprovalSelector,
  setSelectedApprovalDetails,
} from "../../../Store/EJourney/EJourneySlice";
import { IEjourney } from "../../../Model/ServerResponse";
import { ISystemDetailsCardBaseProp } from "../../../Model/UI";
import "./EJourneyDetailsCard.scss";
import { useAppDispatch } from "../../../Store/store";
import { RequestStatus } from "../../../Model/Enums";
import { Message1 } from "../../../Components2/MessageTemplates/Message1/Message1";
import { DetailSkeleton1 } from "../../../Components2/Skeletons/DetailSkeletons/DetailSkeleton1/DetailSkeleton1";
import { EJourneyHeader } from "../EJourneyHeader/EJourneyHeader";
import { EJourneyDetailKeyValueList } from "../EJourneyDetailKeyValueList/EJourneyDetailKeyValueList";
import { EJourneyTabs } from "../EJourneyTabs/EJourneyTabs";
export interface IEJourneyDetailsCardProp<T>
  extends ISystemDetailsCardBaseProp<T> {}

export function EJourneyDetailsCard(
  props: IEJourneyDetailsCardProp<IEjourney>
) {
  let appDispatch = useAppDispatch();

  let selectedApproval = useSelector(SelectedApprovalSelector);
  let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);

  let selectedApprovalDetails = useSelector(SelectedApprovalDetailsSelector);
  let selectedApprovalDetailsStatus = useSelector(
    SelectedApprovalDetailStatusSelector
  );
  let selectedApprovalDetailsError = useSelector(
    SelectedApprovalDetailErrorSelector
  );

  let pendingApprovalsStatus = useSelector(PendingApprovalsStatusSelector);

  //Re-render
  React.useEffect(() => {
    appDispatch(setSelectedApprovalDetails(null));

    if (selectedApproval) {
      appDispatch(GetApprovalDetails());
    }
  }, [selectedApproval]);

  return <div className="ej-details-card">{detailsUI()} </div>;

  function detailsUI() {
    if (
      selectedApprovalDetailsStatus === RequestStatus.Loading ||
      (pendingApprovalsStatus === RequestStatus.Loading && !selectedApproval)
    ) {
      return loadingUI();
    } else if (selectedApprovalDetailsStatus === RequestStatus.Failed) {
      return errorUI();
    } else if (!selectedApproval) {
      return emptyIndicatorUI();
    }

    if (selectedApprovalDetails) {
      return (
        <>
          <div className="ej-details-header">
            <EJourneyHeader
              eJ={selectedApprovalDetails as IEjourney}
            ></EJourneyHeader>
            <div className="dividerDiv"></div>
          </div>

          <div className="ej-details-content">
            <EJourneyDetailKeyValueList
              ej={selectedApprovalDetails as IEjourney}
            ></EJourneyDetailKeyValueList>
            <EJourneyTabs
              ej={selectedApprovalDetails as IEjourney}
            ></EJourneyTabs>
          </div>

          {actionContainerUI()}
        </>
      );
    } else {
      return <></>;
    }
  }

  function loadingUI() {
    return <DetailSkeleton1 />;
  }

  function emptyIndicatorUI() {
    return (
      <div className="message-container">
        <Message1
          title="No details to show."
          imgSrc="CardAssets/NoPendingRequests.svg"
        ></Message1>
      </div>
    );
  }

  function errorUI() {
    if (selectedApprovalDetailsError) {
      return (
        <div className="message-container">
          <Message1
            title={selectedApprovalDetailsError.message}
            subTitle={selectedApprovalDetailsError.description}
            imgSrc="CardAssets/error.svg"
          ></Message1>
        </div>
      );
    }
    return <></>;
  }

  function actionContainerUI() {
    if (selectedApprovalDetails && multiSelectedApprovals.length === 0) {
      return (
        <div className="action-container">
          {props.getActionsUI(false, [selectedApprovalDetails as IEjourney])}
        </div>
      );
    }
    return <></>;
  }
}
