import React from "react";
import { CommentsTemplate } from "../../CommentsTemplate/CommentsTemplate";
import { IComment } from "../../../Model/UI/IComment";
import "./EQuoteComments.scss";
import Utility from "../../../common/utilities";
import { IEQuoteTab } from "Model/ServerResponse/IEquoteTab";
import { IEQuoteCommentData } from "Model/ServerResponse/IEQuoteCommentData";

export interface IEQuoteCommentsProp {
  eQuoteTabData: IEQuoteTab | undefined;
}

export function EQuoteComments(props: IEQuoteCommentsProp) {
  let commentDataModel: IComment[] = [];

  return <div className="eQuote-comments-tab">{tabUI()}</div>;

  function tabUI() {
    if (
      props.eQuoteTabData &&
      !Utility.isArrayEmpty(props.eQuoteTabData.data)
    ) {
      const commentData: IEQuoteCommentData[] = props.eQuoteTabData
        .data as IEQuoteCommentData[];
      commentData.forEach((cd) => {
        let dateItem = cd;
        commentDataModel.push({
          label1: dateItem.date,
          label2: dateItem.commentBy,
          label3: dateItem.comment,
        });
      });

      return (
        <CommentsTemplate commentData={commentDataModel}></CommentsTemplate>
      );
    } else {
      return <div className="no-comments">No Comments</div>;
    }
  }
}
