import React from "react";
import { ITableDataMapping } from "../../../Model/UI";
import {
  IEjourneyApproverData,
  IEjourneyTab,
} from "../../../Model/ServerResponse";
import { TableComponent } from "../../TableComponent/TableComponent";
import { TableCell, TableRow } from "@material-ui/core";
import Utility from "../../../common/utilities";
import "./EJourneyApprovers.scss";

export interface IEJApproversProp {
  ejApproversTabData: IEjourneyTab | undefined;
}

export function EJourneyApprovers(props: IEJApproversProp)  {
  let tableHeaderMapping: ITableDataMapping[] = [
    {
      id: "level",
      label: "Level/Code",
    },
    {
      id: "approverName",
      label: "Approver",
    },
    {
      id: "approverId",
      label: "Contact No.",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "actionDate",
      label: "Action Date",
    },
  ];

  return (
    <div data-testid="ej-approvers-tab" className="ej-approvers-tab">
      {tabUI()}
    </div>
  );

  function tabUI()  {
    if (
      props.ejApproversTabData &&
      !Utility.isArrayEmpty(props.ejApproversTabData.data)
    ) {
      const approverData: IEjourneyApproverData[] = props.ejApproversTabData
        .data as IEjourneyApproverData[];
      return (
        <TableComponent
          tableData={approverData}
          mappingTableData={tableHeaderMapping}
          getTableRow={getTableApproversRow}
        ></TableComponent>
      );
    } else {
      return <div className="no-approvers">No Approvers</div>;
    }
  }

  function getTableApproversRow(
    index: number,
    row: IEjourneyApproverData
  )  {
    return (
      <>
        <TableRow className="spacing"></TableRow>
        <TableRow data-testid="table-row" className="main-row" key={index}>
          <TableCell className="approval-level">{row.level}</TableCell>
          <TableCell className="approver-name">
            <strong>{row.approverName}</strong>
          </TableCell>
          <TableCell className="job-title">{row.approverId}</TableCell>
          <TableCell
            data-testid="approver-status-cell"
            className={Utility.mergeCssClassNames([
              getApproverStatusStyle(row.status),
            ])}
          >
            {row.status}
          </TableCell>
          <TableCell className="action-date">{row.actionDate}</TableCell>
        </TableRow>
      </>
    );
  }

  function getApproverStatusStyle(status: string): string {
    let result = "";
    switch (status.toLowerCase()) {
      case "approved":
        result = "approved";
        break;
      case "rejected":
        result = "rejected";
        break;
      case "pending":
        result = "pending";
        break;
      default:
        return "";
    }
    return result;
  }
}
