import { ScreenSize } from "Model/Enums";
import { useState, useEffect } from "react";

export function useWindowSize(): ScreenSize {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState<any>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  if(windowSize.width <= ScreenSize.MobileMx){
    return  ScreenSize.MobileMx;
  }
  else if(windowSize.width <= ScreenSize.TabletMx){
    return  ScreenSize.TabletMx;
  }
  else if(windowSize.width <= ScreenSize.LaptopXx){
    return  ScreenSize.LaptopXx;
  }
  else{
    return  ScreenSize.DesktopMx;
  }
}
