import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import {
  ApprovalOperationsEditMapSelector,
  SetupUserSystemModalData,
} from "../../../Store/UserSystemProfile/UserSystemModalSlice";
import {
  AddNewApprovalOperationAddedByUser,
  ApprovalOperationKeysSelector,
  ApprovalOperationsMapSelector,
  ApprovalSystemsMapSelector,
  RemoveApprovalOperationRemovedByUser,
  TempApprovalSystemAdd,
  TempSystemNameSelector,
} from "../../../Store/UserSystemProfile/UserSystemSlice";
import * as React from "react";
import "./SystemSelectionNav.scss";
import { IDefaultUIProp } from "../../../Model/UI";
import CancelIcon from "@material-ui/icons/Cancel";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../Store/store";
import Utility from "../../../common/utilities";
export interface ISystemSelectionNavProp extends IDefaultUIProp {
  open: boolean;
  onClose: (e: any) => void;
}

export function SystemSelectionNav(props: ISystemSelectionNavProp) {
  const [open, setOpen] = React.useState(false);
  const [systemSelectedData, setSystemselectedData] = React.useState<any>([]);
  let approvalSystemsMap = useSelector(ApprovalSystemsMapSelector);
  let approvalOperationsMap = useSelector(ApprovalOperationsMapSelector);
  let approvalsystems = useSelector(ApprovalOperationKeysSelector);
  let tempApprovalsSystemName = useSelector(TempSystemNameSelector);
  let approvalOperationsEditMap = useSelector(
    ApprovalOperationsEditMapSelector
  );
  let appDispatch = useAppDispatch();
  React.useEffect(() => {
    appDispatch(SetupUserSystemModalData());
    setOpen(props.open);
  }, [appDispatch, props.open]);

  React.useEffect(() => {
    const systemList = systemSelectedData.filter(
      (item) => item !== tempApprovalsSystemName
    );
    setSystemselectedData(systemList);
    if (
      !Utility.isObjectEmpty(approvalOperationsEditMap) &&
      systemSelectedData &&
      systemSelectedData.length === 0
    ) {
      const selectedSystems = approvalsystems
        .filter(
          (x) =>
            approvalOperationsEditMap[x].isEnabled &&
            !approvalOperationsEditMap[x].isChecked
        )
        .map((x) => {
          return x;
        });
      setSystemselectedData(selectedSystems);
    }
  }, [approvalOperationsEditMap]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        scroll={"body"}
        onClose={props.onClose}
        disableBackdropClick={false}
        className="system-selection-modal"
        PaperProps={{
          className: "system-dialog",
        }}
      >
        <DialogTitle>
          <div className="system-title">
            <div>
              <p className="system-title-heading">Other Systems</p>
            </div>
            <div>
              <IconButton onClick={(e) => handleClose(e)}>
                <CancelIcon data-testid="close-button"className="closeButton"></CancelIcon>
              </IconButton>
            </div>
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="system-container">
            {systemSelectedData && systemSelectedData?.length > 0 ? (
              systemSelectedData.map((aok) => {
                return (
                  <div className="system-row" key={aok.key}>
                    <div className="system-row-container">
                      <div
                        className="system-cell"
                        onClick={(e) => handleSystemClick(aok)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="system-img"
                          alt={aok.key}
                          src={`./DrawerMenuAssets/${aok}.svg`}
                        />
                        <p className="description">
                          {approvalOperationsMap[aok].description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="nosystem">
                You don't have any other systems available to view.
              </p>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );

  function handleClose(e: any) {
    setOpen(false);
    if (props.onClose) {
      props.onClose(e);
    }
  }

  //   Method to be called on system selection from left drawer
  function handleSystemClick(e: any) {
    appDispatch(
      RemoveApprovalOperationRemovedByUser({
        approvalOperationKeyRemoved: tempApprovalsSystemName,
      })
    );
    appDispatch(
      TempApprovalSystemAdd({
        approvalOperationKeyAdded: e,
      })
    );
    appDispatch(
      AddNewApprovalOperationAddedByUser({
        approvalOperationKeyAdded: e,
        approvalOperationsMap: approvalOperationsMap,
        approvalSystemsMap: approvalSystemsMap,
      })
    );
    setOpen(false);
    if (props.onClose) {
      props.onClose("");
    }
  }
}
