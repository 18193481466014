import { ApproveModal } from "Components2/Modals/ApproveModal/ApproveModal";
import { ApproveRejectActionBar } from "Components2/Modals/ApproveRejectBar/ApproveRejectActionBar";
import { ForwardSearchModal } from "Components2/Modals/ForwardSearchModal/ForwardSearchModal";
import { RejectModal } from "Components2/Modals/RejectModal/RejectModal";
import React, { useState } from "react";
import "./ActionsHandler1.scss";
export interface IActionsHandler1Prop {
  rejectLabel?: string;
  isMulti: boolean;
  count: number;
  containsChildItems?: boolean;
  childItemCount?: number;
  isApproveCommentMandatory?: boolean;
  rejecteDisabled?: boolean;
  approveDisabled?: boolean;
  rejectDropDown?  ;
  rejectReasonValue?: string;
  onApprove: (e: any, isMulti: boolean, comment: string) => void;
  isRejectCommentMandatory?: boolean;
  onReject: (e: any, isMulti: boolean, comment: string) => void;
  onCancel?: () => void;
  onForward?: (
    e: any,
    isMulti: boolean,
    comment: string,
    approver: string
  ) => void;
}

export function ActionHandler1(props: IActionsHandler1Prop)  {
  let [approveModalOpen, setApproveModalOpen] = useState<boolean>(false);
  let [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  let [forwardSearchModalOpen, setForwardSearchModalOpen] =
    useState<boolean>(false);

  const handleForwardSearchModalClose = () => {
    setForwardSearchModalOpen(false);
  };

  const MaxCharacters: number = 500;
  const IsApproveCommentMandatoryDefault = true;
  const IsRejectCommentMandatoryDefault = true;

  function getMultiSelectLabel()  {
    return (
      <div className="multi-select-label">
        (Multiple requests may be present in each card(s). Kindly review before
        approval)
      </div>
    );
  }

  return (
    <>
      <ApproveModal
        open={approveModalOpen}
        requestDescription={getNumberOfRequests()}
        maxCommentChar={MaxCharacters}
        isCommentMandatory={
          props.isApproveCommentMandatory !== undefined
            ? props.isApproveCommentMandatory
            : IsApproveCommentMandatoryDefault
        }
        onApprove={(e: any, comment: string) => {
          setApproveModalOpen(false);
          props.onApprove(e, props.isMulti, comment);
        }}
        onCancel={(e: any) => {
          setApproveModalOpen(false);
        }}
      />

      <RejectModal
        rejectLabel={props.rejectLabel}
        open={rejectModalOpen}
        requestDescription={getNumberOfRequests()}
        maxCommentChar={MaxCharacters}
        rejectReasonValue={props.rejectReasonValue}
        rejectDropDown={props.rejectDropDown}
        isCommentMandatory={
          props.isRejectCommentMandatory !== undefined
            ? props.isRejectCommentMandatory
            : IsRejectCommentMandatoryDefault
        }
        onReject={(e: any, comment: string) => {
          setRejectModalOpen(false);
          props.onReject(e, props.isMulti, comment);
        }}
        onCancel={(e: any) => {
          setRejectModalOpen(false);
          if (props.rejectDropDown && props.onCancel) {
            props.onCancel();
          }
        }}
      />

      {forwardSearchModalOpen && (
        <ForwardSearchModal
          onForward={props.onForward}
          maxCommentChar={MaxCharacters}
          onClose={handleForwardSearchModalClose}
        />
      )}

      <ApproveRejectActionBar
        rejectLabel={props.rejectLabel}
        rejecteDisabled={props.rejecteDisabled}
        approveDisabled={props.approveDisabled}
        onApprove={(e: any) => {
          setApproveModalOpen(true);
          setRejectModalOpen(false);
        }}
        onReject={(e: any) => {
          setApproveModalOpen(false);
          setRejectModalOpen(true);
        }}
        onForward={
          props.onForward
            ? (e: any) => {
                setForwardSearchModalOpen(true);
                setApproveModalOpen(false);
                setRejectModalOpen(false);
              }
            : undefined
        }
      />
    </>
  );
  function getNumberOfRequests()  {
    if (props.isMulti) {
      return (
        <>
          <span>
            {props.count > 1
              ? `${props.count} Requests`
              : `${props.count} Request`}
          </span>
          {/* only added for systems with sub-requests in a card */}
          {props.containsChildItems ? (
            <span>{getMultiSelectLabel()}</span>
          ) : (
            <></>
          )}
        </>
      );
    } else if (props.childItemCount && props.childItemCount > 0) {
      return (
        <span>
          {props.childItemCount > 1
            ? `${props.childItemCount} Requests`
            : `${props.childItemCount} Request`}
        </span>
      );
    } else {
      return <></>;
    }
  }
}
