import {} from "@material-ui/core";
import { IDefaultUIProp } from "../../../Model/UI";
import * as React from "react";
import { AttachmentCell } from "../../AttachmentList/AttachmentCell";
import {
  IAttachmentData,
  IQuestAttachmentData,
  IServerResponse,IQuest
} from "../../../Model/ServerResponse";
import { AttachmentModal } from "../../Modals/AttachmentModal/AttachmentModal";
import { useState, useEffect } from "react";
import { AttachmentService } from "../../../Services";
import utilities from "../../../common/utilities";
import "./QuestAttachment.scss";
import { QUEST_ATTACHMENTS_TAB_ID } from "../QuestUtil";

export interface IQuestAttachmentProp extends IDefaultUIProp {
  quest: IQuest;
}

export function QuestAttachment(props: IQuestAttachmentProp) {
  let attachmentsData = props.quest.tabs.find(
    (td) => td.tabId === QUEST_ATTACHMENTS_TAB_ID
  );
  let attachmentData = attachmentsData?.data as IQuestAttachmentData[];
  let [isAttachmentDownloadable, setisAttachmentDownloadable] = useState(false);
  useEffect(() => {
    if (props.quest.isAttachmentDownloadable) {
      setisAttachmentDownloadable(true);
    }
  }, [props.quest]);
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [selectedFilename, setSelectedFilename] = useState("");
  const [selectedFileID, setselectedFileID] = useState("");

  const handleAttachmentModalClose = () => {
    setAttachmentModalOpen(false);
  };

  function handleAttachmentClick(e: any, fileName: string, fileID: string) {
    if (isAttachmentDownloadable) {
      setAttachmentModalOpen(true);
      setSelectedFilename(fileName);
      setselectedFileID(fileID);
    }
  }

  let attachmentService = AttachmentService.getInstance();

  async function getAttachmentData(
    fileID: string,
    filePath: string
  ): Promise<IAttachmentData> {
    return attachmentService
      .getAttachmentData(
        `api/approvals/${
          utilities.getSystemActivityMapping(props.quest.approvalSystemId)[1]
        }/${
          utilities.getSystemActivityMapping(props.quest.approvalSystemId)[0]
        }/${props.quest.id}/attachments/${fileID}`
      )
      .then((attachmentResponse: IServerResponse<IAttachmentData>) => {
        return attachmentResponse.payload!;
      });
  }

  function getAttachmentList() {
    if (attachmentData ) {
      return (
        <div data-testid="attachment-list" className="attachment-list">
          {attachmentData.map((e, index) => {
            return (
              <AttachmentCell
                key={e.id}
                onClick={handleAttachmentClick}
                fileID={e.id.toString()}
                fileName={e.fileName}
                isDownloadable={isAttachmentDownloadable}
              />
            );
          })}
        </div>
      );
    } else return <div className="no-attachment"> No Attachments </div>;
  }

  return (
    <>
      <div data-testid="attachment-modal">
        <div data-testid="attachment-cell">{getAttachmentList()}</div>
        <AttachmentModal
          open={attachmentModalOpen}
          selectedFilename={selectedFilename}
          selectedFileID={selectedFileID}
          getAttachmentData={getAttachmentData}
          onClose={handleAttachmentModalClose}
        />
      </div>
    </>
  );
}
