import React from "react";
import { IPretrip, IPretripDetailData, } from "../../../Model/ServerResponse";
import { PRETRIP_DETAILS_TAB_ID } from "../PreTripUtil";
import { PreTripHSEDetails } from "./HSEDetails/PreTripHSEDetails";
import "./PreTripDetails.scss";
import { PreTripTripDetails } from "./TripDetails/PreTripTripDetails";


export interface IPreTripDetailsProp {
  pretrip: IPretrip;
}

export function PreTripDetails(props: IPreTripDetailsProp){
  let _pretripDetailData = props.pretrip?.tabs.find(td => td.tabId === PRETRIP_DETAILS_TAB_ID);
  return (
    <>
      <div className="pretrip-trip-container">
        {getTripDetailsUI()}
        {getHSEDetailsUI()}
      </div>
    </>
  );

  function getTripDetailsUI(){
    return (
      <>
        <PreTripTripDetails tableData={props.pretrip} />
      </>);
  }

  function getHSEDetailsUI(){
    let _preTripHSEData = _pretripDetailData?.data as IPretripDetailData;

    return (
      <>
        <PreTripHSEDetails pretripDetailData={props.pretrip} />
      </>);
  }
}
