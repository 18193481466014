import moment from "moment";
import { IDisplayCount } from "Model/ServerResponse";
import {
  IApprovalOperationEntity,
  IApprovalSystemEntity,
  IEntity,
  ISubSystem,
} from "Model/UI";

export default class Utility {
  public static isObjectEmpty = (obj: any) => {
    return (
      obj === null ||
      obj === undefined ||
      (Object.keys(obj).length === 0 && obj.constructor === Object)
    );
  };

  public static isArrayEmpty = (arr: any) => {
    return arr === undefined || arr === null || arr.length === 0;
  };

  public static isStringEmpty = (str: string) => {
    return str === null || str === undefined || str === "";
  };

  public static createUniqueId(element, index) {
    // Assuming 'element' is an object, you can stringify it and concatenate with the index
    // You might need to adjust this based on the structure of 'element'
    return JSON.stringify(element) + "_" + index;
  }
  

  // public static isSystemSelected = (approval: any) => {
  //   let isSelected: boolean = false;
  //   let isEnabled: boolean = false;
  //   let shouldAddApprovalToList: boolean = false;
  //   // check if atleast 1 system is selected and enabled
  //   approval?.approvalSystems?.map((system: any) => {
  //     isSelected = isSelected || system.isSelected;
  //   });

  //   approval?.approvalSystems?.map((system: any) => {
  //     isEnabled = isEnabled || system.isEnabled;
  //   });

  //   shouldAddApprovalToList = isSelected;
  //   return shouldAddApprovalToList;
  // };

  // public static isSystemSelectedAndEnabled = (approval: any) => {
  //   let isSelected: boolean = false;
  //   let isEnabled: boolean = false;
  //   let shouldAddApprovalToList: boolean = false;
  //   // check if atleast 1 system is selected and enabled
  //   approval?.approvalSystems?.map((system: any) => {
  //     isSelected = isSelected || system.isSelected;
  //   });

  //   approval?.approvalSystems?.map((system: any) => {
  //     isEnabled = isEnabled || system.isEnabled;
  //   });

  //   shouldAddApprovalToList = isSelected && isEnabled;
  //   return shouldAddApprovalToList;
  // };

  // public static isAnyApprovalSelected = (approvalsList: any[]) => {
  //   let isSystemSelected = false;
  //   let isAnySystemSelected = false;
  //   approvalsList.map((approval: any) => {
  //     isSystemSelected = false;
  //     approval.approvalSystems.map((system: any) => {
  //       isSystemSelected = isSystemSelected || system.isSelected;
  //     });
  //     isAnySystemSelected = isAnySystemSelected || isSystemSelected;
  //   });

  //   return isAnySystemSelected;
  // };

  ////////// For Refac ///////////////

  public static getClassNamesFromProps = (
    feed: string,
    className?: string
  ): string => {
    if (className) {
      return feed + " " + className;
    }
    return feed;
  };

  public static stringToPascalCase(s: string = "") {
    let r = "";
    for (let i = 0; i < s.length; i++)
      r += i == 0 || s[i - 1] == " " ? s[i].toUpperCase() : s[i].toLowerCase();
    return r;
  }

  public static mergeCssClassNames = (
    classes: (string | undefined | null)[]
  ): string => {
    let space = " ";
    let cssClasses = classes.filter((c) => c ?? "");
    return cssClasses.join(space);
  };

  public static formatDate = (date: string, format: string) => {
    return moment(date).format(format).toString();
  };

  public static convertDisplayCountToSubSytem(
    displayCount: IDisplayCount[]
  ): ISubSystem[] {
    let result: ISubSystem[] = [];
    displayCount.forEach((dc) => {
      result.push({
        systemName: dc.systemName,
        count: dc.count,
        filters: dc.filters,
        isEnabled: dc.isEnabled,
        errorMessage: dc.errorMessage,
      });
    });
    return result;
  }

  public static sumOfSubSytemsCount(subSystems: ISubSystem[]) {
    let result = 0;
    subSystems.forEach((ss) => {
      result += ss.count;
    });
    return result;
  }

  public static generateSecureRandomString() {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0].toString(36);
  }
  

  public static groupBy<T, U>(list: T[], keyGetter: (arg0: T) => U) {
    const map = new Map<U, [T]>();
    list.forEach((item: T) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  public static arrayUnique<T, U>(array: T[], keyGetter: (arg0: T) => U) {
    let a = array.concat();

    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        const key1 = keyGetter(a[i]);
        const key2 = keyGetter(a[j]);
        if (key1 === key2) a.splice(j--, 1);
      }
    }

    return a;
  }

  public static getSystemActivityMapping(approvalSystemId: string): string[] {
    switch (approvalSystemId) {
      case "QE-MOC":
        return ["QuestChange", "MOC"];
      case "QE-Exemption":
        return ["QuestChange", "Exemption"];
      case "PreTrip-OSP":
        return ["Travel", "OneSitePass"];
      case "eJourney":
        return ["Trip", "eJourney"];
      case "JRA":
        return ["FDP", "JRA"];
      case "DLP":
        return ["Plan", "DLP"];
      case "TaskSheet":
        return ["Watershed", "LRS"];
      case "eQuote":
        return ["Quote", "RFQ"];
      case "Time Sheet-Load Chart":
        return ["TimeSheet", "Loadchart"];
      case "Shopping Cart":
        return ["SRM", "ShoppingCart"];

      default:
        return approvalSystemId.split("-");
    }
  }

  public static getApprovalOperationKeysHavingAtleastOneEnabledSelected(
    approvalOperationKeys: string[],
    approvalOperationsMap: IEntity<IApprovalOperationEntity>,
    approvalSystemsMap: IEntity<IApprovalSystemEntity>
  ): string[] {
    const _aoKeys = approvalOperationKeys;
    const _aoMap = approvalOperationsMap;
    const _asMap = approvalSystemsMap;

    //Resul array from which we create routes, defaultRoute
    let enabledSelectedApprovalOperations: string[] = [];

    _aoKeys.forEach((aok) => {
      //Approval systems of a particular approval operation
      //Ex: ITT & Optimus of PO
      let approvalSystemKeys: string[] = _aoMap[aok].approvalSystems;

      //Find if atleast one approval system is enabled & selected
      let isEnabledSelected = false;
      approvalSystemKeys.forEach((ask: string) => {
        isEnabledSelected =
          isEnabledSelected ||
          (_asMap[ask].isSelected && _asMap[ask].isEnabled);
      });

      //Push to result array if all approval systems are enabled & selected
      if (isEnabledSelected) {
        enabledSelectedApprovalOperations?.push(aok);
      }
    });
    return enabledSelectedApprovalOperations;
  }

  public static getApprovalOperationsHavingAtleastOneEnabled(
    approvalOperationKeys: string[],
    approvalOperationsMap: IEntity<IApprovalOperationEntity>,
    approvalSystemsMap: IEntity<IApprovalSystemEntity>
  ): string[] {
    const _aoKeys = approvalOperationKeys;
    const _aoMap = approvalOperationsMap;
    const _asMap = approvalSystemsMap;

    //Resul array from which we create routes, defaultRoute
    let enabledApprovalOperations: string[] = [];

    _aoKeys.forEach((aok) => {
      //Approval systems of a particular approval operation
      //Ex: ITT & Optimus of PO
      let approvalSystemKeys: string[] = _aoMap[aok].approvalSystems;

      //Find if atleast one approval system is enabled & selected
      let isEnabled = false;
      approvalSystemKeys.forEach((ask: string) => {
        isEnabled = isEnabled || (_asMap[ask] ? _asMap[ask].isEnabled : false);
      });

      //Push to result array if all approval systems are enabled & selected
      if (isEnabled) {
        enabledApprovalOperations.push(aok);
      }
    });
    return enabledApprovalOperations;
  }

  public static getApprovalOperationsHavingAtleastOneSelected(
    approvalOperationKeys: string[],
    approvalOperationsMap: IEntity<IApprovalOperationEntity>,
    approvalSystemsMap: IEntity<IApprovalSystemEntity>
  ): string[] {
    const _aoKeys = approvalOperationKeys;
    const _aoMap = approvalOperationsMap;
    const _asMap = approvalSystemsMap;

    //Resul array from which we create routes, defaultRoute
    let selectedApprovalOperations: string[] = [];

    _aoKeys.forEach((aok) => {
      //Approval systems of a particular approval operation
      //Ex: ITT & Optimus of PO
      let approvalSystemKeys: string[] = _aoMap[aok].approvalSystems;

      //Find if atleast one approval system is enabled & selected
      let isSelected = false;
      approvalSystemKeys.forEach((ask: string) => {
        if (ask === "Exemption" || ask === "MOC") {
          isSelected =
            isSelected ||
            (_asMap["Quest"] ? _asMap["Quest"].isSelected : false);
        } else {
          isSelected =
            isSelected || (_asMap[ask] ? _asMap[ask].isSelected : false);
        }
      });

      //Push to result array if all approval systems are enabled & selected
      if (isSelected) {
        selectedApprovalOperations.push(aok);
      }
    });
    return selectedApprovalOperations;
  }

  //Sort ApprovalOperationKeys based on description
  public static sortApprovalOperationKeys(
    approvalOperationKeys: string[],
    approvalOperationsMap: IEntity<IApprovalOperationEntity>
  ): string[] {
    approvalOperationKeys.sort((k1, k2) => {
      let k1Descp = approvalOperationsMap[k1]?.description.toLowerCase();
      let k2Descp = approvalOperationsMap[k2]?.description.toLowerCase();
      if (k1Descp === k2Descp) {
        return 0;
      }
      if (k1Descp < k2Descp) {
        return -1;
      }
      return 1;
    });

    return approvalOperationKeys;
  }

  public static refreshScreen() {
    window.location.reload();
  }

  public static calculateSelectedSystemsCount(
    approvalSystemsMaps: any
  ): number {
    let exemptionFound = false;
    let mocFound = false;
    let ittFound = false;
    let optimusFound = false;
    let questFound = false;
    let selectedSystemsCount = 0;
    (Object.values(approvalSystemsMaps) as IApprovalSystemEntity[]).forEach(
      (system) => {
        if (system.isSelected) {
          switch (system.name) {
            case "Exemption":
              exemptionFound = true;
              break;
            case "MOC":
              mocFound = true;
              break;
            case "ITT":
              ittFound = true;
              break;
            case "Optimus":
              optimusFound = true;
              break;
            case "Quest":
              questFound = true;
              break;
            default:
              selectedSystemsCount++;
              break;
          }
        }
      }
    );

    if (exemptionFound && mocFound && questFound) {
      selectedSystemsCount++;
    }
    if (ittFound || optimusFound) {
      selectedSystemsCount++;
    }
    return selectedSystemsCount;
  }
}
