import {
  IFailedTakeAction,
  ISystem,
  ITakeActionRequest,
  ITimeSheet,
} from "../../../Model/ServerResponse";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  PostDeleteApprovals,
  reCalculateSelectedApproval,
  SelectedApprovalSelector,
  setIsTabletView,
  setSelectedApproval,
  MultiSelectedApprovalsSelector,
  resetSystemSliceState,
  timesheetSliceInitialState,
  SearchQuerySelector,
  SubSystemsSelector,
  setSearchQuery,
  FilterQuerySelector,
  setFilterQuery,
  GetPendingApprovals,
} from "../../../Store/TimeSheet/TimeSheetSlice";
import { ListDetailsView } from "../../ListDetailsView/ListDetailsView";
import { TimeSheetActions } from "../TimeSheetActions/TimeSheetActions";
import { TakeActionFailedModal } from "../../Modals/TakeActionFailedModal/TakeActionFailedModal";
import "./TimeSheetPage.scss";
import { useAppDispatch } from "../../../Store/store";
import { TimeSheetListView } from "../TimeSheetListView/TimeSheetListView";
import { TimeSheetDetailsCard } from "../TimeSheetDetailsCard/TimeSheetDetailsCard";
import { Box, FormControl, Select, MenuItem } from "@material-ui/core";
import Utility from "common/utilities";

export interface ITimeSheetPageProp {
}

export function TimeSheetPage(props: ITimeSheetPageProp) {
  const [selectedLeaveItems, setSelectedLeaveItems] = React.useState<string[]>(
    []
  );
  const handleSelectedLeaveItems = (data: string[]) => {
    setSelectedLeaveItems(data);
  };
  const filterQueryValue = useSelector(FilterQuerySelector);
  let searchQuery = useSelector(SearchQuerySelector);

  let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);
  let subSystems = useSelector(SubSystemsSelector);
  let appDispatch = useAppDispatch();
  const [filterlist, setFilterList] = React.useState<string[]>([]);
  let selectedApproval = useSelector(SelectedApprovalSelector);

  let [takeActionFailedModalOpen, setTakeActionFailedModalOpen] =
    useState<boolean>(false);
  let [failedTakeActions, setFailedTakeActions] = useState<IFailedTakeAction[]>(
    []
  );
  const [searchTextValue, setsearchTextValue] = useState("0");

  const handleChange = (event) => {
    const changedValue = event.target.value;
    appDispatch(setFilterQuery(changedValue));
    const existingSearch = searchQuery.split("&filter=")[0];
    searchQuery =
      existingSearch +
      "&filter=" +
      (changedValue === "All" ? "" : changedValue);
    appDispatch(setSearchQuery(searchQuery));
  };
  useEffect(() => {
    if (subSystems && subSystems.length > 0) {
      setFilterList(subSystems[0].filters);
    }
  }, [subSystems]);

  useEffect(() => {
    if (!filterQueryValue) {
      appDispatch(setFilterQuery("All"));
    }
  }, [appDispatch, filterQueryValue]);


  return (
    <div className="timesheet-page-container">
      {takeActionFailedModalOpen && (
        <TakeActionFailedModal
          open={takeActionFailedModalOpen}
          failedActions={failedTakeActions}
          onOk={(e: any) => setTakeActionFailedModalOpen(false)}
        />
      )}
      <ListDetailsView
        selectedApproval={selectedApproval}
        onBackClick={handleBackClick}
        onWindowSizeChange={handleWindowSizeChange}
        listView={listUI}
        detailsView={detailsUI}
      />
    </div>
  );

  function listUI() {
    return (
      <TimeSheetListView
        getFilterUI={getFilterUI}
        getActionsUI={actionsUI}
        searchValue={searchTextValue}
      />
    );
  }

  function detailsUI() {
    return (
      <TimeSheetDetailsCard
        sendSelectedLeaves={handleSelectedLeaveItems}
        getActionsUI={actionsUI}
      />
    );
  }

  function actionsUI(isMulti: boolean, approvals: ITimeSheet[]) {
    return (
      <TimeSheetActions
        approveDisabled={
          selectedLeaveItems?.length === 0 &&
          multiSelectedApprovals?.length === 0
        } // Set value true in case of approve button to be disabled
        rejecteDisabled={
          selectedLeaveItems?.length === 0 &&
          multiSelectedApprovals?.length === 0
        } // Set value true in case of reject button to be disabled
        isMulti={isMulti}
        count={approvals.length}
        childItemCount={selectedLeaveItems.length}
        onApprove={handleOnApprove}
        onReject={handleOnReject}
      />
    );
  }

  function getFilterUI() {
    return (
      <Box
        className="filter-dropdown"
        sx={{
          borderRadius: 4,
          borderColor: "white",
          bgcolor: "white",
        }}
      >
        <FormControl fullWidth>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            disableUnderline={true}
            value={filterQueryValue}
            label="Filter"
            onChange={handleChange}
          >
            <MenuItem value={"All"}>All</MenuItem>
            {filterlist?.map((filter, index) => (
              <MenuItem
                style={{ whiteSpace: "inherit" }}
                key={Utility.createUniqueId(filter, index)}
                value={filter}
              >
                {filter}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  }

  function handleBackClick(e: any) {
    appDispatch(setSelectedApproval(null));
  }

  function handleWindowSizeChange(e: any, isTabletView: boolean) {
    appDispatch(setIsTabletView(isTabletView));
    appDispatch(reCalculateSelectedApproval());
  }

  function handleOnApprove(e: any, isMulti: boolean, comment: string) {
    takeAction(true, isMulti, comment);
  }

  function handleOnReject(e: any, isMulti: boolean, comment: string) {
    takeAction(false, isMulti, comment);
  }

  async function takeAction(
    isApprove: boolean,
    isMulti: boolean,
    comment: string
  ): Promise<void> {
    let result = (
      await appDispatch(
        PostDeleteApprovals({
          isApprove: isApprove,
          isMulti: isMulti,
          comment: comment,
          getRequestBody: getTakeActionRequestBody,
        })
      )
    ).payload as {
      passedApprovals: ITimeSheet[];
      failedTakeActions: IFailedTakeAction[];
    };
    if (result.failedTakeActions.length > 0) {
      setTakeActionFailedModalOpen(true);
      setFailedTakeActions(result.failedTakeActions);
    } else {
      if (searchQuery) {
        appDispatch(resetSystemSliceState(timesheetSliceInitialState));
        setsearchTextValue(Utility.generateSecureRandomString());
        appDispatch(setFilterQuery("All"));
      } else {
        appDispatch(resetSystemSliceState(timesheetSliceInitialState));
        //Get approvals
        appDispatch(GetPendingApprovals());
      }
    }
  }
  function getTakeActionRequestBody(_approval: ISystem, _comment: string): any {
    let _timesheet = _approval as ITimeSheet;
    let arr: ITakeActionRequest<{ approverType: string }>[] = [];
    if (selectedLeaveItems && selectedLeaveItems.length > 0) {
      arr.push({
        approvalId: _timesheet.id?.toString(),
        childs: selectedLeaveItems.filter((child) => child && child != ""),
        comment: _comment,
      });
    } else if (
      selectedLeaveItems.length === 0 ||
      selectedLeaveItems.length === _timesheet.items
    ) {
      arr.push({
        approvalId: _timesheet.id?.toString(),
        childs: _timesheet.childs,
        comment: _comment,
      });
    }
    return arr;
  }
}
