import React from "react";
import { ActionModal } from "../ActionModal/ActionModal";
import "./RejectModal.scss";

export interface IRejectModalProp {
  rejectLabel?: string;
  open: boolean;
  isCommentMandatory?: boolean;
  requestDescription?: string | React.ReactNode;
  maxCommentChar?: number;
  rejectDropDown?: React.ReactNode;
  rejectReasonValue?: string;
  onCancel?: (e: any) => void;
  onReject: (e: any, comment: string) => void;
}

export function RejectModal(props: IRejectModalProp) {
  return (
    <ActionModal
      open={props.open}
      title={"Confirmation"}
      question={
        "Are you sure you want to" +
        (props.rejectLabel
          ? " " + props.rejectLabel.toLowerCase() + "? "
          : " reject?")
      }
      requestDescription={props.requestDescription}
      isCommentMandatory={props.isCommentMandatory}
      maxCommentChar={props.maxCommentChar}
      primaryActionLabel={props.rejectLabel ? "Delete" : "Reject"}
      onCancel={props?.onCancel}
      rejectReasonValue={props?.rejectReasonValue}
      rejectDropDown={props?.rejectDropDown ? props?.rejectDropDown : <></>}
      onPrimaryAction={props.onReject}
      className="reject-modal"
    ></ActionModal>
  );
}
