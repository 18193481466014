import {
  AuthenticationResult,
  BrowserCacheLocation,
  Configuration,
  EventMessage,
  EventType,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { Constants } from "common/constants";

const loggerCallback = (logLevel: LogLevel, message: string) => {
  console.log(message);
};

// MSAL configuration
const configuration: Configuration = {
  auth: {
    authority: Constants.AUTHORITY,
    clientId: Constants.CLIENT_ID,
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false // set to true for IE 11
  },
  system: {
    loggerOptions: {
      loggerCallback,
      logLevel: LogLevel.Info,
      piiLoggingEnabled: false
    }
  }
};

export const msalInstance = new PublicClientApplication(configuration);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

