import Utility from "common/utilities";
import { IPretrip } from "Model/ServerResponse";
import { ISystemHeaderBaseProp } from "Model/UI";
import React from "react";
// It is important that we import local scss after Header1/2/3 so the styles are overidden in correct way
import "./PreTripHeader.scss";
import { Constants } from "common/constants";
import { Header6 } from "Components2/HeaderTemplates/Header6/Header6";

export interface IPreTripHeaderData extends ISystemHeaderBaseProp {
  pretrip: IPretrip;
}

export function PreTripHeader(props: IPreTripHeaderData) {
  function getHeaderUI() {
    return (
      <Header6
        label1={props.pretrip.approvalSystemId}
        label2={Utility.formatDate(props.pretrip.date, Constants.DD_MM_YY)}
        label3={props.pretrip.title}
        label4={props.pretrip.isCompliant ? "Compliant" : "Non-Compliant"}
        userDetails={{
          userId: props.pretrip.requestorID,
          userCardName: props.pretrip.requestor,
          userOrganization: props.pretrip.jobTitle,
        }}
        className={Utility.mergeCssClassNames([
          "pretrip-header",
          getCompliantStyle(props.pretrip.isCompliant)
        ])}
        label5={"(Advance booking policy 10 days)"}
      ></Header6>
    );
  }

  function getCompliantStyle(isCompliant: boolean): string {
    return isCompliant ? "is-compliant-green" : "is-compliant-red";
  }


  return (getHeaderUI());
}
