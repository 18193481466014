import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  getPendingApprovals1Thunk,
  getApprovalDetails1Thunk,
  postDeleteApprovals1Thunk,
  deleteApprovals1Thunk,
  systemSlice1Reducer,
  ISystemSlice1State,
} from "../../Store/SystemTemplate/SystemSlice1";
import { RequestStatus } from "../../Model/Enums";
import { IAppState } from "../store";

export const srmSliceInitialState: ISystemSlice1State = {
  pendingApprovalsStatus: RequestStatus.Idle,
  pendingApprovalsError: null,
  selectedApprovalDetailStatus: RequestStatus.Idle,
  selectedApprovalDetailError: null,
  supportMulti: false,
  selectedApproval: null,
  selectedApprovalDetails: null,
  multiSelectedApprovals: [],
  searchQuery: "",
  isAsc: false,
  selectedSubSystem: null,
  fromPosition: 0,
  subSystems: [],
  totalCountForFilter: 0,
  pendingApprovals: [],
  isTabletView: false,
};
const SRMslice = createSlice({
  name: "SRM",
  initialState: srmSliceInitialState,
  reducers: systemSlice1Reducer,
});

export const GetPendingApprovals = getPendingApprovals1Thunk(
  srmSliceInitialState,
  SRMslice,
  "SRM",
  "SRM"
);
export const GetApprovalDetails = getApprovalDetails1Thunk(
  SRMslice,
  "SRM",
  "SRM"
);
export const PostDeleteApprovals = postDeleteApprovals1Thunk(SRMslice, "SRM");
export const DeleteApprovals = deleteApprovals1Thunk(SRMslice, "SRM", "SRM");
//Action
export const {
  resetSystemSliceState,
  setPendingApprovalsStatus,
  setSelectedApproval,
  setSelectedApprovalDetails,
  setMultiSelectedApprovals,
  setSearchQuery,
  setIsAsc,
  setSelectedSubSystem,
  setFromPosition,
  setSubSystems,
  setTotalCountForFilter,
  setPendingApprovals,
  addToPendingApprovals,
  reCalculateSelectedApproval,
  setIsTabletView,
} = SRMslice.actions;

//Selector
export const SRMSelector = (state: IAppState) => state.SRM;
export const PendingApprovalsStatusSelector = createSelector(
  SRMSelector,
  (details) => details?.pendingApprovalsStatus
);
export const PendingApprovalsErrorSelector = createSelector(
  SRMSelector,
  (details) => details?.pendingApprovalsError
);
export const SelectedApprovalDetailStatusSelector = createSelector(
  SRMSelector,
  (details) => details?.selectedApprovalDetailStatus
);
export const SelectedApprovalDetailErrorSelector = createSelector(
  SRMSelector,
  (details) => details?.selectedApprovalDetailError
);
export const SupportMultiSelector = createSelector(
  SRMSelector,
  (details) => details?.supportMulti
);
export const SelectedApprovalSelector = createSelector(
  SRMSelector,
  (details) => details?.selectedApproval
);
export const SelectedApprovalDetailsSelector = createSelector(
  SRMSelector,
  (details) => details?.selectedApprovalDetails
);
export const MultiSelectedApprovalsSelector = createSelector(
  SRMSelector,
  (details) => details?.multiSelectedApprovals
);
export const SearchQuerySelector = createSelector(
  SRMSelector,
  (details) => details?.searchQuery
);
export const IsAscSelector = createSelector(
  SRMSelector,
  (details) => details?.isAsc
);
export const SelectedSubSystemSelector = createSelector(
  SRMSelector,
  (details) => details?.selectedSubSystem
);
export const FromPositionSelector = createSelector(
  SRMSelector,
  (details) => details?.fromPosition
);
export const SubSystemsSelector = createSelector(
  SRMSelector,
  (details) => details?.subSystems
);
export const TotalCountForFilterSelector = createSelector(
  SRMSelector,
  (details) => details?.totalCountForFilter
);
export const PendingApprovalsSelector = createSelector(
  SRMSelector,
  (details) => details?.pendingApprovals
);
export const IsTabletViewSelector = createSelector(
  SRMSelector,
  (details) => details?.isTabletView
);


export const SRMReducer = SRMslice.reducer;
