import { ISystemCardBaseProp } from "Model/UI";
import React from "react";
import { Card4 } from "../../CardTemplates/Card4/Card4";
import { ITimeSheet } from "Model/ServerResponse";
import { Constants } from "common/constants";
import "./TimeSheetCard.scss";
import Utility from "common/utilities";

export interface ITimeSheetCardProp extends ISystemCardBaseProp {
  timeSheet: ITimeSheet;
}

export function TimeSheetCard(props: ITimeSheetCardProp) {
  function getCardUI()  {
    return (
      <Card4
        label1={props.timeSheet.approvalSystemId}
        label2={props.timeSheet.title}
        label3={props.timeSheet.total + ' Request(s)'}
        label4={Utility.formatDate(props.timeSheet.date, Constants.DD_MM_YY)}
        keyValue1={{
          _key: '',
          value: '',
        }}
        userDetails={{
          userId: props.timeSheet.requestorID,
          userCardName: props.timeSheet.requestor,
          userOrganization: props.timeSheet.jobTitle,
        }}
        supportMulti={props.supportMulti}
        multiSelectUI={props.multiSelectUI}
        className="timesheet-card"
      ></Card4>
    );
  }

  return getCardUI();
}
