import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Checkbox,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { IDefaultUIProp } from "Model/UI";
import * as React from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "Store/store";
import {
  ResetProfileStatusSelector,
  ApprovalOperationsEditMapSelector,
  ToggelIsCheckedForApprovalOperation,
  UpdateEditedApprovalOperations,
  ResetProfileCache,
} from "../../../Store/UserSystemProfile/UserSystemModalSlice";
import {
  ApprovalOperationKeysSelector,
  ApprovalOperationsMapSelector,
  ApprovalSystemsMapSelector,
  UserProfileResponseSelector
} from "Store/UserSystemProfile/UserSystemSlice";
import "./SystemSelectionModal.scss";
import Utility from "common/utilities";
import { IFailedTakeAction } from "Model/ServerResponse";
import { ResetProfileModal } from "../ResetProfileModal/ResetProfileModal";
import { TakeActionFailedModal } from "../TakeActionFailedModal/TakeActionFailedModal";
import { useState } from "react";
import AddBoxIcon from "@material-ui/icons/AddBox";
import RemoveIcon from "@material-ui/icons/Remove";
import { SetupUserSystemModalData } from "Store/UserSystemProfile/UserSystemModalSlice";
import { makeStyles } from "@material-ui/core/styles";
import { SidebarMenuItemState } from "Model/Enums";

export interface ISystemSelectionModalProp extends IDefaultUIProp {
  open: boolean;
  onClose?: any;
}

export function SystemSelectionModal(
  props: ISystemSelectionModalProp
) {
  let userProfileResponse = useSelector(UserProfileResponseSelector);
  let resetProfileStatus = useSelector(ResetProfileStatusSelector);
  const [resetProfileModalOpen, setResetProfileModalOpen] =
    React.useState(false);
  let appDispatch = useAppDispatch();
  let approvalOperationKeys = useSelector(ApprovalOperationKeysSelector);
  let approvalOperationsMap = useSelector(ApprovalOperationsMapSelector);
  let approvalOperationsEditMap = useSelector(
    ApprovalOperationsEditMapSelector
  );
  let approvalSystemsMaps = useSelector(ApprovalSystemsMapSelector);
  const [uncheckedSystems, setUncheckedSystems] = React.useState<string[]>([]);
  const [checkedSystems, setCheckedSystems] = React.useState<string[]>([]);
  const isLoading = Object.values(approvalOperationsMap).some(
    (entity) => entity.state === SidebarMenuItemState.Loading
  );

  const useStyles = makeStyles((theme) => ({
    customTooltip: {
      fontSize: "14px",
      textAlign: "center",
      background: "#505575",
    },
  }));

  const selectedCount =
    Utility.calculateSelectedSystemsCount(approvalSystemsMaps);
  const classes = useStyles();
  const internetFailure: IFailedTakeAction[] = [
    { message: "nointernet", approvalId: "", detail: "", reason: "" },
  ];

  let [
    takeActionInternetFailedModalOpen,
    setTakeActionInternetFailedModalOpen,
  ] = useState<boolean>(false);

  React.useEffect(() => {
    const tempCheckedSystems = approvalOperationKeys.filter(
      (aok) => approvalOperationsEditMap[aok]?.isChecked
    );
    setCheckedSystems(tempCheckedSystems);

    const tempUncheckedSystems = approvalOperationKeys.filter(
      (aok) => !approvalOperationsEditMap[aok]?.isChecked
    );
    setUncheckedSystems(tempUncheckedSystems);
  }, [approvalOperationKeys, approvalOperationsEditMap]);

  React.useEffect(() => {
    if (props.open && approvalSystemsMaps) {
      appDispatch(SetupUserSystemModalData());
    }
  }, [approvalOperationKeys, props.open, approvalSystemsMaps]);

  function getIsCheckedCount() {
    let isCheckedCount = 0;
    approvalOperationKeys.forEach((aok) => {
      if (
        approvalOperationsEditMap[aok] &&
        approvalOperationsEditMap[aok].isChecked
      )
        isCheckedCount += 1;
    });
    return isCheckedCount;
  }

  async function handleRefresh() {
    if (!navigator.onLine) {
      setTakeActionInternetFailedModalOpen(true);
    } else {
      setResetProfileModalOpen(true);
      if (props.onClose) {
        props.onClose();
      }
      appDispatch(ResetProfileCache());
    }
  }

  const handleResetModalClose = () => {
    setResetProfileModalOpen(false);
  };

  const isMaxChecked = getIsCheckedCount() === 5;
  const isLeastChecked = getIsCheckedCount() === 1;
  return (
    <React.Fragment>
      {takeActionInternetFailedModalOpen && (
        <TakeActionFailedModal
          showSubTitle={false}
          open={takeActionInternetFailedModalOpen}
          failedActions={internetFailure}
          onOk={(e: any) => setTakeActionInternetFailedModalOpen(false)}
        />
      )}
      <ResetProfileModal
        open={resetProfileModalOpen}
        status={resetProfileStatus}
        onClose={handleResetModalClose}
      />
      {}
      <Dialog
        open={props.open}
        onClose={props.onClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        className="system-selection-profile-modal"
      >
        <div className="title-text1-container">
          <p className="titleText">User System Profile</p>
          <div className="spacer"></div>
          {selectedCount <= 5 && (
            <IconButton onClick={(e) => handleClose(e)}>
              <CancelIcon className="closeButton"></CancelIcon>
            </IconButton>
          )}
        </div>
        {selectedCount <= 5 && (
          <div className="note">
            <div className="note-style">
              <span className="note-text">
                <div className="refresh-section">
                  <div className="information">
                    If systems aren't visible,{" "}
                    <span className="highlight-text">Refresh your Profile</span>{" "}
                    allowed once every 24 hours.
                  </div>
                  <div className="spacer"></div>
                  <div className="action-container">
                    <Button
                      disabled={!userProfileResponse?.isRefreshEnabled}
                      className={Utility.mergeCssClassNames([
                        "refresh-btn",
                        getRefreshStyle(userProfileResponse?.isRefreshEnabled),
                      ])}
                      onClick={() => handleRefresh()}
                    >
                      Refresh profile
                    </Button>
                  </div>
                </div>
              </span>
            </div>
          </div>
        )}

        <DialogContent className="user-system-content">
          <div className="user-system-profile-box">
            <div className="preferred-system">
              <div className="title-text-container">
                <p className="access-label">{"Favourites"}</p>
                <div className="checked-system-container">
                  {Array(5)
                    .fill(null)
                    .map((_, idx) => {
                      if (checkedSystems[idx]) {
                        return (
                          <div className="system-row" key={checkedSystems[idx]}>
                            {renderSystemRow(checkedSystems[idx])}
                          </div>
                        );
                      } else {
                        return (
                          <div key={Utility.createUniqueId(_,idx)} className="space">
                            <div key={Utility.createUniqueId(_,idx)} className="empty-container">
                              {/* <img
                                alt="placeholder"
                                src={`./DrawerMenuAssets/add.svg`}
                              ></img> */}
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </div>

            <p className="information"></p>

            <div
              className="unchecked-system-container"
              style={isMaxChecked ? { position: "relative" } : {}}
            >
              <div className="available-systems-label">
                {isMaxChecked && (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      opacity: 0.5,
                      background: "#F7F9FC",
                      borderRadius: "10px",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: 1,
                    }}
                  ></div>
                )}
                <p className="access-label">{"Available systems"}</p>
                <p className="information-unchecked">
                  Click on the <span className="font-style">plus(+)</span> icon
                  to add it to{" "}
                  <span className="font-style">Favourite Systems</span>
                </p>
              </div>
              {isLoading ? (
                <div className="loading-container">
                  <CircularProgress className="loading" />
                </div>
              ) : (
                <>
                  {uncheckedSystems.map((aok, index) => {
                    if (
                      approvalOperationsEditMap[aok] &&
                      approvalOperationsMap[aok]
                    ) {
                      return (
                        <div key={Utility.createUniqueId(aok,index)} className="unchecked-system-row">
                          <div className="system-row-container">
                            <div
                              className={
                                approvalOperationsEditMap[aok].isEnabled
                                  ? "system-cell"
                                  : "disable-system-cell"
                              }
                            >
                              <img
                                className="system-img"
                                alt="image"
                                src={`./DrawerMenuAssets/${aok}.svg`}
                              ></img>
                              <p className="description">
                                {approvalOperationsMap[aok].description}
                              </p>
                            </div>
                            <Tooltip
                              classes={{ tooltip: classes.customTooltip }}
                              title={
                                isMaxChecked
                                  ? "First you have to remove system from Favourite section to add/replace"
                                  : ""
                              }
                              aria-label="add"
                              placement="top"
                            >
                              <span>
                                <Checkbox
                                  icon={<AddBoxIcon className="add-icon" />}
                                  checkedIcon={
                                    <RemoveIcon className="remove-icon" />
                                  }
                                  className="checkbox"
                                  disabled={
                                    !approvalOperationsEditMap[aok].isEnabled
                                  }
                                  defaultChecked={
                                    approvalOperationsEditMap[aok].isChecked
                                  }
                                  inputProps={{ "aria-label": "controlled" }}
                                  checked={
                                    approvalOperationsEditMap[aok].isChecked
                                  }
                                  onChange={(e: any) =>
                                    handleOnCheckChange(e, aok)
                                  }
                                />
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </>
              )}
              <div className="empty"></div>
            </div>
          </div>

          <p className="information"></p>
        </DialogContent>
        <DialogActions className="action-container">
          {selectedCount <= 5 ? (
            <></>
          ) : (
            <>
              <div className="confirmation-container">
                <div className="confirmation-text">
                  In order to use the ApproveBuddy, it is mandatory
                </div>

                <div className="confirmation-text-2">
                  to add at least{" "}
                  <span className="colored-text">one system</span> as{" "}
                  <span className="colored-text">Favourite System</span>.
                </div>
              </div>
            </>
          )}

          {selectedCount <= 5 ? (
            <>
              <Button
                disabled={getIsCheckedCount() === 0}
                className="action-btn save-btn"
                onClick={handleSave}
              >
                SAVE
              </Button>
            </>
          ) : (
            <>
              <Button
                disabled={getIsCheckedCount() === 0}
                className="action-btn save-btn"
                onClick={handleSave}
              >
                CONFIRM
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

  function handleOnCheckChange(e: any, approvalOperationKey: string) {
    appDispatch(
      ToggelIsCheckedForApprovalOperation({
        approvalOperationKey: approvalOperationKey,
        isChecked: e.target.checked,
      })
    );
  }

  function handleClose(e: any) {
    if (props.onClose) {
      props.onClose(e);
    }
  }

  function handleSave(e: any) {
    if (navigator.onLine) {
      appDispatch(UpdateEditedApprovalOperations());
    }
    if (props.onClose) {
      props.onClose(e);
    }
    if (!navigator.onLine) {
      setTakeActionInternetFailedModalOpen(true);
    }
  }

  function getRefreshStyle(isRefreshEnabled?: boolean): string {
    let result = "";
    if (isRefreshEnabled === false) {
      result = "disabled-refresh-btn";
    }
    return result;
  }

  function renderSystemRow(aok: string) {
    return (
      <div className="system-row-container">
        <div
          className={
            approvalOperationsEditMap[aok].isEnabled
              ? "system-cell"
              : "disable-system-cell"
          }
        >
          <img
            className="system-img"
            alt="image"
            src={`./DrawerMenuAssets/${aok}.svg`}
          ></img>
          <p className="description">
            {approvalOperationsMap[aok].description}
          </p>
        </div>
        <Tooltip
          classes={{ tooltip: classes.customTooltip }}
          title={
            isLeastChecked
              ? "A minimum of one system is required in Favourites"
              : ""
          }
          aria-label="add"
          placement="top"
        >
          <span>
            <Checkbox
              style={{ pointerEvents: "all" }}
              icon={<AddBoxIcon className="add-icon" />}
              checkedIcon={<RemoveIcon className="remove-icon" />}
              className="checkbox"
              disabled={
                isLeastChecked || !approvalOperationsEditMap[aok].isEnabled
              }
              defaultChecked={approvalOperationsEditMap[aok].isChecked}
              inputProps={{ "aria-label": "controlled" }}
              checked={approvalOperationsEditMap[aok].isChecked}
              onChange={(e: any) => handleOnCheckChange(e, aok)}
            />
          </span>
        </Tooltip>
      </div>
    );
  }
}
