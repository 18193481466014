import { IDLPRisk, IDLPRiskItem } from "Model/ServerResponse";
import "./DLPRiskAssessmentDetails.scss";
import React from "react";
import { Chip } from "@material-ui/core";
import Utility from "common/utilities";

export interface IDLPRiskAssessmentDetailsProp {
  dlpRiskDetail: IDLPRisk | undefined;
}

export function DLPRiskAssementDetails(
  props: IDLPRiskAssessmentDetailsProp
)  {
  let dlpRiskDetailContent = props.dlpRiskDetail?.items as IDLPRiskItem[];
  return (
    <div
      data-testid="dlp-risk-assessment-details"
      className="dlp-risk-assessment"
    >
      {getHeaderUI()}
      {getContentUI()}
    </div>
  );

  function getHeaderUI() {
    return (
      <div className="main-container">
        <div className="title-container">
          <div className="dlp-section-title">
            <b>{props.dlpRiskDetail?.title}</b>
            <div className="spacer"></div>
            <div className="col-1">
              <Chip
                className={Utility.mergeCssClassNames([
                  "chip-1",
                  getChipStyle(props.dlpRiskDetail?.risk),
                ])}
                label={Utility.stringToPascalCase(props.dlpRiskDetail?.risk)}
              ></Chip>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getContentUI() {
    return (
      <div className="risk-assessment">
        <div className="key-value-list">
          {dlpRiskDetailContent?.map((e: IDLPRiskItem) => {
            return (
              <div key={e.value} className="key-value">
                <p className="key">{e.label}</p>
                <p className="value load-Value">
                  <strong>{e.value}</strong>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function getChipStyle(chipText: string | undefined): string {
    let result = "";

    switch (chipText?.toLowerCase()) {
      case "high":
        result = "chip-1-high";
        break;
      case "medium":
        result = "chip-1-medium";
        break;
      case "low":
        result = "chip-1-low";
        break;
    }
    return result;
  }
}
