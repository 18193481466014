import React from "react";
import { IPRCommentData, IPRTab } from "../../../Model/ServerResponse";
import { CommentsTemplate } from "../../CommentsTemplate/CommentsTemplate";
import { IComment } from "../../../Model/UI/IComment";
import "./PRComments.scss";
import Utility from "../../../common/utilities";

export interface IPRCommentsProp {
    prTabData: IPRTab | undefined;
}

export function PRComments(props: IPRCommentsProp){
    let commentDataModel: IComment[] = [];

    return (
        <div className="pr-comments-tab">
            {tabUI()}
        </div>
    );

    function tabUI(){
        if (props.prTabData && !Utility.isArrayEmpty(props.prTabData.data)) {
            props.prTabData.data.forEach((cd: any) => {
                let dateItem = cd as IPRCommentData;
                commentDataModel.push({ label1: dateItem.date, label2: dateItem.commentBy, label3: dateItem.comment });
            })

            return (<CommentsTemplate commentData={commentDataModel} ></CommentsTemplate>);
        }
        else {
            return (
                <div className="no-comments">
                    No Comments
                </div>
            );
        }
    }
}