import React from "react";
import { ICart, ICartTab } from "../../../Model/ServerResponse";
import { ITabData } from "../../../Model/UI/ITabData";
import { DetailTabsComponent } from "../../DetailTabsComponent/DetailTabsComponent";
import { SWPSItems } from "../SWPSItems/SWPSItems";
import { SWPSApprovers } from "../SWPSApprovers/SWPSApprovers";
import { SWPSComments } from "../SWPSComments/SWPSComments";
import { SWPSAttachment } from "../SWPSAttachment/SWPSAttachment";
import {
  CART_ITEMS_TAB_ID,
  CART_APPROVERS_TAB_ID,
  CART_ATTACHMENTS_TAB_ID,
  CART_COMMENTS_TAB_ID,
} from "../SWPSUtil";

export interface ISWPSTabsProp {
  swps: ICart;
}

export function SWPSTabs(props: ISWPSTabsProp) {
  function getTabsUI(){
    const data = getTabsData(props.swps.tabs);
    return <DetailTabsComponent tabs={data}></DetailTabsComponent>;
  }

  function getTabsData(tabs: ICartTab[]): ITabData[] {
    if (!Array.isArray(tabs)) {
      console.error("tabs is not an array or is undefined");
      return [];
    }
    let tdata: ITabData[] = [];

    tabs.forEach((tab: ICartTab) => {
      let tabInfo: ITabData = {
        tabId: "",
        title: tab.title,
        imageSrc: "",
        count: tab.count,
      };

      switch (tab.tabId) {
        case CART_ITEMS_TAB_ID:
          tabInfo.tabId = CART_ITEMS_TAB_ID;
          tabInfo.imageSrc = "./DetailTab/details_icn.svg";
          tabInfo.getTabUI = ItemsUI;
          break;
        case CART_APPROVERS_TAB_ID:
          tabInfo.tabId = CART_APPROVERS_TAB_ID;
          tabInfo.imageSrc = "./DetailTab/approvericn.svg";
          tabInfo.getTabUI = ApproversUI;
          break;
        case CART_ATTACHMENTS_TAB_ID:
          tabInfo.tabId = CART_ATTACHMENTS_TAB_ID;
          tabInfo.imageSrc = "./DetailTab/attachment_icn.svg";
          tabInfo.getTabUI = AttachmentsUI;
          break;
        case CART_COMMENTS_TAB_ID:
          tabInfo.tabId = CART_COMMENTS_TAB_ID;
          tabInfo.imageSrc = "./DetailTab/comment_icn.svg";
          tabInfo.getTabUI = CommentsUI;
          break;
        default:
          break;
      }
      tdata.push(tabInfo);
    });
    return tdata;
  }

  function ItemsUI(){
    let cartData = props.swps.tabs.find((td) => td.tabId === CART_ITEMS_TAB_ID);
    return <SWPSItems cartTabData={cartData}></SWPSItems>;
  }

  function ApproversUI(){
    let cartData = props.swps.tabs.find(
      (td) => td.tabId === CART_APPROVERS_TAB_ID
    );
    return <SWPSApprovers cartTabData={cartData} />;
  }

  function AttachmentsUI(){
    return <SWPSAttachment swps={props.swps} />;
  }

  function CommentsUI(){
    let cartData = props.swps.tabs.find(
      (td) => td.tabId === CART_COMMENTS_TAB_ID
    );
    return <SWPSComments cartTabData={cartData} />;
  }

  return getTabsUI();
}
