import { IUpdateProfileRequest, IServerResponse } from "Model/ServerResponse";
import { IUpdateProfileResponse } from "Model/ServerResponse/IUpdateProfileResponse";
import { ServicesUtility } from "Services";

export class SystemSelectionService {
    private static instance: SystemSelectionService;

    private constructor() { }

    public static getInstance(): SystemSelectionService {
        if (!this.instance) {
            this.instance = new SystemSelectionService();
        }

        return this.instance;
    }

    //post system selection
    public async updateUserProfile(reqBody: IUpdateProfileRequest[]): Promise<IServerResponse<IUpdateProfileResponse>> {
        // let token = await ServicesUtility.getGraphToken();
        let token = await ServicesUtility.getGraphToken();
        if (!token) {
            throw new Error('Failed to get authentication token');
          }
        let method = `/api/UserProfile/UpdateSystems`;
        let url = ServicesUtility.prefixBaseUrl(method);
        let requestOptions = {
            method: 'POST',
            headers: {
                ...ServicesUtility.getDefaultHeaders(token)
            },
            body: JSON.stringify(reqBody)
        };

        let result: IServerResponse<IUpdateProfileResponse> = { statusCode: 0, payload: null, errorMessage: "" };

        return ServicesUtility.timedRequest(ServicesUtility.Min5, url, requestOptions).then(async response => {
            try {
                result.statusCode = response.status;
                let x = await response.json();
                result.payload = x as IUpdateProfileResponse;
                return result;
            } catch (error) {
                result.statusCode = response.status;
                result.errorMessage = response.statusText;
                return result;
            }
        },
            (error: Error) => {
                result.errorMessage = error.message;
                return result;
            })
    }

    public async resetCache(): Promise<IServerResponse<void>> {
        let token = await ServicesUtility.getGraphToken();
        if (!token) {
            throw new Error('Failed to get authentication token');
          }
        let url = ServicesUtility.prefixBaseUrl(`/api/cache/reset`);
        let requestOptions = {
            method: "POST",
            headers: {
                ...ServicesUtility.getDefaultHeaders(token),
            },
        };

        let result: IServerResponse<void> = {
            statusCode: 0,
            payload: null,
            errorMessage: "",
        };
        return ServicesUtility.timedRequest(
            ServicesUtility.Min5,
            url,
            requestOptions
        ).then(
            async (response) => {
                try {
                    result.statusCode = response.status;
                    return result;
                } catch (error) {
                    result.statusCode = response.status;
                    result.errorMessage = response.statusText;
                    return result;
                }
            },
            (error: Error) => {
                result.errorMessage = error.message;
                return result;
            }
        );
    }

    public async resetSpecificSystemCache(system: string, subsystem: string): Promise<IServerResponse<void>> {
        let token = await ServicesUtility.getGraphToken();
        if (!token) {
            throw new Error('Failed to get authentication token');
          }
        let url = ServicesUtility.prefixBaseUrl(`/api/cache/reset`);
        if (system) {
            url = url + '/' + system
        }
        if (subsystem) {
            url = url + '/' + subsystem
        }
        let requestOptions = {
            method: "POST",
            headers: {
                ...ServicesUtility.getDefaultHeaders(token),
            },
        };

        let result: IServerResponse<void> = {
            statusCode: 0,
            payload: null,
            errorMessage: "",
        };
        return ServicesUtility.timedRequest(
            ServicesUtility.Min5,
            url,
            requestOptions
        ).then(
            async (response) => {
                try {
                    result.statusCode = response.status;
                    return result;
                } catch (error) {
                    result.statusCode = response.status;
                    result.errorMessage = response.statusText;
                    return result;
                }
            },
            (error: Error) => {
                result.errorMessage = error.message;
                return result;
            }
        );
    }
}