import React from "react";
import { IEjourney } from "../../../Model/ServerResponse";
import { Header2 } from "../../HeaderTemplates/Header2/Header2";
import { ISystemHeaderBaseProp } from "../../../Model/UI/ISystemHeaderBaseProp";
import Utility from "../../../common/utilities";
import "./EJourneyHeader.scss";

export interface IEJourneyHeaderData extends ISystemHeaderBaseProp {
  eJ: IEjourney;
}

export function EJourneyHeader(props: IEJourneyHeaderData) {
  function getHeaderUI()  {
    return (
      <Header2
        label1={props.eJ.approvalSystemId}
        label2={props.eJ.title}
        chip1={props.eJ.risk}
        keyValue1={{
          _key: "Risk Score",
          value: props.eJ.riskScore.toLocaleString(),
        }}
        userDetails={{ userId: props.eJ.requestorID, userCardName: props.eJ.requestor, userOrganization: props.eJ.location }}
        className={Utility.mergeCssClassNames([
          "ejourney-header",
          getChipStyle(props.eJ.risk),
        ])}
      ></Header2>
    );
  }

  function getChipStyle(chipText: string): string {
    let result = "";

    switch (chipText.toLowerCase()) {
      case "high":
        result = "chip-1-high";
        break;
      case "medium":
        result = "chip-1-medium";
        break;
      case "low":
        result = "chip-1-low";
        break;
      case "extreme":
        result = "chip-1-extreme";
        break;
    }
    return result;
  }
  return getHeaderUI();
}



