import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
    DeleteApprovals,
    PostDeleteApprovals,
    reCalculateSelectedApproval,
    SelectedApprovalSelector,
    setIsTabletView,
    setSelectedApproval,
} from "../../../Store/JRA/JRASlice";
import { useAppDispatch } from "../../../Store/store";
import { ListDetailsView } from "../../ListDetailsView/ListDetailsView";
import { TakeActionFailedModal } from "../../Modals/TakeActionFailedModal/TakeActionFailedModal";
import { IFailedTakeAction, IJRA, ISystem } from "../../../Model/ServerResponse";
import { JRAListView } from "../JRAListView/JRAListView";
import { JRAActions } from "../JRAActions/JRAActions";
import { JRADetailsCard } from "../JRADetailsCard/JRADetailsCard";
import { UserProfileResponseSelector } from "Store/UserSystemProfile/UserSystemSlice";

export interface IJRAPageProp {
}

export function JRAPage(props: IJRAPageProp)  {

    let appDispatch = useAppDispatch();
    let userProfileResponse = useSelector(UserProfileResponseSelector);

    let selectedApproval = useSelector(SelectedApprovalSelector);

    let [takeActionFailedModalOpen, setTakeActionFailedModalOpen] =
        useState<boolean>(false);
    let [failedTakeActions, setFailedTakeActions] =
        useState<IFailedTakeAction[]>([]);



    return (
        <div>
            {takeActionFailedModalOpen && (
                <TakeActionFailedModal
                    open={takeActionFailedModalOpen}
                    failedActions={failedTakeActions}
                    onOk={(e: any) => setTakeActionFailedModalOpen(false)}
                />
            )}
            <ListDetailsView
                selectedApproval={selectedApproval}
                onBackClick={handleBackClick}
                onWindowSizeChange={handleWindowSizeChange}
                listView={listUI}
                detailsView={detailsUI}
            />
        </div>
    );

    function listUI()  {
        return (
                <JRAListView getActionsUI={actionsUI} />
        );
    }

    function detailsUI()  {
        return (
                <JRADetailsCard getActionsUI={actionsUI} />
        );
    }

    function actionsUI(
        _isMulti: boolean,
        _approvals: IJRA[],
    )  {
        return (
                <JRAActions
                    isMulti={_isMulti}
                    isApproveCommentMandatory={true}
                    count={_approvals.length}
                    onApprove={handleOnApprove}
                    onReject={handleOnReject}
                />
        );
    }

    function handleBackClick(e: any) {
        appDispatch(setSelectedApproval(null));
    }

    function handleWindowSizeChange(e: any, isTabletView: boolean) {
        appDispatch(setIsTabletView(isTabletView));
        appDispatch(reCalculateSelectedApproval());
    }

    function handleOnApprove(e: any, isMulti: boolean, comment: string) {
        takeAction(true, isMulti, comment);
    }

    function handleOnReject(e: any, isMulti: boolean, comment: string) {
        takeAction(false, isMulti, comment);
    }

    async function takeAction(isApprove: boolean, isMulti: boolean, comment: string): Promise<void> {
        let result = ((await appDispatch(PostDeleteApprovals({ isApprove: isApprove, isMulti: isMulti, comment: comment, getRequestBody: getTakeActionRequestBody }))).payload) as { passedApprovals: IJRA[], failedTakeActions: IFailedTakeAction[] };
        if (result.failedTakeActions.length > 0) {
            //Some approvals failed
            setTakeActionFailedModalOpen(true);
            setFailedTakeActions(result.failedTakeActions);
        }

        //Remove cards & subtract count for passed
        appDispatch(DeleteApprovals(result.passedApprovals));
    }

    function getTakeActionRequestBody(_approval: ISystem, _comment: string): any {
        let _jra = _approval as IJRA;
        return {
            approvalId: _jra.id.toString(),
            comment: _comment,
            extra: {
                hashKey: _jra.hashKey,
                Gin: userProfileResponse?.graphDetails.extension_8b4e685f580748b59864d62c2e7fcfad_employeeNumber,
                metroStopName: _jra.metroStopName,
                firstName: userProfileResponse?.graphDetails.givenName,
                lastName: userProfileResponse?.graphDetails.surname
            }
        }
    }
}

