import React from "react";
import { IDefaultUIProp } from "../../../Model/UI";
import "./Message1.scss";

export interface IMessage1Prop extends IDefaultUIProp {
    title: string,
    subTitle?: string | null,
    imgSrc?: string | null,
}

export interface IMessage1ContentProp {
    content?: React.ReactElement | null
    title: string,
    subTitle?: string | null,
    imgSrc?: string | null,
}

export function Message1(props: IMessage1Prop | IMessage1ContentProp){
    return (
        <div className="message-1">
            {props.imgSrc && <img className="message-img" src={props?.imgSrc} alt="" />}
            <span className="title">{props.title}</span>
            {props.subTitle && <span className="sub-title">{props.subTitle}</span>}
            {props.content}
        </div>
    );
}