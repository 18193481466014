import { Button } from "@material-ui/core";
import React from "react";
import { IDefaultUIProp } from "../../../Model/UI";

import "./ApproveRejectActionBar.scss";

export interface IApproveRejectActionBarProp extends IDefaultUIProp {
  onApprove: (e: any) => void;
  onReject: (e: any) => void;
  rejectLabel?: string;
  onForward?: ((e: any) => void) ;
  rejecteDisabled?: boolean;
  approveDisabled?: boolean;
}

export function ApproveRejectActionBar(props: IApproveRejectActionBarProp) {
  return (
    <div className="approve-reject-modal">
      {props.onForward && (
        <Button className="action-btn forward-btn" onClick={handleOnForward}>
          Forward
        </Button>
      )}
      <Button
        disabled={props.rejecteDisabled ? props.rejecteDisabled : false}
        data-testid="reject-action"
        className="action-btn reject-btn"
        onClick={handleOnReject}
      >
        {props.rejectLabel ? props.rejectLabel : "Reject"}
      </Button>
      <Button
        disabled={props.approveDisabled ? props.approveDisabled : false}
        data-testid="approve-action"
        className="action-btn approve-btn"
        onClick={handleOnApprove}
      >
        Approve
      </Button>
    </div>
  );

  function handleOnApprove(e: any) {
    props.onApprove(e);
  }

  function handleOnReject(e: any) {
    props.onReject(e);
  }

  function handleOnForward(e: any) {
    if (props.onForward) {
      props.onForward(e);
    }
  }
}
