import React from "react";
import { IJRA } from "../../../Model/ServerResponse";
import { IKeyValue } from "../../../Model/UI";
import { KeyValueList } from "../../../Components2/KeyValueList/KeyValueList";
import './JRADetailKeyValueList.scss'
import { JRAHeaderTable } from "../JRAHeaderTable/JRAHeaderTable";


export interface IJRADetailKeyValueListProp {
    jra: IJRA;
}

export function JRADetailKeyValueList(props: IJRADetailKeyValueListProp) {

    function getHeaderTableUI()  {
        let _data = getKeyValueListData();
        return (
            <div>
                <KeyValueList data={_data} />
                <JRAHeaderTable jra={props.jra} />
            </div>

        );
    }

    function getKeyValueListData(): IKeyValue[] {
        let dataArray: IKeyValue[] = [];
        dataArray.push({ _key: "Start - End", value: props.jra.fromDate + (props.jra.toDate ? '  -  ' + props.jra.toDate : ''), seperator: ':' });
        if (props.jra.customerName) dataArray.push({ _key: "Customer", value: props.jra.customerName, seperator: ':' });
        if (props.jra.rigName) dataArray.push({ _key: "Rig", value: props.jra.rigName, seperator: ':' });
        if (props.jra.wellName) dataArray.push({ _key: 'Well', value: props.jra.wellName, seperator: ':' });
        if (props.jra.psdManagerName) dataArray.push({ _key: "PSD Manager", value: props.jra.psdManagerName, seperator: ':' });
        if (props.jra.subProductLine) dataArray.push({ _key: "Sub Business Line", value: props.jra.subProductLine, seperator: ':' });
        return dataArray;
    }
    return getHeaderTableUI();

}

