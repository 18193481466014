import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { IPRSplitItem } from "../../../../Model/ServerResponse";
import "./PRSplitItemsRow.scss";


export interface IPRSplitItemsRowProp {
    index: number;
    prSplitItemData: IPRSplitItem;
    noOfColumns: number;
}

export function PRSplitItemsRow(props: IPRSplitItemsRowProp){
    return (
        <>
            <TableRow><div className="pr-split-items-spacing"></div></TableRow>
            {getRow1UI()}
        </>
    );

    function getRow1UI(){
        return (
            // row 1
            <TableRow className="pr-split-items-row-1">
                {/* row 1 - cell 1 */}
                <TableCell>
                    <p className="split-items-row-text-1">{props.prSplitItemData.splitLineItemNumber}</p>
                </TableCell>
                {/* row 1 - cell 2 */}
                <TableCell>
                    <div>
                        <p className="split-items-row-text-1">{props.prSplitItemData.generalLedger}</p>
                        <p className="split-items-row-text-1">{props.prSplitItemData.generalLedgerDescription}</p>
                    </div>
                </TableCell>
                {/* row 1 - cell 3 */}
                <TableCell>
                    <p className="split-items-row-text-1">{props.prSplitItemData.costCenter}</p>
                    <p className="split-items-row-text-1">{props.prSplitItemData.costCenterDescription}</p>
                </TableCell>
                {/* row 1 - cell 4 */}
                <TableCell>
                    <p className="items-row-key-value-1"><span className="splitAmount">{props.prSplitItemData.splitAmount.toString()}</span> <span className="splitCurrency">{props.prSplitItemData.splitCurrency}</span></p>
                </TableCell>
            </TableRow>
        );
    }
}

