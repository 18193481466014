import React from "react";
import { IKeyValue } from "../../../Model/UI";
import "./SRMDetailKeyValueList.scss";
import { KeyValueList } from "../../../Components2/KeyValueList/KeyValueList";
import { ISRM } from "Model/ServerResponse/ISRM";

export interface ISRMDetailKeyValueListProp {
  srm: ISRM;
}

export function SRMDetailKeyValueList(props: ISRMDetailKeyValueListProp) {
  function getHeaderTableUI() {
    let _data = getKeyValueListData();
    return (
      <>
        <div className="srm-details-key-value">
          <KeyValueList data={_data} />
        </div>
      </>
    );
  }

  function getKeyValueListData(): IKeyValue[] {
    let dataArray: IKeyValue[] = [];
    if (props.srm?.cartNumber)
      dataArray.push({
        _key: "SC Number",
        value: props.srm.cartNumber,
        seperator: ":",
      });
    if (props.srm?.description)
      dataArray.push({
        _key: "SC Description",
        value: props.srm.description,
        seperator: ":",
      });
    if (props.srm?.boughtOnBehalf)
      dataArray.push({
        _key: "Bought on behalf of",
        value: props.srm.boughtOnBehalf,
        seperator: ":",
      });
    return dataArray;
  }

  return getHeaderTableUI();
}
