import React from "react";
import { IPR } from "../../../Model/ServerResponse/IPR";
import { Header1 } from "../../HeaderTemplates/Header1/Header1";
import { ISystemHeaderBaseProp } from "../../../Model/UI";
import "./PRHeader.scss";

export interface IPRHeaderData extends ISystemHeaderBaseProp {
  pr: IPR;
}

export function PRHeader(props: IPRHeaderData) {
  function getHeaderUI(){
    return (
      <Header1
        label1={props.pr.approvalSystemId}
        label2={props.pr.date}
        label3={props.pr.title}
        keyValue1={{
          _key: props.pr.amount.toString(),
          value: props.pr.currency,
        }}
        userDetails={{
          userId: props.pr.requestorID,
          userCardName: props.pr.requestor,
          userOrganization: props.pr.plant,
        }}
        className="pr-header"
      ></Header1>
    );
  }

  return getHeaderUI();
}
