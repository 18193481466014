import { ISystemCardBaseProp } from "Model/UI";
import React from "react";
import "./WatershedCard.scss";
import Utility from "common/utilities";
import { IWatershed } from "Model/ServerResponse/IWatershed";
import { Card4 } from "Components2/CardTemplates/Card4/Card4";

export interface IWatershedCardProp extends ISystemCardBaseProp {
  waterShed: IWatershed;
}

export function WatershedCard(props: IWatershedCardProp) {
  function getCardUI() {
    return (
      <Card4
        label1={"Task Sheet"}
        label2={props.waterShed.title}
        label3={""}
        label4={props.waterShed.date}
        keyValue1={{
          _key: "",
          value: "",
          seperator: "",
        }}
        userDetails={{
          userId: props.waterShed.requestorID,
          userCardName: props.waterShed.requestor,
          userOrganization: props.waterShed.documentId,
        }}
        className={Utility.mergeCssClassNames(["watershed-card"])}
        supportMulti={props.supportMulti}
        multiSelectUI={props.multiSelectUI}
      ></Card4>
    );
  }

  return getCardUI();
}
