import { IDLPTeamDetail, IDLPTeamDetailItem } from "Model/ServerResponse";
import "./DLPTeamDetails.scss";
import React from "react";
import { Chip, TableCell, TableRow } from "@material-ui/core";
import { TableComponent } from "Components2/TableComponent/TableComponent";
import Utility from "common/utilities";
import { ITableDataMapping } from "Model/UI";

export interface IDLPTeamDetailsProp {
  dlpTeamDetails: IDLPTeamDetail | undefined;
}

export function DLPTeamDetails(props: IDLPTeamDetailsProp)  {
  return (
      <div data-testid="dlp-team-details" className="dlpdetail-container">
        {getDetailsHeaderUI()}
        {getDetailsUI()}
      </div>
  );

  function getDetailsHeaderUI()  {
    return (
        <div>
          <div className="title-container">
            <div className="dlp-section-title">
              <b>{props.dlpTeamDetails?.title}</b>
              <div className="spacer"></div>
              <div className="col-1">
                <Chip
                  className={Utility.mergeCssClassNames([
                    "chip-1",
                    getChipStyle(props.dlpTeamDetails?.risk),
                  ])}
                  label={Utility.stringToPascalCase(props.dlpTeamDetails?.risk)}
                ></Chip>
              </div>
            </div>
          </div>
        </div>
    );
  }

  function getDetailsUI()  {
    let tableHeaderMapping: ITableDataMapping[] = [
      {
        id: "1",
        label: "Team Member",
      },
      {
        id: "2",
        label: "Role",
      },
      {
        id: "3",
        label: "Type",
      },
      {
        id: "4",
        label: "Certification",
      },
    ];

    return (
        <div className="team-details-table">{teamDetailsTable()}</div>
    );

    function teamDetailsTable()  {
      if (
        props.dlpTeamDetails &&
        !Utility.isArrayEmpty(props.dlpTeamDetails.items)
      ) {
        return (
          <TableComponent
            tableData={props.dlpTeamDetails.items}
            mappingTableData={tableHeaderMapping}
            getTableRow={getTeamDetailRow}
          ></TableComponent>
        );
      } else {
        return <div className="no-pr-split-items">No Split Items</div>;
      }
    }

    function getTeamDetailRow(
      index: number,
      item: IDLPTeamDetailItem
    )  {
      return (
        <TableRow key={index}>
          {/* row 1 - cell 1 */}
          <TableCell>
            <p>
              <strong>{item?.name}</strong>
            </p>
          </TableCell>
          {/* row 1 - cell 2 */}
          <TableCell>
            <div>
              <p>
                <strong>{item?.role}</strong>
              </p>
            </div>
          </TableCell>
          {/* row 1 - cell 3 */}
          <TableCell>
            <p>{item?.type}</p>
          </TableCell>
          {/* row 1 - cell 4 */}
          <TableCell>
            <p>
              <img alt="item-status" src={`./DLP/${item?.status}.svg`}></img>
            </p>
          </TableCell>
        </TableRow>
      );
    }
  }

  function getChipStyle(chipText: string | undefined): string {
    let result = "";

    switch (chipText?.toLowerCase()) {
      case "high":
        result = "chip-1-high";
        break;
      case "medium":
        result = "chip-1-medium";
        break;
      case "low":
        result = "chip-1-low";
        break;
    }
    return result;
  }
}
