import {
  createSelector, createSlice,
} from "@reduxjs/toolkit";
import { RequestStatus } from "Model/Enums";
import { IAppState } from "Store/store";
import { getPendingApprovals1Thunk, getApprovalDetails1Thunk, postDeleteApprovals1Thunk, deleteApprovals1Thunk, systemSlice1Reducer, ISystemSlice1State } from "Store/SystemTemplate/SystemSlice1";

export const prSliceInitialState: ISystemSlice1State = {
  pendingApprovalsStatus: RequestStatus.Idle,
  pendingApprovalsError: null,
  selectedApprovalDetailStatus: RequestStatus.Idle,
  selectedApprovalDetailError: null,
  supportMulti: false,
  selectedApproval: null,
  selectedApprovalDetails: null,
  multiSelectedApprovals: [],
  searchQuery: "",
  isAsc: false,
  selectedSubSystem: null,
  fromPosition: 0,
  subSystems: [],
  totalCountForFilter: 0,
  pendingApprovals: [],
  isTabletView: false,
};

const PRSlice = createSlice({
  name: "PR",
  initialState: prSliceInitialState,
  reducers: systemSlice1Reducer
});

export const GetPendingApprovals = getPendingApprovals1Thunk(prSliceInitialState, PRSlice, "PR", "PR");

export const GetApprovalDetails = getApprovalDetails1Thunk(PRSlice, "PR", "PR");

export const PostDeleteApprovals = postDeleteApprovals1Thunk(PRSlice, "PR");

export const DeleteApprovals = deleteApprovals1Thunk(PRSlice, "PR", "PR");

//Action
export const {
  resetSystemSliceState,
  setPendingApprovalsStatus,
  setSelectedApproval,
  setSelectedApprovalDetails,
  setMultiSelectedApprovals,
  setSearchQuery,
  setIsAsc,
  setSelectedSubSystem,
  setFromPosition,
  setSubSystems,
  setTotalCountForFilter,
  setPendingApprovals,
  addToPendingApprovals,
  reCalculateSelectedApproval,
  setIsTabletView,
} = PRSlice.actions;

//Selector
export const PRSelector = (state: IAppState) => state.PR;
export const PendingApprovalsStatusSelector = createSelector(
  PRSelector,
  (details) => details.pendingApprovalsStatus
);
export const PendingApprovalsErrorSelector = createSelector(
  PRSelector,
  (details) => details.pendingApprovalsError
);
export const SelectedApprovalDetailStatusSelector = createSelector(
  PRSelector,
  (details) => details.selectedApprovalDetailStatus
);
export const SelectedApprovalDetailErrorSelector = createSelector(
  PRSelector,
  (details) => details.selectedApprovalDetailError
);
export const SupportMultiSelector = createSelector(
  PRSelector,
  (details) => details.supportMulti
);
export const SelectedApprovalSelector = createSelector(
  PRSelector,
  (details) => details.selectedApproval
);
export const SelectedApprovalDetailsSelector = createSelector(
  PRSelector,
  (details) => details.selectedApprovalDetails
);
export const MultiSelectedApprovalsSelector = createSelector(
  PRSelector,
  (details) => details.multiSelectedApprovals
);
export const SearchQuerySelector = createSelector(
  PRSelector,
  (details) => details.searchQuery
);
export const IsAscSelector = createSelector(
  PRSelector,
  (details) => details.isAsc
);
export const SelectedSubSystemSelector = createSelector(
  PRSelector,
  (details) => details.selectedSubSystem
);
export const FromPositionSelector = createSelector(
  PRSelector,
  (details) => details.fromPosition
);
export const SubSystemsSelector = createSelector(
  PRSelector,
  (details) => details.subSystems
);
export const TotalCountForFilterSelector = createSelector(
  PRSelector,
  (details) => details.totalCountForFilter
);
export const PendingApprovalsSelector = createSelector(
  PRSelector,
  (details) => details.pendingApprovals
);
export const IsTabletViewSelector = createSelector(
  PRSelector,
  (details) => details.isTabletView
);


export const PRReducer = PRSlice.reducer;

