import React from "react";
import { ITableDataMapping } from "../../../Model/UI";
import { IPRTab } from "../../../Model/ServerResponse";
import { TableComponent } from "../../TableComponent/TableComponent";
import { TableCell, TableRow } from "@material-ui/core";
import Utility from "../../../common/utilities";
import "./PRApprovers.scss";

export interface IPRApproversProp {
  prApproversTabData: IPRTab | undefined;
}

export function PRApprovers(props: IPRApproversProp){
  let tableHeaderMapping: ITableDataMapping[] = [
    {
      id: "level",
      label: "Level/Code",
    },
    {
      id: "approverName",
      label: "Approver",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "comments",
      label: "Approver Comment",
    },

    {
      id: "actionDate",
      label: "Action Date",
    },
  ];

  return (
    <div data-testid="pr-approvers-tab" className="pr-approvers-tab">
      {tabUI()}
    </div>
  );

  function tabUI(){
    if (
      props.prApproversTabData &&
      !Utility.isArrayEmpty(props.prApproversTabData.data)
    ) {
      return (
        <TableComponent
          tableData={props.prApproversTabData.data}
          mappingTableData={tableHeaderMapping}
          getTableRow={getTableApproversRow}
        ></TableComponent>
      );
    } else {
      return <div className="no-approvers">No Approvers</div>;
    }
  }
  function getstatuscolor(cellvalue: string): string {
    let statusClass = "";

    if (cellvalue.toLowerCase()==="approved") 
     
        statusClass = "approved";
      else
    
        statusClass = "not-approved";
        
    
    return statusClass;
  }

  function getTableApproversRow(index: number, row: any){
    let row1: React.ReactNode[] = [];
    let rows: React.ReactNode[]= [];

    // add cells
    for (let _index = 0; _index < tableHeaderMapping.length; _index++) {
      const element = tableHeaderMapping[_index];
      if (element.id == "status") {
        row1.push(
          <TableCell
            data-testid="approver-status-cell"
            className={Utility.mergeCssClassNames([
              "cell" + "-" + _index.toString(),
              getstatuscolor(row[element.id]),
            ])}
            key={_index}
          >
            {row[element.id]}
          </TableCell>
        );
      } else {
        row1.push(
          <TableCell className={"cell" + "-" + _index.toString()} key={_index}>
            {row[element.id]}
          </TableCell>
        );
      }
    }
    // pushing cells to Row
    let elementRow = <TableRow className="main-row">{row1}</TableRow>;
    let spacing = <TableRow className="spacing"></TableRow>;

    rows.push(spacing);
    rows.push(elementRow);

  
    let result=<>{rows.map((r, index) => <React.Fragment key={Utility.createUniqueId(tableHeaderMapping[index].id,index)}>{r}</React.Fragment>)}</>

    return result;
  }
}
