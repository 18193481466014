import { IKeyValue, ISystemCardBaseProp } from "Model/UI";
import React from "react";
import { IPO } from "Model/ServerResponse";
// It is important that we import local scss after Card1/2/3 so the styles are overidden in correct way
import "./OptimusCard.scss";
import Utility from "common/utilities";
import { Constants } from "common/constants";
import { Card7 } from "Components2/CardTemplates/Card7/Card7";

export interface IPOOptimusCardData extends ISystemCardBaseProp {
  po: IPO;
}

export function OptimusCard(props: IPOOptimusCardData) {
  let keyValues: IKeyValue[] = [
    { _key: "PO", value: (props.po?.poNumber), seperator: ':' },
    { _key: "Items", value: props.po.items?.toString(), seperator: ':' }
  ];

  function getCardUI(){
    let poTitle: string = '';
    if (props.po.approvalSystemId === "PO-ITT" || "PO-Optimus") {
      let temppoTitle = props.po.title;
      let tmparr = temppoTitle?.split("|");
      const vendorname = tmparr && tmparr?.length > 1 ? tmparr[1] : '';
      poTitle = vendorname?.toString()?.trim();
    }

    return (
      <Card7
        label1={props.po.approvalSystemId}
        label2={poTitle}
        label3={Utility.formatDate(props.po.date, Constants.DD_MM_YY)}
        chip1={props.po.qualifierTag ? props.po.qualifierTag : ""}
        keyValue2={keyValues}
        keyValue1={{
          _key: props.po.amount?.toLocaleString(),
          value: props.po.currency,
        }}
        userDetails={{
          userId: props.po.requestorID,
          userCardName: props.po.requestor,
          userOrganization: props.po.organization,
        }}
        supportMulti={props.supportMulti}
        multiSelectUI={props.multiSelectUI}
        className={Utility.mergeCssClassNames([
          "optimus-card",
          getChipStyle(props.po.qualifierTag), 'po-itt'
        ])}
      ></Card7>
    );
  }

  function getChipStyle(chipText: string | null): string {
    let result = "";
    if (!chipText) {
      result = "chip1-hidden";
    }
    return result;
  }

  return getCardUI();
}
