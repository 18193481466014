import { TableCell, TableRow } from "@material-ui/core";
import { KeyValue } from "Components2/KeyValue/KeyValue";
import React from "react";
import { IPRItemData } from "../../../../Model/ServerResponse";
import { IKeyValue } from "../../../../Model/UI";
import { KeyValueList } from "Components2/KeyValueList/KeyValueList";
import { PRItemsOtherDetails } from "../PRItemsOtherDetails/PRItemsOtherDetails";
import { PRItemsShippingDetails } from "../PRItemsShippingDetails/PRItemsShippingDetails";
import { PRItemsSplitItemDetails } from "../PRItemsSplitItemDetails/PRItemsSplitItemDetails";
import "./PRItemsRow.scss";

export interface IPRItemsRowProp {
  index: number;
  prItemData: IPRItemData;
  noOfColumns: number;
}

export function PRItemsRow(props: IPRItemsRowProp){
  return (
    <>
      <TableRow>
        <div className="pr-items-spacing"></div>
      </TableRow>
      {getRow1UI()}
      {getRow2UI()}
      {getRow3UI()}
      {getRow4UI()}
      {getRow5UI()}
      {getRow6UI()}
      {getRow7UI()}
    </>
  );

  function getRow1UI(){
    return (
      // row 1
      <TableRow className="pr-items-row-1 pr-items-row">
        {/* row 1 - cell 1 */}
        <TableCell>
          <p className="pr-items-row-text-1">{props.prItemData.lineItemNumber}</p>
        </TableCell>
        {/* row 1 - cell 2 */}
        <TableCell>
          <div>
            <p className="pr-items-row-text-1">{props.prItemData.shortName}</p>
            <p className="pr-items-row-text-1">{props.prItemData.description}</p>
          </div>
        </TableCell>
        {/* row 1 - cell 3 */}
        <TableCell>
          <p className="pr-items-row-text-1">{props.prItemData.itemCategory}</p>
        </TableCell>
        {/* row 1 - cell 4 */}
        <TableCell>
          <p className="pr-items-row-text-1">{props.prItemData.pricePerUnit}</p>
        </TableCell>
        {/* row 1 - cell 5 */}
        <TableCell>
          <p className="pr-items-row-text-1">
            {props.prItemData.unitOfMeasurement}
          </p>
        </TableCell>
        {/* row 1 - cell 6 */}
        <TableCell>
          <p className="pr-items-row-text-1">{props.prItemData.quantity}</p>
        </TableCell>
        {/* row 1 - cell 7 */}
        <TableCell>
          <p className="pr-items-row-text-1">{props.prItemData.totalAmount} <span className="currency"> {props.prItemData.currency}</span></p>
        </TableCell>
      </TableRow>
    );
  }

  function getRow2UI(){
    return (
      // row 2
      <TableRow className="pr-items-row-2">
        {/* row 2 - cell 1 - empty */}
        <TableCell></TableCell>
        {/* row 2 - cell 2 */}
        <TableCell>
          <p className="pr-items-row-text-1">Discount</p>
        </TableCell>
        {/* row 2 - cell 3 */}
        <TableCell></TableCell>
        {/* row 2 - cell 4 - empty */}
        <TableCell></TableCell>
        {/* row 2 - cell 5 - empty */}
        <TableCell></TableCell>
        {/* row 2 - cell 6 - empty */}
        <TableCell></TableCell>
        {/* row 2 - cell 7 - empty */}
        <TableCell>
          <KeyValue
            className="pr-items-row-key-value-1"
            _key={props.prItemData.discount.amount.toString()}
            value={props.prItemData.discount.currency}
          />
        </TableCell>
      </TableRow>
    );
  }

  function getRow3UI(){
    return (
      // row 2
      <TableRow className="pr-items-row-3">
        {/* row 2 - cell 1 - empty */}
        <TableCell></TableCell>
        {/* row 2 - cell 2 */}
        <TableCell>
          <p className="pr-items-row-text-1">Charges</p>
        </TableCell>
        {/* row 2 - cell 3 */}
        <TableCell></TableCell>
        {/* row 2 - cell 4 - empty */}
        <TableCell></TableCell>
        {/* row 2 - cell 5 - empty */}
        <TableCell></TableCell>
        {/* row 2 - cell 6 - empty */}
        <TableCell></TableCell>
        {/* row 2 - cell 7 - empty */}
        <TableCell>
          <KeyValue
            className="pr-items-row-key-value-1"
            _key={props.prItemData.charges.amount.toString()}
            value={props.prItemData.charges.currency}
          />
        </TableCell>
      </TableRow>
    );
  }

  function getRow4UI(){
    let _data: IKeyValue[] = [
      { _key: "Supplier", value: props.prItemData.supplier, seperator: ':' },
      { _key: "Account Type", value: props.prItemData.accountType, seperator: ':' },
      { _key: "Bill To", value: props.prItemData.billingAddress, seperator: ':' },
    ];

    return (
      <TableRow className="pr-items-row-4">
        <TableCell colSpan={props.noOfColumns}>
          <KeyValueList data={_data} />
        </TableCell>
      </TableRow>
    );
  }

  function getRow5UI(){
    return (
      <TableRow className="pr-items-row-7">
        <TableCell className="split-item-details" colSpan={props.noOfColumns}>
          <PRItemsSplitItemDetails prItemData={props.prItemData} />
        </TableCell>
      </TableRow>
    );
  }

  function getRow6UI(){
    return (
      <TableRow className="pr-items-row-5">
        <TableCell colSpan={props.noOfColumns} className="other-details">
          <PRItemsOtherDetails prItemData={props.prItemData} />
        </TableCell>
      </TableRow>
    );
  }

  function getRow7UI(){
    return (
      <TableRow className="pr-items-row-6">
        <TableCell colSpan={props.noOfColumns}>
          <PRItemsShippingDetails prItemData={props.prItemData} />
        </TableCell>
      </TableRow>
    );
  }
}
