import {
    IServerResponse,
} from "Model/ServerResponse";
import { ServicesUtility } from "./servicesUtility";

export class POService {
    private static instance: POService;

    private constructor() { }

    public static getInstance(): POService {
        if (!this.instance) {
            this.instance = new POService();
        }

        return this.instance;
    }
    public async getPODetailsData(
        workItemId: string,
        poNumber: string,
        itemNumber: string
    ): Promise<IServerResponse<any>> {
        let token = await ServicesUtility.getGraphToken();
        if (!token) {
            throw new Error('Failed to get authentication token');
          }
        let url = ServicesUtility.prefixBaseUrl(
            `api/approvals/itt/po/${workItemId}/item/${poNumber}/${itemNumber}`
        );
        let requestOptions = {
            method: "GET",
            headers: {
                ...ServicesUtility.getDefaultHeaders(token),
            },
        };
        let result: IServerResponse<any> = {
            statusCode: 0,
            payload: null,
            errorMessage: "",
        };
        return ServicesUtility.timedRequest(
            ServicesUtility.Min5,
            url,
            requestOptions
        ).then(
            async (response) => {
                try {
                    result.statusCode = response.status;
                    let x = await response.json();
                    result.payload = x;
                    return result;
                } catch (error) {
                    result.statusCode = response.status;
                    result.errorMessage = response.statusText;
                    return result;
                }
            },
            (error: Error) => {
                result.errorMessage = error.message;
                return result;
            }
        );
    }

}

