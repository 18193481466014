import Utility from "common/utilities";
import { TableComponent } from "Components2/TableComponent/TableComponent";
import { ITableDataMapping } from "Model/UI/ITableDataMapping";
import React from "react";
import { IPRItemData, IPRTab } from "../../../Model/ServerResponse";
import { PRItemsRow } from "./PRItemsRow/PRItemsRow";
import "./PRItems.scss";

export interface IPRItemsProp {
  prItemsTabData: IPRTab | undefined;
}

export function PRItems(props: IPRItemsProp){
  let tableHeaderMapping: ITableDataMapping[] = [
    {
      id: "1",
      label: "Line Item No",
    },
    {
      id: "2",
      label: "Description",
    },
    {
      id: "3",
      label: "Item Category",
    },
    {
      id: "4",
      label: "Per Unit",
    },
    {
      id: "5",
      label: "UOM",
    },
    {
      id: "6",
      label: "Quantity",
    },
    {
      id: "7",
      label: "Amount",
    },
  ];

  return <div className="pr-items-tab">{tabUI()}</div>;

  function tabUI() {
    if (
      props.prItemsTabData &&
      !Utility.isArrayEmpty(props.prItemsTabData.data)
    ) {
      return (
        
          <TableComponent
            tableData={props.prItemsTabData.data}
            mappingTableData={tableHeaderMapping}
            getTableRow={getTablePRItemRow}
          ></TableComponent>
        
      );
    } else {
      return <div className="no-pr-items">No Items</div>;
    }
  }

  function getTablePRItemRow(_index: number, row: IPRItemData){
    return (
      <PRItemsRow
        index={_index}
        prItemData={row}
        noOfColumns={tableHeaderMapping.length}
      />
    );
  }
}
