import {
  IDLPConsideration,
  IDLPConsiderationDetail,
  IDLPConsiderationItem,
} from "Model/ServerResponse";
import "./DLPConsideration.scss";
import React from "react";
import Utility from "common/utilities";
import { Chip } from "@material-ui/core";
import { Accordian } from "Components2/Accordian/Accordian";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

export interface IDLPConsiderationProp {
  dlpConsiderationDetail: IDLPConsiderationDetail;
}

export function DLPConsideration(props: IDLPConsiderationProp)  {
  return (
    <div data-testid="dlp-consideration" className="dlpconsideration-container">
      {getHeaderUI()}
      {getContentUI()}
    </div>
  );
  function getHeaderUI() {
    return (
      <div className="main-container">
        <div className="title-container">
          <div className="dlp-section-title">
            <b>{props.dlpConsiderationDetail?.title}</b>
            <div className="spacer"></div>
            <div className="col-1">
              <Chip
                className={Utility.mergeCssClassNames([
                  "chip-1",
                  getChipStyle(props.dlpConsiderationDetail?.risk),
                ])}
                label={Utility.stringToPascalCase(
                  props.dlpConsiderationDetail?.risk
                )}
              ></Chip>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getContentUI() {
    return (
      <div className="subtitle-container">
        <Accordian
          headerContent={getConsiderationHeaderUI(
            props.dlpConsiderationDetail.considerations
          )}
          content={getConsiderationContentUI(
            props.dlpConsiderationDetail.considerations
          )}
        />
        <Accordian
          headerContent={getConsiderationHeaderUI(
            props.dlpConsiderationDetail.verifications
          )}
          content={getConsiderationContentUI(
            props.dlpConsiderationDetail.verifications
          )}
        />
      </div>
    );
  }

  function getConsiderationHeaderUI(row: IDLPConsideration)  {
    return (
      <p className="dlp-section-title">
        <b>{row.header}</b>{" "}
        <span className="verification-count">
          <b>({row.count?.toString().padStart(2, "0")})</b>
        </span>
      </p>
    );
  }

  function getConsiderationContentUI(row: IDLPConsideration)  {
    return (
      <div>
        <div className="key-value-list multAccordin-keyvalue">
          {row?.items.map((e: IDLPConsiderationItem) => (
            <div key={e.text} className="key-value">
              {e.status === "CHECKED" ? (
                <CheckCircleOutlineIcon
                  data-testid="checked-icon"
                  className="key checked"
                />
              ) : (
                <p className="checked">{e.status}</p>
              )}
              <p className="value">
                <strong>{e.text}</strong>
              </p>
            </div>
          ))}
        </div>
        {row?.additionalProps ? (
          <div className="accordian-additional-details">
            <div className="key-value">
              <p className="additionaldetailskey">
                {row?.additionalProps?.title}
              </p>
              <p className="additionaldetailsvalue">
                <strong>{row?.additionalProps?.description}</strong>
              </p>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  function getChipStyle(chipText: string | undefined): string {
    let result = "";

    switch (chipText?.toLowerCase()) {
      case "high":
        result = "chip-1-high";
        break;
      case "medium":
        result = "chip-1-medium";
        break;
      case "low":
        result = "chip-1-low";
        break;
    }
    return result;
  }
}
