import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({



    paper: {
        width: "400px",
        height: "20%",
    },

    paperCenter: {
        width: "400px",
        minHeight: "450px",
        maxHeight: "600px",
    },

    alertTitle: {
        height: "45px",
        color: "#9B9B9B",
        fontSize: "18px",
        fontWeight: 600,
        textAlign: "center",
    },

    alertDescription: {
        color: "#000000",
        fontSize: "14px",
        height: "-webkit-fill-available",
        textAlign: "center",
    },


    okButton: {
        color: "#ffffff",
        backgroundColor: "#106FF9",
        height: "36px",
        borderRadius: "8px",
        margin: "10px",
        minWidth: "90px",

        "&&:hover": {
            backgroundColor: "#106FF9",
        },
    },

    centerOkButton: {
        fontWeight: 600,
        color: "#0014DC",
        backgroundColor: "white",
        height: "36px",
        borderRadius: "4px",
        margin: "-7px auto 10px auto !important", // Adjusted for auto margins on both sides
        minWidth: "90px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px #0014DC solid",
    },


    dialogContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
    },


    downloadButton: {
        color: "#ffffff",
        backgroundColor: "#106FF9",
        height: "36px",
        borderRadius: "8px",
        margin: "10px",
        minWidth: "90px",
        display: "flex-start",
        justifyContent: "center",
        alignItems: "center",
        "&&:hover": {
            backgroundColor: "#106FF9",
        },
    },


    cancelButton: {
        color: "#ffffff",
        backgroundColor: "#3C3C3C",
        height: "36px",
        borderRadius: "8px",
        margin: "10px",
        minWidth: "90px",

        "&&:hover": {
            backgroundColor: "#3C3C3C",
        },
    },
}));

export interface AlertDialogProps {
    icon?: any;
    title?: string;
    content?: any;
    okButtontext?: string;
    centerOkButtontext?: string;
    cancelButtonText?: string;
    handleOkButton?: any;
    handleCancelButton?: any;
    handleDownloadButton?: any;
}

const AlertDialog: React.FC<AlertDialogProps> = (props: AlertDialogProps) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(true);

    function handleOpen() {
        setOpen(false);
        if (props.handleOkButton) props.handleOkButton();
    }

    function handleClose() {
        setOpen(false);
        if (props.handleCancelButton) props.handleCancelButton();
    }

    function handleDownload() {
        setOpen(false);
        if (props.handleDownloadButton) props.handleDownloadButton();
    }

    return (
        <Dialog
            open={open}
            // onClose={handleClose}
            classes={{ paper: props.centerOkButtontext ? classes.paperCenter : classes.paper }}

            style={{ zIndex: 10000 }}
        >
            {props.icon && <img alt="icon" src={props.icon}></img>}

            {props.title && <DialogTitle className={classes.alertTitle} id="alert-dialog-title"><b>{props.title}</b></DialogTitle>}

            {props.content && (
                <DialogContent className={classes.dialogContent}>
                    <DialogContentText className={classes.alertDescription} id="alert-dialog-description">{props.content}</DialogContentText>
                </DialogContent>
            )}

            <DialogActions>
                {props.cancelButtonText && (
                    <Button onClick={handleClose} className={classes.cancelButton}>
                        {props.cancelButtonText}
                    </Button>
                )}
                {props.handleDownloadButton && (
                    <>
                        <Button onClick={handleDownload} className={classes.downloadButton}>
                            View Document
                        </Button>
                        <div className="spacer"></div>
                    </>
                )}
                {props.okButtontext && (
                    <Button onClick={handleOpen} className={classes.okButton}>
                        {props.okButtontext}
                    </Button>
                )}
                {props.centerOkButtontext && (
                    <Button onClick={handleOpen} className={classes.centerOkButton}>
                        {props.centerOkButtontext}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;
