import { v4 as uuidv4 } from "uuid";
import { SessionStorageService } from "./SessionStorageService";
import VariablesJson from '../configVariables.json';
import { Account } from "msal";
import { msalInstance } from "authProvider";
import { IServerResponse, IServerResponseError } from "Model/ServerResponse";
import { Constants } from "common/constants";
import { Console } from "console";
import { authentication } from "@microsoft/teams-js";
import { TokenService } from "./TokenService";

export class ServicesUtility {

  public static readonly Min5: number = 300000;

  public static prefixBaseUrl(url: string): string {
    let baseUrl = VariablesJson.Variables.baseurl;
    return baseUrl + url;
  }

  public static async getGraphToken(account?: Account): Promise<string | undefined> {

    if (SessionStorageService.getInstance().IsTeamsApp) {
      console.log("Teams App Token");

      return authentication
        .getAuthToken()
        .then((result) => {
          console.log("Teams SSO Token: ", result);
          return TokenService.getInstance().getAPIAccessToken(result, Constants.DEFAULT_SCOPE_API).then((token) => {
            console.log("Teams Access Token: ", token);
            return token as string;
          })
        })
        .catch((error) => {
          console.error("Teams Auth Token Error: ", error);
          return "";
        });
    } else {
      console.log("Web App Token");
      const silentRequest = {
        account: msalInstance.getAllAccounts()[0],
        scopes: [Constants.DEFAULT_SCOPE],
      };

      return msalInstance
        .acquireTokenSilent(silentRequest)
        .then((response) => {
          let result =
            VariablesJson.Variables.temp_token_enabled.toLowerCase() == "true"
              ? VariablesJson.Variables.temp_token_value
              : response.accessToken;
          // console.error("Web Access Token: ", result);
          return result;
        })
        .catch((error) => {
          return "";
        });
    }
  }

  public static timedRequest(time: number, url: string, requestOptions: any): Promise<any> {
    return new Promise(async function (resolve, reject) {
      const timeout = setTimeout(function () {
        reject(new Error("timeout"));
      }, time);

      try {
        let response = await fetch(url, requestOptions);
        clearTimeout(timeout);
        resolve(response);
      } catch (error) {
        reject(new Error("internet"));
      }
    });
  }

  private static createTransactionToken(): string {
    const token = uuidv4();
    const date = new Date();
    const uniqueSessionTokenObject = {
      expires: new Date(new Date().setDate(date.getDate() + 1)),
      token,
    };
    SessionStorageService.getInstance().transactionToken = JSON.stringify(
      uniqueSessionTokenObject
    );
    return token;
  }

  private static getTransactionToken(): string {
    // Added to generate unique ID for each api call
    let transactionID = '', i, randomValue;
    for (i = 0; i < 32; i++) {
      randomValue = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        transactionID += "-"
      }
      transactionID += (i == 12 ? 4 : (i == 16 ? (randomValue & 3 | 8) : randomValue)).toString(16);
    }
    return transactionID;

    // const tokenObject = SessionStorageService.getInstance().transactionToken;
    // const date = new Date();
    // if (tokenObject.trim().length > 0) {
    //   const uniqueSessionTokenObject = JSON.parse(tokenObject);
    //   if (date < new Date(uniqueSessionTokenObject.expires)) {
    //     return uniqueSessionTokenObject.token;
    //   } else {
    //     return ServicesUtility.createTransactionToken();
    //   }
    // } else {
    //   return ServicesUtility.createTransactionToken();
    // }
  }

  public static getDefaultHeaders(token: string, mockdata?: boolean | '', source?: string): any {
    if (!token) {
      throw new Error("Token is required.");
    }

    let transactionToken = this.getTransactionToken();
    let debugAlias = SessionStorageService.getInstance().debugAlias;
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      "x-debug-params-alias": debugAlias,
      "x-debug-mock-data": mockdata || undefined,
      "x-transaction-id": transactionToken,
      "x-environment": VariablesJson.Variables["x-environment"],
      "x-app-source": source,
      "x-apikey": VariablesJson.Variables["x-apikey"] 
    };
  }



  public static getHeadersForApproval(token: string, mockdata?: boolean): any {
    let transactionToken = this.getTransactionToken();
    let debugAlias = SessionStorageService.getInstance().debugAlias;
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      "x-debug-mock-data": mockdata,
      "x-debug-params-alias": debugAlias,
      "x-transaction-id": transactionToken,
      "x-environment": VariablesJson.Variables["x-environment"],
      "x-apikey": VariablesJson.Variables["x-apikey"] 
    };
  }

  public static getParamValue(url: string, paramName: string, splitter: string) {
    // Remove any preceding hash mark (#) from the URL
    url = url.split(splitter)[1];
    // Split the URL by the question mark (?) to get the query string
    const queryString = url.split("?")[1];
    // If no query string is present, return null
    if (!queryString) {
      return null;
    }
    // Split the query string by the ampersand (&) to get the parameters
    var parameters = queryString.split("&");
    // Iterate over the parameters to find the specified parameter and its value
    for (let i = 0; i < parameters.length; i++) {
      const parameter = parameters[i].split("=");
      const paramNameFromURL = decodeURIComponent(parameter[0]);
      // If the parameter name matches the specified name, return its value
      if (paramNameFromURL === paramName) {
        return decodeURIComponent(parameter[1]);
      }
    }
    // If the parameter is not found, return null
    return null;
  }

  public static ParseResponseForErrors(response: IServerResponse<any>): IServerResponseError | null {
    let result: IServerResponseError | null = null;
    switch (response.statusCode) {
      case 200: break;

      case 401: result = {
        message: "Unauthorized",
        description: "Unable to access your authorization, please relogin or contact admin."
      };
        break;
      case 204: result = {
        message: "No details found",
        description: "No details found in this request."
      };
        break;
      case 400: result = {
        message: "Data issue",
        description: "Unable to process due to some data discrepancy, please contact respective admin"
      };
        break;
    }

    if (response.payload?.hasOwnProperty("code")) {

      switch (response.payload.code) {
        case "BFF500":
          result = {
            message: "Server Error",
            description: "Something went wrong, please try again later.",
          };
          break;
        case "AS500":
          result = {
            message: "Server Error",
            description: "We are unable to process your request at this time, please try again later.",
          };
          break;
        case "BES500":
          result = {
            message: "Internal Server Error",
            description: "We are unable to process your request at this time, due to server error.",
          };
          break;
      }
    }

    if (response.statusCode == 0 && response.errorMessage?.toLowerCase() === 'internet') {
      result = {
        message: "Network/Connection Error",
        description: "The server is taking too long to respond OR something is wrong with your internet connection. Please try again later.",
      };
    }
    return result;
  }

  public static async getPowerBiToken(account?: Account): Promise<string> {

    if (SessionStorageService.getInstance().IsTeamsApp) {
      console.log("Teams App Token");

      return authentication
        .getAuthToken()
        .then((result) => {
          console.log("Teams SSO BI Token: ", result);
          return TokenService.getInstance().getAPIAccessToken(result, Constants.POWER_BI_SCOPE_API).then((token) => {
            console.log("Teams BI Access Token: ", token);
            return token as string;
          })
        })
        .catch((error) => {
          console.error("Teams Auth Token Error: ", error);
          return "";
        });

    } else {
      const silentRequest = {
        account: msalInstance.getAllAccounts()[0],
        scopes: [Constants.POWER_BI_SCOPE],
      };

      return msalInstance
        .acquireTokenSilent(silentRequest)
        .then((response) => {
          let result = response.accessToken;
          return result;
        })
        .catch((error) => {
          console.log("Error Report: " + error);
          return "";
        });
    }
  }
}


