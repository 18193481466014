import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import "./CardSkeleton1.scss";

export function CardSkeleton1() {
  return (
    <div className="card-skeleton-1">
      <Skeleton
        variant="text"
        width="100%"
        height="20px"
        className="default-margin"
        data-testid="skeleton-text-1"
      />
      <Skeleton
        variant="text"
        width="100%"
        height="20px"
        className="default-margin"
        data-testid="skeleton"
      />
      {/* <Skeleton variant="text" width="30%" height="20px" className="default-margin"/> */}

      <div className="row-1">
        <Skeleton
          variant="circle"
          width="42px"
          height="42px"
          className="default-margin"
          data-testid="skeleton-circle"
        />
        <div className="column-1" data-testid="column-1">
          <Skeleton
            variant="text"
            width="40%"
            height="20px"
            data-testid="skeleton-text-column-1"
          />
          <Skeleton
            variant="text"
            width="40%"
            height="20px"
            data-testid="skeleton-text-column-1-2"
          />
        </div>
      </div>
    </div>
  );
}
