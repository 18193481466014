import {
  ActionHandler1,
  IActionsHandler1Prop,
} from "../../../Components2/ActionsHandlerTemplate/ActionsHandler1/ActionsHandler1";
import React from "react";

export function TimeSheetActions(props: IActionsHandler1Prop)  {
  return (
    <ActionHandler1
      approveDisabled={props.approveDisabled}
      rejecteDisabled={props.rejecteDisabled}
      rejectLabel="Delete"
      isMulti={props.isMulti}
      count={props.count}
      containsChildItems={true}
      childItemCount={props.childItemCount}
      isApproveCommentMandatory={false}
      onApprove={props.onApprove}
      isRejectCommentMandatory={true}
      onReject={props.onReject}
    />
  );
}
