import React from "react";
import { useSelector } from "react-redux";
import { GetApprovalDetails, IsTabletViewSelector, MultiSelectedApprovalsSelector, PendingApprovalsStatusSelector, SelectedApprovalDetailErrorSelector, SelectedApprovalDetailsSelector, SelectedApprovalDetailStatusSelector, SelectedApprovalSelector, setSelectedApprovalDetails } from "../../../Store/JRA/JRASlice";
import { IJRA } from "../../../Model/ServerResponse";
import { ISystemDetailsCardBaseProp } from "../../../Model/UI";
import "./JRADetailsCard.scss";
import { useAppDispatch } from "../../../Store/store";
import { RequestStatus } from "../../../Model/Enums";
import { Message1 } from "../../../Components2/MessageTemplates/Message1/Message1";
import { DetailSkeleton1 } from "../../../Components2/Skeletons/DetailSkeletons/DetailSkeleton1/DetailSkeleton1";
import { JRAHeader } from "../JRAHeader/JRAHeader";
import { JRADetailKeyValueList } from "../JRADetailKeyValueList/JRADetailKeyValueList";
import { JRATabs } from "../JRATabs/JRATabs";
import { Typography } from "@material-ui/core";

export interface IJRADetailsCardProp<T>
    extends ISystemDetailsCardBaseProp<T> {
}

export function JRADetailsCard(props: IJRADetailsCardProp<IJRA>) {
    let appDispatch = useAppDispatch();

    let selectedApproval = useSelector(SelectedApprovalSelector);
    let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);

    let selectedApprovalDetails = useSelector(SelectedApprovalDetailsSelector);
    let selectedApprovalDetailsStatus = useSelector(SelectedApprovalDetailStatusSelector);
    let selectedApprovalDetailsError = useSelector(SelectedApprovalDetailErrorSelector);

    let pendingApprovalsStatus = useSelector(PendingApprovalsStatusSelector);

    let isTabletView = useSelector(IsTabletViewSelector);
    //Re-render
    React.useEffect(() => {
        appDispatch(setSelectedApprovalDetails(null));

        if (selectedApproval) {
            appDispatch(GetApprovalDetails());
        }

    }, [selectedApproval]);

    return (
            <div className="jra-details-card">
                {detailsUI()}
            </div>

    );

    function detailsUI()  {

        if (selectedApprovalDetailsStatus === RequestStatus.Loading || (pendingApprovalsStatus === RequestStatus.Loading && !selectedApproval)) {
            return loadingUI();
        }
        else if (selectedApprovalDetailsStatus === RequestStatus.Failed) {
            return errorUI();
        }
        else if (!selectedApproval) {
            return emptyIndicatorUI();
        }

        if (selectedApprovalDetails) {
            return (
                <>
                    <div className="jra-details-header">
                        <JRAHeader jra={selectedApprovalDetails as IJRA} ></JRAHeader>
                        <div className="dividerDiv" ></div>
                    </div>

                    <div className="jra-details-content">
                        <JRADetailKeyValueList jra={selectedApprovalDetails as IJRA}></JRADetailKeyValueList>
                        <JRATabs jra={selectedApprovalDetails as IJRA}></JRATabs>
                    </div>

                    {actionContainerUI()}
                </>
            );
        }
        else {
            return <></>
        }

    }

    function loadingUI()  {
        return <DetailSkeleton1 />;
    }

    function emptyIndicatorUI()  {
        return (
            <div className="message-container">
                <Message1 title="No details to show." imgSrc="CardAssets/NoPendingRequests.svg"></Message1>
            </div>
        )
    }

    function errorUI()  {
        if (selectedApprovalDetailsError) {
            return (
                <div className="message-container">
                    <Message1 title={selectedApprovalDetailsError.message}
                        imgSrc="CardAssets/error.svg"
                        subTitle={selectedApprovalDetailsError.description}></Message1>
                </div>
            )
        }
        return <></>;
    }

    function actionContainerUI()  {
        if ((selectedApprovalDetails && multiSelectedApprovals.length === 0) || isTabletView) {
            let actionData = selectedApprovalDetails as IJRA
            return (
                    <div className="action-container">
                        {(() => {

                            if (actionData?.title && actionData?.url) {
                                return (
                                    <Typography className="link-Text">Click link to view request on respective system
                                        <br></br>
                                        <a className="link" target="_blank" href={actionData?.url}>
                                            {actionData?.title}
                                        </a>
                                    </Typography>
                                )
                            } else {
                                return <></>
                            }
                        })()}

                        <div className="spacer">

                        </div>
                        {props.getActionsUI(false, [selectedApprovalDetails as IJRA])}
                    </div>

            );
        }
        return <></>;
    }
}
