import React from "react";
import { IPR } from "../../../Model/ServerResponse";
import { IKeyValue } from "../../../Model/UI";
import "./PRDetailKeyValueList.scss";
import { KeyValueList } from "Components2/KeyValueList/KeyValueList";

export interface IPRDetailKeyValueListProp {
  pr: IPR;
}

export function PRDetailKeyValueList(props: IPRDetailKeyValueListProp) {

  function getHeaderTableUI(){
    let _data = getKeyValueListData();
    return (
      <>
        <div className="pr-details-key-value">
          <KeyValueList data={_data} />
        </div>
      </>
    );
  }

  function getKeyValueListData(): IKeyValue[] {
    let dataArray: IKeyValue[] = [];
    if (props.pr.purchasingUnit) dataArray.push({ _key: "Purchasing Unit", value: props.pr.purchasingUnit, seperator: ":" });
    if (props.pr.companyCode) dataArray.push({ _key: "Company Code", value: props.pr.companyCode, seperator: ":" });
    if (props.pr.erpRefId) dataArray.push({ _key: "ERP Ref ID", value: props.pr.erpRefId, seperator: ":" });
    return dataArray;
  }

  return getHeaderTableUI();
}

