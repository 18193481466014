import { ISystemDetailsCardBaseProp } from "Model/UI";
import React from "react";
import { useSelector } from "react-redux";
import { GetApprovalDetails, MultiSelectedApprovalsSelector, PendingApprovalsStatusSelector, SelectedApprovalDetailErrorSelector, SelectedApprovalDetailsSelector, SelectedApprovalDetailStatusSelector, SelectedApprovalSelector, setSelectedApprovalDetails } from "Store/PR/PRSlice";
import { PRDetailKeyValueList } from "../PRDetailKeyValueList/PRDetailKeyValueList";
import { PRTabs } from "../PRTabs/PRTabs";
import { PRHeader } from "../PRHeader/PRHeader";
import "./PRDetailsCard.scss";
import { useAppDispatch } from "Store/store";
import { RequestStatus } from "Model/Enums";
import { Message1 } from "Components2/MessageTemplates/Message1/Message1";
import { DetailSkeleton1 } from "Components2/Skeletons/DetailSkeletons/DetailSkeleton1/DetailSkeleton1";
import { IPR } from "Model/ServerResponse/IPR";
import { Typography } from "@material-ui/core";

export interface IPRDetailsCardProp<T>
  extends ISystemDetailsCardBaseProp<T> { }

export function PRDetailsCard(props: IPRDetailsCardProp<IPR>) {

  let appDispatch = useAppDispatch();

  let selectedApproval = useSelector(SelectedApprovalSelector);
  let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);

  let selectedApprovalDetails = useSelector(SelectedApprovalDetailsSelector);
  let selectedApprovalDetailsStatus = useSelector(SelectedApprovalDetailStatusSelector);
  let selectedApprovalDetailsError = useSelector(SelectedApprovalDetailErrorSelector);

  let pendingApprovalsStatus = useSelector(PendingApprovalsStatusSelector);

  //Re-render
  React.useEffect(() => {
    appDispatch(setSelectedApprovalDetails(null));

    if (selectedApproval) {
      appDispatch(GetApprovalDetails());
    }

  }, [selectedApproval]);

  return (
    <React.Fragment>
      <div className="pr-details-card">
        {detailsUI()}
      </div>
    </React.Fragment>

  );

  function detailsUI(){

    if (selectedApprovalDetailsStatus === RequestStatus.Loading || (pendingApprovalsStatus === RequestStatus.Loading && !selectedApproval)) {
      return loadingUI();
    }
    else if (selectedApprovalDetailsStatus === RequestStatus.Failed) {
      return errorUI();
    }
    else if (!selectedApproval) {
      return emptyIndicatorUI();
    }


    if (selectedApprovalDetails) {
      return (
        <>
          <div className="pr-details-header">
            <PRHeader pr={selectedApprovalDetails as IPR} ></PRHeader>
            <div className="dividerDiv" ></div>
          </div>

          <div className="pr-details-content">
            <PRDetailKeyValueList pr={selectedApprovalDetails as IPR}></PRDetailKeyValueList>
            <PRTabs pr={selectedApprovalDetails as IPR}></PRTabs>
          </div>

          {actionContainerUI()}
        </>
      );
    }
    else {
      return <></>
    }

  }

  function loadingUI(){
    return <DetailSkeleton1 />;
  }

  function emptyIndicatorUI(){
    return (
      <div className="message-container">
        <Message1 title="No details to show." imgSrc="CardAssets/NoPendingRequests.svg"></Message1>
      </div>
    )
  }

  function errorUI(){
    if (selectedApprovalDetailsError) {
      return (
        <div className="message-container">
          <Message1 title={selectedApprovalDetailsError.message}
            imgSrc="CardAssets/error.svg"
            subTitle={selectedApprovalDetailsError.description}></Message1>
        </div>
      )
    }
    return <></>;
  }

  function actionContainerUI(){
    if ((selectedApprovalDetails && multiSelectedApprovals.length === 0)) {
      let actionData = selectedApprovalDetails as IPR
      return (
        <>
          <div className="action-container">
            {(() => {

              if (actionData?.title && actionData?.redirectUrl) {
                return (
                  <Typography className="link-Text">Click link to view request on respective system
                    <br></br>
                    <a className="link" target="_blank" href={actionData?.redirectUrl}>
                      {actionData?.title}
                    </a>
                  </Typography>
                )
              } else {
                return <></>
              }
            })()}

            <div className="spacer">

            </div>
            {props.getActionsUI(false, [selectedApprovalDetails as IPR])}
          </div>
        </>

      );
    }
    return <></>;
  }
}
