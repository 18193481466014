import React from "react";
import { IEjourney } from "../../../Model/ServerResponse";
import { IKeyValue } from "../../../Model/UI";
import { KeyValueList } from "../../../Components2/KeyValueList/KeyValueList";

export interface IEJourneyDetailKeyValueListProp {
  ej: IEjourney;
}

export function EJourneyDetailKeyValueList(props: IEJourneyDetailKeyValueListProp) {

  function getHeaderTableUI()  {
    let _data = getKeyValueListData();
    return (
      <KeyValueList data={_data} />
    );
  }

  function getKeyValueListData(): IKeyValue[] {

    let dataArray: IKeyValue[] = [];
    if (props.ej.reason) dataArray.push({ _key: "Trip Reason", value: props.ej.reason, seperator: ":" });
    if (props.ej.segment) dataArray.push({ _key: "Business Segment", value: props.ej.segment, seperator: ":" });
    if (props.ej.subSegment) dataArray.push({ _key: "Sub Segment", value: props.ej.subSegment, seperator: ":" });
    return dataArray;
  }
  return getHeaderTableUI();
}
