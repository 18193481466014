import React from "react";
import { IDefaultUIProp } from "../../Model/UI";
import Utility from "common/utilities";
import ArrowIcon from '../../arrow_icon.svg';

import "./CardContainer.scss";

export interface ICardContainerProp extends IDefaultUIProp {
  isSelected: boolean;
  className?: string;
}

export interface ICardContentProp {
  content : React.ReactNode ;
  className?: string;
  isSelected: boolean;
}


export function CardContainer(props: ICardContainerProp | ICardContentProp) {
  return (
    <>
      <div
        data-testid='card-container'
        className={Utility.getClassNamesFromProps(
          "card-container",
          props.className
        )}
      >
        <div className={"content-container"}>{props.content}</div>

        {props.isSelected ? (
          <div data-testid="selected-indicator" className="selected-indicator">
            <img data-testid="selected-indicator-icon" src={ArrowIcon} className="selected-indicator-icon" alt="arrow-icon" />
          </div>
        ) : (
          <div data-testid="not-selected-indicator" className="not-selected-indicator">
          </div>
        )}
      </div>
    </>
  );
}
