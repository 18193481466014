import {
  ActionHandler1,
  IActionsHandler1Prop,
} from "../../../Components2/ActionsHandlerTemplate/ActionsHandler1/ActionsHandler1";
import React from "react";

export function EJourneyActions(props: IActionsHandler1Prop)  {
  return (
    <ActionHandler1
      isMulti={props.isMulti}
      count={props.count}
      isApproveCommentMandatory={false}
      onApprove={props.onApprove}
      isRejectCommentMandatory={true}
      onReject={props.onReject}
    />
  );
}
