import React from "react";
import { Grid } from "@material-ui/core";
import { KeyValue } from "../../KeyValue/KeyValue";
import { Chip } from "../../Chip/Chip";
import UserDetails from "../../UserDetails/UserDetails";
import { IKeyValue, ICardTemplateBaseProp } from "../../../Model/UI";

import "./Card2.scss";
import Utility from "common/utilities";

export interface ICard2Prop extends ICardTemplateBaseProp {
  label1: string;
  label2: string;
  label3: string;
  keyValue1: IKeyValue[];
  chip1: string;
}
/*
UI placement:

    label1          chip1
    label2
    keyValue1[]
    userDetails     label3        
 */

export function Card2(props: ICard2Prop)  {
  function getCardUI()  {
    return (
      <div
        className={Utility.getClassNamesFromProps("card-2", props.className)}
      >
        {/* 1st row */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="row-1"
        >
          <div className="dot"></div>
          <p className="label-1">{props.label1}</p>
          <div className="spacer"></div>
          <Chip label={props.chip1} className="chip-1"></Chip>
        </Grid>

        {/* 2nd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-2"
        >
          <p className="label-2">{props.label2}</p>
        </Grid>

        {/* 3nd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-3"
        >
          {props.keyValue1.map((kv: IKeyValue, index: number) => {
            return (
              <KeyValue
                key={Utility.createUniqueId(kv,index)}
                className="key-value"
                _key={kv._key}
                value={kv.value}
                seperator={kv.seperator}
              ></KeyValue>
            );
          })}
        </Grid>

        {/* 4th row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-4"
        >
          <UserDetails
            userId={props.userDetails.userId}
            userCardName={props.userDetails.userCardName}
            userOrganization={props.userDetails.userOrganization}
            // className="user-details"
          ></UserDetails>
          <div className="spacer"></div>
          <p className="label-3">{props.label3}</p>
        </Grid>
      </div>
    );
  }

  return getCardUI();
}
