import React from "react";
import { ITabData } from "../../../Model/UI";
import { DetailTabsComponent } from "../../DetailTabsComponent/DetailTabsComponent";

import { SRM_APPROVERS_TAB_ID, SRM_ITEMS_TAB_ID } from "../SRMUtil";
import { ISRM } from "Model/ServerResponse/ISRM";
import { ISRMTab } from "Model/ServerResponse/ISRMtab";
import { SRMItems } from "../SRMItem/SRMItem";
import SRMApprovers from "../SRMApprovers/SRMApprovers";

export interface ISRMTabsProp {
  srm: ISRM;
}

export function SRMTabs(props: ISRMTabsProp) {
  function tabsUI(props) {
    const data = getTabsData(props.srm.tabs);
    return <DetailTabsComponent tabs={data}></DetailTabsComponent>;
  }

  function getTabsData(tabs: ISRMTab[]): ITabData[] {
    let tdata: ITabData[] = [];

    tabs.forEach((tab: ISRMTab) => {
      let tabInfo: ITabData = {
        tabId: "",
        title: tab.title,
        imageSrc: "",
        count: tab.count,
      };

      switch (tab.tabId) {
        case SRM_ITEMS_TAB_ID:
          tabInfo.tabId = SRM_ITEMS_TAB_ID;
          tabInfo.imageSrc = "./DetailTab/details_icn.svg";
          tabInfo.getTabUI = itemsUI;
          break;
        case SRM_APPROVERS_TAB_ID:
          tabInfo.tabId = SRM_APPROVERS_TAB_ID;
          tabInfo.imageSrc = "./DetailTab/approvericn.svg";
          tabInfo.getTabUI = approversUI;
          break;
        default:
          break;
      }
      tdata.push(tabInfo);
    });
    return tdata;
  }

  function itemsUI() {
    return <SRMItems srmData={props.srm}></SRMItems>;
  }

  function approversUI() {
    let approversData = props.srm.tabs.find(
      (td) => td.tabId === SRM_APPROVERS_TAB_ID
    );
    return <SRMApprovers srmTabData={approversData}></SRMApprovers>;
  }
  return tabsUI(props);
}
