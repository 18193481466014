import React from "react";
import { IKeyValue } from "../../../Model/UI";
import { KeyValueList } from "../../../Components2/KeyValueList/KeyValueList";
import { IEQuote } from "Model/ServerResponse/IEquote";

export interface IEQuoteDetailKeyValueListProp {
  equote: IEQuote;
}

export function EQuoteDetailKeyValueList(props: IEQuoteDetailKeyValueListProp) {
  function getHeaderTableUI() {
    let _data = getKeyValueListData();
    return <KeyValueList data={_data} />;
  }

  function getKeyValueListData(): IKeyValue[] {
    let dataArray: IKeyValue[] = [];
    dataArray.push({
      _key: "Mfg Center",
      value: props.equote.mfgCenter ? props.equote.mfgCenter : "-",
      seperator: ":",
    });
    dataArray.push({
      _key: "Product Line",
      value: props.equote.productLine ? props.equote.productLine : "-",
      seperator: ":",
    });
    dataArray.push({
      _key: "Part Number",
      value: props.equote.partNumber ? props.equote.partNumber : "-",
      seperator: ":",
    });
    dataArray.push({
      _key: "Part Description",
      value: props.equote.partDescription ? props.equote.partDescription : "-",
      seperator: ":",
    });
    dataArray.push({
      _key: "Country",
      value: props.equote.country ? props.equote.country : "-",
      seperator: ":",
    });
    dataArray.push({
      _key: "Final Dest. Country",
      value: props.equote.destinationCountry
        ? props.equote.destinationCountry
        : "-",
      seperator: ":",
    });
    dataArray.push({
      _key: "Client",
      value: props.equote.client ? props.equote.client : "-",
      seperator: ":",
    });
    return dataArray;
  }
  return getHeaderTableUI();
}
