import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Utility from "../../../common/utilities";
import { IDefaultUIProp } from "../../../Model/UI";
import "./TakeActionReferenceModal.scss";

export interface ITakeActionReferenceModalProp extends IDefaultUIProp {
  open: boolean;
  title?: string;
  subTitle?: string;
  content: any;
  onOk?: (e: any) => void;
}

export function TakeActionReferenceModal(
  props: ITakeActionReferenceModalProp
) {
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
      className={Utility.mergeCssClassNames([
        "take-action-reference-modal",
        props.className,
      ])}
    >
      <DialogTitle>
        {<p className="title">{props.title ? props.title : "Success"}</p>}
      </DialogTitle>

      <DialogContent className="action-content">
        {
          <span className="subtitle">
            {props.subTitle
              ? props.subTitle
              : "Your request has been successfully processed."}
          </span>
        }
        <div className="details">{props.content}</div>
      </DialogContent>
      <DialogActions className="action-container">
        <Button className="action-btn cancel-btn" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );

  function handleClose(e: any) {
    setOpen(false);
    if (props.onOk) {
      props.onOk(e);
    }
  }
}
