import { } from "@material-ui/core";
import { IDefaultUIProp } from "../../../Model/UI";
import * as React from "react";
import { AttachmentCell } from "../../AttachmentList/AttachmentCell";
import {
  IAttachmentData,
  IServerResponse,
} from "Model/ServerResponse";
import { AttachmentModal } from "Components2/Modals/AttachmentModal/AttachmentModal";
import { useEffect, useState } from "react";
import { AttachmentService } from "Services";
import utilities from "common/utilities";
import "./PRAttachment.scss";
import { PR_ATTACHMENTS_TAB_ID } from "../PRUtil";
import { IPR, IPRAttachmentData } from "../../../Model/ServerResponse";

export interface IPRAttachmentProp extends IDefaultUIProp {
  pr: IPR;
}

export function PRAttachment(props: IPRAttachmentProp){
  let attachmentsData = props.pr.tabs.find(
    (td) => td.tabId === PR_ATTACHMENTS_TAB_ID
  );

  let attachmentData = attachmentsData?.data as IPRAttachmentData[];
  let [isAttachmentDownloadable, setisAttachmentDownloadable] = useState(false);
  useEffect(() => {
    if (props.pr.isAttachmentDownloadable) {
      setisAttachmentDownloadable(true);
    }
  }, [props.pr]);;
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [selectedFilename, setSelectedFilename] = useState("");
  const [selectedFileID, setselectedFileID] = useState("");
  const handleAttachmentModalClose = () => {
    setAttachmentModalOpen(false);
  };

  function handleAttachmentClick(e: any, fileName: string, fileID: string) {
    if (isAttachmentDownloadable === true) {
      setAttachmentModalOpen(true);
      setSelectedFilename(fileName);
      setselectedFileID(fileID);
    }
  }

  let attachmentService = AttachmentService.getInstance();

  async function getAttachmentData(
    fileID: string,
    filePath: string
  ): Promise<IAttachmentData> {
    return attachmentService
      .getAttachmentData(
        `api/approvals/${utilities.getSystemActivityMapping(props.pr.approvalSystemId)[1]
        }/${utilities.getSystemActivityMapping(props.pr.approvalSystemId)[0]
        }/${props.pr.id}/attachments/${fileID}`
      )
      .then((attachmentResponse: IServerResponse<IAttachmentData>) => {
        return attachmentResponse.payload!;
      });
  }

  function getAttachmentList(){
    if (attachmentData) {
      return (
        <div className="attachment-list">
          {attachmentData.map((e) => {
            return (
              <AttachmentCell
                key={e.id}
                onClick={handleAttachmentClick}
                fileID={e.id}
                fileName={e.fileName}
                isDownloadable={isAttachmentDownloadable}
              />
            );
          })}
        </div>
      );
    } else return <div className="no-attachment"> No Attachments </div>;
  }

  return (
    <>
      {getAttachmentList()}
      <AttachmentModal
        open={attachmentModalOpen}
        selectedFilename={selectedFilename}
        selectedFileID={selectedFileID}
        getAttachmentData={getAttachmentData}
        onClose={handleAttachmentModalClose}
      />
    </>
  );
}
