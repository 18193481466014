import React from "react";
import "./ErrorContainer.scss";
import { Grid, Button } from "@material-ui/core";

export interface IErrorConatiner {
  errorName: string;
  errorImage: string;
  errorHelperText?: string;
  errorId: string;
}

export default function ErrorConatiner(props: IErrorConatiner) {
  return ErrorViewUI();

  function handleRefreshClick() {
    window.location.reload();
  }
  function ErrorViewUI()  {
    return (
      <>
        <Grid className="errorDiv">
          <Grid className="errorCard">
            <div>
              <img alt="" src={props.errorImage} className="errorImage" />
            </div>
            <div className="namecontainer">
              <div className="errorName">{props.errorName}</div>
            </div>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className="errorHelperText"
          >
            {props.errorHelperText}
          </Grid>
          {props.errorId ? (
            <div className="refreshcontainer">
              <Button
                className="buttonRefresh"
                onClick={() => handleRefreshClick()}
              >
                {"Refresh Now"}
              </Button>
            </div>
          ) : null}
        </Grid>
      </>
    );
  }
}
