import { SearchFilterToolbar } from "../../../Components2/SearchFilterToolbar/SearchFilterToolbar";
import { Message1 } from "../../../Components2/MessageTemplates/Message1/Message1";
import React, { useEffect, useRef } from "react";
import { ListView } from "../../ListView/ListView";
import { TimeSheetCard } from "../TimeSheetCard/TimeSheetCard";
import { ISystemListViewBaseProp } from "../../../Model/UI";
import { useSelector } from "react-redux";
import {
  IsAscSelector,
  MultiSelectedApprovalsSelector,
  PendingApprovalsSelector,
  SearchQuerySelector,
  SelectedApprovalSelector,
  SelectedSubSystemSelector,
  setIsAsc,
  setMultiSelectedApprovals,
  setFromPosition,
  setSearchQuery,
  setSelectedApproval,
  setSelectedSubSystem,
  SubSystemsSelector,
  TotalCountForFilterSelector,
  GetPendingApprovals,
  resetSystemSliceState,
  PendingApprovalsStatusSelector,
  PendingApprovalsErrorSelector,
  SupportMultiSelector,
  timesheetSliceInitialState,
  IsTabletViewSelector,
  setFilterQuery,
  FilterQuerySelector,
} from "../../../Store/TimeSheet/TimeSheetSlice";
import { ITimeSheet } from "../../../Model/ServerResponse";
import "./TimeSheetListView.scss";
import { useAppDispatch } from "../../../Store/store";
import { RequestStatus } from "../../../Model/Enums";

export interface ITimeSheetListViewProp<T> extends ISystemListViewBaseProp<T> {
  getFilterUI?: () => React.ReactNode;
  searchValue?: string;
}

export function TimeSheetListView(props: ITimeSheetListViewProp<ITimeSheet>) {
  const searchComponentRef = useRef<any>(null);
  let appDispatch = useAppDispatch();

  let supportMulti = useSelector(SupportMultiSelector);
  const filterQueryValue = useSelector(FilterQuerySelector);
  let pendingApprovalsStatus = useSelector(PendingApprovalsStatusSelector);
  let pendingApprovalsError = useSelector(PendingApprovalsErrorSelector);

  let selectedApproval = useSelector(SelectedApprovalSelector);
  let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);
  let subSystems = useSelector(SubSystemsSelector);
  let totalCountForFilter = useSelector(TotalCountForFilterSelector);
  let pendingApprovals = useSelector(PendingApprovalsSelector);

  let searchQuery = useSelector(SearchQuerySelector);
  let isAsc = useSelector(IsAscSelector);
  let selectedSubSystem = useSelector(SelectedSubSystemSelector);

  let isTabletView = useSelector(IsTabletViewSelector);
  useEffect(() => {
    appDispatch(resetSystemSliceState(timesheetSliceInitialState));
  }, []);
  //Set up data
  useEffect(() => {
    appDispatch(setSelectedApproval(null));
    appDispatch(GetPendingApprovals());
  }, [searchQuery, isAsc, selectedSubSystem]);

  useEffect(() => {
    if (props.searchValue && props.searchValue !== "0") {
      ClearSearchTextBox();
    }
  }, [props.searchValue]);

  return (
    <div className="timesheet-list-container">
      <SearchFilterToolbar
        multiSelectedCount={multiSelectedApprovals?.length}
        title="Time Sheet"
        error={pendingApprovalsStatus === RequestStatus.Failed}
        totalCount={totalCountForFilter}
        count={pendingApprovals?.length}
        subSystems={subSystems}
        loading={pendingApprovalsStatus === RequestStatus.Loading}
        onRefresh={handleRefresh}
        onSearch={handleSearchChange}
        onSort={handleSortChange}
        ref={searchComponentRef}
        onSubSystemChange={handleSubSystemChange}
      ></SearchFilterToolbar>

      {listUI()}
    </div>
  );

  function getRowUI(
    data: ITimeSheet,
    multiSelectUI? 
  )  {
    return (
      <TimeSheetCard
        supportMulti={supportMulti}
        multiSelectUI={multiSelectUI}
        timeSheet={data}
      ></TimeSheetCard>
    );
  }

  function listUI()  {
    if (pendingApprovalsStatus === RequestStatus.Failed) {
      return errorUI();
    }

    return (
      <>
        <ListView
          className="timesheet-list"
          totalCount={totalCountForFilter}
          dataList={pendingApprovals}
          selectedData={selectedApproval}
          supportFilter={true}
          getFilterUI={props.getFilterUI}
          multiSelectedData={multiSelectedApprovals}
          loading={pendingApprovalsStatus === RequestStatus.Loading}
          supportMulti={supportMulti}
          getRowUI={getRowUI}
          getUniqueDataIdentifier={getUniqueDataIndentifier}
          onSelectChange={handleSelectChange}
          onMultiSelectChange={handleMultiSelectChange}
          onFromPositionChange={handleFromPositionChange}
          getEmptyIndicatorUI={emptyIndicatorUI}
          isTabletView={isTabletView}
        ></ListView>

        {multiSelectedApprovals?.length > 0 && actionContainer()}
      </>
    );
  }

  function ClearSearchTextBox() {
    if (searchComponentRef.current) {
      searchComponentRef.current.clearSearchTextBoxValue();
    }
  }

  function emptyIndicatorUI()  {
    return (
      <div className="message-container">
        <Message1
          title="No approvals."
          imgSrc="CardAssets/NoPendingRequests.svg"
        ></Message1>
      </div>
    );
  }

  function errorUI()  {
    if (pendingApprovalsError) {
      return (
        <div className="message-container">
          <Message1
            title={pendingApprovalsError.message}
            imgSrc="CardAssets/error.svg"
            subTitle={pendingApprovalsError.description}
          ></Message1>
        </div>
      );
    }
    return <></>;
  }

  function handleRefresh(e: any) {
    //Reset all state
    appDispatch(resetSystemSliceState(timesheetSliceInitialState));
    //Get approvals
    appDispatch(GetPendingApprovals());
  }

  function handleSearchChange(e: any, query: string) {
    const targetElement = e.currentTarget;
    if (targetElement?.classList.contains("close-icon")) {
      appDispatch(setFilterQuery(""));
      appDispatch(setSearchQuery(""));
    } else {
      const filtersearch =
        filterQueryValue !== "All"
          ? query + "&filter=" + filterQueryValue
          : query;
      appDispatch(setSearchQuery(filtersearch));
    }
  }

  function handleSortChange(e: any, _isAsc: boolean) {
    appDispatch(setIsAsc(_isAsc));
  }

  function handleSubSystemChange(e: any, _subSystem: any) {
    appDispatch(setSelectedSubSystem(_subSystem));
  }

  function getUniqueDataIndentifier(_data: ITimeSheet): string {
    //Return unique identifier
    return _data.id.toString();
  }

  function handleFromPositionChange(_fromPostion: number): void {
    appDispatch(setFromPosition(_fromPostion));
    appDispatch(GetPendingApprovals());
  }

  function handleMultiSelectChange(e: any, _values: any) {
    appDispatch(setMultiSelectedApprovals(_values));
  }

  function handleSelectChange(e: any, _value: any) {
    appDispatch(setSelectedApproval(_value));
  }

  function actionContainer()  {
    if (props.getActionsUI) {
      return (
        <div className="action-container">
          {props.getActionsUI(true, multiSelectedApprovals as ITimeSheet[])}
        </div>
      );
    }
    return <></>;
  }
}
