import { IServerResponse, IAttachmentData } from "Model/ServerResponse";

import { ServicesUtility } from "./servicesUtility";

export class AttachmentService {
  private static instance: AttachmentService;
  private constructor() {}
  public static getInstance(): AttachmentService {
    if (!this.instance) {
      this.instance = new AttachmentService();
    }
    return this.instance;
  }

  public async getAttachmentData(
    method: string
  ): Promise<IServerResponse<IAttachmentData>> {
    let token = await ServicesUtility.getGraphToken();
    if (!token) {
      throw new Error('Failed to get authentication token');
    }
    let url = ServicesUtility.prefixBaseUrl(method);
    let requestOptions = {
      method: "GET",
      headers: {
        ...ServicesUtility.getDefaultHeaders(token),
      },
    };
    let result: IServerResponse<IAttachmentData> = {
      statusCode: 0,
      payload: null,
      errorMessage: "",
    };
    return ServicesUtility.timedRequest(
      ServicesUtility.Min5,
      url,
      requestOptions
    ).then(
      async (response) => {
        try {
          result.statusCode = response.status;
          let x = await response.json();
          result.payload = x as IAttachmentData;
          return result;
        } catch (error) {
          result.statusCode = response.status;
          result.errorMessage = response.statusText;
          return result;
        }
      },
      (error: Error) => {
        result.errorMessage = error.message;
        return result;
      }
    );
  }
}
