import { ICart } from "Model/ServerResponse";
import { ISystemDetailsCardBaseProp } from "Model/UI";
import React from "react";
import { useSelector } from "react-redux";
import { GetApprovalDetails, MultiSelectedApprovalsSelector, PendingApprovalsStatusSelector, SelectedApprovalDetailErrorSelector, SelectedApprovalDetailsSelector, SelectedApprovalDetailStatusSelector, SelectedApprovalSelector, setSelectedApprovalDetails, } from "Store/SWPS/SWPSSlice";
import { SWPSDetailKeyValueList } from "../SWPSDetailKeyValueList/SWPSDetailKeyValueList";
import { SWPSTabs } from "../SWPSTabs/SWPSTabs";
import { SWPSHeader } from "../SWPSHeader/SWPSHeader";
import "./SWPSDetailsCard.scss";
import { useAppDispatch } from "Store/store";
import { RequestStatus } from "Model/Enums";
import { Message1 } from "Components2/MessageTemplates/Message1/Message1";
import { DetailSkeleton1 } from "Components2/Skeletons/DetailSkeletons/DetailSkeleton1/DetailSkeleton1";

export interface ISWPSDetailsCardProp<T>
  extends ISystemDetailsCardBaseProp<T> { }

export function SWPSDetailsCard(props: ISWPSDetailsCardProp<ICart>) {

  let appDispatch = useAppDispatch();

  let selectedApproval = useSelector(SelectedApprovalSelector);
  let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);

  let selectedApprovalDetails = useSelector(SelectedApprovalDetailsSelector);
  let selectedApprovalDetailsStatus = useSelector(SelectedApprovalDetailStatusSelector);
  let selectedApprovalDetailsError = useSelector(SelectedApprovalDetailErrorSelector);

  let pendingApprovalsStatus = useSelector(PendingApprovalsStatusSelector);

  //Re-render
  React.useEffect(() => {
    appDispatch(setSelectedApprovalDetails(null));

    if (selectedApproval) {
      appDispatch(GetApprovalDetails());
    }

  }, [selectedApproval]);

  return (
    <div className="swps-details-card">
        {detailsUI()}
      </div>

  );

  function detailsUI(){

    if (selectedApprovalDetailsStatus === RequestStatus.Loading || (pendingApprovalsStatus === RequestStatus.Loading && !selectedApproval)) {
      return loadingUI()
    }
    else if (selectedApprovalDetailsStatus === RequestStatus.Failed) {
      return errorUI()
    }
    else if (!selectedApproval) {
      return emptyIndicatorUI()
    }


    if (selectedApprovalDetails) {
      return (
        <>
          <div className="swps-details-header">
            <SWPSHeader swps={selectedApprovalDetails as ICart} ></SWPSHeader>
            <div className="dividerDiv" ></div>
          </div>

          <div className="swps-details-content">
            <SWPSDetailKeyValueList swps={selectedApprovalDetails as ICart}></SWPSDetailKeyValueList>
            <SWPSTabs swps={selectedApprovalDetails as ICart}></SWPSTabs>
          </div>

          {actionContainerUI()}
        </>
      );
    }
    else {
      return <></>
    }

  }

  function loadingUI(){
    return <DetailSkeleton1 />;
  }

  function emptyIndicatorUI(){
    return (
      <div className="message-container">
        <Message1 title="No details to show." imgSrc="CardAssets/NoPendingRequests.svg"></Message1>
      </div>
    )
  }

  function errorUI(){
    if (selectedApprovalDetailsError) {
      return (
        <div className="message-container">
          <Message1 title={selectedApprovalDetailsError.message}
            imgSrc="CardAssets/error.svg"
            subTitle={selectedApprovalDetailsError.description}></Message1>
        </div>
      )
    }
    return <></>;
  }

  function actionContainerUI(){
    if (selectedApprovalDetails && multiSelectedApprovals.length === 0) {
      return (
        <div className="action-container">
          {props.getActionsUI(false, [selectedApprovalDetails as ICart])}
        </div>
      );
    }
    return <></>;
  }
}
