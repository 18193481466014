import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import "./NewSystemModal.scss";
import { DialogActions } from "@material-ui/core";
import { IApprovalOperationEntity } from "Model/UI";

export interface INewSystemModalProp {
  open: boolean;
  approvalOperationEntity: IApprovalOperationEntity;
  onAddIn: (e: any) => void;
  onSkip: (e: any) => void;
}

export interface NewSystemPopUpDialogState {}

export function NewSystemModal(props: INewSystemModalProp) {
  let [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);



  function handleSkip(e: any) {
    props.onSkip(e);
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      className={"new-system-modal"}
    >
      <div className={"new-system-content"}>
        <Typography className={"title"}>
          New System Approval Available
        </Typography>

        <div className="bg-container">
          <img
            alt=""
            src={"./NewSystemPopUpAssets/newSystemBg.svg"}
            className={"background-img"}
          />
          <div className={"approval-operation-container"}>
            <img
              alt=""
              src={`./DrawerMenuAssets/${props.approvalOperationEntity.id}.svg`}
              className={"approval-operation-img"}
            />
            <Typography className={"approval-operation-description"}>
              {props.approvalOperationEntity.description}
            </Typography>
          </div>
        </div>

        <Typography className={"message"}>
          {"The new system is now accessible for addition, and you can add it to Favourites from “User System Profile” via the settings menu"}
        </Typography>
      </div>

      <DialogActions className="action-container">
        <Button
          className={"action-btn cancel-btn"}
          onClick={() => {
            handleSkip({});
          }}
        >
          GOT IT
        </Button>
      </DialogActions>
    </Dialog>
  );
}
