import { IKeyValue, ISystemCardBaseProp } from "Model/UI";
import React from "react";
import { Card2 } from "../../CardTemplates/Card2/Card2";
import { IQuest } from "Model/ServerResponse";
import Utility from "common/utilities";

// It is important that we import local scss after Card1/2/3 so the styles are overidden in correct way
import "./QuestCard.scss";
import { Constants } from "common/constants";

export interface IQuestCardProp extends ISystemCardBaseProp {
  quest: IQuest;
}

export function QuestCard(props: IQuestCardProp) {
  let keyValues: IKeyValue[] = [
    { _key: "Report No.", value: props.quest?.exemptionNumber, seperator: ":" },
    {
      _key: "Rq Period",
      value:
        Utility.formatDate(props.quest?.from, Constants.DD_MM_YY) +
        " - " +
        Utility.formatDate(props.quest?.to, Constants.DD_MM_YY),
      seperator: ":",
    },
  ];

  function getCardUI(){
    return (
      <Card2
        label1={props.quest.approvalSystemId}
        label2={props.quest.title}
        label3={Utility.formatDate(props.quest.date, Constants.DD_MM_YY_HH_mm)}
        chip1={props.quest.potentialRisk}
        keyValue1={keyValues}
        userDetails={{
          userId: props.quest.requestorID,
          userCardName: props.quest.originator,
          userOrganization: props.quest.location,
        }}
        className={Utility.mergeCssClassNames([
          "quest-card",
          getChipStyle(props.quest.potentialRisk),
        ])}
      ></Card2>
    );
  }

  function getChipStyle(chipText: string | null): string {
    let result = "";
    if (!chipText ) {
      return 'chip1-hidden';
  }
    switch (chipText.toLowerCase()) {
      case "high":
        result = "chip-1-high";
        break;
      case "medium":
        result = "chip-1-medium";
        break;
      case "low":
        result = "chip-1-low";
        break;
      case "insignificant":
        result = "chip-1-insignificant";
        break;
    }
    return result;
  }

  return getCardUI();
}
