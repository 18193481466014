import { SidebarMenuItemState } from "Model/Enums";
import { schema } from "normalizr";

export class Constants {

    public static AUTHORITY = "https://login.windows.net/41ff26dc-250f-4b13-8981-739be8610c21/";
    public static CLIENT_ID = "130a56e1-f6c1-4b6f-9049-b4c1134232ab";
    // public static CLIENT_ID = "3b0a592f-6d96-4a41-b114-735c97e7d297"; //pwa-qa
    public static DEFAULT_SCOPE = "https://41ff26dc-250f-4b13-8981-739be8610c21/ApprovalService/user_impersonation";
    public static DEFAULT_SCOPE_API = "https://41ff26dc-250f-4b13-8981-739be8610c21/ApprovalService";
    public static POWER_BI_SCOPE = "https://analysis.windows.net/powerbi/api/Report.Read.All";
    public static POWER_BI_SCOPE_API = "https://analysis.windows.net/powerbi/api";
    public static GRAPH_SCOPE = 'User.ReadBasic.All';

    public static DD_MM_YY: string = 'DD MMM YY';
    public static DD_MM_YY_HH_mm: string = 'DD MMM YY HH:mm';
    public static D_MMM_xa0_HH_mm: string = 'D MMM \xa0 HH.mm';
    public static POINSIGHTNOTE: string = "NOTE : Spend Insights relies on the profit center from first line of purchase order and cannot provide values for orders with multiple business lines. Spend information is not available for Non-Geo/Non Business lines.";
    public static SPENDINSIGHTNOTE: string = "NOTE : Material DSOH(Plant) : 999 - material had no consumption in last 6 months";

    public static SWPSSystemId = "cart";
    public static SWPSActivityId = "SWPS";
    public static PageSize: number = 10;

    public static readonly approvalSystemEntity = new schema.Entity('approvalSystems', {}, {
        idAttribute: 'name',
        processStrategy: (entity) => {
            return {
                ...entity,
                count: 0
            };
        }
    });

    public static readonly approvalOperationEntity = new schema.Entity('approvalOperations', {
        approvalSystems: [Constants.approvalSystemEntity]
    }, {
        processStrategy: (entity) => {
            return {
                ...entity,
                status: SidebarMenuItemState.Loaded
            };
        }
    });

}