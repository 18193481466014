import React from "react";
import { IKeyValue } from "../../Model/UI";
import { KeyValue } from "../../Components2/KeyValue/KeyValue";
import "./KeyValueList.scss";

export interface IKeyValueListProp {
  data: IKeyValue[];
}

export function KeyValueList(props: IKeyValueListProp)  {
  return (
    <div className="key-value-list ">
      {props.data.map((d) => {
        return (
          <KeyValue
            key={d._key}
            className={"key-value"}
            _key={d._key}
            value={d.value}
            seperator={d.seperator}
          ></KeyValue>
        );
      })}
    </div>
  );
}
