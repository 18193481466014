import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import Utility from "common/utilities";
import { IAppState } from "Store/store";

export interface IRoutesSliceState {
  paths: string[],
  defaultPath: string | null
}

const initialState: IRoutesSliceState = {
  paths: [],
  defaultPath: null
};

export const SetRoutes = createAsyncThunk("RoutesSlice/setRoutesFromUserProfile", async (_, thunkApi): Promise<void> => {
  let userSystemState = (thunkApi.getState() as IAppState).UserSystem;
  let dispatch = thunkApi.dispatch;
  let selfActions = RoutesSlice.actions;

  const _sidebarAoKeys = userSystemState.approvalOperationKeysToShowInSidebar;
  const _aoMap = userSystemState.approvalOperationsMap;

  //Create routes
  let tempPaths: string[] = []
  _sidebarAoKeys.forEach(esao => {
    tempPaths.push(_aoMap[esao].id);
  })
  tempPaths = Utility.sortApprovalOperationKeys(tempPaths, userSystemState.approvalOperationsMap);

  //Set state
  dispatch(selfActions.setPaths(tempPaths));
  dispatch(selfActions.setDefaultPath(tempPaths.length > 0 ? tempPaths[0] : null));

});

const RoutesSlice = createSlice({
  name: "RoutesSlice",
  initialState: initialState,
  reducers: {
    setPaths(state: IRoutesSliceState,
      action: PayloadAction<string[]>) {
      state.paths = action.payload;
    },
    setDefaultPath(state: IRoutesSliceState,
      action: PayloadAction<string | null>) {
        state.defaultPath = action.payload;
      }
  }
});

export const { setPaths: setRoutes } = RoutesSlice.actions;

//Selector
export const RoutesSliceSelector = (state: IAppState) =>
  state.Routes;
export const DefaultPathSelector = createSelector(
  RoutesSliceSelector,
  (details) => details.defaultPath
);
export const PathsSelector = createSelector(
  RoutesSliceSelector,
  (details) => details.paths
);

export const RoutesReducer = RoutesSlice.reducer;

