import { Grid, Typography } from '@material-ui/core';
import Utility from 'common/utilities';
import { Accordian } from 'Components2/Accordian/Accordian';
import { IJRA, IJRAMetroStopItemData, IJRAMetroStopItemHeaderItemData, IJRAMetroStopsData, IJRARiskData, IJRARiskHeaderItemData, IJRARiskItemData, IJRATabData } from 'Model/ServerResponse';
import React from 'react';
import './JRADesign.scss'


export interface JRADesignProp {
    jraData: IJRA
}
export function JRADesign(props: JRADesignProp) {
    let designData: IJRATabData | undefined = props.jraData.tabs.find(td => td.tabId === 'JRA-Details')?.data;

    return (
        getContentUI()
    );

    function getContentUI()  {
        if (!designData) {
            return <></>;
        }

        return <>
                {!Utility.isArrayEmpty(designData.metroStops) ?
                    designData.metroStops.map((row: IJRAMetroStopsData, index: number) => (
                        <div key={Utility.createUniqueId(index,row)} data-testid="jra-design" className='jra-design'>
                            <Accordian data-testid="accordian" key={Utility.createUniqueId(index,row)} headerContent={getMetroStopsHeaderUI(row)} content={getMetroStopsContentUI(row)} />
                        </div>
                    ))
                    : <></>
                }
                {!Utility.isArrayEmpty(designData.risks) ?
                    designData.risks.map((row: IJRARiskData, index: number) => (
                        <div key={Utility.createUniqueId(index,row)} className='jra-design'>
                            <Accordian data-testid="accordian" key={Utility.createUniqueId(index,row)} headerContent={getRiskHeaderUI(row)} content={getRisksContentUI(row)} />
                        </div>
                    ))
                    : <></>
                }
                </>
    }



    function getMetroStopsContentUI(row: IJRAMetroStopsData)  {
        return (
            
                <Grid container direction="column" style={{ flexWrap: "unset" }}>
                    {row.items.map((item: IJRAMetroStopItemData, itemIndex: number) => (
                        <div key={Utility.createUniqueId(item,itemIndex)}>

                            <Grid container className='questions-header' >
                                <Grid item xs={8} sm={10}>
                                    <Typography >
                                        {item.header}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sm={2} >
                                    <Typography className='header-col2 ans-btn'>
                                        Answer
                                    </Typography>
                                </Grid>
                            </Grid>
                            {item.items.map((_row: IJRAMetroStopItemHeaderItemData, index: number) => (
                                    <Grid key={Utility.createUniqueId(index,_row)} container className={index + 1 < item?.items?.length ? 'body-wrap' : ''}>
                                        <Grid item xs={8} sm={10}>
                                            <Grid container className='question-body'>
                                                <Typography className='question-label'>
                                                    {(index + 1) + '.'}&nbsp;{_row.question}
                                                </Typography>
                                                <Grid container>
                                                    <Typography className='question-label'>
                                                        <span className='comment-label'>Comment:</span><span className='comment-value'>{_row.comment}</span>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} sm={2} className='answer-block'>
                                            <span className='answer'>
                                                <p>
                                                    <span className='answer-value'>{_row.userAnswer}</span>
                                                </p>
                                            </span>
                                        </Grid>
                                    </Grid>
                            )
                            )}
                        </div>
                    ))}
                </Grid>
          

        );
    }

    function getRisksContentUI(row: IJRARiskData)  {
        return (<Grid container direction="column" style={{ flexWrap: "unset" }}>
                {row.items.map((item: IJRARiskItemData, itemIndex: number) => (
                    <div key={Utility.createUniqueId(item,itemIndex)}>
                        <Grid container className='questions-header' >
                            <Grid item xs={12} sm={10}>
                                <Typography >
                                    {item.header}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography className='header-col2'>
                                    Additional Risk
                                </Typography>
                            </Grid>
                        </Grid>
                        {item.items.map((_row: IJRARiskHeaderItemData, index: number) => (
                                <Grid key={Utility.createUniqueId(index,_row)} container className={index + 1 < item?.items?.length ? 'body-wrap' : ''}>
                                    <Grid item xs={12} sm={10}>
                                        <Grid container className='question-body'>
                                            <Typography className='question-label'>
                                                {(index + 1) + '.'}&nbsp;{_row.dimension}
                                            </Typography>
                                            <Grid container>
                                                <Typography className='question-label'>
                                                    <span className='comment-label'>Answer:</span><span className='comment-value'>{_row.answer}</span>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={2} className='additional-risk-block '>
                                        <div className='risk-btn'>
                                            <span className={'btn ' + _row.risk?.toLowerCase()}><span className='risk-value'>{_row.risk}</span></span>
                                        </div>

                                    </Grid>
                                </Grid>
                        )
                        )}
                    </div>
                ))}
            </Grid>
            );
            
    }

    function getAccordionSubHeading(title: string)  {
        switch (title) {
            case "Design":
            case "Prepare":
                return (<span className='non-compliant-heading'>Non Compliant Answers</span>);
            default: return (<span></span>);
        }
    }

    function getItemCount(count: number)  {
        return (<span className='heading-item-count'><span className='count-value'>{count}</span></span>);
    }

    function getMetroStopsHeaderUI(row: IJRAMetroStopsData)  {
        return (
                <span className='accrodian-header'><b>{row.title} -</b>{getAccordionSubHeading(row.title)}{getItemCount(row.count)}</span>
        );
    }

    function getRiskHeaderUI(row: IJRARiskData)  {
        return (
                <span className='accrodian-header'><b>{row.title}</b>{getItemCount(row.count)}</span>
        );
    }

}

