import React from "react";
import { IPRItemData } from "Model/ServerResponse";
import { IKeyValue } from "Model/UI";
import "./PRItemsShippingDetails.scss";
import { KeyValueList } from "Components2/KeyValueList/KeyValueList";


export interface IPRItemsShippingDetailsProp {
    prItemData: IPRItemData;
}

export function PRItemsShippingDetails(props: IPRItemsShippingDetailsProp){
    return (
        <>
            <div className="shipping-details-header">{getHeaderUI()}
                <div className="shipping-details-content">{getContentUI()}</div>
            </div>
        </>
    );

    function getHeaderUI() {
        return (
            <>
                <div>Shipping Details</div>
            </>
        );
    }

    function getContentUI() {
        let _data = getKeyValueListData();
        return (
            <KeyValueList data={_data} />
        );
    }
    function getKeyValueListData() {
        let dataArray: IKeyValue[] = [
            { _key: "Ship To (Plant)", value: props.prItemData.supplierPartNumber, seperator: ':' },
            { _key: "Need By", value: props.prItemData.estimateDeliveryDate, seperator: ':' }
        ];
        return dataArray;
    }
}