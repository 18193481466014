import React from "react";
import { ITimeSheet, ITimeSheetTab } from "../../../Model/ServerResponse";
import { ITabData } from "../../../Model/UI/ITabData";
import { DetailTabsComponent } from "../../DetailTabsComponent/DetailTabsComponent";
import { TimeSheetDetails } from "../../TimeSheet/TimeSheetDetails/TimeSheetDetails";
import { TIMESHEET_DETAILS_TAB_ID } from "../TimeSheetUtil";

export interface ITimeSheetTabsProp {
  timeSheet: ITimeSheet;
  sendSelectedLeaves: (data: string[]) => void
}

export function TimeSheetTabs(props: ITimeSheetTabsProp) {
  const handleSelectedLeaveItems = (data: string[]) => {
    props.sendSelectedLeaves(data)
  };

  function getTabsUI()  {
    const data = getTabsData(props.timeSheet.tabs);
    return (
      <DetailTabsComponent tabs={data}></DetailTabsComponent>
    );
  }

  function getTabsData(tabs: ITimeSheetTab[]): ITabData[] {
    let tdata: ITabData[] = [];

    tabs.forEach((tab: ITimeSheetTab) => {

      let tabInfo: ITabData = {
        tabId: "",
        title: tab.title,
        imageSrc: "",
        count: tab.data.length,
      };

      //By id
      if (tab.tabId === TIMESHEET_DETAILS_TAB_ID) {
        tabInfo.tabId = TIMESHEET_DETAILS_TAB_ID;
        tabInfo.imageSrc = "./DetailTab/details_icn.svg";
        tabInfo.getTabUI = ItemsUI;
      }
      tdata.push(tabInfo);
    });
    return tdata;
  }

  function ItemsUI()  {
    let timeSheetData = props.timeSheet.tabs.find(td => td.tabId === TIMESHEET_DETAILS_TAB_ID)
    return (
      <TimeSheetDetails timeSheetDetails={timeSheetData} sendSelectedLeaves={handleSelectedLeaveItems} ></TimeSheetDetails>
    )
  }

  return getTabsUI();
}


