import { LinearProgress } from "@material-ui/core";
import { Message1 } from "Components2/MessageTemplates/Message1/Message1";
import React from "react";
import { useSelector } from "react-redux";
import {
  SplashErrorSelector,
  SplashProgressOpenSelector,
  SplashProgressSelector,
} from "Store/Common/CommonSlice";
import "./AppSplashScreen.scss";

export function AppSplashScreen(_props: any) {
  let splashProgress = useSelector(SplashProgressSelector);
  let splashProgressOpen = useSelector(SplashProgressOpenSelector);
  let splashProgressError = useSelector(SplashErrorSelector);

  return getUI();

  function getUI() {
    if (splashProgressOpen) {
      return (
        <div className="app-splash-screen">
          <img
            alt="logo"
            src={"./AppLoader/Test_Logo_SLB_Decoy.svg"}
            className="logo-image"
          />
          <div className="row-1">
            <h2 style={{ color: "white" }}>Approve Buddy</h2>
            {/* <img className="AB-logo" src={"./AppBar/AB_Splash_Screen_Logo.svg"}></img> */}
          </div>

          <div className="row-2">{loaderUI()}</div>
        </div>
      );
    } else {
      return <></>;
    }
  }

  function loaderUI()   {
    if (splashProgressError) {
      return errorUI();
    }

    return (
      <>
        <p className="loading-message">
          Please wait while we compile your approval systems.
        </p>
        <div className="linear-progress-container">
          <LinearProgress
            data-testid="linear-progress"
            className="linear-progress"
            classes={{
              colorPrimary: "barColor1",
              barColorPrimary: "barColor2",
            }}
            variant="buffer"
            value={splashProgress}
            valueBuffer={0}
          />
          <span className="percentage">{splashProgress + "%"}</span>
        </div>
      </>
    );
  }

  function errorUI()   {
    if (splashProgressError) {
      return (
        <div className="message-container">
          <Message1
            title={splashProgressError.message}
            subTitle={splashProgressError.description}
          ></Message1>
        </div>
      );
    }
    return <></>;
  }
}
