import { IFailedTakeAction, ISystem } from "Model/ServerResponse";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  DeleteApprovals,
  PostDeleteApprovals,
  reCalculateSelectedApproval,
  SelectedApprovalSelector,
  setIsTabletView,
  setSelectedApproval,
} from "Store/Watershed/WatershedSlice";
import { ListDetailsView } from "../../ListDetailsView/ListDetailsView";
import { TakeActionFailedModal } from "../../Modals/TakeActionFailedModal/TakeActionFailedModal";
import { useAppDispatch } from "Store/store";
import { WatershedActions } from "../WatershedActions/WatershedActions";
import { WatershedListView } from "../WatershedListView/WatershedListView";
import { IWatershed } from "Model/ServerResponse/IWatershed";
import { WatershedDetailsCard } from "../WatershedDetailsCard/WatershedDetailsCard";

export interface IWatershedPageProp {
}

export function WatershedPage(props: IWatershedPageProp) {
  let appDispatch = useAppDispatch();

  let selectedApproval = useSelector(SelectedApprovalSelector);

  let [takeActionFailedModalOpen, setTakeActionFailedModalOpen] =
    useState<boolean>(false);
  let [failedTakeActions, setFailedTakeActions] = useState<IFailedTakeAction[]>(
    []
  );

  return (
    <div>
      {takeActionFailedModalOpen && (
        <TakeActionFailedModal
          open={takeActionFailedModalOpen}
          failedActions={failedTakeActions}
          onOk={(e: any) => setTakeActionFailedModalOpen(false)}
        />
      )}
      <ListDetailsView
        selectedApproval={selectedApproval}
        onBackClick={handleBackClick}
        onWindowSizeChange={handleWindowSizeChange}
        listView={listUI}
        detailsView={detailsUI}
      />
    </div>
  );

  function listUI() {
    return (
      <>
        <WatershedListView getActionsUI={actionsUI} />
      </>
    );
  }

  function detailsUI() {
    return (
      <>
        {<WatershedDetailsCard getActionsUI={actionsUI}></WatershedDetailsCard>}
      </>
    );
  }

  function actionsUI(_isMulti: boolean, _approvals: IWatershed[]) {
    return (
      <>
        <WatershedActions
          isMulti={_isMulti}
          count={_approvals.length}
          onApprove={handleOnApprove}
          onReject={handleOnReject}
        />
      </>
    );
  }

  function handleBackClick(e: any) {
    appDispatch(setSelectedApproval(null));
  }

  function handleWindowSizeChange(e: any, isTabletView: boolean) {
    appDispatch(setIsTabletView(isTabletView));
    appDispatch(reCalculateSelectedApproval());
  }

  function handleOnApprove(e: any, isMulti: boolean, comment: string) {
    takeAction(true, isMulti, comment);
  }

  function handleOnReject(e: any, isMulti: boolean, comment: string) {
    takeAction(false, isMulti, comment);
  }

  async function takeAction(
    isApprove: boolean,
    isMulti: boolean,
    comment: string
  ): Promise<void> {
    let result = (
      await appDispatch(
        PostDeleteApprovals({
          isApprove: isApprove,
          isMulti: isMulti,
          comment: comment,
          getRequestBody: getTakeActionRequestBody,
        })
      )
    ).payload as {
      passedApprovals: any[];
      failedTakeActions: IFailedTakeAction[];
    };
    if (result.failedTakeActions.length > 0) {
      //Some approvals failed
      setTakeActionFailedModalOpen(true);
      setFailedTakeActions(result.failedTakeActions);
    }

    //Remove cards & subtract count for passed
    appDispatch(DeleteApprovals(result.passedApprovals));
  }

  function getTakeActionRequestBody(_approval: ISystem, _comment: string): any {
    let _watershed = _approval as IWatershed;
    let arr = {
      approvalId: _watershed.id?.toString(),
      comment: _comment,
    };
    return arr;
  }
}
