import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IServerResponseError } from "Model/ServerResponse";
import { IAppState } from "Store/store";

export interface ICommonState {
    screenLoader: boolean;
    sidebarOpen: boolean;
    splashProgressOpen: boolean;
    splashProgress: number;
    splashError: IServerResponseError | null;
}

const initialState: ICommonState = {
    screenLoader: false,
    sidebarOpen: true,
    splashProgressOpen: false,
    splashProgress: 0,
    splashError: null
}

export const SetScreenLoader = createAsyncThunk("CommonStateSlice/setScreenLoader", async (isLoading: boolean, thunkApi): Promise<void> => {
    let dispatch = thunkApi.dispatch;
    dispatch(setScreenLoader(isLoading));
});


export const SetSplashProgressOpen = createAsyncThunk("CommonStateSlice/SetSplashProgressOpen", async (open: boolean, thunkApi): Promise<void> => {
    let dispatch = thunkApi.dispatch;
    let selfActions = CommonStateSlice.actions;
    const progressOpen: boolean = open ? open : true;
    dispatch(selfActions.setSplashProgressOpen(progressOpen));
});

// Use this method in case of deep link/ passing id through url
export const SetSplashProgressBar = createAsyncThunk("CommonStateSlice/SetSplashProgressBar", async (_open: boolean, thunkApi): Promise<void> => {
  let dispatch = thunkApi.dispatch;
  let selfActions = CommonStateSlice.actions;  
  dispatch(selfActions.setSplashProgress(50));
  setTimeout(() => {
      dispatch(selfActions.setSplashProgress(100));
    }, 500);
  setTimeout(() => {
      dispatch(selfActions.setSplashProgressOpen(false));
    }, 500);
});

export const SetSplashProgress = createAsyncThunk("CommonStateSlice/setSplashProgress", async ({ taskKey, error }: { taskKey: string, error: IServerResponseError | null }, thunkApi): Promise<void> => {
  let dispatch = thunkApi.dispatch;
  let selfActions = CommonStateSlice.actions;
  let currentApprovalOperationOpen = window.location.hash.slice(2);

  //Set splash progress
  if (taskKey.toLowerCase() === "userprofile") {
    dispatch(selfActions.setSplashProgress(50));
  } else if (
    currentApprovalOperationOpen.toLowerCase().includes(taskKey.toLowerCase())
  ) {
    dispatch(selfActions.setSplashProgress(100));
    setTimeout(() => {
      dispatch(selfActions.setSplashProgressOpen(false));
    }, 500);
  }

  //Set splash error
  dispatch(selfActions.setSplashError(error));
});


const CommonStateSlice = createSlice({
    name: "CommonState",
    initialState: initialState,
    reducers: {
        setScreenLoader(state: ICommonState,
            action: PayloadAction<boolean>) {
            state.screenLoader = action.payload;
        },
        toggleSidebarOpen(state: ICommonState) {
            state.sidebarOpen = !state.sidebarOpen;
        },
        setSplashProgressOpen(state: ICommonState,
            action: PayloadAction<boolean>) {
            state.splashProgressOpen = action.payload;
        },
        setSplashProgress(state: ICommonState,
            action: PayloadAction<number>) {
            state.splashProgress = action.payload;
        },
        setSplashError(state: ICommonState,
            action: PayloadAction<IServerResponseError|null>) {
            state.splashError = action.payload;
        }
    }
});

//Action
export const { setScreenLoader, toggleSidebarOpen } = CommonStateSlice.actions;

//Selector
export const CommonStateSelector = (state: IAppState) => state.Common;
export const ScreenLoaderSelector = createSelector(
    CommonStateSelector,
    (details) => details.screenLoader
);
export const SidebarOpenSelector = createSelector(
    CommonStateSelector,
    (details) => details.sidebarOpen
);
export const SplashProgressOpenSelector = createSelector(
    CommonStateSelector,
    (details) => details.splashProgressOpen
)
export const SplashProgressSelector = createSelector(
    CommonStateSelector,
    (details) => details.splashProgress
);
export const SplashErrorSelector = createSelector(
    CommonStateSelector,
    (details) => details.splashError
);

export const CommonStateReducer = CommonStateSlice.reducer;
