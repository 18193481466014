import React from "react";
import { ITableDataMapping } from "../../../Model/UI";
import { IPOTab } from "../../../Model/ServerResponse";
import { TableComponent } from "../../TableComponent/TableComponent";
import { TableCell, TableRow } from "@material-ui/core";
import Utility from "../../../common/utilities";
import "./POOptimusApprovers.scss";

export interface IPOOptimusApproversProp {
  poTabData: IPOTab | undefined;
}

export function POOptimusApprovers(
  props: IPOOptimusApproversProp
) {
  let tableHeaderMapping: ITableDataMapping[] = [
    {
      id: "approvalLevel",
      label: "Level/Code",
    },
    {
      id: "approverName",
      label: "Approver",
    },
    {
      id: "approvalStatus",
      label: "Status",
    },
  ];

  return <div data-testid="po-approvers-tab" className="po-approvers-tab">{tabUI()}</div>;

  function tabUI() {
    if (props.poTabData && !Utility.isArrayEmpty(props.poTabData.data)) {
      return (
        <TableComponent
          tableData={props.poTabData.data}
          mappingTableData={tableHeaderMapping}
          getTableRow={getTableApproversRow}
        ></TableComponent>
      );
    } else {
      return <div className="no-approvers">No Approvers</div>;
    }
  }

  function getTableApproversRow(index: number, row: any) {
    return (
      <>
        <TableRow className="spacing"></TableRow>
        <TableRow className="main-row" key={index}>
          <TableCell className="approval-level">{row.approvalLevel}</TableCell>
          <TableCell className="approver-name">{row.approverName}</TableCell>
          <TableCell  className="approval-status">
            <span data-testid="approver-status-cell" className={getApproverStatusStyle(row.approvalStatus)}>
              {row.approvalStatus}
            </span>
          </TableCell>
        </TableRow>
      </>
    );
  }

  function getApproverStatusStyle(status: string): string {
    switch (status.toLowerCase()) {
      case "approved":
        return "approved";
      case "rejected":
        return "rejected";
      case "pending":
        return "pending";
      case "forwarded":
        return "forwarded";
      default:
        return "";
    }
  }
}
