import React from "react";
import { IPOCommentData, IPOTab } from "../../../Model/ServerResponse";
import { CommentsTemplate } from "../../CommentsTemplate/CommentsTemplate";
import { IComment } from "../../../Model/UI/IComment";
import "./POComments.scss";
import Utility from "../../../common/utilities";

export interface IPOCommentsProp {
    poTabData: IPOTab | undefined;
}

export function POComments(props: IPOCommentsProp) {
    let commentDataModel: IComment[] = [];

    return (
        <div className="po-comments-tab">
            {tabUI()}
        </div>
    );

    function tabUI(){
        if (props.poTabData && !Utility.isArrayEmpty(props.poTabData.data)) {
            props.poTabData.data.forEach((cd: any) => {
                let dateItem = cd as IPOCommentData;
                commentDataModel.push({ label1: dateItem.date, label2: dateItem.commentBy, label3: dateItem.comment, label4: dateItem.title });
            })

            return (<CommentsTemplate commentData={commentDataModel} ></CommentsTemplate>);
        }
        else {
            return (
                <div className="no-comments">
                    No Comments
                </div>
            );
        }
    }
}