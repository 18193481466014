import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Accordian } from 'Components2/Accordian/Accordian';
import { IJRA, IJRATabData } from 'Model/ServerResponse';
import React from 'react';
import './JRAComments.scss'
import Utility from 'common/utilities';


export interface JRACommentsProp {
    jraData: IJRA
}
export function JRAComments(props: JRACommentsProp)  {
    const commentData: IJRATabData | undefined = props.jraData.tabs.find(td => td.tabId === 'JRA-Details')?.data;
    return (
            <div data-testid="jra-comments" className='jra-comments'>
                <Accordian headerContent={getHeaderUI()} content={getContentUI()} />
            </div>
    )

    function getContentUI()  {
        const colHeaders = GetcommentsColumns();

        if (!commentData) {
            return <></>;
        }

        return (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow className='headerRow'>
                                {colHeaders.map((column: any, index: number) => (
                                    <TableCell className='headerTextCell'
                                        key={Utility.createUniqueId(index,column)}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {commentData.comments.map((row: any, index: number) => (
                                <TableRow key={Utility.createUniqueId(index,row)}>
                                    {colHeaders.map((column: any, indexColumn: number) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                className='valueTextCell'
                                                key={Utility.createUniqueId(indexColumn,column)}
                                            >
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
        );
    }


    function GetcommentsColumns() {
        return [
            {
                label: "Date & Time",
                id: "date",
            },
            {
                label: "Status",
                id: "title",
            },
            {
                label: "Name",
                id: "commentBy",
            },
            {
                label: "Comments",
                id: "comment",
            },
        ];
    }

    function getItemCount(count: number)  {
        return (<span className='heading-item-count'><span className='count-value'>{count}</span></span>);
    }

    function getHeaderUI()  {
        if (!commentData) {
            return <></>;
        }

        return (
                <span className='commentHeader'><b>Requester Comments {getItemCount(commentData.comments?.length)}</b></span>
        );
    }
}

