import { IDLPLoadFactor, IDLPLoadFactorDetail } from "Model/ServerResponse";
import "./DLPLoadFactorDetails.scss";
import React from "react";
import Utility from "common/utilities";
import { Chip } from "@material-ui/core";

export interface IDLPLoadFactorDetailsProp {
  dlpLoadFactorDetail: IDLPLoadFactor | undefined;
}

export function DLPLoadFactorDetails(
  props: IDLPLoadFactorDetailsProp
)  {
  return (
    <div data-testid="dlp-load-factor-details" className="dlploader-container">
      {getHeaderUI()}
      {getContentUI()}
    </div>
  );
  function getHeaderUI() {
    return (
      <div className="main-container">
        <div className="title-container">
          <div className="dlp-section-title">
            <b>{props.dlpLoadFactorDetail?.title}</b>
            <div className="spacer"></div>
            <div className="col-1">
              <Chip
                className={Utility.mergeCssClassNames([
                  "chip-1",
                  getChipStyle(props.dlpLoadFactorDetail?.risk),
                ])}
                label={Utility.stringToPascalCase(
                  props.dlpLoadFactorDetail?.risk
                )}
              ></Chip>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getContentUI() {
    const loadfactorDetail: IDLPLoadFactorDetail = props.dlpLoadFactorDetail
      ?.loadFactorDetail as IDLPLoadFactorDetail;
    return (
      <div className="subtitle-container">
        <div className="key-value-list ">
          <div className="key-value key-value">
            <p className="key">{loadfactorDetail.title}</p>
            <p className="value load-Value">
              <strong>
                {loadfactorDetail.description
                  ? loadfactorDetail.description + "%"
                  : ""}
              </strong>
            </p>
          </div>
        </div>
      </div>
    );
  }

  function getChipStyle(chipText: string | undefined): string {
    let result = "";

    switch (chipText?.toLowerCase()) {
      case "high":
        result = "chip-1-high";
        break;
      case "medium":
        result = "chip-1-medium";
        break;
      case "low":
        result = "chip-1-low";
        break;
    }
    return result;
  }
}
