import React from "react";
import { IPO, IPOTab } from "../../../Model/ServerResponse";
import { ITabData } from "../../../Model/UI";
import { DetailTabsComponent } from "../../DetailTabsComponent/DetailTabsComponent";
import { POItems } from "../../PO/POItems/POItems";
import { POApprovers } from "../../PO/POApprovers/POApprovers";
import { POAttachment } from "../../PO/POAttachment/POAttachment";

import { PO_ITEMS_TAB_ID, PO_APPROVERS_TAB_ID, PO_ATTACHMENTS_TAB_ID, PO_COMMENTS_TAB_ID } from "../../PO/POUtil";
import { POOptimusItems } from "../POOptimusItems/POOptimusItems";
import { POOptimusApprovers } from "../POOptimusApprovers/POOptimusApprovers";
import { POComments } from "../POComments/POComments";

export interface IPOTabsProp {
    po: IPO;
}

export function POTabs(props: IPOTabsProp) {
    function getTabsUI() {
        const data = getTabsData(props.po.tabs);
        return (
            <DetailTabsComponent tabs={data}></DetailTabsComponent>
        );
    }

    function getTabsData(tabs: IPOTab[]): ITabData[] {
        let tdata: ITabData[] = [];

        tabs.forEach((tab: IPOTab) => {
            let tabInfo: ITabData = {
                tabId: "",
                title: tab.title,
                imageSrc: "",
                count: tab.count,
            };

            switch (tab.tabId) {
                case PO_ITEMS_TAB_ID:
                    tabInfo.tabId = PO_ITEMS_TAB_ID; tabInfo.imageSrc = "./DetailTab/details_icn.svg"; tabInfo.getTabUI = itemsUI;
                    break;
                case PO_APPROVERS_TAB_ID:
                    tabInfo.tabId = PO_APPROVERS_TAB_ID; tabInfo.imageSrc = "./DetailTab/approvericn.svg"; tabInfo.getTabUI = approversUI;
                    break;
                case PO_ATTACHMENTS_TAB_ID:
                    tabInfo.tabId = PO_ATTACHMENTS_TAB_ID; tabInfo.imageSrc = "./DetailTab/attachment_icn.svg"; tabInfo.getTabUI = attachmentsUI;
                    break;
                case PO_COMMENTS_TAB_ID:
                    tabInfo.tabId = PO_COMMENTS_TAB_ID; tabInfo.imageSrc = "./DetailTab/comment_icn.svg"; tabInfo.getTabUI = commentsUI;
                    break;
                default:
                    break;
            }
            tdata.push(tabInfo);
        });
        return tdata;
    }

    function itemsUI(){
        return (
            <>
                {props.po?.approvalSystemId?.toLowerCase() != "po-optimus" ?
                    <POItems poData={props.po}></POItems>
                    : <POOptimusItems poData={props.po}></POOptimusItems>}
            </>
        )
    }

    function approversUI() {
        let approversData = props.po.tabs.find(td => td.tabId === PO_APPROVERS_TAB_ID)
        return (
            <>
                {props.po?.approvalSystemId?.toLowerCase() != "po-optimus" ?
                    <POApprovers poTabData={approversData}></POApprovers> :
                    <POOptimusApprovers poTabData={approversData}></POOptimusApprovers>
                }
            </>
        )
    }

    function attachmentsUI(){
        return (
            <POAttachment po={props.po}></POAttachment>
        )
    }

    function commentsUI(){
        let commentsData = props.po.tabs.find(td => td.tabId === PO_COMMENTS_TAB_ID)
        return (
            <POComments poTabData={commentsData}></POComments>
        )
    }

    return (
        <>
            {getTabsUI()}
        </>
    );

}
