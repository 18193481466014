import React from "react";
import "./EQuoteDetails.scss";
import { IEQuoteTab } from "Model/ServerResponse/IEquoteTab";
import {
  IEQuoteDetailsData,
  IEQuoteDetailsRowData,
} from "Model/ServerResponse/IEQuoteDetailsData";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import Utility from "common/utilities";

const EQuoteTableCell = withStyles((theme) => ({
  root: {
    width: "calc(100%/3)",
  },
}))(TableCell);
export interface IEQquoteDetailsProp {
  eQuoteDetailsTab: IEQuoteTab | undefined;
}

export function EQuoteDetails(props: IEQquoteDetailsProp) {
  let eQuoteDetailsTabData = props.eQuoteDetailsTab?.data as IEQuoteDetailsData;
  return (
    <>
      <div className="equote-details">
        <Table className="grey-bg-color">
          <TableHead>
            <TableRow>
              <EQuoteTableCell>
                {" "}
                <div className="top-margin-10">
                  <span className="header-details-label">Difference%</span>
                </div>
              </EQuoteTableCell>
              <EQuoteTableCell align="right">
                <div>
                  <span className="header-details-label">
                    Unit Product Cost Old
                  </span>
                  <div className="header-container-margin">
                    <span className="header-details-subheading-label">
                      RFQ ID -{" "}
                      <span className="header-details-subheading-label-value">
                        {eQuoteDetailsTabData.rfqIdOld}
                      </span>
                    </span>
                  </div>
                  <div className="header-container-margin">
                    <span className="header-details-subheading-label">
                      RFQ Date -{" "}
                      <span className="header-details-subheading-label-value">
                        {eQuoteDetailsTabData.rfqDateOld}
                      </span>
                    </span>
                  </div>
                </div>
              </EQuoteTableCell>
              <EQuoteTableCell align="right">
                <div>
                  <span className="header-details-label">
                    Unit Product Cost New
                  </span>
                  <div className="header-container-margin">
                    <span className="header-details-subheading-label">
                      RFQ ID -{" "}
                      <span className="header-details-subheading-label-value">
                        {eQuoteDetailsTabData.rfqIdNew}
                      </span>
                    </span>
                  </div>
                  <div className="header-container-margin">
                    <span className="header-details-subheading-label">
                      RFQ Date -{" "}
                      <span className="header-details-subheading-label-value">
                        {eQuoteDetailsTabData.rfqDateNew}
                      </span>
                    </span>
                  </div>
                </div>
              </EQuoteTableCell>
            </TableRow>
          </TableHead>
        </Table>
        <Table className="grey-bg-color top-margin-5">
          <TableHead>
            <TableRow>
              <EQuoteTableCell>
                {" "}
                <span className="header-details-value">
                  {eQuoteDetailsTabData.difference
                    ? eQuoteDetailsTabData.difference + " %"
                    : ""}
                </span>
              </EQuoteTableCell>
              <EQuoteTableCell align="right">
                <span className="header-details-value">
                  {eQuoteDetailsTabData.productCostOld
                    ? eQuoteDetailsTabData.productCostOld
                    : "N.A"}
                </span>
                {eQuoteDetailsTabData.productCostOld ? (
                  <span className="header-details-currency">
                    {eQuoteDetailsTabData.productCostUnit}
                  </span>
                ) : (
                  <></>
                )}
              </EQuoteTableCell>
              <EQuoteTableCell align="right">
                <span className="header-details-value">
                  {" "}
                  {eQuoteDetailsTabData.productCostNew
                    ? eQuoteDetailsTabData.productCostNew
                    : "N.A"}
                </span>
                {eQuoteDetailsTabData.productCostNew ? (
                  <span className="header-details-currency">
                    {eQuoteDetailsTabData.productCostUnit}
                  </span>
                ) : (
                  <></>
                )}
              </EQuoteTableCell>
            </TableRow>
          </TableHead>
        </Table>
        {getDetailsUI()}
      </div>
    </>
  );

  function getDetailsUI() {
    let eQuoteDetailsTabData = props.eQuoteDetailsTab
      ?.data as IEQuoteDetailsData;
    return (
      <TableContainer className="top-margin-5">
        <Table>
          <TableHead className="grey-bg-color">
            <TableRow>
              <EQuoteTableCell>Other Details</EQuoteTableCell>
              <EQuoteTableCell align="right">Old</EQuoteTableCell>
              <EQuoteTableCell align="right">New</EQuoteTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!Utility.isArrayEmpty(eQuoteDetailsTabData?.values) ? (
              eQuoteDetailsTabData?.values.map((row: IEQuoteDetailsRowData) => {
                return (
                  <TableRow key={row.label} className="row-border">
                    <TableCell>{row?.label}</TableCell>
                    <TableCell align="right">
                      <span>{row?.oldValue ? row?.oldValue : "N.A"}</span>
                      {row?.oldValue ? (
                        <span className="currency">{row?.unit}</span>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <span>{row?.newValue ? row?.newValue : "N.A"}</span>
                      {row?.newValue ? (
                        <span className="currency">{row?.unit}</span>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <div className="no-equote-details">No Details</div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
