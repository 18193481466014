import Utility from "../../../common/utilities";
import { TableComponent } from "../../TableComponent/TableComponent";
import React from "react";
import { ITableDataMapping } from "../../../Model/UI/ITableDataMapping";
import "./WatershedDetails.scss";
import { TableCell, TableRow } from "@material-ui/core";
import { IWatershed } from "Model/ServerResponse/IWatershed";
import { WATERSHED_DETAILS_TAB_ID } from "../WatershedUtil";
import { IWatershedDetailData } from "Model/ServerResponse/IWatershedDetailData";

export interface IWatershedDetailsProp {
  watershedDetails: IWatershed;
}

export function WatershedDetails(props: IWatershedDetailsProp) {
  let tableHeaderMapping: ITableDataMapping[] = [
    {
      id: "activity",
      label: "Task",
    },
    {
      id: "score",
      label: "Score",
    },
    {
      id: "date",
      label: "Completion Date",
    },
    {
      id: "documentLink",
      label: "Task Sheet",
    },
  ];

  return <div className="watershed-sheet-details">{tabUI()}</div>;

  function tabUI() {
    let _watershedDetailData = props.watershedDetails?.tabs.find(
      (td) => td.tabId === WATERSHED_DETAILS_TAB_ID
    );
    if (
      props.watershedDetails &&
      !Utility.isArrayEmpty(_watershedDetailData?.data)
    ) {
      return (
        <TableComponent
          tableData={_watershedDetailData?.data ?? []}
          mappingTableData={tableHeaderMapping}
          getTableRow={getTableTaskSheetDetailsRow}
        ></TableComponent>
      );
    } else {
      return <div className="no-watershed-sheet-details">No Details</div>;
    }
  }

  function getTableTaskSheetDetailsRow(
    index: number,
    row: IWatershedDetailData
  ) {
    return (
      <>
        <TableRow className="spacing"></TableRow>
        <TableRow className="main-row" key={row?.activity}>
          <TableCell className="table-text">{row?.activity}</TableCell>
          <TableCell className="table-text">{row?.score}</TableCell>
          <TableCell className="table-text">{row?.date}</TableCell>
          <TableCell className="table-text">
            <a
              target="_blank"
              rel="noreferrer"
              className="link"
              href={row?.documentLink}
            >
              {"View >"}
            </a>
          </TableCell>
        </TableRow>
      </>
    );
  }
}
