import React from "react";
import "./Ticker.scss";
import { UserProfileResponseSelector } from "Store/UserSystemProfile/UserSystemSlice";
import { useSelector } from "react-redux";
import VariablesJson from "../../configVariables.json";
import Marquee from "react-fast-marquee";

const Ticker = ({ textArray }) => {
  let userProfileResponse = useSelector(UserProfileResponseSelector);
  const textLength = userProfileResponse?.notification?.length || 0;
  const speed = 45 + (textLength * 0.2);  // Adjust as needed

  return (
    <>
      {userProfileResponse?.notification ? (
        <div className="ticker-box">
          <div className="spacer"></div>
          <div className="box">
            <div className="box-content">
              <Marquee pauseOnHover={true} className="span-text" speed={speed}>
                &nbsp; &nbsp; {userProfileResponse?.notification}&nbsp;-
                <span className="click-here">
                  <a href={VariablesJson.Variables.ticker.yammer} target="_blank" rel="noopener noreferrer">
                    Yammer Link
                  </a>
                </span>
                , &nbsp;if any issues Kindly create an&nbsp;
                <span className="click-here">
                  <a href={VariablesJson.Variables.ticker.esm} target="_blank" rel="noopener noreferrer">
                    ESM incident
                  </a>
                </span>
              </Marquee>
            </div>
          </div>
        </div>
      ) : (
        <></> 
      )}
    </>
  );
};

export default Ticker;
