import {} from "@material-ui/core";
import { IDefaultUIProp } from "../../../Model/UI";
import { AttachmentCell } from "../../AttachmentList/AttachmentCell";
import { IAttachmentData } from "Model/ServerResponse";
import { AttachmentModal } from "Components2/Modals/AttachmentModal/AttachmentModal";
import React, { useState, useEffect } from "react";
import "./DLPAttachment.scss";
import { IDLP } from "../../../Model/ServerResponse";
import { DLP_ATTACHMENTS_TAB_ID } from "../DLPUtil";

export interface IDLPAttachmentProp extends IDefaultUIProp {
  dlp: IDLP;
}

export function DLPAttachment(props: IDLPAttachmentProp)  {
  let attachmentsData = props.dlp.tabs.find(
    (td) => td.tabId === DLP_ATTACHMENTS_TAB_ID
  );

  let attachmentData: any = attachmentsData?.data;

  const [isAttachmentDownloadable, setIsAttachmentDownloadable] =
    useState(false);

  useEffect(() => {
    if (props.dlp.isAttachmentDownloadable) {
      setIsAttachmentDownloadable(true);
    }
  }, [props.dlp]);

  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [selectedFilename, setSelectedFilename] = useState("");
  const [selectedFileID, setSelectedFileID] = useState("");
  const handleAttachmentModalClose = () => {
    setAttachmentModalOpen(false);
  };

  function handleAttachmentClick(e: any, fileName: string, fileID: string) {
    if (isAttachmentDownloadable === true) {
      setAttachmentModalOpen(true);
      setSelectedFilename(fileName);
      setSelectedFileID(fileID);
    }
  }

  async function getAttachmentData(
    fileID: string,
    filePath: string
  ): Promise<IAttachmentData> {
    return {} as any;
  }

  function getAttachmentList()  {
    if (attachmentData?.length > 0) {
      return (
        <div data-testid="attachment-list" className="attachment-list">
          {attachmentData.map((e: any, index) => (
            <AttachmentCell
              key={e.uniqueIdentifier} // Replace 'e.uniqueIdentifier' with a unique field from your data
              onClick={handleAttachmentClick}
              fileID={e.mediaFile?.filePath}
              fileName={e.mediaFile?.fileName}
              isDownloadable={true}
            />
          ))}
        </div>
      );
    } else {
      return (
        <div data-testid="attachment-list" className="no-attachment">
          {" "}
          No Attachments{" "}
        </div>
      );
    }
  }

  return (
    <div data-testid="attachment-modal">
      <div data-testid="attachment-cell">{getAttachmentList()}</div>
      <AttachmentModal
        open={attachmentModalOpen}
        downloadfromURL={true}
        selectedFilename={selectedFilename}
        selectedFileID={selectedFileID}
        getAttachmentData={getAttachmentData}
        onClose={handleAttachmentModalClose}
      />
    </div>
  );
}
