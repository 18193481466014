import {
  IGetPendingApprovalDetails,
  IGetPendingApprovals,
  IServerResponse,
  ITakeActionRequest,
  ITakeActionResponse,
} from "Model/ServerResponse";
import { ServicesUtility } from "./servicesUtility";
import { SurveyService } from "./SurveyService";


export class ApprovalService {
  private static instance: ApprovalService;

  private constructor() { }

  public static getInstance(): ApprovalService {
    if (!this.instance) {
      this.instance = new ApprovalService();
    }

    return this.instance;
  }

  public async getPendingApprovals<T>(
    method: string,
    mockdata: boolean = false,
  ): Promise<IServerResponse<IGetPendingApprovals<T>>> {

    let token = await ServicesUtility.getGraphToken();
    token = token ? token : "";
    let url = ServicesUtility.prefixBaseUrl(method);
    let source = '';
    // Logic added to track request which is coming for PO Email link -- Start
    if (method.includes("PO")) {
      const path = window.location.href.includes('ponumber');
      if (path && this.getParmFromUrl(method, 'searchString')) {
        source = 'Email';
      }
    }

    //End
    let requestOptions = {
      method: "GET",

      headers: {
        ...ServicesUtility.getDefaultHeaders(token, mockdata, source),
      },
    };

    let result: IServerResponse<IGetPendingApprovals<T>> = {
      statusCode: 0,
      payload: null,
      errorMessage: "",
    };

    return ServicesUtility.timedRequest(
      ServicesUtility.Min5,
      url,
      requestOptions
    ).then(
      async (response) => {
        try {
          result.statusCode = response.status;
          let x = await response.json();
          result.payload = x as IGetPendingApprovals<T>;
          return result;
        } catch (error) {
          result.statusCode = response.status;
          result.errorMessage = response.statusText;
          return result;
        }
      },
      (error: Error) => {
        result.errorMessage = error.message;
        return result;
      }
    );
  }

  public async getPendingApprovalDetails<T>(
    method: string,
    mockdata: boolean = false,
  ): Promise<IServerResponse<IGetPendingApprovalDetails<T>>> {

    let token = await ServicesUtility.getGraphToken();
    token = token ? token : "";
    let url = ServicesUtility.prefixBaseUrl(method);
    let requestOptions = {
      method: "GET",
      headers: {
        ...ServicesUtility.getDefaultHeaders(token, mockdata),
      },
    };

    let result: IServerResponse<IGetPendingApprovalDetails<T>> = {
      statusCode: 0,
      payload: null,
      errorMessage: "",
    };

    return ServicesUtility.timedRequest(
      ServicesUtility.Min5,
      url,
      requestOptions
    ).then(
      async (response) => {
        try {
          result.statusCode = response.status;
          result.payload =
            (await response.json()) as IGetPendingApprovalDetails<T>;
          return result;
        } catch (error) {
          result.statusCode = response.status;
          result.errorMessage = response.statusText;
          return result;
        }
      },
      (error: Error) => {
        result.errorMessage = error.message;
        return result;
      }
    );
  }

  public async approvePendingApprovals(
    systemName: string,
    acitivityName: string,
    reqBody: ITakeActionRequest[],
    mockdata: boolean = false,
  ): Promise<IServerResponse<ITakeActionResponse>> {
    let token = await ServicesUtility.getGraphToken();
    token = token ? token : "";
    // if (systemName=="PR"){
    //   mockdata=true;
    // }

    let url = ServicesUtility.prefixBaseUrl(
      `api/approvals/${systemName}/${acitivityName}/approve`
    );
    let requestOptions = {
      method: "POST",
      headers: {
        ...ServicesUtility.getHeadersForApproval(token, mockdata),
      },
      body: JSON.stringify(reqBody),
    };

    let result: IServerResponse<ITakeActionResponse> = {
      statusCode: 0,
      payload: null,
      errorMessage: "",
    };

    return ServicesUtility.timedRequest(
      ServicesUtility.Min5,
      url,
      requestOptions
    ).then(
      async (response) => {
        try {
          result.statusCode = response.status;
          result.payload = (await response.json()) as ITakeActionResponse;
          await SurveyService.getInstance().tryShowingFeedbackPopup();
          return result;
        } catch (error) {
          console.log(error);
          result.statusCode = response.status;
          result.errorMessage = response.statusText;
          return result;
        }
      },
      (error: Error) => {
        result.errorMessage = error.message;
        return result;
      }
    );
  }
  private getParmFromUrl(url: string, parm: string) {
    const re = new RegExp(".*[?&]" + parm + "=([^&]+)(&|$)");
    const match = url.match(re);
    return (match ? match[1] : "");
  }


  public async forwardPendingApprovals(
    systemName: string,
    acitivityName: string,
    reqBody: ITakeActionRequest[],
    mockdata: boolean = false,
  ): Promise<IServerResponse<ITakeActionResponse>> {
    let token = await ServicesUtility.getGraphToken();
    token = token ? token : "";
    let url = ServicesUtility.prefixBaseUrl(
      `api/approvals/${systemName}/${acitivityName}/forward`
    );
    let requestOptions = {
      method: "POST",
      headers: {
        ...ServicesUtility.getHeadersForApproval(token, mockdata),
      },
      body: JSON.stringify(reqBody),
    };

    let result: IServerResponse<ITakeActionResponse> = {
      statusCode: 0,
      payload: null,
      errorMessage: "",
    };

    return ServicesUtility.timedRequest(
      ServicesUtility.Min5,
      url,
      requestOptions
    ).then(
      async (response) => {
        try {
          result.statusCode = response.status;
          result.payload = (await response.json()) as ITakeActionResponse;
          return result;
        } catch (error) {
          result.statusCode = response.status;
          result.errorMessage = response.statusText;
          return result;
        }
      },
      (error: Error) => {
        result.errorMessage = error.message;
        return result;
      }
    );
  }
  public async rejectPendingApprovals(
    systemName: string,
    acitivityName: string,
    reqBody: ITakeActionRequest[],
    mockdata: boolean = false
  ): Promise<IServerResponse<ITakeActionResponse>> {
    // if (systemName=="PR"){
    //   mockdata=true;
    // }
    let token = await ServicesUtility.getGraphToken();
    token = token ? token : "";
    let url = ServicesUtility.prefixBaseUrl(
      `api/approvals/${systemName}/${acitivityName}/reject`
    );
    let requestOptions = {
      method: "POST",
      headers: {
        ...ServicesUtility.getHeadersForApproval(token, mockdata),
      },
      body: JSON.stringify(reqBody),
    };

    let result: IServerResponse<ITakeActionResponse> = {
      statusCode: 0,
      payload: null,
      errorMessage: "",
    };

    return ServicesUtility.timedRequest(
      ServicesUtility.Min5,
      url,
      requestOptions
    ).then(
      async (response) => {
        try {
          result.statusCode = response.status;
          result.payload = (await response.json()) as ITakeActionResponse;
          await SurveyService.getInstance().tryShowingFeedbackPopup();
          return result;
        } catch (error) {
          result.statusCode = response.status;
          result.errorMessage = response.statusText;
          return result;
        }
      },
      (error: Error) => {
        result.errorMessage = error.message;
        return result;
      }
    );
  }
}
