import React from "react";
import { IDLPDetailData, IDLPTab } from "Model/ServerResponse";
import "./DLPDetails.scss";
import { DLPDetailsRow } from "./DLPDetailsRow/DLPDetailsRow";

export interface IDLPDetailsProp {
  dlpDetailsTab: IDLPTab | undefined;
}

export function DLPDetails(props: IDLPDetailsProp)  {
  let dlpDetailsTabData = props.dlpDetailsTab?.data as IDLPDetailData;

  return <div className="dlp-details">{getDetailsUI()}</div>;

  function getDetailsUI()  {
    return <DLPDetailsRow rowData={dlpDetailsTabData} />;
  }
}
