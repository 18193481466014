import React from "react";
import { RequestStatus } from "Model/Enums";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress
} from "@material-ui/core";
import RefreshProfileIcon from "./RefreshProfile_icn.svg";
import "./ResetProfileModal.scss";

export interface IResetProfileModalProp {
  open: boolean;
  status: RequestStatus;
  onClose?: any;
}

export function ResetProfileModal(props: IResetProfileModalProp) {

  function handleRefresh(e: any) {
    window.location.reload();
  }

  function handleClose(e: any) {
    if (props.onClose) {
      props.onClose(e);
    }
  }


  return (
      <Dialog
        onClose={props.onClose}
        className="reset-modal"
        maxWidth={"xs"}
        open={props.open}
        disableBackdropClick={true}
      >
        <div className="reset-modal-content">
          <DialogContent>{getResetProfileUI(props.status)}</DialogContent>
        </div>
      </Dialog>
  );

  function getResetProfileUI(resetProfileStatus: string) {
    switch (resetProfileStatus) {
      case RequestStatus.Loading:
        return (
          <div className="reset-loading">
            <div className="reset-loading-icon">
              <CircularProgress />
            </div>
            <div className="reset-loading-text">
              Please wait .. while we are refreshing your profile
            </div>
          </div>
        );
      case RequestStatus.Success:
        return (
          <div className="reset-success">
            <img
              className="reset-success-icon"
              src={RefreshProfileIcon}
              alt="refresh"
            />
            <div className="reset-success-text">
              Your profile refreshed successfully!
            </div>
            <DialogActions className="action-container">
              <Button className="action-btn save-btn" onClick={handleRefresh}>
                Restart App
              </Button>
            </DialogActions>
          </div>
        );
      case RequestStatus.Failed:
        return (
          <div className="reset-failed">
            <div className="reset-failed-text">Failed to reset profile</div>
            <DialogActions className="action-container">
              <Button className="action-btn save-btn" onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </div>
        );
      default:
        return "";
    }
  }
}
