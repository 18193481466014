import React from "react";
import { IPretrip } from "Model/ServerResponse";
import { IKeyValue } from "Model/UI";
import "./PreTripDetailKeyValueList.scss";
import { KeyValueList } from "Components2/KeyValueList/KeyValueList";

export interface IPreTripDetailKeyValueListProp {
    pretrip: IPretrip;
}

export function PreTripDetailKeyValueList(props: IPreTripDetailKeyValueListProp) {
    function getHeaderTableUI() {
        let _data = getKeyValueListData();
        return (

            <div className="pretrip-details-key-value">
                <KeyValueList data={_data} />
            </div>

        );
    }

    function getKeyValueListData(): IKeyValue[] {
        let dataArray: IKeyValue[] = [];
        if (props.pretrip.id) dataArray.push({ _key: "Req ID", value: props.pretrip.id, seperator: ":" });
        if (props.pretrip.type) dataArray.push({ _key: "Trip Type", value: props.pretrip.type, seperator: ":" });
        if (props.pretrip.purpose) dataArray.push({ _key: "Purpose", value: props.pretrip.purpose, seperator: ":" });
        if (props.pretrip.travelPurposeCharging) dataArray.push({ _key: "Purpose Type", value: props.pretrip.travelPurposeCharging, seperator: ":" });
        if (props.pretrip.fromDate) dataArray.push({ _key: "Start Date", value: props.pretrip.fromDate, seperator: ":" });
        if (props.pretrip.toDate) dataArray.push({ _key: "End Date", value: props.pretrip.toDate, seperator: ":" });
        if (props.pretrip.requestedBy) dataArray.push({ _key: "Requested By", value: props.pretrip.requestedBy, seperator: ":" });
        if (props.pretrip.requestType) dataArray.push({ _key: "Request Type", value: props.pretrip.requestType, seperator: ":" });

        return dataArray;
    }

    return getHeaderTableUI();
}