import React from "react";
import { Header1 } from "../../HeaderTemplates/Header1/Header1";
import { ISystemHeaderBaseProp } from "../../../Model/UI";

// It is important that we import local scss after Header/2/3 so the styles are overidden in correct way
import "./SRMHeader.scss";
import Utility from "../../../common/utilities";
import { ISRM } from "Model/ServerResponse/ISRM";

export interface ISRMHeaderData extends ISystemHeaderBaseProp {
  srm: ISRM;
}

export function SRMHeader(props: ISRMHeaderData) {
  function getHeaderUI() {
    return (
      <Header1
        label1={props.srm.approvalSystemId}
        label2={props.srm.date}
        label3={" SC Number: " + props.srm.cartNumber}
        keyValue1={{
          _key: props.srm.totalAmount,
          value: props.srm.currency,
        }}
        userDetails={{
          userId: props.srm.requestorID,
          userCardName: props.srm.requestor,
          userOrganization: props.srm.plant,
        }}
        className={Utility.mergeCssClassNames(["srm-header"])}
      ></Header1>
    );
  }
  return getHeaderUI();
}
