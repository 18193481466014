import {
    createSelector, createSlice,
  } from "@reduxjs/toolkit";
  import { RequestStatus } from "Model/Enums";
  import { IAppState } from "Store/store";
  import { getPendingApprovals1Thunk, getApprovalDetails1Thunk, postDeleteApprovals1Thunk, deleteApprovals1Thunk, systemSlice1Reducer, ISystemSlice1State } from "Store/SystemTemplate/SystemSlice1";
  
  export const questSliceInitialState: ISystemSlice1State = {
    pendingApprovalsStatus: RequestStatus.Idle,
    pendingApprovalsError: null,
    selectedApprovalDetailStatus: RequestStatus.Idle,
    selectedApprovalDetailError: null,
    supportMulti: false,
    selectedApproval: null,
    selectedApprovalDetails: null,
    multiSelectedApprovals: [],
    searchQuery: "",
    isAsc: false,
    selectedSubSystem: null,
    fromPosition: 0,
    subSystems: [],
    totalCountForFilter: 0,
    pendingApprovals: [],
    isTabletView: false,

  };
  
  const QuestSlice = createSlice({
    name: "QuestChange",
    initialState: questSliceInitialState,
    reducers: systemSlice1Reducer
  });
  
  export const GetPendingApprovals = getPendingApprovals1Thunk(questSliceInitialState, QuestSlice, "QuestChange", "QuestChange");
  
  export const GetApprovalDetails = getApprovalDetails1Thunk(QuestSlice, "QuestChange", "QuestChange");
  
  export const PostDeleteApprovals = postDeleteApprovals1Thunk(QuestSlice, "QuestChange");
  
  export const DeleteApprovals = deleteApprovals1Thunk(QuestSlice, "QuestChange", "QuestChange");
  
  //Action
  export const {
    resetSystemSliceState,
    setPendingApprovalsStatus,
    setSelectedApproval,
    setSelectedApprovalDetails,
    setMultiSelectedApprovals,
    setSearchQuery,
    setIsAsc,
    setSelectedSubSystem,
    setFromPosition,
    setSubSystems,
    setTotalCountForFilter,
    setPendingApprovals,
    addToPendingApprovals,
    reCalculateSelectedApproval,
    setIsTabletView,
  } = QuestSlice.actions;
  
  //Selector
  export const QuestSelector = (state: IAppState) => state?.QuestChange;
  export const PendingApprovalsStatusSelector = createSelector(
    QuestSelector,
    (details) => details?.pendingApprovalsStatus
  );
  export const PendingApprovalsErrorSelector = createSelector(
    QuestSelector,
    (details) => details?.pendingApprovalsError
  );
  export const SelectedApprovalDetailStatusSelector = createSelector(
    QuestSelector,
    (details) => details?.selectedApprovalDetailStatus
  );
  export const SelectedApprovalDetailErrorSelector = createSelector(
    QuestSelector,
    (details) => details?.selectedApprovalDetailError
  );
  export const SupportMultiSelector = createSelector(
    QuestSelector,
    (details) => details?.supportMulti
  );
  export const SelectedApprovalSelector = createSelector(
    QuestSelector,
    (details) => details?.selectedApproval
  );
  export const SelectedApprovalDetailsSelector = createSelector(
    QuestSelector,
    (details) => details?.selectedApprovalDetails
  );
  export const MultiSelectedApprovalsSelector = createSelector(
    QuestSelector,
    (details) => details?.multiSelectedApprovals
  );
  export const SearchQuerySelector = createSelector(
    QuestSelector,
    (details) => details?.searchQuery
  );
  export const IsAscSelector = createSelector(
    QuestSelector,
    (details) => details?.isAsc
  );
  export const SelectedSubSystemSelector = createSelector(
    QuestSelector,
    (details) => details?.selectedSubSystem
  );
  export const FromPositionSelector = createSelector(
    QuestSelector,
    (details) => details?.fromPosition
  );
  export const SubSystemsSelector = createSelector(
    QuestSelector,
    (details) => details?.subSystems
  );
  export const TotalCountForFilterSelector = createSelector(
    QuestSelector,
    (details) => details?.totalCountForFilter
  );
  export const PendingApprovalsSelector = createSelector(
    QuestSelector,
    (details) => details?.pendingApprovals
  );
  export const IsTabletViewSelector = createSelector(
    QuestSelector,
    (details) => details?.isTabletView
  );
  
  
  
  export const QuestReducer = QuestSlice.reducer;
  
  