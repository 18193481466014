import {
  createSelector, createSlice,
} from "@reduxjs/toolkit";
import { RequestStatus } from "../../Model/Enums";
import { IAppState } from "../../Store/store";
import { getPendingApprovals1Thunk, getApprovalDetails1Thunk, postDeleteApprovals1Thunk, postForwardApprovals1Thunk , deleteApprovals1Thunk, systemSlice1Reducer, ISystemSlice1State } from "../../Store/SystemTemplate/SystemSlice1";
  
export const poSliceInitialState: ISystemSlice1State = {
  pendingApprovalsStatus: RequestStatus.Idle,
  forwardApprovalStatus: RequestStatus.Idle,
  pendingApprovalsError: null,
  selectedApprovalDetailStatus: RequestStatus.Idle,
  selectedApprovalDetailError: null,
  supportMulti: false,
  selectedApproval: null,
  selectedApprovalDetails: null,
  multiSelectedApprovals: [],
  searchQuery: "",
  isAsc: false,
  selectedSubSystem: null,
  fromPosition: 0,
  subSystems: [],
  totalCountForFilter: 0,
  pendingApprovals: [],
  isTabletView: false,
  

};

  
  const POSlice = createSlice({
    name: "PO",
    initialState: poSliceInitialState,
    reducers: systemSlice1Reducer
  });
  
  export const GetPendingApprovals = getPendingApprovals1Thunk(poSliceInitialState, POSlice, "PO", "PO");
  
  export const GetApprovalDetails = getApprovalDetails1Thunk(POSlice, "PO", "PO");
  
  export const PostDeleteApprovals = postDeleteApprovals1Thunk(POSlice, "PO");
  
  export const PostForwardApprovals = postForwardApprovals1Thunk(POSlice, "PO");

  export const DeleteApprovals = deleteApprovals1Thunk(POSlice, "PO", "PO");
  
  //Action
  export const {
    resetSystemSliceState,
    setPendingApprovalsStatus,
    setForwardApprovalStatus,
    setSelectedApproval,
    setSelectedApprovalDetails,
    setMultiSelectedApprovals,
    setSearchQuery,
    setIsAsc,
    setSelectedSubSystem,
    setFromPosition,
    setSubSystems,
    setTotalCountForFilter,
    setPendingApprovals,
    addToPendingApprovals,
    reCalculateSelectedApproval,
    setIsTabletView,
  } = POSlice.actions;
  
  //Selector
  export const POSelector = (state: IAppState) => state.PO;
  export const PendingApprovalsStatusSelector = createSelector(
    POSelector,
    (details) => details?.pendingApprovalsStatus
  );
   
  export const ForwardApprovalStatusSelector = createSelector(
    POSelector,
    (details) => details?.forwardApprovalStatus
  );

  export const PendingApprovalsErrorSelector = createSelector(
    POSelector,
    (details) => details?.pendingApprovalsError
  );
  export const SelectedApprovalDetailStatusSelector = createSelector(
    POSelector,
    (details) => details?.selectedApprovalDetailStatus
  );
  export const SelectedApprovalDetailErrorSelector = createSelector(
    POSelector,
    (details) => details?.selectedApprovalDetailError
  );
  export const SupportMultiSelector = createSelector(
    POSelector,
    (details) => details?.supportMulti
  );

  export const SelectedApprovalSelector = createSelector(
    POSelector,
    (details) => details?.selectedApproval
  );
  export const SelectedApprovalDetailsSelector = createSelector(
    POSelector,
    (details) => details?.selectedApprovalDetails
  );
  export const MultiSelectedApprovalsSelector = createSelector(
    POSelector,
    (details) => details?.multiSelectedApprovals
  );
  export const SearchQuerySelector = createSelector(
    POSelector,
    (details) => details?.searchQuery
  );
  export const IsAscSelector = createSelector(
    POSelector,
    (details) => details?.isAsc
  );
  export const SelectedSubSystemSelector = createSelector(
    POSelector,
    (details) => details?.selectedSubSystem
  );
  export const FromPositionSelector = createSelector(
    POSelector,
    (details) => details?.fromPosition
  );
  export const SubSystemsSelector = createSelector(
    POSelector,
    (details) => details?.subSystems
  );
  export const TotalCountForFilterSelector = createSelector(
    POSelector,
    (details) => details?.totalCountForFilter
  );
  export const PendingApprovalsSelector = createSelector(
    POSelector,
    (details) => details?.pendingApprovals
  );
  export const IsTabletViewSelector = createSelector(
    POSelector,
    (details) => details?.isTabletView
  );
  
  export const POReducer = POSlice.reducer;
  
  