import { LoggingService } from "./LoggingService";


export class SurveyService {
  private static instance: SurveyService | null = null;
  private constructor() { }


  public static getInstance(): SurveyService {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new SurveyService();
    return this.instance;
  }

  private isEligibleForSurveyKey = "IsEligibleForSurvey";
  private surveyUrlKey = "surveyUrl"

  get isEligibleForSurvey(): boolean {
    return sessionStorage.getItem(this.isEligibleForSurveyKey) === "true" || false;
  }
  

  get surveyUrl(): string {
    return sessionStorage.getItem(this.surveyUrlKey) || "";
  }
  

  set isEligibleForSurvey(value: boolean) {
    sessionStorage.setItem(
      this.isEligibleForSurveyKey,
      value ? "true" : "false"
    );
  }

  set surveyUrl(value: string) {
    sessionStorage.setItem(this.surveyUrlKey, value);
  }

  public async tryShowingFeedbackPopup() {
    try {
      if (this.isEligibleForSurvey) {
        let url = this.surveyUrl;
        let windowObj = window.open(
          url,
          "_blank",
          "width=900,height=850,toolbar=0,menubar=0,location=0,top=10,left=25"
        );
        if (windowObj) {
          windowObj.focus();
          this.isEligibleForSurvey = false;
        }
      }
    } catch (err) {
      // Since logError returns a Promise, it should be handled properly.
      try {
        await LoggingService.getInstance().logError(
          "SurveyService : tryShowingFeedbackPopup", 
          "SurveyService", 
          JSON.stringify(err, Object.getOwnPropertyNames(err)), 
          JSON.stringify(err, Object.getOwnPropertyNames(err))
        );
      } catch (logError) {
        console.error('Error logging error:', logError);
      }
    }
  }
  
}
