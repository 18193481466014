import React from "react";
import { ITableDataMapping } from "../../../Model/UI";
import { ICartApproverData, ICartTab, } from "../../../Model/ServerResponse";
import { TableComponent } from "../../TableComponent/TableComponent";
import { TableCell, TableRow } from "@material-ui/core";
import Utility from "../../../common/utilities";
import "./SWPSApprovers.scss";

export interface ISWPSApproversProp {
    cartTabData: ICartTab | undefined;
}

export function SWPSApprovers(props: ISWPSApproversProp){

    let tableHeaderMapping: ITableDataMapping[] =
        [
            {
                id: "sequence",
                label: "Level/Code",
            },
            {
                id: "approverName",
                label: "Approver",
            },
            {
                id: "approverType",
                label: "Approver Type",
            },
            {
                id: "status",
                label: "Status",
            },
            {
                id: "approvalDate",
                label: "Action Date",
            }
        ];


    return (
        <div data-testid="swps-approvers-tab" className="swps-approvers-tab">
            {tabUI()}
        </div>
    );

    function tabUI() {
        if (props.cartTabData && !Utility.isArrayEmpty(props.cartTabData.data)) {
            return <TableComponent tableData={props.cartTabData.data} mappingTableData={tableHeaderMapping} getTableRow={getTableApproversRow}></TableComponent>
        }
        else {
            return (
                <div className="no-approvers">
                    No Approvers
                </div>
            );
        }
    }

    function getTableApproversRow(index: number, row: ICartApproverData){
        return (
            <>
                <TableRow className="spacing"></TableRow>
                <TableRow className="main-row" key={index}>
                    <TableCell className="approval-level"><strong>{row.sequence}</strong></TableCell>
                    <TableCell className="approver-name"><strong>{row.approverName}</strong></TableCell>
                    <TableCell className="job-title">{row.approverType}</TableCell>
                    <TableCell data-testid="approver-status-cell"
                        className={Utility.mergeCssClassNames([
                            getApproverStatusStyle(row.status),
                        ])}
                    >
                        {row.status}
                    </TableCell>
                    <TableCell className="action-date">{row.approvalDate}</TableCell>
                </TableRow>
            </>
        );
    }
    function getApproverStatusStyle(status: string): string {
        let result = "";
        switch (status.toLowerCase()) {
            case "approved":
                result = "approved";
                break;
            case "rejected":
                result = "rejected";
                break;
            case "pending":
                result = "pending";
                break;
            default:
                return "";
        }
        return result;
    }

}