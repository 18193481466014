import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./Accordian.scss";

export interface IAccordianProp {
  headerContent: any;
  content: any;
}

export function Accordian(props: IAccordianProp) {
  return (
    <>
      <div data-testid="accordian" className="accordian-container">
        <Accordion className="accordian">
          <AccordionSummary
            className="accordian-summary"
            expandIcon={<ExpandMoreIcon />}
          >
            <div className="header-content">{props.headerContent}</div>
          </AccordionSummary>
          <AccordionDetails>{props.content}</AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}
