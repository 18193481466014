import { Card1 } from "Components2/CardTemplates/Card1/Card1";
import { ICart } from "Model/ServerResponse";
import { ISystemCardBaseProp } from "Model/UI";
import React from "react";
import Utility from "common/utilities";
// It is important that we import local scss after Card1/2/3/etc so the styles are overidden in correct way
import "./SWPSCard.scss";

export interface ISWPSCardProp extends ISystemCardBaseProp {
  swps: ICart;
}

export function SWPSCard(props: ISWPSCardProp) {
  function getCardUI() {
    return (
      <Card1
        label1={props.swps.approvalSystemId}
        label2={props.swps.title}
        label3={props.swps.date}
        chip1={props.swps.qualifierTag ? "Over Due" : ""}
        keyValue2={{ _key: "Items", value: props.swps.items?.toString() }}
        keyValue1={{
          _key: props.swps.amount?.toString(),
          value: props.swps.currency,
        }}
        userDetails={{
          userId: props.swps.requestorID,
          userCardName: props.swps.requestor,
          userOrganization: props.swps.organization,
        }}
        supportMulti={props.supportMulti}
        multiSelectUI={props.multiSelectUI}
        className={Utility.mergeCssClassNames([
          "swps-card",
          getChipStyle(props.swps.qualifierTag),
        ])}
      ></Card1>
    );
  }

  function getChipStyle(chipText: string | null): string {
    let result = "";
    if (!chipText) {
      result = "chip1-hidden";
    }
    return result;
  }

  return getCardUI();
}
