import {
    Dialog,
    DialogContent,
    DialogContentText,
    Divider,
    IconButton,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { IDefaultUIProp } from "Model/UI";
import * as React from "react";
import "./HSEDetailPopup.scss";
import { IPretripDetailData } from "Model/ServerResponse";

export interface IHSEDetailPopupProp extends IDefaultUIProp {
    hseDetailData: IPretripDetailData | any;
    headerLabel?: string
    open: boolean;
    systemName: string;
    handleClose: (e: any) => void;
}

export function HSEDetailPopup(
    props: IHSEDetailPopupProp
){
    return (
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                className="hse-detail-modal"
            >
                <div className="title-text1-container">
                    <p className="titleText">{props.headerLabel}</p>
                    <div className="spacer"></div>
                    <IconButton onClick={(e) => props.handleClose(e)}>
                        <CancelIcon className="closeButton"></CancelIcon>
                    </IconButton>
                </div>

                <DialogContent className="hse-information-content">
                    <Divider
                        style={{ marginTop: "10px", width: "100%", marginBottom: "20px" }}
                        variant="fullWidth"
                    />

                    <DialogContentText>{getdetailsData(props.systemName)}</DialogContentText>
                </DialogContent>
            </Dialog>
      
    );

    function getdetailsData(hsetype: string){
        switch (hsetype?.toLowerCase()) {
            case "country":
                return getCountryInfoPopup();
            case "health":
                return getHealthExposureUI();
            case "hserequirement":
                return getHSERequirementPopup();
        }
        return <></>;
    }

    function getCountryInfoPopup() {
        if (!props.hseDetailData || props.hseDetailData.countryInformation === null) {
            return (
                <div className="no-approvers">No Information</div>
            );
        } else {
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: props.hseDetailData.countryInformation,
                    }}
                ></div>);
        }
    }

    function getHealthExposureUI() {
        if ( !props.hseDetailData || props.hseDetailData.healthExposureInstructions === null) {
            return (
                <div className="no-approvers">No Information</div>
            );
        } else {
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: props.hseDetailData.healthExposureInstructions,
                    }}
                ></div>);
        }
    }

    function getHSERequirementPopup() {
        if (! props.hseDetailData || props.hseDetailData.hseRequirement === null) {
            return (
                <div className="no-approvers">No Information</div>
            );
        } else {
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: props.hseDetailData.hseRequirement,
                    }}
                ></div>);
        }
    }

}
