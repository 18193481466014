import { IFailedTakeAction, ISystem } from "Model/ServerResponse";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  DeleteApprovals,
  PostDeleteApprovals,
  reCalculateSelectedApproval,
  SelectedApprovalSelector,
  setIsTabletView,
  setSelectedApproval,
} from "Store/Quest/QuestSlice";
import { ListDetailsView } from "../../ListDetailsView/ListDetailsView";
import { QuestActions } from "../QuestActions/QuestActions";
import { TakeActionFailedModal } from "../../Modals/TakeActionFailedModal/TakeActionFailedModal";
import { useAppDispatch } from "Store/store";
import { QuestListView } from "../QuestListView/QuestListView";
import { QuestDetailsCard } from "../QuestDetailsCard/QuestDetailsCard";
import "./QuestPage.scss";
import { IQuest } from "Model/ServerResponse/IQuest";
import clipboard from "clipboard-copy";
import { TakeActionReferenceModal } from "Components2/Modals/ReferenceModel/TakeActionReferenceModal";

export interface IQuestPageProp {
}

export function QuestPage(props: IQuestPageProp) {
  let appDispatch = useAppDispatch();
  const [copied, setCopied] = useState(false);
  let selectedApproval = useSelector(SelectedApprovalSelector);
  const [refrenceQuestNumber, setrefrenceQuestNumber] = React.useState(
    "" as string
  );
  let [takeActionReferenceModalOpen, setTakeActionReferenceModalOpen] =
    useState<boolean>(false);
  let [takeActionFailedModalOpen, setTakeActionFailedModalOpen] =
    useState<boolean>(false);
  let [failedTakeActions, setFailedTakeActions] = useState<IFailedTakeAction[]>(
    []
  );

  const handleCopyClick = async () => {
    try {
      await clipboard(refrenceQuestNumber);
      setCopied(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };


  return (
    <div>
      {takeActionFailedModalOpen && (
        <TakeActionFailedModal
          open={takeActionFailedModalOpen}
          failedActions={failedTakeActions}
          onOk={(e: any) => setTakeActionFailedModalOpen(false)}
        />
      )}
      {takeActionReferenceModalOpen && (
        <TakeActionReferenceModal
          open={takeActionReferenceModalOpen}
          content={referenceDetails()}
          onOk={(e: any) => setTakeActionReferenceModalOpen(false)}
        />
      )}

      <ListDetailsView
        selectedApproval={selectedApproval}
        onBackClick={handleBackClick}
        onWindowSizeChange={handleWindowSizeChange}
        listView={listUI}
        detailsView={detailsUI}
      />
    </div>
  );

  function listUI() {
    return (
      <>
        <QuestListView getActionsUI={actionsUI} />
      </>
    );
  }

  function detailsUI() {
    return (
      <>
        <QuestDetailsCard getActionsUI={actionsUI} />
      </>
    );
  }

  function actionsUI(_isMulti: boolean, _approvals: IQuest[]) {
    return (
      <>
        <QuestActions
          isMulti={_isMulti}
          count={_approvals.length}
          onApprove={handleOnApprove}
          onReject={handleOnReject}
        />
      </>
    );
  }

  function handleBackClick(e: any) {
    appDispatch(setSelectedApproval(null));
  }

  function handleWindowSizeChange(e: any, isTabletView: boolean) {
    appDispatch(setIsTabletView(isTabletView));
    appDispatch(reCalculateSelectedApproval());
  }

  function handleOnApprove(e: any, isMulti: boolean, comment: string) {
    takeAction(true, isMulti, comment);
  }

  function handleOnReject(e: any, isMulti: boolean, comment: string) {
    takeAction(false, isMulti, comment);
  }

  async function takeAction(
    isApprove: boolean,
    isMulti: boolean,
    comment: string
  ): Promise<void> {
    setrefrenceQuestNumber("");
    setCopied(false);
    let result = (
      await appDispatch(
        PostDeleteApprovals({
          isApprove: isApprove,
          isMulti: isMulti,
          comment: comment,
          getRequestBody: getTakeActionRequestBody,
        })
      )
    ).payload as {
      passedApprovals: IQuest[];
      failedTakeActions: IFailedTakeAction[];
    };
    if (result.failedTakeActions.length > 0) {
      //Some approvals failed
      setTakeActionFailedModalOpen(true);
      setFailedTakeActions(result.failedTakeActions);
    } else {
      appDispatch(DeleteApprovals(result?.passedApprovals));
    }
  }

  function getTakeActionRequestBody(_approval: ISystem, _comment: string): any {
    let _quest = _approval as IQuest;
    if (_quest.exemptionNumber) {
      setTakeActionReferenceModalOpen(true);
      setrefrenceQuestNumber(_quest.exemptionNumber);
    } else {
      setTakeActionReferenceModalOpen(false);
    }
    return {
      approvalId: _quest.id.toString(),
      comment: _comment,
      extra: {
        startDate: _quest.from,
        endDate: _quest.to,
      },
    };
  }
  function referenceDetails() {
    return (
      <div className="questReference">
        <p>
          <span>Quest Ref. No.</span>:{" "}
          <span className="questValue"> {refrenceQuestNumber}</span>
          <span
            className="copyBtn"
            title="copy to clipboard"
            onClick={handleCopyClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <mask
                id="mask0_2576_263"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="14"
                height="14"
              >
                <rect width="14" height="14" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2576_263)">
                <path
                  d="M11.0852 11.0835H4.66849C4.34765 11.0835 4.073 10.9693 3.84453 10.7408C3.61606 10.5123 3.50182 10.2377 3.50182 9.91683V1.75016C3.50182 1.42933 3.61606 1.15468 3.84453 0.926205C4.073 0.697732 4.34765 0.583496 4.66849 0.583496H8.75182L12.2518 4.0835V9.91683C12.2518 10.2377 12.1376 10.5123 11.9091 10.7408C11.6806 10.9693 11.406 11.0835 11.0852 11.0835ZM8.16849 4.66683V1.75016H4.66849V9.91683H11.0852V4.66683H8.16849ZM2.33515 13.4168C2.01432 13.4168 1.73967 13.3026 1.5112 13.0741C1.28272 12.8457 1.16849 12.571 1.16849 12.2502V4.0835H2.33515V12.2502H8.75182V13.4168H2.33515Z"
                  fill="#696969"
                />
              </g>
            </svg>
          </span>
        </p>
        {copied && <span className="copiedText">Copied!</span>}
      </div>
    );
  }
}
