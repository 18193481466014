import "@grapecity/wijmo.styles/wijmo.css";
import * as React from "react";
import * as wjcCore from "@grapecity/wijmo";
import * as wjcXlsx from "@grapecity/wijmo.xlsx";
import "./ExcelViewer.scss";
import { IAttachmentData } from "Model/ServerResponse";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { Theme, styled } from "@material-ui/core/styles";
import Utility from "common/utilities";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #dee2e6",
  marginBottom: "2rem",
  overflow: "visible!important",
  "& div.MuiTabs-scroller": {
    overflow: "visible!important",
  },
  "&.Mui-selected": {
    color: "#495057",
    backgroundColor: "red",
    borderColor: `#dee2e6 #dee2e6 #fff`,
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
});

const StyledTab = styled(Tab)<{ theme: Theme }>((theme) => ({
  textTransform: "none",
  fontWeight: "normal",
  fontSize: "15rem",
  marginRight: "1rem",
  color: "#0d6efd",
  background: "0 0",
  border: "1px solid transparent",
  borderTopLeftRadius: "0.25rem",
  borderTopRightRadius: "0.25rem",
  padding: ".5rem 1rem",
  textDecoration: "none",
  transition: `color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out`,
  "&.Mui-selected": {
    color: "#495057",
    backgroundColor: "#fff",
    borderColor: "#dee2e6 #dee2e6 #fff",
    marginBottom: "-2px",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

export interface IExcelDataProps {
  excelBlob?: any;
  excelLink?: string;
  excelName?: string;
  attachmentData: IAttachmentData | null;
}
interface IExcelDataState {
  workbook: wjcXlsx.Workbook;
  sheetIndex: number;
}

export default class ExcelViewer extends React.Component<
  IExcelDataProps,
  IExcelDataState
> {
  constructor(props: IExcelDataProps) {
    super(props);

    this.state = {
      workbook: new wjcXlsx.Workbook(),
      sheetIndex: -1,
    };
  }

  render() {
    let lists: React.ReactNode[] = [];

    if (this.state.workbook != null) {
      lists = this.state.workbook.sheets.map((sheet, index) => {
        return (
          <Tab
            label={sheet.name}
            key={Utility.createUniqueId(sheet.name, index)}
            onClick={(e) => {
              e.preventDefault();
              this._drawSheet(index);
            }}
          />
        );
      });
    }

    return (
      <div className="container-fluid">
        <div className="row"></div>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.sheetIndex}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {lists}
          </Tabs>
        </AppBar>
        <div
          data-testid="tableHost"
          className="table-main excel-table"
          id="tableHost"
        ></div>
        <br></br>
      </div>
    );
  }

  componentDidMount() {
    this._loadWorkbook();
  }

  //
  _drawSheet(sheetIndex) {
    let drawRoot = document.getElementById("tableHost");
    drawRoot!.textContent = "";
    this.setState({ sheetIndex: sheetIndex });
    this._drawWorksheet(this.state.workbook, sheetIndex, drawRoot, 200, 100);
  }

  async _loadWorkbook() {
    const blob =
      this.props.excelLink !== undefined
        ? await this.getBase64(this.props.excelLink, this.props.excelName)
        : this.props.excelBlob;
    console.log(blob);
    if (blob) {
      let workbook = new wjcXlsx.Workbook();
      workbook.loadAsync(
        blob,
        (result) => {
          console.log(result);
          this.setState({ workbook: result }, () => {
            this._drawSheet(this.state.workbook.activeWorksheet || 0);
          });
        },
        (errorResult) => {
          console.log(errorResult);
        }
      );
    }
  }

  async getBase64(filePath, filename) {
    if (filePath === null) {
      return null;
    }
    const file = await this.getFileFromUrl(filePath, filename);
    return new Promise((resolve) => {
      let baseURL: string = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result !== null) {
          baseURL = reader.result.toString();
          resolve(baseURL);
        }
      };
    });
  }

  async getFileFromUrl(url, fileName) {
    const blob = await fetch(url)
      .then((response) => {
        return response.blob();
      })
      .catch((error) => {
        throw error;
      });
    return new File([blob], fileName);
  }

  //
  _drawWorksheet(workbook, sheetIndex, rootElement, maxRows, maxColumns) {
    if (
      !workbook ||
      !workbook.sheets ||
      sheetIndex < 0 ||
      workbook.sheets.length == 0
    ) {
      return;
    }
    //
    sheetIndex = Math.min(sheetIndex, workbook.sheets.length - 1);
    //
    if (maxRows == null) {
      maxRows = 200;
    }
    //
    if (maxColumns == null) {
      maxColumns = 100;
    }

    let sheet = workbook.sheets[sheetIndex],
      defaultRowHeight = 20,
      defaultColumnWidth = 60,
      tableEl = document.createElement("table");
    tableEl.style.borderCollapse = "collapse";
    //
    let maxRowCells = 0;
    maxRowCells = this.maxRowCells(sheet, maxRowCells);

    let columns = sheet.columns || [],
      invisColCnt = columns.filter((col) => col.visible === false).length;
    //
    maxRowCells = this.maxRowCellsData(
      sheet,
      maxRowCells,
      columns,
      maxColumns,
      tableEl,
      defaultColumnWidth
    );

    this.rowData(
      maxRows,
      sheet,
      tableEl,
      columns,
      maxRowCells,
      invisColCnt,
      defaultRowHeight
    );
    rootElement.appendChild(tableEl);
  }

  private _shouldSkipRow(row) {
    return row && !row.visible;
  }

  private _createRowElement(tableEl, row) {
    let rowEl = document.createElement("tr");
    tableEl.appendChild(rowEl);
    return rowEl;
  }

  private _applyRowStyle(rowEl, row) {
    if (row && row.style) {
      this._importStyle(rowEl.style, row.style);
      if (row.height != null) {
        rowEl.style.height = row.height + "px";
      }
    }
  }
  private _setRowHeight(rowEl, row, defaultRowHeight) {
    if (!rowEl.style.height) {
      rowEl.style.height = defaultRowHeight + "px";
    }
  }
  private rowData(
    maxRows: any,
    sheet: any,
    tableEl: HTMLTableElement,
    columns: any,
    maxRowCells: number,
    invisColCnt: any,
    defaultRowHeight: number
  ) {
    let rowCount = Math.min(maxRows, sheet.rows.length);
    for (let r = 0; sheet.rows && r < rowCount; r++) {
      let row = sheet.rows[r],
        cellsCnt = 0; // including colspan

      if (this._shouldSkipRow(row)) {
        continue;
      }
      let rowEl = this._createRowElement(tableEl, row);
      this._applyRowStyle(rowEl, row);
      //
      if (row) {
        //
        cellsCnt = this._populateRowCells(row, columns, cellsCnt, rowEl);
      }
      let padCellsCount = maxRowCells - cellsCnt - invisColCnt;
      for (let i = 0; i < padCellsCount; i++) {
        rowEl.appendChild(document.createElement("td"));
      }
      //
      this._setRowHeight(rowEl, row, defaultRowHeight);
    }
  }

  private shouldSkipCell(col: any): boolean {
    return col && !col.visible;
  }

  private createCellElement(
    rowEl: HTMLTableRowElement,
    cell: any
  ): HTMLTableCellElement {
    const cellEl = document.createElement("td");
    rowEl.appendChild(cellEl);
    return cellEl;
  }

  private processCellValue(cell: any, cellEl: HTMLTableCellElement): void {
    if (!cell) {
      return;
    }

    this._importStyle(cellEl.style, cell.style);

    const value = cell.value;
    if (!(value == null || value !== value)) {
      this.processFormattedValue(cell, cellEl, value);
    }

    if (cell.note) {
      this.addNoteStyles(cellEl, cell.note);
    }
  }

  private addNoteStyles(cellEl: HTMLTableCellElement, note: any): void {
    wjcCore.addClass(cellEl, "cell-note");
    cellEl.title = note.text;
  }

  private processFormattedValue(
    cell: any,
    cellEl: HTMLTableCellElement,
    value: any
  ): void {
    if (wjcCore.isString(value) && value.charAt(0) == "'") {
      value = value.substring(1);
    }
    const netFormat =
      cell.style && cell.style.format
        ? wjcXlsx.Workbook.fromXlsxFormat(cell.style.format)[0]
        : "";

    const fmtValue = netFormat
      ? wjcCore.Globalize.format(value, netFormat)
      : value;
    cellEl.innerHTML = wjcCore.escapeHtml(fmtValue);
  }

  private handleColSpan(
    columns: any,
    c: number,
    cell: any,
    cellEl: HTMLTableCellElement,
    cellsCnt: number
  ): void {
    if (cell && cell.colSpan && cell.colSpan > 1) {
      cellEl.colSpan = this._getVisColSpan(columns, c, cell.colSpan);
    }
  }
  private _populateRowCells(
    row: any,
    columns: any,
    cellsCnt: number,
    rowEl: HTMLTableRowElement
  ) {
    for (let c = 0; row.cells && c < row.cells.length; c++) {
      const cell = row.cells[c];
      const col = columns[c];

      if (this.shouldSkipCell(col)) {
        continue;
      }

      //
      cellsCnt++;
      const cellEl = this.createCellElement(rowEl, cell);
      this.processCellValue(cell, cellEl);
      this.handleColSpan(columns, c, cell, cellEl, cellsCnt);
    }
    return cellsCnt;
  }

  private maxRowCellsData(
    sheet: any,
    maxRowCells: number,
    columns: any,
    maxColumns: any,
    tableEl: HTMLTableElement,
    defaultColumnWidth: number
  ) {
    if (sheet.columns) {
      maxRowCells = Math.min(Math.max(maxRowCells, columns.length), maxColumns);
      //
      for (let c = 0; c < maxRowCells; c++) {
        let col = columns[c];
        //
        if (col && !col.visible) {
          continue;
        }
        //
        let colEl = document.createElement("col");
        tableEl.appendChild(colEl);
        let colWidth = defaultColumnWidth + "px";
        if (col) {
          this._importStyle(colEl.style, col.style);
          if (col.autoWidth) {
            colWidth = "";
          } else if (col.width != null) {
            colWidth = col.width + "px";
          }
        }
        colEl.style.width = colWidth;
      }
    }
    return maxRowCells;
  }

  private maxRowCells(sheet: any, maxRowCells: number) {
    for (let r = 0; sheet.rows && r < sheet.rows.length; r++) {
      if (sheet.rows[r] && sheet.rows[r].cells) {
        maxRowCells = Math.max(maxRowCells, sheet.rows[r].cells.length);
      }
    }
    return maxRowCells;
  }

  //
  _getVisColSpan(columns, startFrom, colSpan) {
    let res = colSpan;
    //
    for (
      let i = startFrom;
      i < columns.length && i < startFrom + colSpan;
      i++
    ) {
      let col = columns[i];
      if (col && !col.visible) {
        res--;
      }
    }
    //
    return res;
  }

  _importStyle(cssStyle, xlsxStyle) {
    if (!xlsxStyle) {
      return;
    }

    this._applyFillStyle(cssStyle, xlsxStyle.fill);
    this._applyHorizontalAlignment(cssStyle, xlsxStyle.hAlign);
    this._applyFontStyles(cssStyle, xlsxStyle.font);
  }

  _applyFillStyle(cssStyle, fillStyle) {
    if (fillStyle && fillStyle.color) {
      cssStyle.backgroundColor = fillStyle.color;
    }
  }

  _applyHorizontalAlignment(cssStyle, hAlign) {
    if (hAlign && hAlign !== wjcXlsx.HAlign.Fill) {
      cssStyle.textAlign = wjcXlsx.HAlign[hAlign].toLowerCase();
    }
  }

  _applyFontStyles(cssStyle, font) {
    if (font) {
      this._applyFontFamily(cssStyle, font.family);
      this._applyFontWeight(cssStyle, font.bold);
      this._applyFontStyle(cssStyle, font.italic);
      this._applyFontSize(cssStyle, font.size);
      this._applyTextDecoration(cssStyle, font.underline);
      this._applyFontColor(cssStyle, font.color);
    }
  }

  _applyFontFamily(cssStyle, fontFamily) {
    if (fontFamily) {
      cssStyle.fontFamily = fontFamily;
    }
  }

  _applyFontWeight(cssStyle, isBold) {
    if (isBold) {
      cssStyle.fontWeight = "bold";
    }
  }

  _applyFontStyle(cssStyle, isItalic) {
    if (isItalic) {
      cssStyle.fontStyle = "italic";
    }
  }

  _applyFontSize(cssStyle, fontSize) {
    if (fontSize != null) {
      cssStyle.fontSize = fontSize + "px";
    }
  }

  _applyTextDecoration(cssStyle, isUnderline) {
    if (isUnderline) {
      cssStyle.textDecoration = "underline";
    }
  }

  _applyFontColor(cssStyle, fontColor) {
    if (fontColor) {
      cssStyle.color = fontColor;
    }
  }
}
