import React from "react";
import { ITableDataMapping } from "../../../Model/UI";
import { TableComponent } from "../../TableComponent/TableComponent";
import { TableCell, TableRow } from "@material-ui/core";
import "./SRMApprovers.scss";
import { ISRMTab } from "Model/ServerResponse/ISRMtab";

interface ISRMApproversProps {
  srmTabData: ISRMTab | undefined;
}

const SRMApprovers: React.FC<ISRMApproversProps> = ({ srmTabData }) => {
  const tableHeaderMapping: ITableDataMapping[] = [
    {
      id: "approverType",
      label: "Level/Code",
    },
    {
      id: "approverName",
      label: "Approver",
    },
    {
      id: "requestDate",
      label: "Received On",
    },
    {
      id: "actionDate",
      label: "Processed On",
    },
  ];

  return (
    <div data-testid="srm-approvers-tab" className="srm-approvers-tab">
     {tabUI({srmTabData})}
    </div>
  );

  function tabUI({ srmTabData }: { srmTabData: ISRMTab | undefined }) {
    if (srmTabData && srmTabData.data && srmTabData.data.length > 0) {
      return (
        <TableComponent
          tableData={srmTabData.data}
          mappingTableData={tableHeaderMapping}
          getTableRow={getTableApproversRow}
        />
      );
    } else {
      return <div className="no-approvers">No Approvers</div>;
    }
  }

  function getTableApproversRow(index: number, row: any) {
    return (
      <React.Fragment key={index}>
        <TableRow className="spacing"></TableRow>
        <TableRow className="main-row">
          <TableCell className="approval-level">{row.level}</TableCell>
          <TableCell className="approver-name">{row.approverName}</TableCell>
          <TableCell>{row.requestDate}</TableCell>
          <TableCell>{row.actionDate}</TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
};

export default SRMApprovers;
