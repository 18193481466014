import { IServerResponse, IUserDetails, IUserProfile } from "Model/ServerResponse";
import { ServicesUtility } from "Services";
import { SurveyService } from "./SurveyService";
import * as microsoftTeams from "@microsoft/teams-js";

export class UserProfileService {
    private static instance: UserProfileService;

    private constructor() {
    }

    public static getInstance(): UserProfileService {
        if (!this.instance) {
            this.instance = new UserProfileService();
        }

        return this.instance;
    }

    //get user details
    public async getUserDetails(userId: string): Promise<IServerResponse<IUserDetails>> {
        let token = await ServicesUtility.getGraphToken();
        let method = `api/userprofile/details?requestorId=${userId}`;
        let url = ServicesUtility.prefixBaseUrl(method);
        let requestOptions = {
            method: 'GET',
            headers: {
                ...ServicesUtility.getDefaultHeaders(token!)
            },
        };

        let result: IServerResponse<IUserDetails> = { statusCode: 0, payload: null, errorMessage: "" };
        return ServicesUtility.timedRequest(ServicesUtility.Min5, url, requestOptions).then(async response => {
            try {
                result.statusCode = response.status;
                let x = await response.json();
                result.payload = x as IUserDetails;
                return result;
            } catch (error) {
                result.statusCode = response.status;
                result.errorMessage = response.statusText;
                return result;
            }
        },
            (error: Error) => {
                result.errorMessage = error.message;
                return result;
            })
    }

    public async getUserProfile(): Promise<IServerResponse<IUserProfile>> {
        let token = await ServicesUtility.getGraphToken();
        if (!token) {
            throw new Error('Unable to retrieve Graph token');
        }
        let method = `api/v3.0/UserProfile?queryparams=givenname,surname,mail, extension_8b4e685f580748b59864d62c2e7fcfad_employeeNumber`;
        let url = ServicesUtility.prefixBaseUrl(method);
        // Logic added to track request which is coming from either browser or Teams
        const teamsApp = this.checkInTeams();
        let source = "";
        if (teamsApp) {
            source = 'Teams';
        }
        //End
        let requestOptions = {
            method: 'GET',
            headers: {
                ...ServicesUtility.getDefaultHeaders(token, '', source)
            },
        };

        let result: IServerResponse<IUserProfile> = { statusCode: 0, payload: null, errorMessage: "" };

        return ServicesUtility.timedRequest(ServicesUtility.Min5, url, requestOptions).then(async response => {
            try {
                result.statusCode = response.status;
                let x = await response.json();
                result.payload = x as IUserProfile;
                SurveyService.getInstance().isEligibleForSurvey = result.payload?.isEligibleForSurvey;
                SurveyService.getInstance().surveyUrl = result.payload?.surveyUrl;
                return result;
            } catch (error) {
                result.statusCode = response.status;
                result.errorMessage = response.statusText;
                return result;
            }
        },
            (error: Error) => {
                result.errorMessage = error.message;
                return result;
            })
    }

    private checkInTeams() {
        const microsoftTeamsLib: any = microsoftTeams || window["microsoftTeams" as any];

        if (!microsoftTeamsLib) {
            return false; // the Microsoft Teams library is for some reason not loaded
        }

        if ((window.parent === window.self && (window as any).nativeInterface) ||
            window.name === "embedded-page-container" ||
            window.name === "extension-tab-frame") {
            return true;
        }
        return false;
    };

}
