import React from "react";
import { ICart } from "../../../Model/ServerResponse";
import { Header1 } from "../../HeaderTemplates/Header1/Header1";
import { ISystemHeaderBaseProp } from "../../../Model/UI";

// It is important that we import local scss after Header/2/3 so the styles are overidden in correct way
import "./SWPSHeader.scss";

export interface ISWPSHeaderData extends ISystemHeaderBaseProp {
  swps: ICart;
}

export function SWPSHeader(props: ISWPSHeaderData) {
  function getHeaderUI() {
    return (
      <Header1
        label1={props.swps.approvalSystemId}
        label2={props.swps.date}
        // label2={props.swps.date}
        label3={props.swps.title}
        keyValue1={{
          _key: props.swps.amount ? props.swps.amount.toLocaleString() : "",
          value: props.swps.currency,
        }}
        userDetails={{
          userId: props.swps.requestorID,
          userCardName: props.swps.requestor,
          userOrganization: props.swps.organization,
        }}
        className="swps-header"
      ></Header1>
    );
  }

  return getHeaderUI();
}
