import React from "react";
import { IQuest, IQuestTab } from "../../../Model/ServerResponse";
import { ITabData } from "../../../Model/UI/ITabData";
import { DetailTabsComponent } from "../../DetailTabsComponent/DetailTabsComponent";
import { QuestDetails } from "../QuestDetails/QuestDetails";
import { QuestApprovers } from "../QuestApprovers/QuestApprovers";
import {QUEST_DETAILS_TAB_ID, QUEST_APPROVERS_TAB_ID, QUEST_ATTACHMENTS_TAB_ID, QUEST_LINKS_TAB_ID} from"../QuestUtil";
import { QuestAttachment } from "../QuestAttachment/QuestAttachment";
import { QuestLinks } from "../QuestLinks/QuestLinks";

export interface IQuestTabsProp {
  quest: IQuest;
}

export function QuestTabs(props: IQuestTabsProp) {

  function getTabsUI(){
    const data = getTabsData(props.quest.tabs);                                                                                                    
    return (
        <DetailTabsComponent tabs={data}></DetailTabsComponent>
    );
  }

  function getTabsData(tabs: IQuestTab[]): ITabData[] {
    let tdata: ITabData[] = [];

    tabs.forEach((tab: IQuestTab) => {

      let tabInfo: ITabData  = {
        tabId: "",
        title: tab.title,
        imageSrc: "",
        count: tab.count,
      };

      //By id
      switch (tab.tabId) {
        case QUEST_DETAILS_TAB_ID:
          tabInfo.tabId=QUEST_DETAILS_TAB_ID; tabInfo.imageSrc="./DetailTab/details_icn.svg"; tabInfo.getTabUI=detailsUI;
          break;
        case QUEST_APPROVERS_TAB_ID:
          tabInfo.tabId=QUEST_APPROVERS_TAB_ID; tabInfo.imageSrc="./DetailTab/approvericn.svg"; tabInfo.getTabUI=approversUI;
          break;
        case QUEST_ATTACHMENTS_TAB_ID:
          tabInfo.tabId=QUEST_ATTACHMENTS_TAB_ID; tabInfo.imageSrc="./DetailTab/attachment_icn.svg"; tabInfo.getTabUI=attachmentsUI;
          break;
        case QUEST_LINKS_TAB_ID:
          tabInfo.tabId=QUEST_LINKS_TAB_ID; tabInfo.imageSrc="./DetailTab/link_icn.svg"; tabInfo.getTabUI=linksUI;
          break;
        default:
          break;
      }
      tdata.push(tabInfo);
    });
    return tdata;
  }

  function detailsUI(){
    let _questDetailsData = props.quest.tabs.find(td => td.tabId === QUEST_DETAILS_TAB_ID);
    return(
      <>
        <QuestDetails questDetailsTabData={_questDetailsData}/>
      </>
    )
  }

  function approversUI(){
    let _questApproversData = props.quest.tabs.find(td => td.tabId === QUEST_APPROVERS_TAB_ID);
    return (
     <>
      <QuestApprovers questApproversTabData = {_questApproversData}/>
     </>
    )
  }

  function attachmentsUI(){
    return (
      <>
        <QuestAttachment quest={props.quest}/>
      </>
    )
  }

  function linksUI(){
    return (
      <>
        <QuestLinks quest={props.quest}/>
      </>
    )
  }

  return (
    <>
      {getTabsUI()}
    </>
  );
}



