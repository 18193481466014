import { Menu, MenuItem, Typography } from "@material-ui/core";
import { SwitchProfile } from "Components2/Modals/SwitchProfile/SwitchProfile";
import { UserGuide } from "Components2/Modals/UserGuide/UserGuide";
import React, { useState , useEffect} from "react";
import { useSelector } from "react-redux";
import "./AppHeader.scss";
import UserDetails from "Components2/UserDetails/UserDetails";
import { isMobile } from "react-device-detect";
import { SessionStorageService } from "Services";
import { useAppDispatch } from "Store/store";
import { toggleSidebarOpen } from "Store/Common/CommonSlice";
import { msalInstance } from "authProvider";
import VariablesJson from "../../configVariables.json";
import Ticker from "Components2/Ticker/Ticker";
import { SystemSelectionModal } from "Components2/Modals/SystemSelectionModal/SystemSelectionModal";
import {UserProfileResponseSelector} from "Store/UserSystemProfile/UserSystemSlice";
export function AppHeader({ systemSelectOpen, setsystemSelectUserSystemOpen, InstallEvent }) {
  let appDispatch = useAppDispatch();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  let userProfileResponse = useSelector(UserProfileResponseSelector);
  const [switchProfileOpen, setSwitchProfileOpen] = useState(false);
  const [userGuideOpen, setUserGuideOpen] = useState(false);
  const [userRole, setUserRole] = useState<string>();
  const [installStatus, setInstallStatus] = useState<boolean>(false);
  const [closedFromPrompt, setClosedFromPrompt] = useState<boolean>(true);

  useEffect(() => {
    if (InstallEvent && closedFromPrompt) {
      setInstallStatus(true);
    }
  }, [InstallEvent, closedFromPrompt]);

  const installHandler = (result: boolean) => {
    setInstallStatus(false);
    if (result && InstallEvent) {
      InstallEvent.prompt();
      InstallEvent.userChoice.then((choiceResult: { outcome: string }) => {
        if (choiceResult.outcome === "accepted") {
          // user accepted the install prompt
        } else {
          // user rejected the install prompt
          setClosedFromPrompt(false);
        }
      });
    }
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };


  const handleSystemSelectClick = () => {
    setsystemSelectUserSystemOpen(true);
    setAnchorElUser(null);
  };

  const handleSystemSelectClose = () => {
    setsystemSelectUserSystemOpen(false);
  };

  const handleSwitchProfileClick = () => {
    setSwitchProfileOpen(true);
    setAnchorElUser(null);
  };

  const handleSwitchProfileClose = () => {
    setSwitchProfileOpen(false);
  };

  const handleUserGuideClick = () => {
    setUserGuideOpen(true);
    setAnchorElUser(null);
  };

  const handleUserGuideClose = () => {
    setUserGuideOpen(false);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleAddToHomeScreenClick = async () => {
    // Close the menu
    setAnchorElUser(null);

    // Proceed with the install prompt logic
    setInstallStatus(false);
    if (InstallEvent) {
      InstallEvent.prompt();
      const choiceResult = await InstallEvent.userChoice;
      if (choiceResult.outcome === 'accepted') {
        // user accepted the install prompt
      } else {
        // user rejected the install prompt
        setClosedFromPrompt(false);
      }
    }
  };



  const handleLogOutUser = () => {
    SessionStorageService.clear();
    localStorage.clear();
    msalInstance.logoutRedirect();
  };
  function getDebugAlias() {
    return SessionStorageService.getInstance().debugAlias;
  }

  function getLoggedInUser() {
    if (SessionStorageService.getInstance().IsTeamsApp) {
      return SessionStorageService.getInstance().TeamsUser;
    } else {
      return msalInstance.getActiveAccount()?.username;
    }
  }

  function getLoggedInUserDisplayName() {
    if (SessionStorageService.getInstance().IsTeamsApp) {
      return SessionStorageService.getInstance().TeamsUserDisplayName;
    } else {
      return msalInstance.getActiveAccount()?.name;
    }
  }

  return (
    <div className="app-header">
      <div className="AB-logo-container">
        <img
          alt="logo"
          className="AB-logo"
          style={isMobile ? { cursor: "pointer" } : { pointerEvents: "none" }}
          src={"./AppBar/AB_logo_header.svg"}
          onClick={handleSidebarToggle}
        />
      </div>

      <div className="logo-main">
        <div className="heading">
          <span className="approve">{"Approve"}&nbsp;</span>
          <span className="buddy">{"Buddy"}</span>
        </div>
      </div>
      <div className="header-ticker">
        <Ticker textArray={""} />
      </div>
      <div id="username_dt" style={{ display: "none" }}>
        {getLoggedInUser()}
      </div>
      <div className="user-image">
        <div className="icon-image" onClick={handleOpenUserMenu}>
          <UserDetails
            // userId={authProvider.getAccount().userName}
            userId={getLoggedInUser()}
            forHeader={true}
            userRole={(role: string) => {
              setUserRole(role);
            }}
          />
        </div>
        <div>{getMenuUI()}</div>
        <div>
          <SystemSelectionModal
            open={systemSelectOpen}
            onClose={handleSystemSelectClose}
          />
          {VariablesJson.Variables.switchProfile.toLowerCase() == "true" ? (
            <SwitchProfile
              open={switchProfileOpen}
              onClose={handleSwitchProfileClose}
            />
          ) : (
            <></>
          )}
          <UserGuide open={userGuideOpen} onClose={handleUserGuideClose} />
        </div>
      </div>
    </div>
  );

  function getMenuUI() {
    return (
      <Menu
        id="menu-appbar"
        className="menu-appbar"
        anchorEl={anchorElUser}
        style={{ top: "3rem", right: "3rem" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        MenuListProps={{ disablePadding: true }}
      >
        <div className="menu-item-header">
          <Typography className="login">Logged In</Typography>
          <Typography className="name">
            {getLoggedInUserDisplayName()}
          </Typography>
          <Typography className="role">{userRole}</Typography>
        </div>
        <MenuItem onClick={handleSystemSelectClick}>
          <Typography>User System Profile</Typography>
        </MenuItem>
        {VariablesJson.Variables.switchProfile.toLowerCase() == "true" ? (
          <MenuItem onClick={handleSwitchProfileClick}>
            <Typography>Switch Profile {getDebugAlias()}</Typography>
          </MenuItem>
        ) : (
          <></>
        )}

        <MenuItem onClick={handleUserGuideClick}>
          <Typography>User Guide</Typography>
        </MenuItem>

        {userProfileResponse && userProfileResponse.isNewUser === false && closedFromPrompt && installStatus && (
          <MenuItem onClick={handleAddToHomeScreenClick}>
            <Typography>Add to Home Screen</Typography>
          </MenuItem>
        ) }

        {!SessionStorageService.getInstance().IsTeamsApp && (
          <MenuItem className="logout" onClick={handleLogOutUser}>
            <Typography>Logout</Typography>
          </MenuItem>
        )}

        
      </Menu>
    );
  }

  function handleSidebarToggle(_e: any) {
    if (isMobile) {
      appDispatch(toggleSidebarOpen());
    }
  }
}
