import React from "react";
import { Grid } from "@material-ui/core";
import { KeyValue } from "../../KeyValue/KeyValue";
import { Chip } from "../../Chip/Chip";
import UserDetails from "../../UserDetails/UserDetails";
import { IKeyValue, ICardTemplateBaseProp } from "../../../Model/UI";

import "./Card7.scss";
import Utility from "common/utilities";

export interface ICard1Prop extends ICardTemplateBaseProp {
  label1: string;
  label2: string;
  label3: string;
  keyValue1: IKeyValue;
  keyValue2: IKeyValue[];
  chip1: string;
}
/*
UI placement:

    label1          keyValue1
    label2
    keyValue2[]
    userDetails     chip1
                    label3
 */

export function Card7(props: ICard1Prop)  {
  function getCardUI()  {
    return (
      <div
        className={Utility.getClassNamesFromProps("card-7", props.className)}
      >
        {/* 1st row */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="row-1"
        >
          {props.supportMulti ? (
            props.multiSelectUI
          ) : (
            <div className="dot"></div>
          )}
          <p className="label-1">{props.label1}</p>
          <div className="spacer"></div>
          <KeyValue
            className="key-value-1"
            _key={props.keyValue1._key}
            value={props.keyValue1.value}
          ></KeyValue>
        </Grid>

        {/* 2nd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-2"
        >
          <p className="label-2">{props.label2}</p>
        </Grid>

        {/* 3rd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-3"
        >
          {props.keyValue2.map((kv: IKeyValue, index: number) => {
            return (
              <KeyValue
                key={Utility.createUniqueId(kv,index)}
                className="key-value"
                _key={kv._key}
                value={kv.value}
                seperator={kv.seperator}
              ></KeyValue>
            );
          })}
        </Grid>

        {/* 4th row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-4"
        >
          <div className="user-details">
            <UserDetails
              userId={props.userDetails.userId}
              userCardName={props.userDetails.userCardName}
              userOrganization={props.userDetails.userOrganization}
            ></UserDetails>
          </div>
          <div className="spacer"></div>
          <div className="col-1">
            <Chip className="chip-1" label={props.chip1}></Chip>
            <p className="label-3">{props.label3}</p>
          </div>
        </Grid>
      </div>
    );
  }

  return getCardUI();
}
