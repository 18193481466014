import { Grid } from "@material-ui/core";
import Utility from "common/utilities";
import React from "react";
import { IKeyValue, ICardTemplateBaseProp } from "../../../Model/UI";
import { KeyValue } from "../../KeyValue/KeyValue";
import UserDetails from "../../UserDetails/UserDetails";
import "./Card5.scss";

export interface ICard5Prop extends ICardTemplateBaseProp {
  label1: string;
  label2: string;
  label3: string;
  label4: string;
  keyValue1: IKeyValue;
}
/*
UI placement:

    label1          label2
    label3
    keyValue1
    userDetails     label4      
 */

export function Card5(props: ICard5Prop)  {
  function getCardUI()  {
    return (
      <div
        className={Utility.getClassNamesFromProps("card-5", props.className)}
      >
        {/* 1st row */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="row-1"
        >
          {props.supportMulti ? (
            props.multiSelectUI
          ) : (
            <div className="dot"></div>
          )}
          <p className="label-1">{props.label1}</p>
          <div className="spacer"></div>
          <p className="label-2">{props.label2}</p>
        </Grid>

        {/* 2nd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-2"
        >
          <p className="label-3">{props.label3}</p>
        </Grid>

        {/* 3nd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-3"
        >
          <KeyValue
            className="key-value-1"
            _key={props.keyValue1._key}
            value={props.keyValue1.value}
            seperator={":"}
          />
        </Grid>

        {/* 4th row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-4"
        >
          <UserDetails
            userId={props.userDetails.userId}
            userCardName={props.userDetails.userCardName}
            userOrganization={props.userDetails.userOrganization}
          ></UserDetails>
          <div className="spacer"></div>
          <p className="label-4">{props.label4}</p>
        </Grid>
      </div>
    );
  }

  return getCardUI();
}
