import React from "react";
import "./SubSystemList.scss";
import { Grid } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { KeyValue } from "../../../Components2/KeyValue/KeyValue";
import { ISubSystem } from "Model/UI";
import Utility from "common/utilities";

// Update the SubSystem component to accept a single props object
export function SubSystem({ subSystem, isSelected, loading, onClick }) {
  return (
    <div
      onClick={(e) => {
        if (!subSystem.isEnabled || subSystem.count === 0) {
          return;
        } else if (!loading) {
          onClick(e, subSystem);
        }
      }}
    >
      <KeyValue
        className={
          "sub-system" +
          (isSelected && subSystem.count > 0 ? " sub-system-highlight" : "") +
          (!subSystem.isEnabled || subSystem.count === 0
            ? " sub-system-disabled"
            : "")
        }
        _key={subSystem.systemName.split("-")[1]}
        value={subSystem && subSystem.count ? subSystem.count.toString() : "0"}
      ></KeyValue>
    </div>
  );
}

export function SubSystemList({
  subSystems,
  value,
  loading,
  onSubSystemChange,
}) {
  function handleClearSubsytemFilter(e: any) {
    onSubSystemChange(e, null);
  }

  function handleSubSytemClick(e: any, ss: ISubSystem) {
    onSubSystemChange(e, ss);
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className="sub-system-list"
    >
      {subSystems.map((ss, index) => (
        <div key={Utility.createUniqueId(ss?.systemName, index)}>
          {/* Update how SubSystem is called */}
          <SubSystem
            data-testid="SubSystem"
            subSystem={ss}
            isSelected={ss?.systemName === value?.systemName}
            loading={loading}
            onClick={handleSubSytemClick}
          />
        </div>
      ))}

      {value && (
        <CancelIcon
          data-testid="CancelIcon"
          className="sub-system-cancel-icon"
          onClick={handleClearSubsytemFilter}
        />
      )}
    </Grid>
  );
}
