import {} from "@material-ui/core";
import { IDefaultUIProp } from "../../../Model/UI";
import { ICart } from "Model/ServerResponse/ICart";
import * as React from "react";
import { AttachmentCell } from "../../AttachmentList/AttachmentCell";
import {
  IAttachmentData,
  ICartAttachmentData,
  IServerResponse,
} from "Model/ServerResponse";
import { AttachmentModal } from "Components2/Modals/AttachmentModal/AttachmentModal";
import { useState, useEffect } from "react";
import { AttachmentService } from "Services";
import utilities from "common/utilities";
import "./SWPSAttachment.scss";
import { CART_ATTACHMENTS_TAB_ID } from "../SWPSUtil";

export interface ISWPSAttachmentProp extends IDefaultUIProp {
  swps: ICart;
}

export function SWPSAttachment(props: ISWPSAttachmentProp) {
  let attachmentsData = props.swps.tabs.find(
    (td) => td.tabId === CART_ATTACHMENTS_TAB_ID
  );

  let attachmentData = attachmentsData?.data as ICartAttachmentData[];

  let [isAttachmentDownloadable, setIsAttachmentDownloadable] = useState(false);

  useEffect(() => {
    if (props.swps.isAttachmentDownloadable) {
      setIsAttachmentDownloadable(true);
    }
  }, [props.swps]);

  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [selectedFilename, setSelectedFilename] = useState("");
  const [selectedFileID, setSelectedFileID] = useState("");
  const handleAttachmentModalClose = () => {
    setAttachmentModalOpen(false);
  };

  function handleAttachmentClick(e: any, fileName: string, fileID: string) {
    if (isAttachmentDownloadable === true) {
      setAttachmentModalOpen(true);
      setSelectedFilename(fileName);
      setSelectedFileID(fileID);
    }
  }

  let attachmentService = AttachmentService.getInstance();

  async function getAttachmentData(
    fileID: string,
    filePath: string
  ): Promise<IAttachmentData> {
    return attachmentService
      .getAttachmentData(
        `api/approvals/${
          utilities.getSystemActivityMapping(props.swps.approvalSystemId)[1]
        }/${
          utilities.getSystemActivityMapping(props.swps.approvalSystemId)[0]
        }/${props.swps.id}/attachments/${fileID}`
      )
      .then((attachmentResponse: IServerResponse<IAttachmentData>) => {
        return attachmentResponse.payload!;
      });
  }

  function getAttachmentList() {
    if (attachmentData !== undefined && attachmentData.length > 0) {
      return (
        <div data-testid="attachment-list" className="attachment-list">
          {attachmentData.map((e) => {
            return (
              <AttachmentCell
                key={e.id}
                onClick={handleAttachmentClick}
                fileID={e.id}
                fileName={e.fileName}
                isDownloadable={isAttachmentDownloadable}
              />
            );
          })}
        </div>
      );
    } else
      return (
        <div data-testid="attachment-list" className="no-attachment">
          {" "}
          No Attachments{" "}
        </div>
      );
  }

  return (
    <div data-testid="attachment-modal">
        <div data-testid="attachment-cell">{getAttachmentList()}</div>
        <AttachmentModal
          open={attachmentModalOpen}
          selectedFilename={selectedFilename}
          selectedFileID={selectedFileID}
          getAttachmentData={getAttachmentData}
          onClose={handleAttachmentModalClose}
        />
      </div>
  );
}
