import React from "react";
import { Header1 } from "../../HeaderTemplates/Header1/Header1";
import "./WatershedHeader.scss";
import { ISystemHeaderBaseProp } from "Model/UI";
import { IWatershed } from "Model/ServerResponse/IWatershed";

export interface ITimeSheetHeaderData extends ISystemHeaderBaseProp {
  watershed: IWatershed | any;
}

export function WatershedHeader(props: ITimeSheetHeaderData) {
  function getHeaderUI() {
    return (
      <Header1
        label1={"Task Sheet"}
        label2={props.watershed.date}
        label3={props.watershed.title}
        keyValue1={{ _key: "", value: "" }}
        userDetails={{
          userId: props.watershed.requestorID,
          userCardName: props.watershed.requestor,
          userOrganization: props.watershed.documentId,
        }}
        className="watershead-header"
      ></Header1>
    );
  }

  return getHeaderUI();
}
