import React, { useEffect, useState } from "react";
import "./ListDetailsView.scss";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { useWindowSize } from "../../Hooks";
import { Button, Dialog } from "@material-ui/core";
import { ScreenSize } from "../../Model/Enums";
import ErrorConatiner from "Components2/ErrorScreens/ErrorContainer";
import { Message1 } from "Components2/MessageTemplates/Message1/Message1";

export interface IListDetailsView {
  selectedApproval: any;
  listView: () => React.ReactNode;
  detailsView: () => React.ReactNode;
  onWindowSizeChange: (e: any, isTabletView: boolean) => void;
  onBackClick: (e: any) => void;
}

export function ListDetailsView(props: IListDetailsView) {
  const timeOutMsg =
    "You have been inactive for more than 30 minutes, Please refresh your page";
  let [intervalError, setIntervalError] = useState<boolean>(false);
  let MINUTE_MS = 1800000; //30 Min TimeOut
  let timeOut: NodeJS.Timeout;

  const restartAutoReset = () => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    timeOut = setTimeout(() => {
      setIntervalError(true);
    }, MINUTE_MS);
  };

  const onMouseMove = () => {
    restartAutoReset();
  };

  let [isTabletView, setTabletView] = useState<boolean>(false);
  let windowSize = useWindowSize();

  useEffect(() => {

    // initiate timeout
    restartAutoReset();

    // listen for mouse events
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("click", onMouseMove);
    window.addEventListener("keypress", onMouseMove);
    window.addEventListener("scroll", onMouseMove);

    // cleanup
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
        window.removeEventListener("mousemove", onMouseMove);
        window.addEventListener("click", onMouseMove);
        window.addEventListener("keypress", onMouseMove);
        window.addEventListener("scroll", onMouseMove);
      }
    };
  }, []);

  useEffect(() => {
    if (windowSize <= ScreenSize.TabletMx) {
      setTabletView(true);
      props.onWindowSizeChange(null, true);
    } else {
      setTabletView(false);
      props.onWindowSizeChange(null, false);
    }
  }, [windowSize]);

  return (
    <div className="list-details-view">
      {!intervalError ? (
        <>
          {" "}
          <div className="list-view">{props.listView()}</div>
          {getDetailsUI()}
        </>
      ) : (
        <>
          <ErrorConatiner
            errorId="reload"
            errorImage="ErrorImages/Reloadpage_icn.svg"
            errorName=""
            errorHelperText={timeOutMsg}
          ></ErrorConatiner>
        </>
      )}
    </div>
  );

  function getDetailsUI()  {
    if (isTabletView) {
      if (props.selectedApproval) {
        return mobileDetailsUI();
      } else {
        return <div data-testid="no-seletedapproval"></div>;
      }
    } else {
      return desktopDetailsUI();
    }
  }

  function mobileDetailsUI()  {
    return (
      <Dialog fullScreen draggable={false} open={true}>
        <div data-testid="mobile-details-view"className="mobile-details-view">
          <div className="header">
            <KeyboardArrowLeft
              data-testid="back-icon"
              className="back-icon"
              onClick={handleBackClick}
            />
            <p className="title">Approval Details</p>
          </div>
          <div className="content-container">{props.detailsView()}</div>
        </div>
      </Dialog>
    );

    function handleBackClick(e: any) {
      props.onBackClick(e);
    }
  }

  function desktopDetailsUI()  {
    return (
      <div data-testid="details-view"className="details-view">
        {navigator.onLine ? (
          <> {props.detailsView()}</>
        ) : (
          <>{noInternetDetailUI()}</>
        )}
      </div>
    );
  }

  function handleRefreshClick() {
    if (navigator.onLine) {
      window.location.reload();
    }
  }

  function noInternetDetailUI()  {
    return (
      <div className="details-card-empty-container">
        <div className="message-container">
          <Message1
            title="Network/Connection Error"
            subTitle={
              "The server is taking too long to respond OR something is wrong with your internet connection. Please try again later."
            }
            content={refreshButton()}
          ></Message1>
        </div>
      </div>
    );
  }
  function refreshButton()  {
    return (
      <div className="refreshcontainer">
        <Button className="buttonRefresh" onClick={() => handleRefreshClick()}>
          {"Refresh Now"}
        </Button>
      </div>
    );
  }
}

