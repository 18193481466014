import React from "react";
import { IPR, IPRTab } from "../../../Model/ServerResponse";
import { ITabData } from "../../../Model/UI/ITabData";
import { DetailTabsComponent } from "../../DetailTabsComponent/DetailTabsComponent";
import { PRItems } from "../PRItems/PRItems";
import { PRComments } from "../PRComments/PRComments";
import { PRApprovers } from "../PRApprovers/PRApprovers";
import {PR_ITEMS_TAB_ID, PR_APPROVERS_TAB_ID, PR_ATTACHMENTS_TAB_ID, PR_COMMENTS_TAB_ID} from"../PRUtil";
import { PRAttachment } from "../PRAttachment/PRAttachment";
import "./PRTabs.scss";

export interface IPRTabsProp {
  pr: IPR;
}

export function PRTabs(props: IPRTabsProp) {

  function getTabsUI(){
    const data = getTabsData(props.pr.tabs);                                                                                                    
    return (
        <DetailTabsComponent tabs={data}></DetailTabsComponent>
    );
  }

  function getTabsData(tabs: IPRTab[]): ITabData[] {
    let tdata: ITabData[] = [];

    tabs.forEach((tab: IPRTab) => {

      let tabInfo: ITabData  = {
        tabId: "",
        title: tab.title,
        imageSrc: "",
        count: tab.data.length,
      };

      //By id
      switch (tab.tabId) {
        case PR_ITEMS_TAB_ID:
          tabInfo.tabId=PR_ITEMS_TAB_ID; tabInfo.imageSrc="./DetailTab/details_icn.svg"; tabInfo.getTabUI=itemsUI;
          break;
        case PR_APPROVERS_TAB_ID:
          tabInfo.tabId=PR_APPROVERS_TAB_ID; tabInfo.imageSrc="./DetailTab/approvericn.svg"; tabInfo.getTabUI=approversUI;
          break;
        case PR_ATTACHMENTS_TAB_ID:
          tabInfo.tabId=PR_ATTACHMENTS_TAB_ID; tabInfo.imageSrc="./DetailTab/attachment_icn.svg"; tabInfo.getTabUI=attachmentsUI;
          break;
        case PR_COMMENTS_TAB_ID:
          tabInfo.tabId=PR_COMMENTS_TAB_ID; tabInfo.imageSrc="./DetailTab/comment_icn.svg"; tabInfo.getTabUI=commentsUI;
          break;
        default:
          break;
      }
      tdata.push(tabInfo);
    });
    return tdata;
  }

  function itemsUI(){
    let _prItemsData = props.pr.tabs.find(td => td.tabId === PR_ITEMS_TAB_ID);
    return(
      <>
        <PRItems prItemsTabData={_prItemsData}/>
      </>
    )
  }

  function approversUI() {
    let _prApproversData = props.pr.tabs.find(td => td.tabId === PR_APPROVERS_TAB_ID);
    return (
     <>
      <PRApprovers prApproversTabData = {_prApproversData}/>
     </>
    )
  }

  function attachmentsUI(){
    return (
      <>
        <PRAttachment pr={props.pr}/>
      </>
    )
  }

  function commentsUI(){
    let _prItemsData = props.pr.tabs.find(td => td.tabId === PR_COMMENTS_TAB_ID);
    return (
      <>
        <PRComments prTabData={_prItemsData}/>
      </>
    )
  }

  return getTabsUI();
}


