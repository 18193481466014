import { SearchFilterToolbar } from "../../SearchFilterToolbar/SearchFilterToolbar";
import { Message1 } from "../../MessageTemplates/Message1/Message1";
import React, { useEffect, useState } from "react";
import { ListView } from "../../ListView/ListView";
import { QuestCard } from "../../Quest/QuestCard/QuestCard";
import { ISystemListViewBaseProp } from "Model/UI";
import { useSelector } from "react-redux";
import { IQuest } from "../../../Model/ServerResponse";
import { RequestStatus } from "../../../Model/Enums";
import {
  IsAscSelector,
  MultiSelectedApprovalsSelector,
  PendingApprovalsSelector,
  SearchQuerySelector,
  SelectedApprovalSelector,
  SelectedSubSystemSelector,
  setIsAsc,
  setMultiSelectedApprovals,
  setFromPosition,
  setSearchQuery,
  setSelectedApproval,
  setSelectedSubSystem,
  SubSystemsSelector,
  TotalCountForFilterSelector,
  GetPendingApprovals,
  resetSystemSliceState,
  PendingApprovalsStatusSelector,
  PendingApprovalsErrorSelector,
  SupportMultiSelector,
  questSliceInitialState,
  IsTabletViewSelector,
} from "Store/Quest/QuestSlice";
import { useAppDispatch } from "../../../Store/store";
import "./QuestListView.scss";

export interface IQuestListViewProp<T> extends ISystemListViewBaseProp<T> { }

export function QuestListView(props: IQuestListViewProp<IQuest>) {
  let appDispatch = useAppDispatch();
  let [onSubSystemClick, setOnSubSystemClick] = useState(false);
  let supportMulti = useSelector(SupportMultiSelector);

  let pendingApprovalsStatus = useSelector(PendingApprovalsStatusSelector);
  let pendingApprovalsError = useSelector(PendingApprovalsErrorSelector);

  let selectedApproval = useSelector(SelectedApprovalSelector);
  let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);
  let subSystems = useSelector(SubSystemsSelector);
  let totalCountForFilter = useSelector(TotalCountForFilterSelector);
  let pendingApprovals = useSelector(PendingApprovalsSelector);

  let searchQuery = useSelector(SearchQuerySelector);
  let isAsc = useSelector(IsAscSelector);
  let selectedSubSystem = useSelector(SelectedSubSystemSelector);

  let isTabletView = useSelector(IsTabletViewSelector);

  useEffect(() => {
    appDispatch(resetSystemSliceState(questSliceInitialState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Set up data
  useEffect(() => {
    appDispatch(setSelectedApproval(null));
    appDispatch(GetPendingApprovals());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, isAsc, selectedSubSystem]);

  return (
    <div className="quest-list-container">
      <SearchFilterToolbar
        multiSelectedCount={multiSelectedApprovals?.length}
        title="Quest"
        error={pendingApprovalsStatus === RequestStatus.Failed}
        totalCount={totalCountForFilter}
        count={pendingApprovals?.length}
        subSystems={subSystems}
        loading={pendingApprovalsStatus === RequestStatus.Loading}
        onRefresh={handleRefresh}
        onSearch={handleSearchChange}
        onSort={handleSortChange}
        onSubSystemChange={handleSubSystemChange}
      ></SearchFilterToolbar>

      {listUI()}
    </div>
  );

  function getRowUI(data: IQuest, multiSelectUI?){
    return (
      <>
        <QuestCard
          supportMulti={supportMulti}
          multiSelectUI={multiSelectUI}
          quest={data}
        ></QuestCard>
      </>
    );
  }

  function listUI(){
    if (pendingApprovalsStatus === RequestStatus.Failed) {
      return errorUI();
    }

    return (
      <>
        <ListView
          className="quest-list"
          totalCount={totalCountForFilter}
          dataList={pendingApprovals}
          selectedData={selectedApproval}
          multiSelectedData={multiSelectedApprovals}
          loading={pendingApprovalsStatus === RequestStatus.Loading}
          supportMulti={supportMulti}
          getRowUI={getRowUI}
          getUniqueDataIdentifier={getUniqueDataIndentifier}
          onSelectChange={handleSelectChange}
          onMultiSelectChange={handleMultiSelectChange}
          onFromPositionChange={handleFromPositionChange}
          getEmptyIndicatorUI={emptyIndicatorUI}
          isTabletView={isTabletView}
        ></ListView>

        {multiSelectedApprovals?.length > 0 && actionContainer()}
      </>
    );
  }

  function emptyIndicatorUI(){
    return (
      <div className="message-container">
        <Message1
          title="No approvals."
          imgSrc="CardAssets/NoPendingRequests.svg"
        ></Message1>
      </div>
    );
  }

  function errorUI(){
    if (pendingApprovalsError) {
      return (
        <div className="message-container">
          <Message1
            title={pendingApprovalsError.message}
            imgSrc="CardAssets/error.svg"
            subTitle={pendingApprovalsError.description}
          ></Message1>
        </div>
      );
    }
    return <></>;
  }

  function handleRefresh(e: any) {
    //Reset all state
    appDispatch(resetSystemSliceState(questSliceInitialState));
    //Get approvals
    appDispatch(GetPendingApprovals());
  }

  function handleSearchChange(e: any, _query: string) {
    appDispatch(setSearchQuery(_query));
  }

  function handleSortChange(e: any, _isAsc: boolean) {
    appDispatch(setIsAsc(_isAsc));
  }

  function handleSubSystemChange(e: any, _subSystem: any) {
    setOnSubSystemClick(true);
    appDispatch(setSelectedSubSystem(_subSystem));
  }

  function getUniqueDataIndentifier(_data: IQuest): string {
    //Return unique identifier
    return _data.id.toString();
  }

  function handleFromPositionChange(_fromPostion: number): void {
    if (!onSubSystemClick) {
      appDispatch(setFromPosition(_fromPostion));
      appDispatch(GetPendingApprovals());
    }
  }

  function handleMultiSelectChange(e: any, _values: any) {
    appDispatch(setMultiSelectedApprovals(_values));
  }

  function handleSelectChange(e: any, _value: any) {
    appDispatch(setSelectedApproval(_value));
  }

  function actionContainer() {
    if (props.getActionsUI) {
      return (
        <div className="action-container">
          {props.getActionsUI(true, multiSelectedApprovals as IQuest[])}
        </div>
      );
    }
    return <></>;
  }
}
