import React from "react";
import { ICartCommentData, ICartTab, } from "../../../Model/ServerResponse";
import { CommentsTemplate } from "../../CommentsTemplate/CommentsTemplate";
import { IComment } from "../../../Model/UI/IComment";
import "./SWPSComments.scss";
import Utility from "../../../common/utilities";

export interface ISWPSCommentsProp {
    cartTabData: ICartTab | undefined;
}

export function SWPSComments(props: ISWPSCommentsProp){
    let commentDataModel: IComment[] = [];

    return (
        <div className="swps-comments-tab">
            {tabUI()}
        </div>
    );

    function tabUI() {
        if (props.cartTabData && !Utility.isArrayEmpty(props.cartTabData.data)) {
            props.cartTabData.data.forEach((cd: any) => {
                let dateItem = cd as ICartCommentData;
                commentDataModel.push({ label1: dateItem.date, label2: dateItem.commentBy, label3: dateItem.comment });
            })

            return (<CommentsTemplate commentData={commentDataModel} ></CommentsTemplate>);
        }
        else {
            return (
                <div className="no-comments">
                    No Comments
                </div>
            );
        }
    }
}