import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import "./DetailSkeleton1.scss";

export function DetailSkeleton1() {
  return (
    <div className="detail-skeleton-1">
      <Skeleton data-testid="skeleton" variant="text" className="row-1 s-1" />
      <Skeleton variant="text" data-testid="skeleton" className="row-2 s-1" />
      {/* <Skeleton variant="text" width="30%" height="20px" className="default-margin"/> */}

      <div className="row-3">
        <Skeleton
          data-testid="skeleton-circle"
          variant="circle"
          className="circle-1"
        />
        <div className="row-3-column-1">
          <Skeleton className="s-2" />
          <Skeleton className="s-2" />
        </div>
      </div>

      <Skeleton data-testid="skeleton-rect" variant="rect" className="s-3" />
    </div>
  );
}
