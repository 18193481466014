import Utility from "common/utilities";
import { IDefaultUIProp } from "Model/UI";
import React from "react";
import "./Chip.scss";

export interface IChipProp extends IDefaultUIProp{
    label : string;
    className ?: string;
}

export function Chip(props : IChipProp){
    
    return (
        <>
            <div className={Utility.getClassNamesFromProps("chip", props.className)}>
                <p className="label">{props.label}</p>
            </div>
        </>
    );
}