import { IPO, IFailedTakeAction, ISystem } from "../../../Model/ServerResponse";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../Store/store";
import {
  DeleteApprovals,
  PostDeleteApprovals,
  PostForwardApprovals,
  reCalculateSelectedApproval,
  SelectedApprovalSelector,
  setIsTabletView,
  setSelectedApproval,
  ForwardApprovalStatusSelector,
} from "../../../Store/PO/POSlice";
import { ListDetailsView } from "../../ListDetailsView/ListDetailsView";
import { PODetailsCard } from "../PODetailsCard/PODetailsCard";
import { POListView } from "../POListView/POListView";
import { ForwardStatusModal } from "../../Modals/ForwardStatusModal/ForwardStatusModal";
import { POActions } from "../POActions/POActions";
import { TakeActionFailedModal } from "../../Modals/TakeActionFailedModal/TakeActionFailedModal";
import { TakeActionReferenceModal } from "Components2/Modals/ReferenceModel/TakeActionReferenceModal";
import "./POPage.scss";
import clipboard from "clipboard-copy";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import Utility from "common/utilities";
export interface IPOPageProp {
}

export function POPage(props: IPOPageProp) {
  let appDispatch = useAppDispatch();
  const [copied, setCopied] = useState(false);
  let selectedApproval = useSelector(SelectedApprovalSelector);

  let forwardApprovalsStatus = useSelector(ForwardApprovalStatusSelector);
  const [forwardStatusModalOpen, setForwardStatusModalOpen] =
    React.useState(false);
  const [approverName, setApproverName] = React.useState(
    "" as string | undefined
  );
  const [refrencePONumber, setRefrencePONumber] = React.useState("" as string);
  let [takeActionFailedModalOpen, setTakeActionFailedModalOpen] =
    useState<boolean>(false);
  let [takeActionReferenceModalOpen, setTakeActionReferenceModalOpen] =
    useState<boolean>(false);
  let [failedTakeActions, setFailedTakeActions] = useState<IFailedTakeAction[]>(
    []
  );
  const [selectedDDValue, setSelectedDDValue] = React.useState<string>("");
  const handleChange = (event) => {
    setSelectedDDValue(event.target.value);
  };

  const handleCopyClick = async () => {
    try {
      await clipboard(refrencePONumber);
      setCopied(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };


  const handleForwardStatusClose = () => {
    setForwardStatusModalOpen(false);
  };

  return (
    <div>
      <ForwardStatusModal
        open={forwardStatusModalOpen}
        status={forwardApprovalsStatus}
        onClose={handleForwardStatusClose}
        forwardApproverName={approverName}
      />
      {takeActionFailedModalOpen && (
        <TakeActionFailedModal
          open={takeActionFailedModalOpen}
          failedActions={failedTakeActions}
          onOk={(e: any) => setTakeActionFailedModalOpen(false)}
        />
      )}
      {takeActionReferenceModalOpen && (
        <TakeActionReferenceModal
          open={takeActionReferenceModalOpen}
          content={referenceDetails()}
          onOk={(e: any) => setTakeActionReferenceModalOpen(false)}
        />
      )}
      <ListDetailsView
        selectedApproval={selectedApproval}
        onBackClick={handleBackClick}
        onWindowSizeChange={handleWindowSizeChange}
        listView={listUI}
        detailsView={detailsUI}
      />
    </div>
  );

  function listUI() {
    return <POListView getActionsUI={actionsUI}></POListView>;
  }

  function detailsUI() {
    return <PODetailsCard getActionsUI={actionsUI} />;
  }

  function actionsUI(_isMulti: boolean, _approvals: IPO[]) {
    return (
      <POActions
        isMulti={_isMulti}
        count={_approvals.length}
        onApprove={handleOnApprove}
        onReject={handleOnReject}
        onCancel={handleOnCancel}
        rejectReasonValue={selectedDDValue}
        rejectDropDown={
          _approvals[0]?.approvalSystemId.toLowerCase() !== "po-optimus" ? (
            rejectDropDown()
          ) : (
            <></>
          )
        }
        onForward={
          _approvals[0]?.approvalSystemId.toLowerCase() != "po-optimus"
            ? handleOnForward
            : undefined
        }
      />
    );
  }

  function rejectDropDown() {
    const rejectionReasons = [
      "Insufficient budget available.",
      "Demand cancelled / no longer needed.",
      "Use excess inventory instead - FMT opportunity.",
      "Data Error: Cost/Price/Quantity/Vendor.",
      "Other",
    ];
    return (
      <FormControl variant="outlined" fullWidth style={{ padding: "5px" }}>
        <InputLabel id="outlined-dropdown-label">Select Reason *</InputLabel>
        <Select
          labelId="outlined-dropdown-label"
          id="outlined-dropdown"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          disableUnderline={true}
          value={selectedDDValue}
          onChange={handleChange}
          label="Select Reason"
          input={<OutlinedInput label="Select Reason" />}
        >
          {rejectionReasons.map((option,index) => (
            <MenuItem key={Utility.createUniqueId(option,index)} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  function handleBackClick(e: any) {
    appDispatch(setSelectedApproval(null));
  }

  function handleWindowSizeChange(e: any, isTabletView: boolean) {
    appDispatch(setIsTabletView(isTabletView));
    appDispatch(reCalculateSelectedApproval());
  }

  function handleOnApprove(_e: any, isMulti: boolean, comment: string) {
    takeAction(true, isMulti, comment, false);
  }

  function handleOnReject(_e: any, isMulti: boolean, comment: string) {
    takeAction(false, isMulti, comment, false);
  }

  function handleOnCancel() {
    setSelectedDDValue("");
  }

  function handleOnForward(
    _e: any,
    isMulti: boolean,
    comment: string,
    approver: string,
    _approverName?: string | undefined
  ) {
    takeAction(false, isMulti, comment, true, approver);
    if (navigator.onLine) {
      setForwardStatusModalOpen(true);
      setApproverName(_approverName);
    }
  }

  async function takeAction(
    isApprove: boolean,
    isMulti: boolean,
    comment: string,
    isForward: boolean,
    approver?: string
  ): Promise<void> {
    let result: null | {
      passedApprovals: IPO[];
      failedTakeActions: IFailedTakeAction[];
    } = null;
    setRefrencePONumber("");
    setCopied(false);
    if (isForward) {
      result = (
        await appDispatch(
          PostForwardApprovals({
            isForward: isForward,
            isMulti: isMulti,
            comment: comment,
            approver: approver,
            getRequestBody: getTakeActionRequestBody,
          })
        )
      ).payload as {
        passedApprovals: IPO[];
        failedTakeActions: IFailedTakeAction[];
      };
    } else {
      result = (
        await appDispatch(
          PostDeleteApprovals({
            isApprove: isApprove,
            isMulti: isMulti,
            comment: selectedDDValue
              ? selectedDDValue + " " + comment
              : comment,
            getRequestBody: getApprovalTakeActionRequestBody,
          })
        )
      ).payload as {
        passedApprovals: IPO[];
        failedTakeActions: IFailedTakeAction[];
      };
    }
    if (
      result?.failedTakeActions?.length > 0
    ) {
      //Some approvals failed
      setTakeActionFailedModalOpen(true);
      setFailedTakeActions(result.failedTakeActions);
    } else {
      if (!isForward) {
        setTakeActionReferenceModalOpen(true);
      }
      //Remove cards & subtract count for passed
      appDispatch(DeleteApprovals(result?.passedApprovals));
    }
    setSelectedDDValue("");
  }

  function getTakeActionRequestBody(
    _approval: ISystem,
    _comment: string,
    approver?: string
  ): any {
    let _po = _approval as IPO;
    return {
      approvalId: _po.id.toString(),
      comment: _comment,
      approver: approver,
      extra: {
        poNumber: _po.poNumber,
      },
    };
  }

  function getApprovalTakeActionRequestBody(
    _approval: ISystem,
    _comment: string,
    approver?: string
  ): any {
    let _po = _approval as IPO;
    setRefrencePONumber(_po.poNumber);
    return {
      approvalId: _po.id.toString(),
      comment: _comment,
      approver: approver,
      logData: {
        poNumber: _po.poNumber,
        vendorName: _po.vendorName,
        vendorId: _po.vendorId,
        plant: _po.plant,
        amount: _po.amount,
        date: _po.date,
        currency: _po.currency,
      },
    };
  }

  function referenceDetails() {
    return (
      <div className="poReference">
          <p>
            <span>PO Ref. No.</span>:{" "}
            <span className="poValue"> {refrencePONumber}</span>
            <span
              className="copyBtn"
              title="copy to clipboard"
              onClick={handleCopyClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <mask
                  id="mask0_2576_263"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="14"
                  height="14"
                >
                  <rect width="14" height="14" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2576_263)">
                  <path
                    d="M11.0852 11.0835H4.66849C4.34765 11.0835 4.073 10.9693 3.84453 10.7408C3.61606 10.5123 3.50182 10.2377 3.50182 9.91683V1.75016C3.50182 1.42933 3.61606 1.15468 3.84453 0.926205C4.073 0.697732 4.34765 0.583496 4.66849 0.583496H8.75182L12.2518 4.0835V9.91683C12.2518 10.2377 12.1376 10.5123 11.9091 10.7408C11.6806 10.9693 11.406 11.0835 11.0852 11.0835ZM8.16849 4.66683V1.75016H4.66849V9.91683H11.0852V4.66683H8.16849ZM2.33515 13.4168C2.01432 13.4168 1.73967 13.3026 1.5112 13.0741C1.28272 12.8457 1.16849 12.571 1.16849 12.2502V4.0835H2.33515V12.2502H8.75182V13.4168H2.33515Z"
                    fill="#696969"
                  />
                </g>
              </svg>
            </span>
          </p>
          {copied && <span className="copiedText">Copied!</span>}
        </div>
    );
  }
}
