import Utility from "../../../common/utilities";
import { TableComponent } from "../../../Components2/TableComponent/TableComponent";
import { ITableDataMapping } from "../../../Model/UI/ITableDataMapping";
import React from "react";
import { IPO, IPOItemData } from "../../../Model/ServerResponse";
import "./POOptimusItems.scss";
import { Accordion, AccordionDetails, AccordionSummary, TableCell, TableRow } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PO_ITEMS_TAB_ID } from "../POUtil";
import { KeyValueList } from "Components2/KeyValueList/KeyValueList";
import { IKeyValue } from "Model/UI";
import { KeyValue } from "Components2/KeyValue/KeyValue";



export interface IPOOptimusItemsProp {
    poData: IPO;
}

export function POOptimusItems(props: IPOOptimusItemsProp) {
    let poItemsTabData = props.poData.tabs.find(td => td.tabId === PO_ITEMS_TAB_ID);
    const tableHeaderMapping: ITableDataMapping[] =
        [
            {
                id: "itemNo",
                label: "Item"
            },
            {
                id: "description",
                label: "Description"
            },
            {
                id: "plantInformation",
                label: "Plant"
            },
            {
                id: "quantity",
                label: "Quantity",
            },
            {
                id: "pricePerUnit",
                label: "Unit Cost"
            },
            {
                id: "subTotalCost",
                label: "Subtotal Cost",
                sorting: true
            }
        ];
    return (
        <div className="po-items-tab">
            {tabPOData()}

        </div>
    );



    function tabPOData(){
        if (poItemsTabData && !Utility.isArrayEmpty(poItemsTabData.data)) {
            return (
                <div className="po-optimus-item-rows">
                    <TableComponent tableData={poItemsTabData.data} mappingTableData={tableHeaderMapping} getTableRow={getTablePOItemRow}></TableComponent>
                </div>
            )
        }
        else {
            return (
                <div className="no-po-items">
                    No Items
                </div>
            );
        }
    }

    function getTablePOItemRow(index: number, poItemData: IPOItemData) {
        let poChangedLogsData = poItemData.changeLogs;
        return (
            <>
                <TableRow>
                    <TableRow className="spacing"></TableRow>
                </TableRow>
                <TableRow className="po-items-row-1 po-items-row">
                    <TableCell>
                        <p className="po-items-row-text-1">{poItemData?.itemNo}</p>
                    </TableCell>
                    <TableCell>
                        <p className="po-items-row-text-1">{poItemData?.description}</p>
                    </TableCell>
                    <TableCell>
                        <p className="po-items-row-text-1">
                            {poItemData.plantInformation}
                        </p>
                    </TableCell>
                    <TableCell>
                        <KeyValue
                        className="key-value-1"
                        _key={poItemData?.quantity.toLocaleString()}
                        value={poItemData.unit}
                    ></KeyValue>
                    </TableCell>
                    <TableCell>
                        <KeyValue
                        className="key-value-1"
                        _key={poItemData?.pricePerUnit.toLocaleString()}
                        value={props.poData.currency}
                    ></KeyValue>
                    </TableCell>
                    <TableCell>
                        <KeyValue
                        className="key-value-1"
                        _key={poItemData?.subTotalCost.toLocaleString()}
                        value={props.poData.currency}
                    ></KeyValue>
                    </TableCell>
                </TableRow>
                {poChangedLogsData && poChangedLogsData.length > 0 ? getChangeLogUI(poItemData) : null}
                <TableRow className="detail-row">
                    <TableCell colSpan={7}>{getDetailSection(index, poItemData)}</TableCell>
                </TableRow>
            </>
        )
    }


    function getDetailSection(index: number, poItemData: IPOItemData){
        return (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="header-content"><b>Details</b></div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="border-div">
                            {getMaterialSection(poItemData)}
                    </div>
                </AccordionDetails>
            </Accordion>
            );
        }

        function getMaterialSection(poItemData: IPOItemData) {
            let _data = getMaterialKeyValueList1(poItemData);
            let _data2 = getMaterialKeyValueList2(poItemData);
            let _data3 = getMaterialKeyValueList3(poItemData);
            let _data4 = getMaterialKeyValueList4(poItemData);
            return (
                <>
                <KeyValueList data={_data} />
                {_data2.length !=0  ? <div className="dividerDiv" ></div> : null}
                <KeyValueList data={_data2} />
                {_data3.length !=0  ? <div className="dividerDiv" ></div> : null}
                <KeyValueList data={_data3} />
                {_data4.length !=0  ? <div className="dividerDiv" ></div> : null}
                <KeyValueList data={_data4} />
                </>
            );
        }

    
     

    function getMaterialKeyValueList1(poItemData: any): IKeyValue[] {
        let dataArray: IKeyValue[] = [];
        if (poItemData.materialNumber) dataArray.push({ _key: "Material ID", value: poItemData.materialNumber, seperator: ":" });
        if (poItemData.materialGroup) dataArray.push({ _key: "Material Group", value: poItemData.materialGroup, seperator: ":" });
        if (poItemData.zMatGrpDesc) dataArray.push({ _key: "Material Group Description", value: poItemData.zMatGrpDesc, seperator: ":" });
        
        
        return dataArray;
    }

    function getMaterialKeyValueList2(poItemData: any): IKeyValue[] {
        let dataArray: IKeyValue[] = []; 
        if (poItemData.agreementNumber) dataArray.push({ _key: "Agreement Number", value: poItemData.agreementNumber, seperator: ":" });
        if (poItemData.agreementItem && poItemData.agreementItem !== "00000") dataArray.push({ _key: "Agreement Item", value: poItemData.agreementItem, seperator: ":" });
       
        return dataArray;
    }

    function getMaterialKeyValueList3(poItemData: any): IKeyValue[] {
        let dataArray: IKeyValue[] = [];
        if (poItemData.plantInformation) dataArray.push({ _key: "Plant", value: poItemData.plantInformation, seperator: ":" });
        if (poItemData.plantName) dataArray.push({ _key: "Plant Description", value: poItemData.plantName, seperator: ":" });
        if (poItemData.storageLocation) dataArray.push({ _key: "Storage Location", value: poItemData.storageLocation, seperator: ":" });
        
        return dataArray;
    }

    function getMaterialKeyValueList4(poItemData: any): IKeyValue[] {
        let dataArray: IKeyValue[] = [];
        if (poItemData.deliveryDate) dataArray.push({ _key: "Delivery Date", value: poItemData.deliveryDate, seperator: ":" });
        if (poItemData.deliveryText) dataArray.push({ _key: "Delivery Text", value: poItemData.deliveryText, seperator: ":" });
        return dataArray;
    }




    function getChangeLogUI(poItemData: IPOItemData){
        let quantity = 0;
        let pricePerUnit = 0;
        let subTotalCost = 0;
        if (poItemData?.changeLogs && poItemData?.changeLogs?.length > 1) {
            quantity = poItemData?.changeLogs[0]?.quantity - poItemData?.changeLogs[1]?.quantity;
            pricePerUnit = poItemData?.changeLogs[0]?.pricePerUnit - poItemData?.changeLogs[1]?.pricePerUnit;
            subTotalCost = poItemData?.changeLogs[0]?.subTotalCost - poItemData?.changeLogs[1]?.subTotalCost;
        }
        return (
            <TableRow className="change-log-row po-items-row-1 po-items-row">
                <TableCell>
                    <p className="change-log-text"><b>Changed logs by</b>
                        {poItemData?.changeLogs[0]["reason"] ? <span className="reason-text">Reason : {poItemData?.changeLogs[0]["reason"]}</span> : null}
                    </p>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                    <p className="po-items-row-text">{poItemData?.changeLogs[0]?.pricePerUnit}</p>
                    <p className={Utility.mergeCssClassNames([
                        "po-items-row-text",
                        getChangeLogStyle(pricePerUnit),
                    ])}> <span>{pricePerUnit <= 0 ? "" : "+"}</span>{pricePerUnit}</p>
                </TableCell>
                <TableCell>
                    <p className="po-items-row-text">{poItemData?.changeLogs[0]?.quantity}</p>
                    <p className={Utility.mergeCssClassNames([
                        "po-items-row-text",
                        getChangeLogStyle(quantity),
                    ])} >{quantity}</p>
                </TableCell>
                <TableCell>
                    <p className="po-items-row-text">{poItemData?.changeLogs ? poItemData?.changeLogs[0]?.subTotalCost : ""}</p>
                    <p className={Utility.mergeCssClassNames([
                        "po-items-row-text",
                        getChangeLogStyle(subTotalCost),
                    ])}>{subTotalCost}</p>
                </TableCell>
            </TableRow>
        );
    }

    function getChangeLogStyle(value: number): string {
        if (value > 0) {
            return "green-text";
        } else if (value < 0) {
            return "red-text";
        } else {
            return "po-items-row-text";
        }
    }
}


