import { ServicesUtility } from "./servicesUtility";

export class TokenService {
    private static instance: TokenService;
  
    private constructor() { }
  
    public static getInstance(): TokenService {
      if (!this.instance) {
        this.instance = new TokenService();
      }
  
      return this.instance;
    }

    public async getAPIAccessToken(token: string, scope: string) {
    
        let url = ServicesUtility.prefixBaseUrl(`api/token?scope=${scope}`);
    
        let requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
        };
        
        return ServicesUtility.timedRequest(
          ServicesUtility.Min5,
          url,
          requestOptions
        ).then(
          async (response) => {
            try {
                let result = await response.json();
                // console.log("Token Response: " ,result)
                return result;
            } catch (error) {
                console.log("API Token Error: " ,error)
                return error;
            }
          },
          (error: Error) => {
            return error.message;
          }
        );
      }
    
}