import React from "react";
import { ActionModal } from "../ActionModal/ActionModal";
import "./ForwardModal.scss";

export interface IForwardModalProp {
    open: boolean;
    onCancel?: (e: any) => void;
    onForward?: (e: any, comment: string) => void;
    forwardApprover: string;
}

export function ForwardModal(props: IForwardModalProp) {



    return (
        <React.Fragment>
            <ActionModal
                open={props.open}
                title={"Confirmation"}
                question={
                    "Please only forward to users with appropriate <strong> <i>approval rights.</i></strong> <br>" +
                    "Are you sure you want to forward it to <strong>" + props.forwardApprover + " </strong> ?"
                }
                maxCommentChar={500}
                isCommentMandatory={false}
                primaryActionLabel={"Forward"}
                className="forward-modal"
                onPrimaryAction={props.onForward}
                onCancel={props?.onCancel}
            ></ActionModal>
        </React.Fragment>
    );
}