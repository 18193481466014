import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Utility from "../../../common/utilities";
import { IFailedTakeAction } from "../../../Model/ServerResponse";
import { IDefaultUIProp } from "../../../Model/UI";
import "./TakeActionFailedModal.scss";

export interface ITakeActionFailedModalProp extends IDefaultUIProp {
  open: boolean;
  showSubTitle?: boolean;
  failedActions: IFailedTakeAction[];
  onOk?: (e: any) => void;
}

export function TakeActionFailedModal(props: ITakeActionFailedModalProp) {
  const [open, setOpen] = React.useState(false);
  const [showSubTitle, setShowSubTitle] = React.useState(true);

  useEffect(() => {
    setOpen(props.open);
    if (props.showSubTitle !== undefined) {
      setShowSubTitle(props.showSubTitle);
    }
  }, [props.open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
      className={Utility.mergeCssClassNames([
        "take-action-failed-modal",
        props.className,
      ])}
    >
      <DialogTitle>
        <p className="title">{"Unable to process"}</p>
      </DialogTitle>

      <DialogContent className="take-action-failed-content">
        {showSubTitle ? (
          <p className="sub-title">{`${
            props.failedActions?.length > 0
              ? props.failedActions?.length +
                " request(s) could not be processed."
              : ""
          } `}</p>
        ) : (
          <></>
        )}

        <p className="details">{"Details:"}</p>
        {props.failedActions.map((fa) => {
          return (
            <>
              <p className="message">
                {errorMessageDetail(fa.message, fa.reason)}
              </p>
            </>
          );
        })}
      </DialogContent>
      <DialogActions className="action-container">
        <Button className="ok-btn" onClick={handleClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );

  function handleClose(e: any) {
    setOpen(false);
    if (props.onOk) {
      props.onOk(e);
    }
  }

  function errorMessageDetail(errorText: string, errorReason: string): string {
    let result = "";
    if (errorText === "internet") {
      result =
        "Network/Connection Error - Network connection is too slow. Please try again later";
    } else if (errorText == "nointernet") {
      result =
        "The server is taking too long to respond OR something is wrong with your internet connection. Please try again later.";
    } else {
      result = errorReason;
    }
    return result;
  }
}
