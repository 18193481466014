import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import React from "react";
import { IJRA, IJRATableItems } from "../../../Model/ServerResponse"
import "./JRAHeaderTable.scss";
import Utility from "common/utilities";


export interface IJRAHeaderTableProp {
    jra: IJRA;
}
export function JRAHeaderTable(props: IJRAHeaderTableProp)  {
    const tableHeader: IJRATableItems[] = props.jra.tableItems;
    return (
        <div className="jra-table">
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow className="table-header-row">
                            <TableCell className="table-header">Std.Job Type</TableCell>
                            <TableCell className="table-header">Sub Job Type</TableCell>
                            <TableCell className="table-header"> Business Process</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableHeader.map((row: IJRATableItems, index: number) => (
                            <TableRow className="data-row" key={Utility.createUniqueId(index,row)}>
                                <TableCell><b>{row.jobTypes}</b></TableCell>
                                <TableCell><b>{row.subJobTypes == '' ? <span>- </span> : row.subJobTypes}</b></TableCell>
                                <TableCell><b>{row.businessProcess}</b></TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
