import { Grid } from "@material-ui/core";
import Utility from "common/utilities";
import React from "react";
import { IKeyValue, ICardTemplateBaseProp } from "../../../Model/UI";
import { KeyValue } from "../../KeyValue/KeyValue";
import UserDetails from "../../UserDetails/UserDetails";
import "./Card8.scss";

export interface ICard6Prop extends ICardTemplateBaseProp {
  label1: string;
  label2: string;
  label3: string;
  keyValue1: IKeyValue;
  keyValues: IKeyValue[];
}
/*
UI placement:

    label1          keyValue1
    label2
    keyValue[]
    userDetails     
                    label3      
 */

export function Card8(props: ICard6Prop)  {
  function getCardUI()  {
    return (
      <div
        className={Utility.getClassNamesFromProps("card-8", props.className)}
      >
        {/* 1st row */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="row-1"
        >
          {props.supportMulti ? (
            props.multiSelectUI
          ) : (
            <div className="dot"></div>
          )}
          <p className="label-1">{props.label1}</p>
          <div className="spacer"></div>
          <KeyValue
            className="key-value-1"
            _key={props.keyValue1._key}
            value={props.keyValue1.value}
          ></KeyValue>
        </Grid>

        {/* 2nd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-2"
        >
          <p className="label-2">{props.label2}</p>
        </Grid>

        {/* 3nd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-3"
        >
          {props.keyValues.map((kv, index) => {
            return (
              <KeyValue
                key={Utility.createUniqueId(kv,index)}
                className="key-value"
                _key={kv._key}
                value={kv.value}
                seperator={kv.seperator}
              ></KeyValue>
            );
          })}
        </Grid>

        {/* 4th row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-4"
        >
          <UserDetails
            userId={props.userDetails.userId}
            userCardName={props.userDetails.userCardName}
            userOrganization={props.userDetails.userOrganization}
          ></UserDetails>
          <div className="spacer"></div>
          <div className="col-1">
            <p className="label-3">{props.label3}</p>
          </div>
        </Grid>
      </div>
    );
  }

  return getCardUI();
}
