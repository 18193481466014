import React from "react";
import { Grid } from "@material-ui/core";
import { KeyValue } from "../../../Components2/KeyValue/KeyValue";
import UserDetails from "../../UserDetails/UserDetails";
import { IKeyValue, IHeaderTemplateBaseProp } from "../../../Model/UI";

import "./Header1.scss";
import Utility from "../../../common/utilities";

export interface IHeader1Prop extends IHeaderTemplateBaseProp {
  label1: string;
  label2: string;
  label3: string;
  keyValue1: IKeyValue;
}

/*
UI placement:

    label1                  label2
    label3
    userDetails             keyValue1
                    
*/

export function Header1(props: IHeader1Prop)  {
  function getHeaderUI()  {
    return (
      <div
        data-testid="header-1"
        className={Utility.getClassNamesFromProps("header-1", props.className)}
      >
        {/* 1st row */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="row-1"
        >
          <div className="dot"> </div>
          <p className="label-1">{props.label1}</p>
          <div className="spacer"></div>
          <p className="label-2">{props.label2}</p>
        </Grid>

        {/* 2nd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-2"
        >
          <p className="label-3">{props.label3}</p>
        </Grid>

        {/* 3rd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-3"
        >
          <UserDetails
            userId={props.userDetails.userId}
            userCardName={props.userDetails.userCardName}
            userOrganization={props.userDetails.userOrganization}
          ></UserDetails>
          <div className="spacer"></div>
          <KeyValue
            className="key-value-1"
            _key={props.keyValue1._key}
            value={props.keyValue1.value}
          ></KeyValue>
        </Grid>
      </div>
    );
  }

  return getHeaderUI();
}
