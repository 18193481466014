import Utility from "common/utilities";
import { TableComponent } from "Components2/TableComponent/TableComponent";
import { ITableDataMapping } from "Model/UI";
import React from "react";
import { IPRItemData, IPRSplitItem } from "../../../../Model/ServerResponse";
import { Accordian } from "../../../Accordian/Accordian";
import "./PRItemsSplitItemDetails.scss";
import { PRSplitItemsRow } from "../PRItemsSplitItemDetails/PRSplitItemsRow";

export interface IPRItemsSplitItemDetailsProp {
  prItemData: IPRItemData;
}

export function PRItemsSplitItemDetails(
  props: IPRItemsSplitItemDetailsProp
){
  return (
    <div className="pr-split-items-accordian">
      <Accordian headerContent={getHeaderUI()} content={getContentUI()} />
    </div>
  );

  function getHeaderUI(){
    return (
      <div className="header1">
        <div className="split-item-header-content">Split Item Details</div>
        <div className="spacer"></div>
        <div className="split-item-count">
          ({props.prItemData.splitItems.length})
        </div>
      </div>
    );
  }

  function getContentUI(){
    let tableHeaderMapping: ITableDataMapping[] = [
      {
        id: "1",
        label: "No",
      },
      {
        id: "2",
        label: "GL Account",
      },
      {
        id: "3",
        label: "Cost Center",
      },
      {
        id: "4",
        label: "Split Amount",
      },
    ];
    return (
      <>
        <div className="pr-split-item-details">{splitItemDetailsUI()}</div>
      </>
    );

    function splitItemDetailsUI(){
      if (
        props.prItemData &&
        !Utility.isArrayEmpty(props.prItemData.splitItems[0])
      ) {
        return (
          <TableComponent
            tableData={props.prItemData.splitItems}
            mappingTableData={tableHeaderMapping}
            getTableRow={getTablePRSplitItemRow}
          ></TableComponent>
        );
      } else {
        return <div className="no-pr-split-items">No Split Items</div>;
      }
    }

    function getTablePRSplitItemRow(
      _index: number,
      row: IPRSplitItem
    ){
      return (
        <PRSplitItemsRow
          index={_index}
          prSplitItemData={row}
          noOfColumns={tableHeaderMapping.length}
        />
      );
    }
  }
}
