import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { IDefaultUIProp } from "Model/UI";
import * as React from "react";
import "./SwitchProfile.scss";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import { SessionStorageService } from "Services";
import { withStyles } from "@material-ui/styles";

export interface ISwitchProfileProp extends IDefaultUIProp {
  open: boolean;
  onClose: (e: any) => void;
}

export const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#0041DC", // Set the color for the focused label
    },
    "& label.Mui-focused.Mui-error": {
      color: "#ff0000", // Set the color for the focused label in the error state
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#0041DC", // Set the border color when the input field is hovered
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0041DC", // Set the border color when the input field is focused
      },
      "&.Mui-error fieldset": {
        borderColor: "#ff0000", // Set the border color in the error state
      },
    },
  },
})(TextField);

export function SwitchProfile(props: ISwitchProfileProp) {
  const [alias, setAlias] = useState("");

  function handleAlias() {
    SessionStorageService.getInstance().debugAlias = alias.toLowerCase();
    window.location.reload();
  }

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        disableBackdropClick={true}
        className="switch-profile-modal"
      >
        <div className="title-text1-container">
          <p className="titleText">Switch Profile</p>
          <div className="spacer"></div>
          <IconButton onClick={(e) => props.onClose(e)}>
            <CancelIcon className="closeButton"></CancelIcon>
          </IconButton>
        </div>

        <DialogContent className="switch-profile-content">
          <Divider
            style={{ marginTop: "10px", width: "100%" }}
            variant="fullWidth"
          />
          <div className="title-text-container">
            <CssTextField
              className="access-label"
              onChange={(e) => setAlias(e.target.value)}
              id="alias"
              label="Enter Alias"
              variant="outlined"
            />
            <div className="spacer"></div>
            <div className="action-container">
              <Button className="refresh-btn" onClick={() => handleAlias()}>
                Validate
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
