import {
  createSelector, createSlice,
} from "@reduxjs/toolkit";
import { RequestStatus } from "Model/Enums";
import { IAppState } from "Store/store";
import { getPendingApprovals1Thunk, getApprovalDetails1Thunk, postDeleteApprovals1Thunk, deleteApprovals1Thunk, systemSlice1Reducer, ISystemSlice1State } from "Store/SystemTemplate/SystemSlice1";

export const swpsSliceInitialState: ISystemSlice1State = {
  pendingApprovalsStatus: RequestStatus.Idle,
  pendingApprovalsError: null,
  selectedApprovalDetailStatus: RequestStatus.Idle,
  selectedApprovalDetailError: null,
  supportMulti: true,
  selectedApproval: null,
  selectedApprovalDetails: null,
  multiSelectedApprovals: [],
  searchQuery: "",
  isAsc: false,
  selectedSubSystem: null,
  fromPosition: 0,
  subSystems: [],
  totalCountForFilter: 0,
  pendingApprovals: [],
  isTabletView: false,

};

const SWPSSlice = createSlice({
  name: "Cart",
  initialState: swpsSliceInitialState,
  reducers: systemSlice1Reducer
});

export const GetPendingApprovals = getPendingApprovals1Thunk(swpsSliceInitialState, SWPSSlice, "Cart", "Cart");

export const GetApprovalDetails = getApprovalDetails1Thunk(SWPSSlice, "Cart", "Cart");

export const PostDeleteApprovals = postDeleteApprovals1Thunk(SWPSSlice, "Cart");

export const DeleteApprovals = deleteApprovals1Thunk(SWPSSlice, "Cart", "Cart");

//Action
export const {
  resetSystemSliceState,
  setPendingApprovalsStatus,
  setSelectedApproval,
  setSelectedApprovalDetails,
  setMultiSelectedApprovals,
  setSearchQuery,
  setIsAsc,
  setSelectedSubSystem,
  setFromPosition,
  setSubSystems,
  setTotalCountForFilter,
  setPendingApprovals,
  addToPendingApprovals,
  reCalculateSelectedApproval,
  setIsTabletView,
} = SWPSSlice.actions;

//Selector
export const SWPSSelector = (state: IAppState) => state.Cart;
export const PendingApprovalsStatusSelector = createSelector(
  SWPSSelector,
  (details) => details?.pendingApprovalsStatus
);
export const PendingApprovalsErrorSelector = createSelector(
  SWPSSelector,
  (details) => details?.pendingApprovalsError
);
export const SelectedApprovalDetailStatusSelector = createSelector(
  SWPSSelector,
  (details) => details?.selectedApprovalDetailStatus
);
export const SelectedApprovalDetailErrorSelector = createSelector(
  SWPSSelector,
  (details) => details?.selectedApprovalDetailError
);
export const SupportMultiSelector = createSelector(
  SWPSSelector,
  (details) => details?.supportMulti
);
export const SelectedApprovalSelector = createSelector(
  SWPSSelector,
  (details) => details?.selectedApproval
);
export const SelectedApprovalDetailsSelector = createSelector(
  SWPSSelector,
  (details) => details?.selectedApprovalDetails
);
export const MultiSelectedApprovalsSelector = createSelector(
  SWPSSelector,
  (details) => details?.multiSelectedApprovals
);
export const SearchQuerySelector = createSelector(
  SWPSSelector,
  (details) => details?.searchQuery
);
export const IsAscSelector = createSelector(
  SWPSSelector,
  (details) => details?.isAsc
);
export const SelectedSubSystemSelector = createSelector(
  SWPSSelector,
  (details) => details?.selectedSubSystem
);
export const FromPositionSelector = createSelector(
  SWPSSelector,
  (details) => details?.fromPosition
);
export const SubSystemsSelector = createSelector(
  SWPSSelector,
  (details) => details?.subSystems
);
export const TotalCountForFilterSelector = createSelector(
  SWPSSelector,
  (details) => details?.totalCountForFilter
);
export const PendingApprovalsSelector = createSelector(
  SWPSSelector,
  (details) => details?.pendingApprovals
);
export const IsTabletViewSelector = createSelector(
  SWPSSelector,
  (details) => details?.isTabletView
);



export const SWPSReducer = SWPSSlice.reducer;

