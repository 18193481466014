import {
  createSelector, createSlice,
} from "@reduxjs/toolkit";
import { RequestStatus } from "Model/Enums";
import { IAppState } from "Store/store";
import { getPendingApprovals1Thunk, getApprovalDetails1Thunk, postDeleteApprovals1Thunk, deleteApprovals1Thunk, systemSlice1Reducer, ISystemSlice1State } from "Store/SystemTemplate/SystemSlice1";

export const pretripSliceInitialState: ISystemSlice1State = {
  pendingApprovalsStatus: RequestStatus.Idle,
  pendingApprovalsError: null,
  selectedApprovalDetailStatus: RequestStatus.Idle,
  selectedApprovalDetailError: null,
  supportMulti: true,
  selectedApproval: null,
  selectedApprovalDetails: null,
  multiSelectedApprovals: [],
  searchQuery: "",
  isAsc: false,
  selectedSubSystem: null,
  fromPosition: 0,
  subSystems: [],
  totalCountForFilter: 0,
  pendingApprovals: [],
  isTabletView: false,
};
const Pretripslice = createSlice({
  name: "Travel",
  initialState: pretripSliceInitialState,
  reducers: systemSlice1Reducer
});

export const GetPendingApprovals = getPendingApprovals1Thunk(pretripSliceInitialState, Pretripslice, "Travel", "Travel");
export const GetApprovalDetails = getApprovalDetails1Thunk(Pretripslice, "Travel", "Travel");
export const PostDeleteApprovals = postDeleteApprovals1Thunk(Pretripslice, "Travel");
export const DeleteApprovals = deleteApprovals1Thunk(Pretripslice, "Travel", "Travel");
//Action
export const {
  resetSystemSliceState,
  setPendingApprovalsStatus,
  setSelectedApproval,
  setSelectedApprovalDetails,
  setMultiSelectedApprovals,
  setSearchQuery,
  setIsAsc,
  setSelectedSubSystem,
  setFromPosition,
  setSubSystems,
  setTotalCountForFilter,
  setPendingApprovals,
  addToPendingApprovals,
  reCalculateSelectedApproval,
  setIsTabletView,
} = Pretripslice.actions;

//Selector
export const PretripSelector = (state: IAppState) => state.Travel;
export const PendingApprovalsStatusSelector = createSelector(
  PretripSelector,
  (details) => details?.pendingApprovalsStatus
);
export const PendingApprovalsErrorSelector = createSelector(
  PretripSelector,
  (details) => details?.pendingApprovalsError
);
export const SelectedApprovalDetailStatusSelector = createSelector(
  PretripSelector,
  (details) => details?.selectedApprovalDetailStatus
);
export const SelectedApprovalDetailErrorSelector = createSelector(
  PretripSelector,
  (details) => details?.selectedApprovalDetailError
);
export const SupportMultiSelector = createSelector(
  PretripSelector,
  (details) => details?.supportMulti
);
export const SelectedApprovalSelector = createSelector(
  PretripSelector,
  (details) => details?.selectedApproval
);
export const SelectedApprovalDetailsSelector = createSelector(
  PretripSelector,
  (details) => details?.selectedApprovalDetails
);
export const MultiSelectedApprovalsSelector = createSelector(
  PretripSelector,
  (details) => details?.multiSelectedApprovals
);
export const SearchQuerySelector = createSelector(
  PretripSelector,
  (details) => details?.searchQuery
);
export const IsAscSelector = createSelector(
  PretripSelector,
  (details) => details?.isAsc
);
export const SelectedSubSystemSelector = createSelector(
  PretripSelector,
  (details) => details?.selectedSubSystem
);
export const FromPositionSelector = createSelector(
  PretripSelector,
  (details) => details?.fromPosition
);
export const SubSystemsSelector = createSelector(
  PretripSelector,
  (details) => details?.subSystems
);
export const TotalCountForFilterSelector = createSelector(
  PretripSelector,
  (details) => details?.totalCountForFilter
);
export const PendingApprovalsSelector = createSelector(
  PretripSelector,
  (details) => details?.pendingApprovals
);
export const IsTabletViewSelector = createSelector(
  PretripSelector,
  (details) => details?.isTabletView
);

export const PretripReducer = Pretripslice.reducer;

