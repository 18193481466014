
import React from "react";
import { IJRA } from "../../../Model/ServerResponse";
import { JRAComments } from "../JRAComments/JRAComments";
import { JRADesign } from "../JRADesign/JRADesign";


export interface IJRADetailsProp {
    jra: IJRA;
}

export function JRADetails(props: IJRADetailsProp)  {
    return (
        <React.Fragment>
            <JRADesign jraData={props.jra}></JRADesign>
            <JRAComments jraData={props.jra}></JRAComments>
        </React.Fragment >
    );
}
