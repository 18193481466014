import { KeyValueList } from "Components2/KeyValueList/KeyValueList";
import { IKeyValue } from "Model/UI";
import React from "react";
import { IPRItemData } from "../../../../Model/ServerResponse";
import { Accordian } from "../../../Accordian/Accordian";
import "./PRItemsOtherDetails.scss";

export interface IPRItemsOtherDetailsProp {
  prItemData: IPRItemData;
}

export function PRItemsOtherDetails(
  props: IPRItemsOtherDetailsProp
){
  return (
    <>
      <Accordian headerContent={getHeaderUI()} content={getContentUI()} />
    </>
  );

  function getHeaderUI(){
    return (
      <>
        <div>Other Details</div>
      </>
    );
  }

  function getContentUI(){
    let _data = getKeyValueListData();
    return <KeyValueList data={_data} />;
  }
  function getKeyValueListData(): IKeyValue[] {
    let dataArray: IKeyValue[] = [
      { _key: "Supplier Part No", value: props.prItemData.supplierPartNumber, seperator: ':' },
      { _key: "Material Group", value: props.prItemData.materialGroup, seperator: ':' },
      { _key: "Payment Terms", value: props.prItemData.paymentTerms, seperator: ':' },
      { _key: "Commdity Code", value: props.prItemData.commodityCode, seperator: ':' },
      { _key: "LineType", value: props.prItemData.lineType, seperator: ':' },
      { _key: "Account Category", value: props.prItemData.accountCategory, seperator: ':' },
    ];
    return dataArray;
  }
}
