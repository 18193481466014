import { IDLPCertificate, IDLPCertificateData, IDLPLiftPointAndCog, IDLPLiftPointAndCogData, IDLPVerificationDetail, IDLPVerificationEquipmentDescription } from "Model/ServerResponse";
import "./DLPVerification.scss";
import React from "react";
import Utility from "common/utilities";
import { Chip } from "@material-ui/core";
import { Accordian } from "Components2/Accordian/Accordian";

export interface IDLPVerificationProp {
    dlpVerificationDetail: IDLPVerificationDetail;
}

export function DLPVerification(props: IDLPVerificationProp)  {
    return (
        <>
            <div data-testid="dlp-verification" className="dlpverification-container">
                {getHeaderUI()}
                {getContentUI()}
            </div>

        </>
    );
    function getHeaderUI() {
        return (
            <>
                <div className="main-container">
                    <div className="title-container">
                        <div className="dlp-section-title"><b>{props.dlpVerificationDetail?.title}</b>
                            <div className="spacer"></div>
                            <div className="col-1">
                                <Chip className={Utility.mergeCssClassNames([
                                    "chip-1",
                                    getChipStyle(props.dlpVerificationDetail?.risk),
                                ])} label={Utility.stringToPascalCase(props.dlpVerificationDetail?.risk)}></Chip>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    function getContentUI() {
        return (
            <>
                <div className="subtitle-container">
                    <Accordian headerContent={getEquipmentDescHeaderUI(props.dlpVerificationDetail.equipmentDescription)} content={getEquipmentDescContentUI(props.dlpVerificationDetail.equipmentDescription)} />
                    <Accordian headerContent={getCertificationHeaderUI(props.dlpVerificationDetail.certificationVerification)} content={getCertificationContentUI(props.dlpVerificationDetail.certificationVerification?.items)} />
                    <Accordian headerContent={getLiftPointsCogsHeaderUI(props.dlpVerificationDetail.liftPointsAndCogs)} content={getLiftPointsCogsContentUI(props.dlpVerificationDetail.liftPointsAndCogs?.items)} />
                </div>
            </>
        );
    }

    function getEquipmentDescHeaderUI(row: IDLPVerificationEquipmentDescription)  {
        return (
            <>
                <p className="dlp-section-title"><b>{row.title}</b></p>
            </>
        );
    }

    function getEquipmentDescContentUI(row: IDLPVerificationEquipmentDescription)  {
        return (
            <>
                <p>{row.description}</p>
            </>
        );
    }


    function getCertificationHeaderUI(row: IDLPCertificate)  {
        let statusColor = formatCertification(row.items)
        return (
            <>
                <p className="dlp-section-title"><b>{row.header}</b> <span className={statusColor === true ? 'verification-count text-red' : 'verification-count'}><b>({row.count?.toString().padStart(2, '0')})</b></span></p>
            </>
        );
    }

    function getCertificationContentUI(row: IDLPCertificateData[])  {
        return (
            <>
                <div className="key-value-list ">
                    <div className="key-value">
                        <p className="key">Type</p>
                        <p className="value load-Value"><strong>Certification Expiry Date</strong></p>
                    </div>
                    <br></br>
                    {
                        row?.map(
                            (e: IDLPCertificateData) => {
                                return (
                                    <div key={Utility.createUniqueId(e.date,e.status)} className="key-value">
                                        <p className="key">{e.type}</p>
                                        <p className="value load-Value"><strong className={e.status?.toLowerCase() !== "valid" ? 'text-red' : ''}>{e.date}</strong></p>
                                    </div>
                                );
                            }
                        )
                    }
                </div>
            </>
        );
    }


    function getLiftPointsCogsHeaderUI(row: IDLPLiftPointAndCog)  {
        return (
            <>
                <p className="dlp-section-title"><b>{row.header}</b></p>
            </>
        );
    }

    function getLiftPointsCogsContentUI(row: IDLPLiftPointAndCogData[])  {
        return (
            <>
                <div className="key-value-list ">
                    {
                        row?.map(
                            (e: IDLPLiftPointAndCogData) => {
                                return (
                                    <div key={Utility.createUniqueId(e.answer,e.type)} className="key-value">
                                        <p className="key">{e.type}</p>
                                        {e.type === "Color Code if Applicable" ?
                                            <p style={{ height: "20px", width: "20px", marginTop: "0px", borderRadius: "50%", backgroundColor: e.answer, }}></p> :
                                            <p className="value load-Value"><strong>{e.answer}</strong></p>
                                        }

                                    </div>
                                );
                            }
                        )
                    }
                </div>
            </>
        );
    }

    function formatCertification(items: any) {
        let statusColor: boolean = false;
        for (let item of items) {
            if (item.status === "Invalid") {
                statusColor = true;
                break; 
            }
        }        
        return statusColor;
    }

    function getChipStyle(chipText: string | undefined): string {
        let result = "";

        switch (chipText?.toLowerCase()) {
            case "high":
                result = "chip-1-high";
                break;
            case "medium":
                result = "chip-1-medium";
                break;
            case "low":
                result = "chip-1-low";
                break;
        }
        return result;
    }



}