import React from "react";
import {RequestStatus} from "Model/Enums";
import {Dialog, DialogContent, DialogActions, Button, CircularProgress} from "@material-ui/core";
import "./ForwardStatusModal.scss";

export interface IForwardStatusModalProp {
    open: boolean;
    status: RequestStatus | undefined;
    onClose?: any;
    forwardApproverName : string | undefined;
}

export function ForwardStatusModal(props: IForwardStatusModalProp) {

    function handleClose(e: any) {
        if (props.onClose) {
            props.onClose(e);
        }
    }


    return (
            <Dialog
                onClose={props.onClose}
                className="forward-status-modal"
                maxWidth={"xs"}
                open={props.open}
                disableBackdropClick={true}
            >
                <div className="forward-status-modal-content">
                    <DialogContent>{getForwardStatusUI(props.status)}</DialogContent>
                </div>
            </Dialog>
    );

    function getForwardStatusUI(forwardStatus: string | undefined) {
        switch (forwardStatus) {
            case RequestStatus.Loading:
                return (
                    <div className="forward-status-loading">
                    <div data-testid="forward-status-loading-icon" className="forward-status-loading-icon">
                      <CircularProgress />
                    </div>
                    <div className="forward-status-loading-text">
                      Please wait .. while we are forwarding your request.
                    </div>
                  </div>
                );
            case RequestStatus.Success:
                return (
                    <div className="forward-status-success">
                        <div className="forward-status-success-icon">
                            Success
                        </div>
                        <div className="forward-status-success-text">
                            <span>The purchase order was forwarded to {props.forwardApproverName} </span>
                        </div>
                        <DialogActions className="action-container">
                            <Button className="action-btn save-btn" onClick={handleClose}>
                            OK
                            </Button>
                        </DialogActions>
                    </div>
                );
            case RequestStatus.Failed:
                return (
                    <div className="forward-status-failed">
                        <div className="forward-status-failure-icon">
                            Failed
                        </div>
                        <div className="forward-status-failure-text">
                            <span>Sorry, the purchased request order couldn't be forwarded.</span>
                        </div>
                    </div>
                );
            default:
                return "";
        }
    }
}

export { RequestStatus };
