
import React from "react";
import { ITableDataMapping } from "../../../Model/UI";
import { IPOTab } from "../../../Model/ServerResponse";
import { TableComponent } from "../../TableComponent/TableComponent";
import { TableCell, TableRow } from "@material-ui/core";
import Utility from "../../../common/utilities";


export interface IPOApproversProp {
  poTabData: IPOTab | undefined;
}

export function POApprovers(props: IPOApproversProp){
  let tableHeaderMapping: ITableDataMapping[] = [
    {
      id: "approvalLevel",
      label: "Level/Code",
    },
    {
      id: "approverName",
      label: "Approver",
    },
    {
      id: "forwardedTo",
      label: "Forwarded to/ Delegated to",
    },
    {
      id: "approvalStatus",
      label: "Status",
    },
    {
      id: "approvalDate",
      label: "Action Date",
    },
  ];

  return <div data-testid="po-approvers-tab" className="po-approvers-tab">{tabUI()}</div>;

  function tabUI() {
    if (props.poTabData && !Utility.isArrayEmpty(props.poTabData.data)) {
      return (
        <TableComponent
          tableData={props.poTabData.data}
          mappingTableData={tableHeaderMapping}
          getTableRow={getTableApproversRow}
        ></TableComponent>
      );
    } else {
      return <div className="no-approvers">No Approvers</div>;
    }
  }

  function getTableApproversRow(index: number, row: any){
    return (
      <>
        <TableRow className="spacing"></TableRow>
        <TableRow data-testid="table-row" className="main-row" key={index}>
          <TableCell className="approval-level">{row.approvalLevel}</TableCell>
          <TableCell className="approver-name">{row.approverName}</TableCell>
          <TableCell ><span className="approver-forwarded">{row.forwardedTo ? row.forwardedTo : '-'}</span></TableCell>
          <TableCell
            className="approver-status"
          >
            <span data-testid="approver-status-cell" className={getApproverStatusStyle(row.approvalStatus)}> {row.approvalStatus}</span>
          </TableCell>
          <TableCell className="approval-date">{row.approvalDate}</TableCell>
        </TableRow>
      </>
    );

    function getApproverStatusStyle(status: string): string {
      switch (status.toLowerCase()) {
        case "approved":
          return "approved";
        case "rejected":
          return "rejected";
        case "pending":
          return "pending";
        case "forwarded":
          return "forwarded";
        default:
          return "";
      }
    }
  }

}
