import { SearchFilterToolbar } from "../../SearchFilterToolbar/SearchFilterToolbar";
import { Message1 } from "../../MessageTemplates/Message1/Message1";
import React, { useEffect } from "react";
import { ListView } from "../../ListView/ListView";
import { ISystemListViewBaseProp } from "../../../Model/UI";
import { useSelector } from "react-redux";
import { IJRA } from "../../../Model/ServerResponse";
import { RequestStatus } from "../../../Model/Enums";
import {
    IsAscSelector,
    MultiSelectedApprovalsSelector,
    PendingApprovalsSelector,
    SearchQuerySelector,
    SelectedApprovalSelector,
    SelectedSubSystemSelector,
    setIsAsc,
    setMultiSelectedApprovals,
    setFromPosition,
    setSearchQuery,
    setSelectedApproval,
    setSelectedSubSystem,
    SubSystemsSelector,
    TotalCountForFilterSelector,
    GetPendingApprovals,
    resetSystemSliceState,
    PendingApprovalsStatusSelector,
    PendingApprovalsErrorSelector,
    SupportMultiSelector,
    jraSliceInitialState,
    IsTabletViewSelector
} from "../../../Store/JRA/JRASlice";
import { useAppDispatch } from "../../../Store/store";
import "./JRAListView.scss";
import { JRACard } from "../JRACard/JRACard";

export interface IJRAListViewProp<T> extends ISystemListViewBaseProp<T> { }

export function JRAListView(props: IJRAListViewProp<IJRA>) {
    let appDispatch = useAppDispatch();

    let supportMulti = useSelector(SupportMultiSelector);

    let pendingApprovalsStatus = useSelector(PendingApprovalsStatusSelector);
    let pendingApprovalsError = useSelector(PendingApprovalsErrorSelector);

    let selectedApproval = useSelector(SelectedApprovalSelector);
    let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);
    let subSystems = useSelector(SubSystemsSelector);
    let totalCountForFilter = useSelector(TotalCountForFilterSelector);
    let pendingApprovals = useSelector(PendingApprovalsSelector);

    let searchQuery = useSelector(SearchQuerySelector);
    let isAsc = useSelector(IsAscSelector);
    let selectedSubSystem = useSelector(SelectedSubSystemSelector);

    let isTabletView = useSelector(IsTabletViewSelector);
    useEffect(() => {
        appDispatch(resetSystemSliceState(jraSliceInitialState));
    }, []);

    //Set up data
    useEffect(() => {
        appDispatch(setSelectedApproval(null));
        appDispatch(GetPendingApprovals());
    }, [searchQuery, isAsc, selectedSubSystem]);

    return (
        <div className="jra-list-container">
            <SearchFilterToolbar
                multiSelectedCount={multiSelectedApprovals?.length}
                title="JRA"
                error={pendingApprovalsStatus === RequestStatus.Failed}
                totalCount={totalCountForFilter}
                count={pendingApprovals?.length}
                subSystems={subSystems}
                loading={pendingApprovalsStatus === RequestStatus.Loading}
                onRefresh={handleRefresh}
                onSearch={handleSearchChange}
                onSort={handleSortChange}
                onSubSystemChange={handleSubSystemChange}
            ></SearchFilterToolbar>

            {listUI()}
        </div>
    );

    function getRowUI(data: IJRA, multiSelectUI?)  {
        return (
                <JRACard
                    supportMulti={supportMulti}
                    multiSelectUI={multiSelectUI}
                    jra={data}
                ></JRACard>
        );
    }

    function listUI()  {
        if (pendingApprovalsStatus === RequestStatus.Failed) {
            return errorUI();
        }

        return (
            <>
                <ListView
                    className="jra-list"
                    totalCount={totalCountForFilter}
                    dataList={pendingApprovals}
                    selectedData={selectedApproval}
                    multiSelectedData={multiSelectedApprovals}
                    loading={pendingApprovalsStatus === RequestStatus.Loading}
                    supportMulti={supportMulti}
                    quickSelect={true}
                    getRowUI={getRowUI}
                    getUniqueDataIdentifier={getUniqueDataIndentifier}
                    onSelectChange={handleSelectChange}
                    onMultiSelectChange={handleMultiSelectChange}
                    onFromPositionChange={handleFromPositionChange}
                    getEmptyIndicatorUI={emptyIndicatorUI}
                    isTabletView={isTabletView}
                ></ListView>

                {multiSelectedApprovals?.length == 1 && actionContainer()}
            </>
        );
    }

    function emptyIndicatorUI()  {
        return (
            <div className="message-container">
                <Message1
                    title="No approvals."
                    imgSrc="CardAssets/NoPendingRequests.svg"
                ></Message1>
            </div>
        );
    }

    function errorUI() {
        if (pendingApprovalsError) {
            return (
                <div className="message-container">
                    <Message1
                        title={pendingApprovalsError.message}
                        imgSrc="CardAssets/error.svg"
                        subTitle={pendingApprovalsError.description}
                    ></Message1>
                </div>
            );
        }
        return "";
    }

    function handleRefresh(e: any) {
        //Reset all state
        appDispatch(resetSystemSliceState(jraSliceInitialState));
        //Get approvals
        appDispatch(GetPendingApprovals());
    }

    function handleSearchChange(e: any, _query: string) {
        appDispatch(setSearchQuery(_query));
    }

    function handleSortChange(e: any, _isAsc: boolean) {
        appDispatch(setIsAsc(_isAsc));
    }

    function handleSubSystemChange(e: any, _subSystem: any) {
        appDispatch(setSelectedSubSystem(_subSystem));
    }

    function getUniqueDataIndentifier(_data: IJRA): string {
        //Return unique identifier
        if (_data?.id !== undefined && _data?.id !== null) {
            return _data.id.toString();
        }
        return 'N/A'
    }

    function handleFromPositionChange(_fromPostion: number): void {
        appDispatch(setFromPosition(_fromPostion));
        appDispatch(GetPendingApprovals());
    }

    function handleMultiSelectChange(e: any, _values: any) {
        appDispatch(setMultiSelectedApprovals(_values));
    }

    function handleSelectChange(e: any, _value: any) {
        appDispatch(setSelectedApproval(_value));
    }

    function actionContainer()  {
        if (props.getActionsUI) {
            return (
                <div className="action-container">
                    {props.getActionsUI(false, multiSelectedApprovals as IJRA[])}
                </div>
            );
        }
        return <></>;
    }
}
