import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { ScreenLoaderSelector } from "../../Store/Common/CommonSlice";
import "./ScreenLoader.scss";

export function ScreenLoader() {
  let screenLoader = useSelector(ScreenLoaderSelector);

  return (
    <>
      {screenLoader && (
        <div className="screen-loader">
          <CircularProgress />
        </div>
      )}
    </>
  );
}
