import React, { useRef, useState, useEffect } from "react"

export function useElementOnScreen(options: any): [React.MutableRefObject<null>, boolean] {
  const containerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  const callbackFunction = (entries: any) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }
 
  useEffect(() => {
    
    if (!window.IntersectionObserver) return
    const observer = new IntersectionObserver(callbackFunction, options)
    // @ts-ignore
    if (containerRef.current) observer.observe(containerRef.current)

    return () => {
      // @ts-ignore
      if (containerRef.current) observer.unobserve(containerRef.current)
    }
  }, [containerRef, options])

  return [containerRef, isVisible]
}