import React from "react";
import { IDLP } from "Model/ServerResponse";
import { IKeyValue } from "Model/UI";
import "./DLPDetailKeyValueList.scss";
import { KeyValueList } from "Components2/KeyValueList/KeyValueList";

export interface IDLPDetailKeyValueListProp {
  dlp: IDLP;
}

export function DLPDetailKeyValueList(props: IDLPDetailKeyValueListProp) {
  function getHeaderTableUI()  {
    let _data = getKeyValueListData();
    return (
      <div className="dlp-details-key-value">
        <KeyValueList data={_data} />
      </div>
    );
  }

  function getKeyValueListData(): IKeyValue[] {
    let dataArray: IKeyValue[] = [];
    if (props.dlp.locationName)
      dataArray.push({
        _key: "Location",
        value: props.dlp.locationDetails,
        seperator: ":",
      });
    if (props.dlp.date)
      dataArray.push({
        _key: "Job Date",
        value: props.dlp.date,
        seperator: ":",
      });
    if (props.dlp.businessLine)
      dataArray.push({
        _key: "Business Line",
        value: props.dlp.businessLine,
        seperator: ":",
      });
    return dataArray;
  }

  return getHeaderTableUI();
}
