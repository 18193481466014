import React from "react";
import {
  IQuestDetailData,
  IQuestTab,
} from "../../../Model/ServerResponse";
import { QuestDetailsRow } from "../QuestDetailsRow/QuestDetailsRow";

export interface IQuestDetailsProp {
  questDetailsTabData: IQuestTab | undefined ;
}

export function QuestDetails(props: IQuestDetailsProp) {
  return (
    <>
      <div className="quest-details-section">{getSection()}</div>
    </>
  );

  function getSection() {
    let _questDetailsData = props.questDetailsTabData?.data as IQuestDetailData[];
    return (
      <>
        {_questDetailsData.map((e: IQuestDetailData) => {
          return <QuestDetailsRow key={e.title} rowData={e} />;
        })}
      </>
    );
  }
}


