import {
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { IDefaultUIProp } from "Model/UI";
import * as React from "react";
import "./PreTripTravellerDetails.scss";
import { IPretripTravellerData } from "Model/ServerResponse";

export interface IPreTripTravellerDetails extends IDefaultUIProp {
  travellerData: IPretripTravellerData | undefined;
  open: boolean;
  handleClose: (e: any) => void;
}

export function PreTripTravellerDetails(
  props: IPreTripTravellerDetails
) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      disableBackdropClick={true}
      className="pretrip-traveller-details-modal"
    >
      <div className="title-text1-container">
        <p className="titleText">Traveller Details</p>
        <div className="spacer"></div>
        <IconButton onClick={(e) => props.handleClose(e)}>
          <CancelIcon className="closeButton"></CancelIcon>
        </IconButton>
      </div>

      <DialogContent className="pretrip-traveller-details-content">
        <Divider
          style={{ marginTop: "10px", width: "100%", marginBottom: "20px" }}
          variant="fullWidth"
        />
        <DialogContentText>{getTravellerDetails()}</DialogContentText>
      </DialogContent>
    </Dialog>

  );

  function getTravellerDetails() {
    if (props.travellerData != null) {
      return (
        <>
          <div className="subtitle-container">
            <div className="key-value-list ">
              <div className="key-value key-value">
                <div className="col-1">
                  <div className="value">
                    <strong>{props.travellerData.name}</strong>
                  </div>
                  <p className="key">{props.travellerData.email}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="subtitle-container">
            <div className="key-value-list ">
              <div className="key-value key-value">
                {props.travellerData.mobileNumber != null ? (
                  <>
                    <p className="key">Phone :</p>
                    <div className="value">
                      <strong>{props.travellerData.mobileNumber}</strong>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="subtitle-container">
            <div className="key-value-list ">
              <div className="key-value key-value">
                {props.travellerData.nationality != null ? (
                  <>
                    {" "}
                    <p className="key">Nationality :</p>
                    <div className="value">
                      <strong>{props.travellerData.nationality}</strong>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="subtitle-container">
            <div className="key-value-list ">
              <div className="key-value key-value">
                {props.travellerData.company != null ? (
                  <>
                    <p className="key">Company :</p>
                    <div className="value">
                      <strong>{props.travellerData.company}</strong>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="subtitle-container">
            <div className="key-value-list ">
              <div className="key-value key-value">
                {props.travellerData.comments != null ? (
                  <>
                    <p className="key">Comments :</p>
                    <div className="value">
                      <strong>{props.travellerData.comments}</strong>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return <div className="no-approvers">No Information</div>;
    }
  }
}
