import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Constants } from "common/constants";
import { IApprovalOperation } from "Model/ServerResponse/IApprovalOperation";
import { normalize } from "normalizr";
import { ApprovalService } from "Services";
import { LoggingService } from "Services/LoggingService";
import { IApprovalOperationEntity, IApprovalSystemEntity, IEntity } from "../../Model/UI";
import { IAppState } from "../store";

export interface INewApprovalOperationSelectorState {
  newApprovalOperationKeys: string[];
  newApprovalOperationsMap: IEntity<IApprovalOperationEntity>;
  newApprovalSystemsMap: IEntity<IApprovalSystemEntity>;
}

const initialState: INewApprovalOperationSelectorState = {
  newApprovalOperationKeys: [],
  newApprovalOperationsMap: {},
  newApprovalSystemsMap: {}
};

export const SetNewApprovalOperations = createAsyncThunk("NewApprovalOperationSelectorSlice/setNewApprovalOperations", async (approvalOperations: IApprovalOperation[], thunkApi): Promise<void> => {
  let loggingService = LoggingService.getInstance();
  try {
    let dispatch = thunkApi.dispatch;
    let selfActions = NewApprovalOperationSelectorSlice.actions;

    //Normalize the new approvals
    let normalizedData = normalize(approvalOperations, [Constants.approvalOperationEntity]);
    const _naoKeys = normalizedData.result;
    const _naoMap = normalizedData.entities["approvalOperations"];
    const _nsMap = normalizedData.entities["approvalSystems"];

    if (_naoKeys.length > 0 && _naoMap && _nsMap) {
      dispatch(selfActions.setNewApprovalOperationKeys(_naoKeys));
      dispatch(selfActions.setNewApprovalOperationMaps(_naoMap));
      dispatch(selfActions.setNewApprovalSystemMaps(_nsMap));
    }
  }
  catch (err) {
    await loggingService.logError("NewApprovalOperationsSlice : SetNewApprovalOperations thunk", "", JSON.stringify(err, Object.getOwnPropertyNames(err)), JSON.stringify(err, Object.getOwnPropertyNames(err)));
  }
});

const NewApprovalOperationSelectorSlice = createSlice({
  name: "NewApprovalOperationSelectorSlice",
  initialState: initialState,
  reducers: {
    setNewApprovalOperationKeys(state: INewApprovalOperationSelectorState,
      action: PayloadAction<string[]>) {
      state.newApprovalOperationKeys = action.payload;
    },
    setNewApprovalOperationMaps(state: INewApprovalOperationSelectorState,
      action: PayloadAction<IEntity<IApprovalOperationEntity>>) {
      state.newApprovalOperationsMap = action.payload;
    },
    setNewApprovalSystemMaps(state: INewApprovalOperationSelectorState,
      action: PayloadAction<IEntity<IApprovalSystemEntity>>) {
      state.newApprovalSystemsMap = action.payload;
    }
  }
});

//Selector
export const NewApprovalOperationSliceSelector = (state: IAppState) =>
  state.NewApprovalOperationSelector;
export const NewApprovalOperationKeysSelector = createSelector(
  NewApprovalOperationSliceSelector,
  (details) => details.newApprovalOperationKeys
);
export const NewApprovalOperationMapsSelector = createSelector(
  NewApprovalOperationSliceSelector,
  (details) => details.newApprovalOperationsMap
);
export const NewApprovalSystemMapsSelector = createSelector(
  NewApprovalOperationSliceSelector,
  (details) => details.newApprovalSystemsMap
);

export const NewApprovalOperationSelectorReducer = NewApprovalOperationSelectorSlice.reducer;
