import React from "react";
import { ICartItemData, ICartShippingInformation, ICartTab } from "../../../Model/ServerResponse";
import "./SWPSItems.scss";
import { TableComponent } from "../../TableComponent/TableComponent";
import { TableCell, TableRow } from "@material-ui/core";
import { ITableDataMapping } from "../../../Model/UI/ITableDataMapping";
import Utility from "../../../common/utilities";
import { IKeyValue } from "Model/UI";
import { KeyValueList } from "Components2/KeyValueList/KeyValueList";

export interface ISWPSItemsProp {
    cartTabData: ICartTab | undefined;
}

export function SWPSItems(props: ISWPSItemsProp) {

    let tableHeaderMapping: ITableDataMapping[] =
        [
            {
                id: "partNumber",
                label: "Part No",
            },
            {
                id: "itemDescription",
                label: "Item",
            },
            {
                id: "quantity",
                label: "Quantity",
            },
            {
                id: "unitPrice",
                label: "Unit cost",
                sorting: true,
            }
        ];

    return (
        <div className="swps-items-tab">
            {tabUI()}
        </div>
    );

    function tabUI(){

        if (props.cartTabData && !Utility.isArrayEmpty(props.cartTabData.data)) {
            return <TableComponent tableData={props.cartTabData.data} mappingTableData={tableHeaderMapping} getTableRow={getTableItemsRow}></TableComponent>
        }
        else {
            return (<div className="no-items">
                No Items
            </div>);
        }
    }

    function getTableItemsRow(index: number, rowData: any){

        let row1: React.ReactNode[] = [];
        let row2: React.ReactNode[] = [];
        let rows: React.ReactNode[] = [];

        // add cells
        for (let _index = 0; _index < tableHeaderMapping.length; _index++) {
            const element = tableHeaderMapping[_index];
            row1.push(
                <TableCell className="table-cell" key={_index} >{rowData[element.id]}</TableCell>
            );
        }
        // pushing cells to Row
        let elementRow = <TableRow className="main-row">{row1}</TableRow>
        let spacing = <TableRow className="spacing"></TableRow>

        rows.push(spacing);
        rows.push(elementRow);
        
        // For subrow
        row2.push(
            SubRowUI((rowData as ICartItemData).shippingInformation)
        );
        let subRow = <TableRow className="sub-row"><TableCell colSpan={tableHeaderMapping.length}>{row2}</TableCell></TableRow>
        
        rows.push(subRow);

        const result = <>{rows.map((r, index) => <React.Fragment key={Utility.createUniqueId(tableHeaderMapping[index].id,index)}>{r}</React.Fragment>)}</>;
        return result;
    }

    function SubRowUI(shippingInfo: ICartShippingInformation){

        function getHeaderTableUI() {
            let _data = getKeyValueListData();
            return (
                <div className="sub-row-content">
                     <KeyValueList data={_data}/>
                </div>
            );
        }

        function getKeyValueListData(): IKeyValue[] {
            let dataArray: IKeyValue[] = [
                { _key: "Supplier", value: shippingInfo.supplier },
                { _key: "Cost Center", value: shippingInfo.costCenter },
                { _key: "Delivery Address", value: shippingInfo.shipToAddress },
                { _key: "EDD", value: shippingInfo.deliveryDate },
            ];
            return dataArray;
        }

        return getHeaderTableUI();
    }

}