
import React from "react";
import { IEjourney } from "../../../Model/ServerResponse";
import { EJourneyDetailsComplience } from "./DetailCompliance/EJourneyDetailCompliance";
import { EJourneyDetailsDrivingrisk } from "./DetailDrivingRisk/EJourneyDetailDrivingRisk";
import { EJourneyDetailsTrip } from "./DetailTrip/EJourneyDetailTrip";
import { EJourneyDetailsVehicle } from "./DetailVehicle/EJourneyDetailVehicle";
import { EJourneyDriverFatigue } from "./DriverFatigue/EJourneyDriverFatigue";


export interface IEJItemsProp {
    ej: IEjourney;
}

export function EJourneyDetails(props: IEJItemsProp)  {
    return (
        <React.Fragment>
            <EJourneyDetailsTrip ejItemData={props.ej}></EJourneyDetailsTrip>
            <EJourneyDriverFatigue ejItemData={props.ej}></EJourneyDriverFatigue>
            <EJourneyDetailsVehicle ejItemData={props.ej} />
            <EJourneyDetailsDrivingrisk ejItemData={props.ej}></EJourneyDetailsDrivingrisk>
            <EJourneyDetailsComplience ejItemData={props.ej}></EJourneyDetailsComplience>
        </React.Fragment >
    );
}
