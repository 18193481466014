import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import Utility from "../../../../common/utilities";
import {
  IEjourney,
  IEjourneyDetailData,
} from "../../../../Model/ServerResponse";
import { EJ_ITEMS_TAB_ID } from "../../EJourneyUtil";
import "./EJourneyDetailTrip.scss";

export interface IEJourneyDetailsTripProps {
  ejItemData: IEjourney;
}

export function EJourneyDetailsTrip(
  props: IEJourneyDetailsTripProps
)  {
  const tripData: IEjourneyDetailData = props.ejItemData.tabs.find(
    (td) => td.tabId === EJ_ITEMS_TAB_ID
  )?.data as IEjourneyDetailData;
  let mappedData = geteJourneyTripMapping(tripData);
  let stopsMappedData: any = getStopsMapping();
  return (
    <>
      {!Utility.isArrayEmpty(mappedData) ? (
        <Grid className="cardParent">
          <div className="cardContainer">
            {/* Question category */}
            <Grid container className="questionCategory">
              <b className="panelheadertext">Trip</b>
            </Grid>

            <Grid>
              {/* Row 1*/}
              <Grid container className="firstRowContainer">
                {/* Start */}
                <Grid container xs={4} sm={4} className="destinationContainer">
                  <Typography className="titleText">{"Start"}</Typography>
                  <Typography className="bodyText">
                    {mappedData.field1}
                  </Typography>

                  <Grid container className="riskContainer">
                    <Typography className="riskLabel">
                      {convertDateTimeFormat(props.ejItemData.fromDate)}
                    </Typography>
                  </Grid>
                </Grid>

                {/* End */}
                <Grid
                  container
                  xs={4}
                  sm={4}
                  className="destinationContainer borderbox"
                >
                  <Typography className="titleText">{"End"}</Typography>
                  <Typography className="bodyText">
                    {mappedData.field2}
                  </Typography>

                  <Grid container className="riskContainer">
                    <Typography className="riskLabel">
                      {convertDateTimeFormat(props.ejItemData.toDate)}
                    </Typography>
                  </Grid>
                </Grid>

                {/* Duration */}
                <Grid
                  container
                  xs={4}
                  sm={4}
                  className="destinationContainer borderbox"
                >
                  <Typography className="titleText">
                    {"Distance & Duration"}
                  </Typography>
                  <Typography className="bodyText">
                    {mappedData.field3 + " " + mappedData.field4}
                  </Typography>

                  <Grid className="riskContainer">
                    {getDuration(
                      props.ejItemData.fromDate,
                      props.ejItemData.toDate
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/*Row 2*/}
              {!Utility.isArrayEmpty(tripData.stops) ? (
                <Grid container className="secondRowContainer">
                  {/* Stops */}
                  <Grid
                    lg={4}
                    md={4}
                    xs={4}
                    sm={4}
                    className="questionTitleContainer"
                  >
                    <Typography className="questionTitleText">
                      {"Stops"}
                    </Typography>
                    <Typography className="answerTitleText">
                      {tripData.stops.length}
                    </Typography>
                  </Grid>
                  <Grid
                    lg={8}
                    md={8}
                    xs={8}
                    sm={8}
                    className="answerTitleContainer"
                  >
                    {showStops()}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </div>
        </Grid>
      ) : (
        <div className="noDetails">No trip details to show.</div>
      )}
    </>
  );

  function geteJourneyTripMapping(response: any) {
    let Mappings: any;
    let cardDataModel: any = {};

    Mappings = [
      {
        sourceProp: "field1",
        targetProp: "origin",
      },
      {
        sourceProp: "field2",
        targetProp: "destination",
      },
      {
        sourceProp: "field3",
        targetProp: "distance",
      },
      {
        sourceProp: "field4",
        targetProp: "unit",
      },
    ];

    Mappings.forEach((mapping: any) => {
      cardDataModel[mapping.sourceProp] = response[mapping.targetProp];
    });
    return cardDataModel;
  }

  function getStopsMapping() {
    let tableHeaderMapping: any = [];
    tableHeaderMapping = [
      {
        propName: "checkinDate",
        label: "Time",
      },
      {
        propName: "stopName",
        label: "Location",
      },
    ];

    return tableHeaderMapping;
  }

  function convertDateTimeFormat(date?: string) {
    return moment(date).format("DD MMM YY HH:mm").toString();
  }

  function getDuration(fromdate?: any, toDate?: any) {
    const then = moment(new Date(fromdate));
    const now = moment(new Date(toDate));
    const diff: any = moment.duration(now.diff(then));

    return (
      <Grid container className="durationContainer">
        <Grid item className="durationNum">
          {parseInt(diff.asDays())}
        </Grid>
        <Grid item className="durationLabel">
          {" "}
          Day{" "}
        </Grid>
        <Grid item className="durationLabel">
          {" "}
          :{" "}
        </Grid>
        <Grid item className="durationNum">
          {diff._data.hours}
        </Grid>
        <Grid item className="durationLabel">
          {" "}
          Hr{" "}
        </Grid>
        <Grid item className="durationLabel">
          {" "}
          :{" "}
        </Grid>
        <Grid item className="durationNum">
          {diff._data.minutes}
        </Grid>
        <Grid item className="durationLabel">
          {" "}
          Min
        </Grid>
      </Grid>
    );
  }

  function showStops() {
    return (
      <React.Fragment>
        {
          <Grid>
            <TableContainer className="tableContainer">
              <Table>
                <TableHead>
                  <TableRow className="tabBorder">
                    {stopsMappedData.map((item: any, index: any) => (
                      <TableCell key={Utility.createUniqueId(item,index)} style={{ borderBottom: "none" }}>
                        <div className="tableHeaderCell">
                          <div className="tableHeaderTextStyle">
                            {item.label}
                          </div>
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="tableBodyConatiner tabBorder">
                  {tripData.stops.map((row: any, index: any) =>
                    getCertTableRows(row, index)
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        }
      </React.Fragment>
    );
  }

  function getCertTableRows(row: any, index1: any) {
    let elementColumnArray  : React.ReactNode[] = [];
    let returnedArray : React.ReactNode[]  = [];

    for (let index = 0; index < stopsMappedData.length; index++) {
      const element: any = stopsMappedData[index];
      elementColumnArray.push(tableCellsTemplate(row[element.propName], index));
    }
    let elementColumn = (
      <TableRow key={index1} className="tableRowContainer">
        {elementColumnArray}
      </TableRow>
    );
    returnedArray.push(elementColumn);
    return returnedArray;
  }

  function tableCellsTemplate(property: any, index: any) {
    let columnJSX: any;
    if (index === 0) {
      columnJSX = (
        <TableCell key={index} className="datacol1">
          {convertDateTimeFormat(property)}
        </TableCell>
      );
    } else {
      columnJSX = (
        <TableCell key={index} className="datacol3">
          {property}
        </TableCell>
      );
    }
    return columnJSX;
  }
}
