import { EJ_ITEMS_TAB_ID } from "Components2/EJourney/EJourneyUtil";
import {
  IEjourney,
  IEjourneyDetailData,
  IEjourneyVehicle,
} from "Model/ServerResponse";
import React from "react";
import { Accordian } from "../../../Accordian/Accordian";
import "./EJourneyDetailVehicle.scss";

export interface IEjourneyDetailVehicleProps {
  ejItemData: IEjourney;
}

export function EJourneyDetailsVehicle(
  props: IEjourneyDetailVehicleProps
)  {
  const tripData: IEjourneyDetailData = props.ejItemData.tabs.find(
    (td) => td.tabId === EJ_ITEMS_TAB_ID
  )?.data as IEjourneyDetailData;
  let vehicles = tripData?.vehicles;
  return (
    <div className="vehicle-details">
      <div className="panelheader">
        <b className="panelheadertext">Vehicle & Traveller</b>
        <span className="header-right panelheadertext">
          <b>{("0" + vehicles?.length).slice(-2)}</b>
        </span>
      </div>

      {vehicles.map((element: any) => (
        <div key={element} className="border-bottom-container">
          <Accordian
            headerContent={getHeaderUI(element)}
            content={getContentUI(element)}
          />
        </div>
      ))}
    </div>
  );

  function getHeaderUI(element: IEjourneyVehicle)  {
    return (
      <table className="tableLayout">
        <tr>
          <td className="td-color">Vehicle</td>
          <td className="td-color-black">
            <b>{element.vehicleType}</b>
          </td>
          <td className="td-color-black">
            <b>{element.registrationPlate}</b>
          </td>
        </tr>
      </table>
    );
  }

  function getContentUI(element: IEjourneyVehicle)  {
    return (
      <div className="tableMainLayout">
        <table className="tableLayout">
          {element.loadType != null ? (
            <>
              <tr>
                <td className="td-color">Load Type</td>
                <td className="td-color-black">
                  <b>{element.loadType}</b>
                </td>
              </tr>
              <br></br>
            </>
          ) : (
            <></>
          )}
          <tr>
            <td className="traveller-header">Traveller Type</td>
            <td className="traveller-header">Name</td>
            <td className="traveller-header">Contact</td>
            <td className="traveller-header">RAG Score</td>
          </tr>
          <p></p>
          {element.drivers.map((driver: any) => {
            return (
              <>
                <tr>
                  <td className="td-color">{driver.travellerType}</td>
                  <td>
                    <b>{driver.name}</b>
                  </td>
                  <td>
                    <b>{driver.phoneNumber}</b>
                  </td>
                  <td>
                    <b>{driver.ragScore}</b>
                  </td>
                </tr>
                <p></p>
              </>
            );
          })}
          {element.passengers.map((passanger: any) => {
            return (
              <>
                <tr>
                  <td className="td-color">{passanger.travellerType}</td>
                  <td>
                    <b>{passanger.name}</b>
                  </td>
                  <td>
                    <b>{passanger.phoneNumber}</b>
                  </td>
                  <td>
                    <b>{passanger.ragScore}</b>
                  </td>
                </tr>
                <p></p>
              </>
            );
          })}
        </table>
      </div>
    );
  }
}
