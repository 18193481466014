import React, { useEffect, useState } from "react";
import { IPretrip, IPretripDetailData, IPretripGetQuestCertifications, IServerResponse } from "Model/ServerResponse";
import "./PreTripHSEDetails.scss";
import { CommonService } from "Services/CommonService";
import { CircularProgress } from "@material-ui/core";
import { PRETRIP_DETAILS_TAB_ID } from "../../PreTripUtil";
import { HSEDetailPopup } from "./HSEDetailPopup";

export interface IPreTripHSEDetailsProp {
  pretripDetailData: IPretrip;
}


export function PreTripHSEDetails(props: IPreTripHSEDetailsProp) {
  let hseDetailData: IPretripDetailData = props.pretripDetailData?.tabs.find(td => td.tabId === PRETRIP_DETAILS_TAB_ID)?.data as IPretripDetailData;
  let [certification, setCertification] = useState();
  const [HSECountryOpen, setHSECountryOpen] = useState(false);
  const [HSERequirementOpen, setHSERequirementOpen] = useState(false);
  const [HSEInformationOpen, setHSEInformationOpen] = useState(false);

  const handleCIClick = () => {
    setHSECountryOpen(true);
  };

  const handleCIClose = () => {
    setHSECountryOpen(false);
  };

  const handleHRClick = () => {
    setHSERequirementOpen(true);
  };

  const handleHRClose = () => {
    setHSERequirementOpen(false);
  };

  const handleHEClick = () => {
    setHSEInformationOpen(true);
  };

  const handleHEClose = () => {
    setHSEInformationOpen(false);
  };
  
  useEffect(() => {
    if (props.pretripDetailData.requestType != "3rd Party Proxy") {
      getCertificationData();
    }
  }, [props?.pretripDetailData]);

  function getCertificationData() {
    let hseService = CommonService.getInstance();
    if (hseService) {
        hseService.getQuestCertificationData('onesitepass', 'travel', 'questcertifications', hseDetailData?.destinationLocation?.country, props.pretripDetailData?.requestorID, props.pretripDetailData?.toDate)
            .then((res: IServerResponse<any>) => {
                setCertification(res.payload);
            });
    } else {
        console.error("CommonService instance is undefined");
    }
}

  return (
    <>
      {getSection()}
    </>
  );


  function getSection(){
    return (
      <>
        <div className="pretrip-trip-hse-container">
          {getHSEHeaderUI()}
          {getHSECountry()}
          {getHSERequirement()}
          {getHSEInformation()}
          {getHSEApprovalRequired()}
          {props.pretripDetailData.requestType != "3rd Party Proxy" ?
            getHSEQuestTraining()
            : null}
        </div>
      </>
    );
  }

  function getHSEHeaderUI(){
    return (
      <>
        <div className="main-container">
          <div className="title-container">
            <div className="pretrip-section-title">
              <b>HSE</b>
              <div className="spacer"></div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function getHSECountry(){
    return (
      <>
        <div className="subtitle-container">
          <div className="key-value-list">
            <div className="key-value">
              <p className="key">Country Information</p>
              <div className="value load-Value" onClick={handleCIClick}>
                <strong>View Details</strong>
              </div>
            </div>
          </div>
        </div>
        <HSEDetailPopup
          hseDetailData={hseDetailData}
          headerLabel='Country Information'
          systemName='country'
          open={HSECountryOpen}
          handleClose={handleCIClose}
        />
      </>
    );
  }
  function getHSERequirement() {
    return (
      <>
        <div className="subtitle-container">
          <div className="key-value-list">
            <div className="key-value">
              <p className="key">HSE Requirement</p>
              <div className="value load-Value" onClick={handleHRClick}>
                <strong>View Details</strong>
              </div>
            </div>
          </div>
        </div>
        <HSEDetailPopup
          hseDetailData={hseDetailData}
          headerLabel='HSE Requirement'
          systemName='hserequirement'
          open={HSERequirementOpen}
          handleClose={handleHRClose}
        />
      </>
    );
  }

  function getHSEInformation(){
    return (
      <>
        <div className="subtitle-container">
          <div className="key-value-list">
            <div className="key-value">
              <p className="key">Health Exposure Instructions</p>
              <div className="value load-Value" onClick={handleHEClick}>
                <strong>View Details</strong>
              </div>
            </div>
          </div>
        </div>
        <HSEDetailPopup
          hseDetailData={hseDetailData}
          headerLabel='Health Exposure Instructions'
          systemName='health'
          open={HSEInformationOpen}
          handleClose={handleHEClose}
        />
      </>
    );
  }

  function getHSEApprovalRequired() {
    return (
      <>
        <div className="subtitle-container">
          <div className="key-value-list">
            <div className="key-value">
              <p className="key">HSE Approval Required</p>
              <div className="approval-text">
                <strong>{hseDetailData?.hseApprovalRequired ?? 'NA'}</strong>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function getHSEQuestTraining() {
    return (
      <>
        <div className="subtitle-container">
          <div className="key-value-list">
            <div className="key-value">
              <p className="key">HSE Quest Certification</p>
              <div className="value-cert">
                {certification ? getCertiifcationTable() :
                  <CircularProgress size="25px" />
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function getCertiifcationTable() {
    const certificationData: IPretripGetQuestCertifications = certification as unknown as IPretripGetQuestCertifications;
    return (
      <>
        {
          certificationData?.questCertifications ?
            <>
              <p className={certificationData?.isReady ? 'certi-Status certificate-valid' : 'certi-Status certificate-invalid'}>{certificationData?.isReady ? "Ready" : "Not Ready"}</p>
              <table className="certi-Table">
                <thead>
                  <th className="certi-column certi-header">Certification Name</th>
                  <th className="certi-column certi-header">Valid</th>
                  <th className="certi-column certi-header exp">Expiry Date</th>
                </thead>
                <tbody>
                  {certificationData?.questCertifications?.map((x: any) => {
                    return (
                      <tr key={x.certId}>
                        <td className="certi-column certi-name">{x.certName}</td>
                        <td className={x.isValid == 'No' ? 'certi-column certi-valid certificate-invalid ' : 'certi-column certi-valid certificate-valid'}>{x.isValid}</td>
                        <td className="certi-column certi-date">{x.expiredOn}</td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </table>
            </>
            : <>
              <p className='certi-Status certificate-valid'>Ready</p>
              <div className="no-record-quest">No quest certifications required.</div>
            </>
        }
      </>
    );
  }

}
