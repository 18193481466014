import { SearchFilterToolbar } from "Components2/SearchFilterToolbar/SearchFilterToolbar";
import { Message1 } from "Components2/MessageTemplates/Message1/Message1";
import React, { useEffect } from "react";
import { ListView } from "../../ListView/ListView";
import { SWPSCard } from "../SWPSCard/SWPSCard";
import { ISystemListViewBaseProp } from "Model/UI";
import { useSelector } from "react-redux";
import {
  IsAscSelector,
  MultiSelectedApprovalsSelector,
  PendingApprovalsSelector,
  SearchQuerySelector,
  SelectedApprovalSelector,
  SelectedSubSystemSelector,
  setIsAsc,
  setMultiSelectedApprovals,
  setFromPosition,
  setSearchQuery,
  setSelectedApproval,
  setSelectedSubSystem,
  SubSystemsSelector,
  TotalCountForFilterSelector,
  GetPendingApprovals,
  resetSystemSliceState,
  PendingApprovalsStatusSelector,
  PendingApprovalsErrorSelector,
  SupportMultiSelector,
  swpsSliceInitialState,
  IsTabletViewSelector,
} from "Store/SWPS/SWPSSlice";
import { ICart } from "Model/ServerResponse";
import "./SWPSListView.scss";
import { useAppDispatch } from "Store/store";
import { RequestStatus } from "Model/Enums";

export interface ISWPSListViewProp<T> extends ISystemListViewBaseProp<T> { }

export function SWPSListView(props: ISWPSListViewProp<ICart>) {
  let appDispatch = useAppDispatch();

  let supportMulti = useSelector(SupportMultiSelector);

  let pendingApprovalsStatus = useSelector(PendingApprovalsStatusSelector);
  let pendingApprovalsError = useSelector(PendingApprovalsErrorSelector);

  let selectedApproval = useSelector(SelectedApprovalSelector);
  let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);
  let subSystems = useSelector(SubSystemsSelector);
  let totalCountForFilter = useSelector(TotalCountForFilterSelector);
  let pendingApprovals = useSelector(PendingApprovalsSelector);

  let searchQuery = useSelector(SearchQuerySelector);
  let isAsc = useSelector(IsAscSelector);
  let selectedSubSystem = useSelector(SelectedSubSystemSelector);

  let isTabletView = useSelector(IsTabletViewSelector);

  useEffect(() => {
    appDispatch(resetSystemSliceState(swpsSliceInitialState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Set up data
  useEffect(() => {
    appDispatch(setSelectedApproval(null));
    appDispatch(GetPendingApprovals());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, isAsc, selectedSubSystem]);

  return (
    <div className="swps-list-container">
      <SearchFilterToolbar
        multiSelectedCount={multiSelectedApprovals?.length}
        title="Cart"
        error={pendingApprovalsStatus === RequestStatus.Failed}
        totalCount={totalCountForFilter}
        count={pendingApprovals?.length}
        subSystems={subSystems}
        loading={pendingApprovalsStatus === RequestStatus.Loading}
        onRefresh={handleRefresh}
        onSearch={handleSearchChange}
        onSort={handleSortChange}
        onSubSystemChange={handleSubSystemChange}
      ></SearchFilterToolbar>

      {listUI()}
    </div>
  );

  function getRowUI(data: ICart, multiSelectUI?){
    return (
      <SWPSCard
        supportMulti={supportMulti}
        multiSelectUI={multiSelectUI}
        swps={data}
      ></SWPSCard>
    );
  }

  function listUI(){

    if (pendingApprovalsStatus === RequestStatus.Failed) {
      return errorUI();
    }
    // else if (pendingApprovalsStatus === RequestStatus.Success && pendingApprovals.length === 0) {
    //   return EmptyIndicatorUI();
    // }

    return (
      <>
        <ListView
          className="swps-list"
          totalCount={totalCountForFilter}
          dataList={pendingApprovals}
          selectedData={selectedApproval}
          multiSelectedData={multiSelectedApprovals}
          loading={pendingApprovalsStatus === RequestStatus.Loading}
          supportMulti={supportMulti}
          getRowUI={getRowUI}
          getUniqueDataIdentifier={getUniqueDataIndentifier}
          onSelectChange={handleSelectChange}
          onMultiSelectChange={handleMultiSelectChange}
          onFromPositionChange={handleFromPositionChange}
          getEmptyIndicatorUI={emptyIndicatorUI}
          isTabletView={isTabletView}
        ></ListView>

        {multiSelectedApprovals?.length > 0 && actionContainer()}
      </>
    );
  }

  function emptyIndicatorUI(){
    return (
      <div className="message-container">
        <Message1 title="No approvals." imgSrc="CardAssets/NoPendingRequests.svg"></Message1>
      </div>
    )
  }

  function errorUI(){
    if (pendingApprovalsError) {
      return (
        <div className="message-container">
          <Message1 title={pendingApprovalsError.message}
            imgSrc="CardAssets/error.svg"
            subTitle={pendingApprovalsError.description}></Message1>
        </div>
      )
    }
    return <></>;
  }

  function handleRefresh(e: any) {
    //Reset all state
    appDispatch(resetSystemSliceState(swpsSliceInitialState));
    //Get approvals
    appDispatch(GetPendingApprovals());
  }

  function handleSearchChange(e: any, _query: string) {
    appDispatch(setSearchQuery(_query));
  }

  function handleSortChange(e: any, _isAsc: boolean) {
    appDispatch(setIsAsc(_isAsc));
  }

  function handleSubSystemChange(e: any, _subSystem: any) {
    appDispatch(setSelectedSubSystem(_subSystem));
  }

  function getUniqueDataIndentifier(_data: ICart): string {
    //Return unique identifier
    return _data.id.toString();
  }

  function handleFromPositionChange(_fromPostion: number): void {
    appDispatch(setFromPosition(_fromPostion));
    appDispatch(GetPendingApprovals());
  }

  function handleMultiSelectChange(e: any, _values: any) {
    appDispatch(setMultiSelectedApprovals(_values));
  }

  function handleSelectChange(e: any, _value: any) {
    appDispatch(setSelectedApproval(_value));
  }

  function actionContainer(){
    if (props.getActionsUI) {
      return (
        <div className="action-container">
          {props.getActionsUI(true, multiSelectedApprovals as ICart[])}
        </div>
      );
    }
    return <></>;
  }
}
