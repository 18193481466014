import {
    createSelector, createSlice,
} from "@reduxjs/toolkit";
import { RequestStatus } from "../../Model/Enums";
import { IAppState } from "../../Store/store";
import { getPendingApprovals1Thunk, getApprovalDetails1Thunk, postDeleteApprovals1Thunk, deleteApprovals1Thunk, systemSlice1Reducer, ISystemSlice1State } from "../../Store/SystemTemplate/SystemSlice1";

export const jraSliceInitialState: ISystemSlice1State = {
    pendingApprovalsStatus: RequestStatus.Idle,
    pendingApprovalsError: null,
    selectedApprovalDetailStatus: RequestStatus.Idle,
    selectedApprovalDetailError: null,
    supportMulti: true,
    selectedApproval: null,
    selectedApprovalDetails: null,
    multiSelectedApprovals: [],
    searchQuery: "",
    isAsc: false,
    selectedSubSystem: null,
    fromPosition: 0,
    subSystems: [],
    totalCountForFilter: 0,
    pendingApprovals: [],
    isTabletView: false,
};


const JRASlice = createSlice({
    name: "FDP",
    initialState: jraSliceInitialState,
    reducers: systemSlice1Reducer
});

export const GetPendingApprovals = getPendingApprovals1Thunk(jraSliceInitialState, JRASlice, "FDP", "FDP");

export const GetApprovalDetails = getApprovalDetails1Thunk(JRASlice, "FDP", "FDP");

export const PostDeleteApprovals = postDeleteApprovals1Thunk(JRASlice, "FDP");

export const DeleteApprovals = deleteApprovals1Thunk(JRASlice, "FDP", "FDP");

//Action
export const {
    resetSystemSliceState,
    setPendingApprovalsStatus,
    setSelectedApproval,
    setSelectedApprovalDetails,
    setMultiSelectedApprovals,
    setSearchQuery,
    setIsAsc,
    setSelectedSubSystem,
    setFromPosition,
    setSubSystems,
    setTotalCountForFilter,
    setPendingApprovals,
    addToPendingApprovals,
    reCalculateSelectedApproval,
    setIsTabletView,
} = JRASlice.actions;

//Selector
export const JRASelector = (state: IAppState) => state.FDP;
export const PendingApprovalsStatusSelector = createSelector(
    JRASelector,
    (details) => details?.pendingApprovalsStatus
);
export const PendingApprovalsErrorSelector = createSelector(
    JRASelector,
    (details) => details?.pendingApprovalsError
);
export const SelectedApprovalDetailStatusSelector = createSelector(
    JRASelector,
    (details) => details?.selectedApprovalDetailStatus
);
export const SelectedApprovalDetailErrorSelector = createSelector(
    JRASelector,
    (details) => details?.selectedApprovalDetailError
);
export const SupportMultiSelector = createSelector(
    JRASelector,
    (details) => details?.supportMulti
);
export const SelectedApprovalSelector = createSelector(
    JRASelector,
    (details) => details?.selectedApproval
);
export const SelectedApprovalDetailsSelector = createSelector(
    JRASelector,
    (details) => details?.selectedApprovalDetails
);
export const MultiSelectedApprovalsSelector = createSelector(
    JRASelector,
    (details) => details?.multiSelectedApprovals
);
export const SearchQuerySelector = createSelector(
    JRASelector,
    (details) => details?.searchQuery
);
export const IsAscSelector = createSelector(
    JRASelector,
    (details) => details?.isAsc
);
export const SelectedSubSystemSelector = createSelector(
    JRASelector,
    (details) => details?.selectedSubSystem
);
export const FromPositionSelector = createSelector(
    JRASelector,
    (details) => details?.fromPosition
);
export const SubSystemsSelector = createSelector(
    JRASelector,
    (details) => details?.subSystems
);
export const TotalCountForFilterSelector = createSelector(
    JRASelector,
    (details) => details?.totalCountForFilter
);
export const PendingApprovalsSelector = createSelector(
    JRASelector,
    (details) => details?.pendingApprovals
);
export const IsTabletViewSelector = createSelector(
    JRASelector,
    (details) => details?.isTabletView
  );



export const JRAReducer = JRASlice.reducer;

