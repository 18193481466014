import React, { useEffect, useState } from "react";
import { MsalProvider } from "@azure/msal-react";
import { Approvebuddy2 } from "Approvebuddy2";
import { msalInstance } from "./authProvider";
import "./index.scss";
import { HashRouter, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { createTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import * as store2 from "Store/store";
import { app, authentication } from "@microsoft/teams-js";
import { SessionStorageService, UserProfileService } from "Services";
import Utility from "common/utilities";
import { IServerResponse, IUserDetails } from "Model/ServerResponse";

const SplashScreen = () => {
  const [showDashboard, setShowDashboard] = useState<boolean>(false);
  const [message, setMessage] = useState<any>("");

  useEffect(() => {
    // Initialize the Microsoft Teams SDK
    app.initialize();

    authentication.getAuthToken()?.then((result) => {
      ssoLoginSuccess(result)
    }).catch((error) => {
      ssoLoginFailure(error)
    });

  }, []);

  const ssoLoginSuccess = async (result: string) => {
    console.log("Teams SSO Success");
    SessionStorageService.getInstance()!.IsTeamsApp = true;

    //Get the user context from Teams and set it in the state
    await app.getContext().then(async (context: app.Context) => {
      let userPrincipalName = context.user?.userPrincipalName as string;
      console.log("UserPrincipal: " +userPrincipalName);

      SessionStorageService.getInstance().TeamsUser = userPrincipalName;

      let userProfileService = UserProfileService.getInstance();
      await userProfileService.getUserDetails(userPrincipalName)
          .then((systemResponse: IServerResponse<IUserDetails>) => {
              if (systemResponse.statusCode === 200 && systemResponse.payload) {

                  if (!Utility.isStringEmpty(systemResponse.payload.name)) {
                      console.log("User Display Name: " +systemResponse.payload.name);
                      SessionStorageService.getInstance().TeamsUserDisplayName = systemResponse.payload.name;
                  }
              }
          })
          .catch((err: any) => {
            SessionStorageService.getInstance().TeamsUserDisplayName = userPrincipalName;
          });
    });

    ShowApproveBuddyDashboard();
  }
  const ssoLoginFailure = (error: any) => {
    console.error("Teams SSO failed: ");
    setMessage(error.message || 'An error occurred'); // Set string message
  }

  function ShowApproveBuddyDashboard() {
    setShowDashboard(true)
  }

  return !showDashboard ? (
    <p> Authenticating... {message} </p>
  ) : (
    <MsalProvider instance={msalInstance}>
      <Provider store={store2.default}>
        <React.StrictMode>
          <ThemeProvider theme={appTheme}>
            <CssBaseline />
            <HashRouter>
              <Route
                render={(_: any) => (
                  <>
                    <Approvebuddy2 InstallEvent={null}></Approvebuddy2>
                  </>
                )}
              />
            </HashRouter>
          </ThemeProvider>
        </React.StrictMode>
      </Provider>
    </MsalProvider>
  );
};

const appTheme = createTheme({
  props: {
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application!
    },
  },
  palette: {
    primary: {
      main: "#106ff9", //#0A81D5",
    },
    secondary: {
      main: "#106ff9",
    },
  },
});

export default SplashScreen;

