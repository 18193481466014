import { Badge, CircularProgress, IconButton } from "@material-ui/core";
import Utility from "common/utilities";
import { SidebarMenuItemState } from "Model/Enums";
import { IApprovalOperationEntity } from "Model/UI";
import React, { useEffect, useState, ReactNode } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import VariablesJson from "../../configVariables.json";
import {
  ApprovalOperationKeysSelector,
  ApprovalOperationKeysToShowInSidebarSelector,
  ApprovalOperationsMapSelector,
  ApprovalSystemsMapSelector,
  UserProfileResponseSelector,
} from "Store/UserSystemProfile/UserSystemSlice";
import {
  pretripSliceInitialState,
  resetSystemSliceState,
} from "Store/PreTrip/PreTripSlice";
import "./AppSidebar.scss";
import { useAppDispatch } from "Store/store";
import { SystemSelectionNav } from "../Modals/SystemSelectionNav/SystemSelectionNav";

export function AppSidebar() {
  //Local state
  let [pathname, setPathname] = useState(window.location.pathname);
  const [SystemSelectionNavOpen, setSystemSelectionNavOpen] = useState(false);
  //Hooks
  let windowLocation = useLocation();

  //Using only map from user System
  let approvalOperationKeys: any = useSelector(ApprovalOperationKeysSelector);
  let approvalOperationMaps: any = useSelector(ApprovalOperationsMapSelector);
  let approvalSystemMaps: any = useSelector(ApprovalSystemsMapSelector);
  let approvalOperationKeysToShowInSidebar: any = useSelector(
    ApprovalOperationKeysToShowInSidebarSelector
  );
  let appDispatch = useAppDispatch();
  let userProfileResponse = useSelector(UserProfileResponseSelector);
  const [isBotDialogOpen, setIsBotDialogOpen] = useState(false);

  let [menuListUI, setMenuListUI] = useState<ReactNode | null>(null);
  useEffect(() => {
    setMenuListUI(menuList());
  }, [
    approvalOperationKeysToShowInSidebar,
    approvalOperationKeys,
    approvalOperationMaps,
    approvalSystemMaps,
    pathname,
  ]);

  const [isIframeVisible, setIframeVisible] = useState(false);
  const [showAnimation, setShowAnimation] = useState(true);
  const wobbleClass = showAnimation && !isBotDialogOpen ? "" : "wobble-stop";

  const toggleIframeVisibility = () => {
    setIframeVisible((prevState) => !prevState);
  };

  const handleOpenBotDialog = (event: React.MouseEvent<HTMLElement>) => {
    setIsBotDialogOpen((prevState) => !prevState);
    setShowAnimation(false);
  };

  useEffect(() => {
    appDispatch(resetSystemSliceState(pretripSliceInitialState));
  }, [windowLocation.pathname]);

  useEffect(() => {
    setPathname(windowLocation.pathname);
  }, [windowLocation]);
  const handleSystemSelectionNavClick = () => {
    setSystemSelectionNavOpen(true);
  };

  const handleSystemSelectionNavClose = () => {
    setSystemSelectionNavOpen(false);
  };
  return (
    <div className="app-sidebar">
      <div className="menu">
        {menuListUI}
        <IconButton
          onClick={handleSystemSelectionNavClick}
          className="tempSystemSelection"
          aria-label="toggle-box"
        >
          <span className="iconStyle">...</span>
        </IconButton>
      </div>
      <div className="spacer"></div>
      <div className="branding">
        <div onClick={handleOpenBotDialog}>
          <img
            className={`bot-icon ${wobbleClass}`}
            src={
              isBotDialogOpen ? "./AppLoader/close.svg" : "./AppLoader/bot.svg"
            }
            alt=""
            onClick={toggleIframeVisibility}
            style={{ cursor: "pointer" }} // to indicate it's clickable
          />
        </div>
        <div>{getBotDialogUI()}</div>

        <p className="title">Powered by</p>
        <img className="branding-icon" src="./AppLoader/DPS_logo.svg" alt="" />
        <p className="version">{"V " + VariablesJson.Variables.appversion}</p>
      </div>
      {SystemSelectionNavOpen && (
        <SystemSelectionNav
          open={SystemSelectionNavOpen}
          onClose={handleSystemSelectionNavClose}
        />
      )}
    </div>
  );

  function menuList() {
    if (
      approvalOperationKeysToShowInSidebar.length === 0 ||
      approvalOperationKeys.length === 0 ||
      Object.keys(approvalOperationMaps).length === 0 ||
      Object.keys(approvalSystemMaps).length === 0
    ) {
      return null;
    }

    return (
      <>
        {approvalOperationKeysToShowInSidebar.map((aok) => {
          let m = approvalOperationMaps[aok];
          const isMenuActive = isMenuItemActive(aok);
          if (!m) {
            return null;
          }
          return (
            <Link
              key={aok}
              to={"/" + aok.toLowerCase()}
              className={Utility.mergeCssClassNames([
                "menu-item",
                isMenuItemActive(aok) ? "menu-item-selected" : "",
              ])}
            >
              <div className={"menu-item-selected-indicator"}></div>
              <div className={"menu-item-content"}>
                <Badge badgeContent={badgeUI(m, isMenuActive)} showZero>
                  <img
                    className="system-icon"
                    alt="icon"
                    src={`./DrawerMenuAssets/${aok}.svg`}
                  />
                </Badge>
                <p className="title">{m.description}</p>
              </div>
            </Link>
          );
        })}
      </>
    );
  }

  function getBotDialogUI()  {
    const userId = userProfileResponse?.graphDetails.mail ?? "";
    const filepath = VariablesJson.Variables.chatbot_storage_path;
    return (
      <div className="bot-dialog-ui">
        <iframe
          data-testid="chat-popup"
          title="chatbot"
          className={`chat-popup ${isIframeVisible ? "iframe-visible" : ""}`}
          src={`${filepath}?VarUserId=${encodeURIComponent(userId)}`}
        ></iframe>
      </div>
    );
  }

  function badgeUI(
    menuItem: IApprovalOperationEntity,
    isMenuactive: boolean
  )  {
    if (menuItem.state === SidebarMenuItemState.Loading) {
      return <CircularProgress className="loading" />;
    } else if (menuItem.state === SidebarMenuItemState.Error) {
      return (
        <img
          className="error"
          alt="error"
          src={`./DrawerMenuAssets/errorIcon.svg`}
        />
      );
    }

    return !isMenuactive ? (
      <p className="count">
        <span> {menuItem.count}</span>
      </p>
    ) : (
      <></>
    );
  }

  function isMenuItemActive(approvalOperationId: string): boolean {
    if (pathname.toLowerCase().includes(approvalOperationId.toLowerCase())) {
      return true;
    }
    return false;
  }
}
