import React from "react";
import {
  IDLPConsiderationDetail,
  IDLPDetailData,
  IDLPLoadFactor,
  IDLPRisk,
  IDLPTeamDetail,
  IDLPVerificationDetail,
} from "Model/ServerResponse";
import "./DLPDetailsRow.scss";
import { DLPLoadFactorDetails } from "../DLPLoadFactorDetails/DLPLoadFactorDetails";
import { DLPRiskAssementDetails } from "../DLPRiskAssessmentDetails/DLPRiskAssessmentDetails";
import { DLPMethodStatementDetails } from "../DLPMethodStatementDetails/DLPMethodStatementDetails";
import { DLPTeamDetails } from "../DLPTeamDetails/DLPTeamDetails";
import { DLPVerification } from "../DLPVerification/DLPVerification";
import { DLPConsideration } from "../DLPConsideration/DLPConsideration";

export interface IDLPDetailsRowProp {
  rowData: IDLPDetailData | undefined;
}

export function DLPDetailsRow(props: IDLPDetailsRowProp)  {
  let teamDetailsData = props.rowData?.teamDetails as IDLPTeamDetail;
  let verificationData = props.rowData?.verification as IDLPVerificationDetail;
  let considerationDetails = props.rowData?.considerationDetails as IDLPConsiderationDetail;
  let loadFactorData = props.rowData?.loadFactor as IDLPLoadFactor;
  let riskDetails = props.rowData?.risk as IDLPRisk;
  let methodStatementData = props.rowData?.methodStatement as IDLPRisk;
  // ?.methodStatement as IDLPMethodStatement;
  return (
    <>
      <DLPTeamDetails dlpTeamDetails={teamDetailsData} />
      <DLPVerification dlpVerificationDetail={verificationData} ></DLPVerification>
      <DLPConsideration dlpConsiderationDetail={considerationDetails} ></DLPConsideration>
      <DLPLoadFactorDetails dlpLoadFactorDetail={loadFactorData} />
      <DLPRiskAssementDetails dlpRiskDetail={riskDetails} />
      <DLPMethodStatementDetails dlpMethodStatementDetail={methodStatementData} />
    </>
  );
}


