import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { IDefaultUIProp } from "Model/UI";
import * as React from "react";
import "./ForwardSearchModal.scss";
import { useEffect, useState } from "react";
import { Autocomplete, AutocompleteChangeReason } from "@material-ui/lab";
import { CommonService } from "Services/CommonService";
import { IForwardApproverDetails } from "../../../Model/ServerResponse";
import { ForwardModal } from "../ForwardModal/ForwardModal";
import { msalInstance } from "authProvider";
import { SessionStorageService } from "Services";
import { CssTextField } from "../SwitchProfile/SwitchProfile";

interface PromiseWithCancel<T> extends Promise<T> {
  cancel: () => void;
}

export interface IForwardSearchModalProp extends IDefaultUIProp {
  onClose: (e: any) => void;
  maxCommentChar?: number;
  onForward?: (
    e: any,
    isMulti: boolean,
    comment: string,
    approver: string,
    approverName: string | undefined
  ) => void;
}

export function ForwardSearchModal(
  props: IForwardSearchModalProp
)  {
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [forwardApproverList, setForwardApproverList] = useState<
    IForwardApproverDetails[]
  >([]);

  const [selectedForwardApprover, setSelectedForwardApprover] =
    useState<IForwardApproverDetails>();

  const [noOptionsText, setNoOptionsText] = useState("");
  const [forwardApprovalFinal, setForwardApprovalFinal] = useState(false);
  const disabled = selectedForwardApprover === undefined || getLoggedInUserDisplayName() === selectedForwardApprover?.fullName;

  const handleForwardButton = () => {
    setForwardApprovalFinal(true);
  };

  function getLoggedInUserDisplayName() {
    if (SessionStorageService.getInstance().IsTeamsApp) {
      return SessionStorageService.getInstance().TeamsUserDisplayName;
    } else {
      return msalInstance.getActiveAccount()?.name;
    }
  }

  function isError(selectedForwardApprover: string | undefined): boolean {
    if (selectedForwardApprover === getLoggedInUserDisplayName()) {
      return true;
    }
    return false;
  }

  function fetchDataLogic(shouldFetchData, searchQuery, selectedForwardApprover, setSelectedForwardApprover, setIsLoading, setForwardApproverList, setNoOptionsText) {
    let promise;
  
    if (shouldFetchData) {
      if (selectedForwardApprover?.fullName !== searchQuery) {
        setSelectedForwardApprover(undefined);
      }
  
      promise = getForwardApproverData();
      promise.then((res) => {
        setIsLoading(false);
  
        if (res.statusCode === 200 && res.payload != null) {
          setForwardApproverList(res.payload);
          setNoOptionsText(res.payload.length === 0 ? "No Approvers Found" : "");
        } else {
          setNoOptionsText("Failed to fetch from server");
        }
      }).catch(() => {
        setIsLoading(false);
        setNoOptionsText("Loading failed!");
      });
    } else {
      setForwardApproverList([]);
    }
  
    return promise;
  }

  useEffect(() => {
    const shouldFetchData = searchQuery && searchQuery.length > 2;
    setIsLoading(shouldFetchData || false);
    setNoOptionsText(shouldFetchData ? "Loading" : "");

    const promise = fetchDataLogic(shouldFetchData, searchQuery, selectedForwardApprover, setSelectedForwardApprover, setIsLoading, setForwardApproverList, setNoOptionsText);

    return () => {
      if (promise && promise.cancel) {
        promise.cancel();
      }
    };
  }, [searchQuery, selectedForwardApprover, setIsLoading, setForwardApproverList, setNoOptionsText]);

  

  useEffect(() => {
    if (open) {
      if (selectedForwardApprover == undefined) {
        setForwardApproverList([]);
      }
    } else {
      setNoOptionsText("");
    }
  }, [open]);


  function getForwardApproverData() {
    let forwardService = CommonService.getInstance();

    const controller = new AbortController();
    const signal = controller.signal;
    const promise = new Promise<any>(async (resolve) => {
      const response = await forwardService.getForwardApprovers(
        "itt",
        "po",
        searchQuery,
        signal
      );
      resolve(response);
    });
    (promise as PromiseWithCancel<any>).cancel = () => controller.abort();
    return promise as PromiseWithCancel<any>;
  }
  return (

    <React.Fragment>
      <ForwardModal
        forwardApprover={
          selectedForwardApprover ? selectedForwardApprover.fullName : ""
        }
        open={forwardApprovalFinal}
        onCancel={(e: any) => {
          setForwardApprovalFinal(false);
          props.onClose(null);
        }}
        onForward={(e: any, comment: string) => {
          setForwardApprovalFinal(false);
          props.onClose(null);
          if (props.onForward) {
            props.onForward(e, false, comment, employeeNumber, selectedForwardApprover?.fullName);
          }
        }}
      />

      <Dialog
        open
        onClose={props.onClose}
        disableBackdropClick={true}
        className="forward-search-modal"
      >
        <div className="title-text1-container">
          <p className="titleText">Forward to</p>
          <div className="spacer"></div>
          <IconButton onClick={(e) => props.onClose(e)}>
            <CancelIcon className="closeButton"></CancelIcon>
          </IconButton>
        </div>

        <DialogContent className="forward-search-content">
          <Divider
            style={{ marginTop: "10px", width: "100%" }}
            variant="fullWidth"
          />
          <div className="title-text-container">
            <Autocomplete
              freeSolo={true}
              popupIcon={""}
              id="Search to Forward your request"
              open={open}
              onOpen={() => setOpen(true)}

              loading={isLoading}
              onClose={() =>
                setOpen(false)

              }
              options={forwardApproverList}
              filterOptions={(options) => {
                if (options.length == 0 && noOptionsText == "No Approvers Found") {
                  return [{ fullName: noOptionsText }] as any;
                }
                return options;
              }}

              getOptionDisabled={(option) => 
                (option).fullName === noOptionsText
              }
              
              getOptionLabel={(option) => 
                (option).fullName
              }
              
              getOptionSelected={(option, value) =>
                (option).employeeNumber === (value).employeeNumber
              }
              renderOption={(option) =>
                <>
                  <div>
                    <div style={{ display: "flex", flexDirection: "column" }} >
                      <span>{(option).fullName}</span>
                      <span>{(option).email}</span>
                    </div>
                  </div>
                </>
              }

              style={{ width: 300 }}
              ListboxProps={{
                style: {
                  maxHeight: 200,
                  overflow: "auto",
                },
              }}

              noOptionsText={noOptionsText}
              onInputChange={(e, value) => {
                setSearchQuery(value);
              }}

              onChange={(
                _event: React.ChangeEvent<{}>,
                value: string | IForwardApproverDetails | null,
                reason: AutocompleteChangeReason
              ) => {
                if (reason === "select-option") {
                  let mainValue = value as IForwardApproverDetails;
                  setSelectedForwardApprover(mainValue);
                  setEmployeeNumber(mainValue.employeeNumber);
                  setOpen(false);
                }
                else if (reason === "clear") {
                  setSelectedForwardApprover(undefined);
                  setOpen(false);

                }
              }}

              renderInput={(params) => (
                <CssTextField
                  {...params}
                  error={isError(selectedForwardApprover?.fullName)}
                  helperText={isError(selectedForwardApprover?.fullName) ? "You can't forward request to yourself" : ""}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                  label="Search Forward Approvers"
                  variant="outlined"
                />
              )}
            />

            <div className="spacer"></div>
            <div className="action-container">
              <Button
                className="refresh-btn"
                disabled={disabled}
                onClick={handleForwardButton}
              >
                Forward
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
