import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NewSystemModal } from "Components2/Modals/NewSystemModal/NewSystemModal";
import { IUpdateProfileResponseSystem } from "Model/ServerResponse/IUpdateProfileResponseSystem";
import { IApprovalOperationEntity } from "Model/UI";
import { IUpdateProfileRequest } from "Model/ServerResponse";
import { NewApprovalOperationKeysSelector, NewApprovalOperationMapsSelector, NewApprovalSystemMapsSelector } from "Store/UserSystemProfile/NewApprovalOperationSelectorSlice";
import { AddNewApprovalOperationAddedByUser, UpdateMapsWithSystemProfileResponse } from "Store/UserSystemProfile/UserSystemSlice";
import { useAppDispatch } from "Store/store";
import { SetScreenLoader } from "Store/Common/CommonSlice";
import { SystemSelectionService } from "Services";
import AlertDialog from "Components2/Alert/AlertDialog";

export function NewApprovalOperationsSelector(){

  let [content, setContent] = useState<React.ReactNode>(null);
  let appDispatch = useAppDispatch();

  const newApprovalOperationKeys = useSelector(
    NewApprovalOperationKeysSelector
  );

  const newApprovalOperationsMap = useSelector(
    NewApprovalOperationMapsSelector
  );

  const newApprovalSystemsMap = useSelector(
    NewApprovalSystemMapsSelector
  );

  useEffect(() => {
    //Start the sequence of UI creation from here
    if (newApprovalOperationKeys.length > 0 && newApprovalOperationsMap && newApprovalSystemsMap) {
      determineUI(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newApprovalOperationKeys, newApprovalOperationsMap, newApprovalSystemsMap]);

  //Main return
  return (
    <div data-testid="new-approval-operations-selector"className="new-approval-operations-selector">{content}</div>
  );

  function determineUI(index: number) {
    if (
      index < newApprovalOperationKeys.length
    ) {
      //Set content = new modal UI for index'th position
      setContent(newSystemModel(index));
    }
    else {
      setContent(null);
    }
  }

  function newSystemModel(index: number)  {
    const _naoMap = newApprovalOperationsMap;
    if (_naoMap && _naoMap[newApprovalOperationKeys[index]]) {
      return (
        <NewSystemModal
          data-testid="new-system-model"
          open={true}
          approvalOperationEntity={_naoMap[newApprovalOperationKeys[index]]}
          onAddIn={(e: any) => handleAddIn(e, index)}
          onSkip={(e: any) => handleSkip(e, index)}
        ></NewSystemModal>
      );
    }
    return <></>;
  }

  function handleAddIn(e: any, index: number) {
    if (!newApprovalOperationsMap) {
      return null;
    }

    //Send isSelected = true
    updateSystemProfile(newApprovalOperationsMap[newApprovalOperationKeys[index]], true).then(
      (_updateProfileResponseSystem: IUpdateProfileResponseSystem | null) => {
        if (_updateProfileResponseSystem) {
          //Show success/fail modal
          setContent(successFailModal(index, _updateProfileResponseSystem));

          //Add to list newApprovalOperationsAddedByUser in user profile slice
          appDispatch(AddNewApprovalOperationAddedByUser({ approvalOperationKeyAdded: newApprovalOperationKeys[index], approvalOperationsMap: newApprovalOperationsMap, approvalSystemsMap: newApprovalSystemsMap }));
        }
      }
    );
  }

  function handleSkip(e: any, index: number) {
    if (!newApprovalOperationsMap) {
      return null;
    }

    updateSystemProfile(newApprovalOperationsMap[newApprovalOperationKeys[index]], false).then(
      (_updateProfileResponseSystem: IUpdateProfileResponseSystem | null) => {
        if (_updateProfileResponseSystem) {
          //not required to show success/fail modal
          //show next new system modal
          determineUI(index + 1);
        }
      }
    );
  }

  function successFailModal(
    index: number,
    _updateProfileResponseSystem: IUpdateProfileResponseSystem | null
  ):React.ReactElement | null {

    if (!newApprovalOperationsMap) {
      return null;
    }

    if (_updateProfileResponseSystem) {
      //show Success modal UI
      return (<AlertDialog
        title={"Success"}
        content={<p>{`Added ${newApprovalOperationsMap[newApprovalOperationKeys[index]].description} successfully.`}</p>}
        okButtontext="OK"
        handleOkButton={(e: any) => handleCloseSuccessFailModal(e, index)}
      />);
    } else {
      //show Fail modal UI
      return (<AlertDialog
        title={"Failed"}
        content={<p>{`Failed adding ${newApprovalOperationsMap[newApprovalOperationKeys[index]].description}. Try again later.`}</p>}
        okButtontext="OK"
        handleOkButton={handleCloseSuccessFailModal}
      />);
    }
  }

  function handleCloseSuccessFailModal(e: any, index: number) {
    determineUI(index + 1);
  }

  async function updateSystemProfile(
    _approvalOperation: IApprovalOperationEntity,
    isAdded: boolean
  ): Promise<IUpdateProfileResponseSystem | null> {

    if (!newApprovalSystemsMap) {
      return null;
    }

    let approvalSystemsToUpdate: IUpdateProfileRequest[] = [];
    let approvalSystemKeys: string[] = _approvalOperation.approvalSystems;

    approvalSystemKeys.forEach(ask => {
      let approvalToUpdate: IUpdateProfileRequest = {
        activity: _approvalOperation.id,
        system: ask,
        isSelected: isAdded,
      };

      approvalSystemsToUpdate.push(approvalToUpdate);
    });

    appDispatch(SetScreenLoader(true));
    let systemSelectionService = SystemSelectionService.getInstance();
    let response = await systemSelectionService.updateUserProfile(approvalSystemsToUpdate);
    appDispatch(SetScreenLoader(false));

    if (response.payload) {

      //Add approval operations & systems from old/new arrays to master maps
      appDispatch(UpdateMapsWithSystemProfileResponse(response.payload));

      if (response.payload.systems.length > 0) {
        return response.payload.systems[0];
      }
      return response.payload.failedSystems[0];
    }
    return null;
  }
}

