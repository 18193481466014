import React from "react";

// It is important that we import local scss after Card1/2/3 so the styles are overidden in correct way

import { IKeyValue, ISystemCardBaseProp } from "../../../Model/UI";
import { Card7 } from "../../CardTemplates/Card7/Card7";
import "./SRMCard.scss";
import { ISRM } from "Model/ServerResponse/ISRM";
import Utility from "common/utilities";

export interface ISRMCardData extends ISystemCardBaseProp {
  srm: ISRM;
}

export function SRMCard(props: ISRMCardData) {
  let keyValues: IKeyValue[] = [
    { _key: "Items", value: props.srm.items?.toString(), seperator: ":" },
  ];

  function getCardUI() {
    return (
      <Card7
        label1={props.srm.approvalSystemId}
        label2={props.srm.title}
        label3={props.srm.date}
        chip1={""}
        keyValue2={keyValues}
        keyValue1={{
          _key: props.srm.totalAmount,
          value: props.srm.currency,
        }}
        userDetails={{
          userId: props.srm.requestorID,
          userCardName: props.srm.requestor,
          userOrganization: props.srm.plant,
        }}
        supportMulti={props.supportMulti}
        multiSelectUI={props.multiSelectUI}
        className={Utility.mergeCssClassNames(["srm-card"])}
      ></Card7>
    );
  }
  return getCardUI();
}
