import React from "react";
import { IDLP, IDLPTab } from "Model/ServerResponse";
import { ITabData } from "../../../Model/UI/ITabData";
import { DetailTabsComponent } from "../../DetailTabsComponent/DetailTabsComponent";
import { DLPDetails } from "../../DLP/DLPDetails/DLPDetails";
import { DLP_ATTACHMENTS_TAB_ID, DLP_DETAILS_TAB_ID } from "../DLPUtil";
import { DLPAttachment } from "../DLPAttachment/DLPAttachment";

export interface IDLPTabsProp {
    dlp: IDLP;
}

export function DLPTabs(props: IDLPTabsProp) {
    function getTabsUI()  {
        const data = getTabsData(props.dlp.tabs);
        return (
            <DetailTabsComponent tabs={data}></DetailTabsComponent>
        );
    }

    function getTabsData(tabs: IDLPTab[]): ITabData[] {
        let tdata: ITabData[] = [];

        tabs.forEach((tab: IDLPTab) => {
            let tabInfo: ITabData = {
                tabId: "",
                title: tab.title,
                imageSrc: "",
                count: tab.count,
            };

            //Switvh Tab By id
            switch (tab.tabId) {
                case DLP_DETAILS_TAB_ID:
                    tabInfo.tabId = DLP_DETAILS_TAB_ID; tabInfo.imageSrc = "./DetailTab/details_icn.svg"; tabInfo.getTabUI = ItemsUI;
                    break;
                case DLP_ATTACHMENTS_TAB_ID:
                    tabInfo.tabId = DLP_DETAILS_TAB_ID; tabInfo.imageSrc = "./DetailTab/attachment_icn.svg"; tabInfo.getTabUI = AttachmentsUI;
                    break;
                default:
                    break;
            }
            tdata.push(tabInfo);
        });
        return tdata;
    }

    function ItemsUI()  {
        let _dlpData = props.dlp.tabs.find(td => td.tabId === DLP_DETAILS_TAB_ID)
        return (
            <DLPDetails dlpDetailsTab={_dlpData}></DLPDetails>
        )
    }

    function AttachmentsUI()  {
        return (
            <DLPAttachment dlp={props.dlp} ></DLPAttachment>
        )
    }

    return getTabsUI();
}